import { AnyAction } from 'redux';
import {
  communityHandleError,
  GET_NOTIFICATIONS,
  HANDLE_COMMUNITY_ERROR,
  MARKED_NOTIFICATION,
  notificationType,
  REFRESH_COMMUNITY,
  SELECT_ALL_INTEGRATION_CHANNELS,
  SELECT_ALL_PRIVATE_INTEGRATION_CHANNELS,
  SELECT_ALL_PUBLIC_INTEGRATION_CHANNELS,
  SELECT_COMMUNITY,
  SET_INTEGRATION_CHANNEL_CONFIG,
  SET_NOTIFICATIONS_LOADING,
  SHOW_DATA_LOADING_BANNER,
  TOOGLE_INTEGRATION_CHANNEL_STATUS,
  UNSELECT_ALL_INTEGRATION_CHANNELS,
  UPDATE_COMMUNITY_NOTIFICATIONS_COUNT,
  SET_DISCOURSE_INTEGRATION_LOADING,
  GET_DISCOURSE_INTEGRATION_SECRET,
  GET_DISCOURSE_INTEGRATION_URL,
  SET_COMMUNITY_PLATFORM_DETAILS,
  SET_SELECTED_FILTERS,
  INDIVISUALCHANNELLOAD,
  SET_MEMBER_COUNT,
  SET_LAST_SYNC_TIME,
  RESET_INDIVISUALCHANNELLOAD,
  SET_DATA_LOAD_NOTIFICATION,
  RESET_DATA_LOAD_NOTIFICATION,
  GET_DISCORD_ONBOARDING_ORGANIZATION_DETAILS,
  SET_DISCORD_CUSTOM_BOT,
  SET_COMMUNITY_DETAILS,
  UPDATE_DATE_FILTER,
  SET_COMMUNITY_DATA_HUBSPOT_SYNC,
  SET_SIGNUP_COMMUNITY_DETAILS,
  SET_SLACK_CUSTOM_BOT,
  SET_AI_MODEL_SETTINGS,
  GET_AI_MODELS_LIST,
  SET_EMBEDDING_AI_MODEL_SETTINGS,
  GET_EMBEDDING_AI_MODELS_LIST,
  GET_AI_REASONING_MODELS_LIST,
  SET_AI_REASONING_MODEL_SETTINGS,
} from '../types';

const initialState = {
  communityDetail: undefined,
  notificationsCount: 0,
  communityErrors: undefined,
  notifications: {
    content: [],
    page: 0,
    size: 0,
    totalElements: -1,
    totalPages: -1,
    last: true,
  },
  notificationsLoading: false,
  isDataLoadingBannerVisible: false,
  integrationChannelConfigs: {},
  discourseIntegrationSecret: '',
  discourseIntegrationUrl: '',
  discourseIntegrationLoading: false,
  searchFilters: [],
  channelLoadStatus: {},
  channelUpdates: [],
  memberCount: [],
  dataLoadNotification: {},
  discordOrganization: {},
  customBot: {},
  communityDetails: {},
  communityPlatform: [],
  dateFilter: {},
  hubspotSyncDetails: {},
  signupCommunityDetails: {},
  slackCustomBot: {},
  aiModelSettings: {},
  aiModelsList: [],
  embeddingAiModelSettings: {},
  embeddingAiModelsList: [],
  aiReasoningModelSettings: {},
  aiReasoningModelsList: [],
};

const communityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MEMBER_COUNT:
      return {
        ...state,
        memberCount: payload,
      };
    case INDIVISUALCHANNELLOAD:
      return {
        ...state,
        channelUpdates: [payload],
      };
    case RESET_INDIVISUALCHANNELLOAD:
      return {
        ...state,
        channelUpdates: [],
      };
    case UPDATE_COMMUNITY_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: payload,
      };
    case UPDATE_DATE_FILTER:
      return {
        ...state,
        dateFilter: payload,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
        notificationsLoading: false,
      };
    case MARKED_NOTIFICATION:
      return {
        ...state,
        notifications:
          payload.type === 'markedAll'
            ? {
                ...state.notifications,
                totalElements: state.notifications.totalElements - 1,
                content: [],
              }
            : {
                ...state.notifications,
                totalElements: state.notifications.totalElements - 1,
                content: state.notifications.content.filter(
                  (notification) => notification.id !== payload.notificationId,
                ),
              },
        notificationsCount: payload.type === 'markedAll' ? 0 : state.notificationsCount - 1,
        notificationsLoading: false,
      };
    case SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationsLoading: true,
      };
    case HANDLE_COMMUNITY_ERROR:
      return {
        ...state,
        communityErrors: payload,
        notificationsLoading: false,
      };
    case REFRESH_COMMUNITY:
      // eslint-disable-next-line no-case-declarations
      let newCommunityDetail = null;
      if (payload != null && state.communityDetail != null) {
        newCommunityDetail = (payload.communities || []).find(
          (c) => c.id === state.communityDetail?.id,
        );
      }
      return {
        ...state,
        communityDetail: newCommunityDetail,
      };
    case SELECT_COMMUNITY:
      return {
        ...state,
        communityDetail: payload,
      };
    case SET_LAST_SYNC_TIME:
      return {
        ...state,
        lastSyncTime: payload,
      };

    case SET_SELECTED_FILTERS:
      return {
        ...state,
        searchFilters: payload,
      };
    case SHOW_DATA_LOADING_BANNER:
      return {
        ...state,
        isDataLoadingBannerVisible: payload,
      };
    case SET_INTEGRATION_CHANNEL_CONFIG:
      return {
        ...state,
        integrationChannelConfigs: {
          ...state.integrationChannelConfigs,
          [payload.integrationId]: payload.configs,
        },
      };
    case TOOGLE_INTEGRATION_CHANNEL_STATUS:
      return {
        ...state,
        integrationChannelConfigs: {
          ...state.integrationChannelConfigs,
          [payload.integrationId]: state.integrationChannelConfigs[payload.integrationId].map(
            (config) => {
              if (config.channelExternalId == payload.channelExternalId) {
                return { ...config, isChannelEnabled: !!payload.integrationStatus };
              } else {
                return config;
              }
            },
          ),
        },
      };
    case SELECT_ALL_INTEGRATION_CHANNELS:
      return {
        ...state,
        integrationChannelConfigs: {
          ...state.integrationChannelConfigs,
          [payload.integrationId]: state.integrationChannelConfigs[payload.integrationId].map(
            (config) => {
              return { ...config, isChannelEnabled: true };
            },
          ),
        },
      };
    case SELECT_ALL_PRIVATE_INTEGRATION_CHANNELS:
      return {
        ...state,
        integrationChannelConfigs: {
          ...state.integrationChannelConfigs,
          [payload.integrationId]: state.integrationChannelConfigs[payload.integrationId].map(
            (config) => {
              if (!config.isPrivate) return config;
              return { ...config, isChannelEnabled: true };
            },
          ),
        },
      };
    case SELECT_ALL_PUBLIC_INTEGRATION_CHANNELS:
      return {
        ...state,
        integrationChannelConfigs: {
          ...state.integrationChannelConfigs,
          [payload.integrationId]: state.integrationChannelConfigs[payload.integrationId].map(
            (config) => {
              if (config.isPrivate) return config;
              return { ...config, isChannelEnabled: true };
            },
          ),
        },
      };
    case UNSELECT_ALL_INTEGRATION_CHANNELS:
      return {
        ...state,
        integrationChannelConfigs: {
          ...state.integrationChannelConfigs,
          [payload.integrationId]: state.integrationChannelConfigs[payload.integrationId].map(
            (config) => {
              return { ...config, isChannelEnabled: false };
            },
          ),
        },
      };
    case SET_DISCOURSE_INTEGRATION_LOADING:
      return {
        ...state,
        discourseIntegrationLoading: true,
      };
    case GET_DISCOURSE_INTEGRATION_SECRET:
      return {
        ...state,
        discourseIntegrationSecret: payload,
        discourseIntegrationLoading: false,
      };
    case GET_DISCOURSE_INTEGRATION_URL:
      return {
        ...state,
        discourseIntegrationUrl: payload,
        discourseIntegrationLoading: false,
      };
    case SET_COMMUNITY_PLATFORM_DETAILS:
      return {
        ...state,
        communityPlatform: payload,
      };
    case SET_DATA_LOAD_NOTIFICATION:
      return {
        ...state,
        dataLoadNotification: { ...state.dataLoadNotification, ...payload },
      };
    case RESET_DATA_LOAD_NOTIFICATION:
      return {
        ...state,
        dataLoadNotification: {},
      };
    case GET_DISCORD_ONBOARDING_ORGANIZATION_DETAILS:
      return {
        ...state,
        discordOrganization: payload,
      };
    case SET_DISCORD_CUSTOM_BOT:
      return {
        ...state,
        customBot: payload,
      };
    case SET_SLACK_CUSTOM_BOT:
      return {
        ...state,
        slackCustomBot: payload,
      };
    case SET_COMMUNITY_DETAILS:
      return {
        ...state,
        communityDetails: payload,
      };
    case SET_SIGNUP_COMMUNITY_DETAILS:
      return {
        ...state,
        signupCommunityDetails: payload,
      };
    case SET_COMMUNITY_DATA_HUBSPOT_SYNC:
      return {
        ...state,
        hubspotSyncDetails: payload,
      };
    case SET_AI_MODEL_SETTINGS:
      return {
        ...state,
        aiModelSettings: payload,
      };
    case GET_AI_MODELS_LIST:
      return {
        ...state,
        aiModelsList: payload,
      };
    case SET_EMBEDDING_AI_MODEL_SETTINGS:
      return {
        ...state,
        embeddingAiModelSettings: payload,
      };
    case GET_EMBEDDING_AI_MODELS_LIST:
      return {
        ...state,
        embeddingAiModelsList: payload,
      };
    case SET_AI_REASONING_MODEL_SETTINGS:
      return {
        ...state,
        aiReasoningModelSettings: payload,
      };
    case GET_AI_REASONING_MODELS_LIST:
      return {
        ...state,
        aiReasoningModelsList: payload,
      };
    default:
      return state;
  }
};

export default communityReducer;
