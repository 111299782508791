import Api from 'layers/interceptor/interceptor';
import { Dispatch } from 'redux';
import {
  getCommunityAllNotificationReadApiUrl,
  getCommunityLoadSegmentsApiUrl,
  getCommunityNotificationCountApiUrl,
  getCommunityNotificationListApiUrl,
  getCommunityOneNotificationReadApiUrl,
  getDeleteCommunityApiUrl,
  getDeleteIntegrationApiUrl,
  getIntegrationChannelConfigsUrl,
  getIntegrationLoadMessageApiUrl,
  getIntegrationLoadReactionsApiUrl,
  getIntegrationMembersAndChannelsApiUrl,
  getSaveCommunityApiUrl,
  getSaveOrganisationApiUrl,
  getDiscourseIntegrationSecret,
  getDiscourseIntegrationUrl,
  discourseIntegrationPostApiUrl,
  getCriteria,
  getMemberCountByCommunityId,
  getLastSyncDetails,
  getDiscordOnboardingFormURL,
  slackConnectUrl,
  discordConnectUrl,
  getConnectUsersByProvider,
  discordCustomBotUrl,
  discordCustomBotStep2,
  discordCustomBotStep1,
  discordInteractionUrl,
  getPlatformDetailUrl,
  getUserIdentifyTrackUrl,
  updateSaveCommunityApiUrl,
  getCommunityDetails,
  getDeleteIntegrationInternalApiUrl,
  getTimezoneListApiUrl,
  updateCommunityPreferenceApiUrl,
  getCommunityPreferenceApiUrl,
  slackCustomBotUrl,
  postSlackCustomBotDataUrl,
  refreshSlackCustomBotUrl,
  freshdeskIntegrationPostApiUrl,
  saveWorkSpaceUrl,
  getAiModelSettingsApiUrl,
  getAiModelsListApiUrl,
  saveAiModelApiUrl,
  deleteAiModelAccountApiUrl,
  selectAiModelChangeApiUrl,
  saveEditModelApiUrl,
  getEmbeddingAiModelSettingsApiUrl,
  getEmbeddingAiModelsListApiUrl,
  saveEmbeddingAiModelApiUrl,
  saveEditEmbeddingAiModelApiUrl,
  deleteEmbeddingAiModelAccountApiUrl,
  selectEmbeddingAiModelChangeApiUrl
} from 'constant/apiConstants';
import { pageSize } from 'constant/paginationConstants';
import {
  GET_NOTIFICATIONS,
  HANDLE_COMMUNITY_ERROR,
  MARKED_NOTIFICATION,
  REFRESH_COMMUNITY,
  SELECT_ALL_INTEGRATION_CHANNELS,
  SELECT_ALL_PRIVATE_INTEGRATION_CHANNELS,
  SELECT_ALL_PUBLIC_INTEGRATION_CHANNELS,
  SELECT_COMMUNITY,
  SET_INTEGRATION_CHANNEL_CONFIG,
  SET_NOTIFICATIONS_LOADING,
  SHOW_DATA_LOADING_BANNER,
  TOOGLE_INTEGRATION_CHANNEL_STATUS,
  UNSELECT_ALL_INTEGRATION_CHANNELS,
  UPDATE_COMMUNITY_NOTIFICATIONS_COUNT,
  GET_DISCOURSE_INTEGRATION_SECRET,
  GET_DISCOURSE_INTEGRATION_URL,
  SET_DISCOURSE_INTEGRATION_LOADING,
  SET_SELECTED_FILTERS,
  INDIVISUALCHANNELLOAD,
  RESET_INDIVISUALCHANNELLOAD,
  SET_MEMBER_COUNT,
  SET_LAST_SYNC_TIME,
  SET_DATA_LOAD_NOTIFICATION,
  RESET_DATA_LOAD_NOTIFICATION,
  GET_DISCORD_ONBOARDING_ORGANIZATION_DETAILS,
  SET_DISCORD_CUSTOM_BOT,
  SET_COMMUNITY_PLATFORM_DETAILS,
  SET_COMMUNITY_DETAILS,
  UPDATE_DATE_FILTER,
  SET_COMMUNITY_DATA_HUBSPOT_SYNC,
  SET_SIGNUP_COMMUNITY_DETAILS,
  SET_SLACK_CUSTOM_BOT,
  SET_AI_MODEL_SETTINGS,
  GET_AI_MODELS_LIST,
  GET_EMBEDDING_AI_MODELS_LIST,
  SET_EMBEDDING_AI_MODEL_SETTINGS,
  SET_AI_REASONING_MODEL_SETTINGS,
  GET_AI_REASONING_MODELS_LIST,
} from '../types';
import { AddToast } from 'react-toast-notifications';

import { DISCORD_CONNECT_TO_SERVER } from '../../../constant/urlConstants';
import { removeBotsFromConnectedUser } from '../utils';
import { loadCommunityIdFromLocalStorage } from '../../../utils/segment';

const saveCommunityIdInLocalStorage = (communityWithUserIdString) => {
  localStorage.setItem('selectedCommunity', communityWithUserIdString);
};

export const toggleChannelStatus =
  (channelExternalId, integrationId, integrationStatus) => (dispatch) => {
    dispatch({
      type: TOOGLE_INTEGRATION_CHANNEL_STATUS,
      payload: {
        integrationId,
        channelExternalId: channelExternalId,
        integrationStatus: integrationStatus,
      },
    });
  };

export const selectAllChannels = (integrationId) => (dispatch) => {
  dispatch({
    type: SELECT_ALL_INTEGRATION_CHANNELS,
    payload: { integrationId },
  });
};

export const selectAllPrivateChannels = (integrationId) => (dispatch) => {
  dispatch({
    type: SELECT_ALL_PRIVATE_INTEGRATION_CHANNELS,
    payload: { integrationId },
  });
};

export const selectAllPublicChannels = (integrationId) => (dispatch) => {
  dispatch({
    type: SELECT_ALL_PUBLIC_INTEGRATION_CHANNELS,
    payload: { integrationId },
  });
};
export const unselectAllChannels = (integrationId) => (dispatch) => {
  dispatch({
    type: UNSELECT_ALL_INTEGRATION_CHANNELS,
    payload: { integrationId },
  });
};

export const loadIntegrationChannelConfigsAction = (integrationId) => (dispatch) => {
  Api.get(getIntegrationChannelConfigsUrl(integrationId)).then((res) => {
    // @ts-ignore
    const responseWithId = res.map((iter, index) => {
      return {
        ...iter,
        id: index,
      };
    });
    dispatch({
      type: SET_INTEGRATION_CHANNEL_CONFIG,
      payload: {
        integrationId,
        configs: responseWithId,
      },
    });
  });
};

export const indivisualChannelUpdater = (message) => (dispatch) => {
  dispatch({
    type: INDIVISUALCHANNELLOAD,
    payload: message,
  });
};

export const hubspotSyncProcessEvent = (message) => (dispatch) => {
  dispatch({
    type: SET_COMMUNITY_DATA_HUBSPOT_SYNC,
    payload: message,
  });
};

export const resetIndividualChannalLoad = () => (dispatch) => {
  dispatch({
    type: RESET_INDIVISUALCHANNELLOAD,
  });
};

export const saveIntegrationPreferencesAction =
  (integrationId, addToast, integratedChannel, actionType, onlyToggleChannelList) =>
    (dispatch, getState) => {
      const { communityReducer } = getState();
      if (!communityReducer) {
        addToast('Unable to update the channel configuration', { appearance: 'error' });
        return;
      }

      const { integrationChannelConfigs } = communityReducer;
      if (
        !integrationChannelConfigs ||
        !(integrationId in integrationChannelConfigs) ||
        integrationChannelConfigs[integrationId].length == 0
      ) {
        addToast('Unable to update the channel configuration', { appearance: 'error' });
        return;
      }
      let integratedChannelSingle;
      if (actionType === 'add') {
        integratedChannelSingle = [
          {
            channelExternalId: integratedChannel.channelExternalId,
            isChannelEnabled: true,
          },
        ];
      } else {
        integratedChannelSingle = [
          {
            channelExternalId: integratedChannel.channelExternalId,
            isChannelEnabled: false,
          },
        ];
      }
      const channelData =
        onlyToggleChannelList?.length > 0 ? onlyToggleChannelList : integratedChannelSingle;

      Api.post(getIntegrationChannelConfigsUrl(integrationId), channelData).then(() => {
        if (actionType === 'add') {
          if (integratedChannel?.channelName) {
            addToast(`Data Load has been initiated for ${integratedChannel.channelName}`, {
              appearance: 'success',
            });
          } else {
            addToast(`Data Load has been initiated`, {
              appearance: 'success',
            });
          }
        } else {
          if (integratedChannel?.channelName) {
            addToast(`Data removal for ${integratedChannel.channelName} has been initiated`, {
              appearance: 'success',
            });
          } else {
            addToast(`Data removal has been initiated`, {
              appearance: 'success',
            });
          }
        }
        loadIntegrationChannelConfigsAction(integrationId)(dispatch);
      });
    };

export const switchWorkspacePostAction = (workspace) => {
  return Api.post(saveWorkSpaceUrl(), {
    workspaceId: workspace.id,
  });
};

export const selectCommunityAction =
  (community, userInfo, skipReload = false) =>
    async (dispatch) => {
      if (community) {
        const communityConfig = loadCommunityIdFromLocalStorage();
        saveCommunityIdInLocalStorage(
          JSON.stringify({
            userId: userInfo?.id,
            communityId: community.id,
            communityName: community.name,
            orgId: community?.org?.id,
            orgName: community?.org?.name,
            email: userInfo?.email,
          }),
        );
        if (communityConfig?.orgId !== community?.org?.id && !skipReload) {
          window?.location?.reload();
        }
        try {
          Api.post(getUserIdentifyTrackUrl(), {
            userId: userInfo?.id,
            communityId: community.id,
          });
        } catch { }
      } else {
        saveCommunityIdInLocalStorage(null);
      }

      await dispatch({
        type: SELECT_COMMUNITY,
        payload: community,
      });

      let notificationsCount = 0;
      if (community) {
        try {
          notificationsCount = Number(
            await Api.get(getCommunityNotificationCountApiUrl(community.id)),
          );
        } catch {
          console.error(`Can not load notifications count for community "${community.name}"`);
        }
      }

      dispatch({
        type: UPDATE_COMMUNITY_NOTIFICATIONS_COUNT,
        payload: notificationsCount,
      });
    };

export const setCommunityAction = (community) => (dispatch) => {
  dispatch({
    type: SELECT_COMMUNITY,
    payload: community,
  });
};

export const refreshSelectedCommunityAction = (userInfo) => (dispatch) => {
  dispatch({
    type: REFRESH_COMMUNITY,
    payload: userInfo,
  });
};

export const updateCommunityNotificationsCountAction = (count) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMMUNITY_NOTIFICATIONS_COUNT,
    payload: count,
  });
};

export const loadCommunityNotificationsCountAction = (communityId) => async (dispatch) => {
  const notificationsCount = await Api.get(getCommunityNotificationCountApiUrl(communityId));
  dispatch({
    type: UPDATE_COMMUNITY_NOTIFICATIONS_COUNT,
    payload: notificationsCount,
  });
  return notificationsCount;
};

export const markOneCommunityNotificationReadAction = (notificationId) => (dispatch) => {
  dispatch(setNotificationIsLoading());
  Api.post(getCommunityOneNotificationReadApiUrl(notificationId))
    .then(() => {
      dispatch(markNotifications('markOne', notificationId));
    })
    .catch(() =>
      dispatch(handleCommunityError('Error came while marking notification as read', 'error')),
    );
};

export const markAllCommunityNotificationsReadAction = (communityId) => (dispatch) => {
  dispatch(setNotificationIsLoading());
  Api.post(getCommunityAllNotificationReadApiUrl(communityId))
    .then(() => {
      dispatch(markNotifications('markedAll'));
    })
    .catch(() =>
      dispatch(handleCommunityError('Error came while marking all notifications as read', 'error')),
    );
};

export const loadCommunityNotificationsAction =
  (communityId, page, size = pageSize) =>
    (dispatch) => {
      dispatch(setNotificationIsLoading());
      Api.get(getCommunityNotificationListApiUrl(communityId, page, size))
        //eslint-disable-next-line
        .then((res) => dispatch({ type: GET_NOTIFICATIONS, payload: res }))
        .catch(() =>
          dispatch(handleCommunityError('Error came while fetching notifications', 'error')),
        );
    };

export const getMembersCountByCommunityId = (communityId) => (dispatch) => {
  dispatch(setNotificationIsLoading());
  Api.get(getMemberCountByCommunityId(communityId))
    //eslint-disable-next-line
    .then((res) => dispatch({ type: SET_MEMBER_COUNT, payload: res }))
    .catch(() => dispatch(handleCommunityError('Error came while fetching members', 'error')));
};

export const setNotificationIsLoading = () => ({
  type: SET_NOTIFICATIONS_LOADING,
});

export const handleCommunityError = (message, type) => ({
  type: HANDLE_COMMUNITY_ERROR,
  payload: { message, type },
});

export const markNotifications = (type, notificationId) => ({
  type: MARKED_NOTIFICATION,
  payload: { type, notificationId },
});

export const resetCommunityError = () => ({
  type: HANDLE_COMMUNITY_ERROR,
  payload: undefined,
});

export const setDataLoadNotification = (payload) => (dispatch) => {
  dispatch({
    type: SET_DATA_LOAD_NOTIFICATION,
    payload: payload,
  });
};
export const resetDataLoadNotification = (payload) => (dispatch) => {
  dispatch({
    type: RESET_DATA_LOAD_NOTIFICATION,
    payload: payload,
  });
};

export const deleteIntegrationAction = (integrationId) => async () => {
  return await Api.delete(getDeleteIntegrationApiUrl(integrationId));
};

export const deleteIntegrationInternalAction = (integrationId) => async () => {
  return await Api.delete(getDeleteIntegrationInternalApiUrl(integrationId));
};

export const deleteCommunityAction = (communityId) => async () => {
  return await Api.delete(getDeleteCommunityApiUrl(communityId));
};

export const saveCommunityAction = (values) => async () => {
  return await Api.post(getSaveCommunityApiUrl(), values);
};

export const updateCommunityAction = (values) => async () => {
  return await Api.post(updateSaveCommunityApiUrl(), values);
};

// Create organisation
export const saveCreateOrganisationAction = (userId, values) => async () => {
  return await Api.post(getSaveOrganisationApiUrl(userId), values);
};

export const loadMembersAndChannelsAction = (integrationId) => async () => {
  return await Api.get(getIntegrationMembersAndChannelsApiUrl(integrationId));
};

export const loadMessagesAction = (integrationId) => async () => {
  return await Api.get(getIntegrationLoadMessageApiUrl(integrationId));
};

export const loadReactionsAction = (integrationId) => async () => {
  return await Api.get(getIntegrationLoadReactionsApiUrl(integrationId));
};

export const loadSegments = (communityId) => async () => {
  return await Api.get(getCommunityLoadSegmentsApiUrl(communityId));
};

export const dataLoadingBannerAction = (isBannerVisible) => ({
  type: SHOW_DATA_LOADING_BANNER,
  payload: isBannerVisible,
});

export const getDiscourseIntegrationSecretAction = (communityId) => (dispatch) => {
  dispatch(setDiscourseIntegrationLoading());
  Api.get(getDiscourseIntegrationSecret(communityId))
    //eslint-disable-next-line
    .then((res) => dispatch({ type: GET_DISCOURSE_INTEGRATION_SECRET, payload: res }))
    .catch(() =>
      dispatch(
        handleCommunityError('Error came while fetching discourse integration secret', 'error'),
      ),
    );
};

export const getDiscourseIntegrationUrlAction = (communityId, domain) => (dispatch) => {
  dispatch(setDiscourseIntegrationLoading());
  Api.get(getDiscourseIntegrationUrl(communityId, domain))
    //eslint-disable-next-line
    .then((res) => dispatch({ type: GET_DISCOURSE_INTEGRATION_URL, payload: res }))
    .catch(() =>
      dispatch(
        handleCommunityError('Error came while fetching discourse integration url', 'error'),
      ),
    );
};

export const getCommunityPlatformDetails = () => (dispatch) => {
  Api.get(getPlatformDetailUrl())
    //eslint-disable-next-line
    .then((res) => dispatch({ type: SET_COMMUNITY_PLATFORM_DETAILS, payload: res }))
    .catch(() =>
      dispatch(
        handleCommunityError('Error came while fetching Community Platform Details', 'error'),
      ),
    );
};
export const getSavedFilters = (communityId) => (dispatch) => {
  Api.get(getCriteria(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({ type: SET_SELECTED_FILTERS, payload: res });
    })
    .catch(() =>
      dispatch(handleCommunityError('Error came while fetching saved filters', 'error')),
    );
};

export const setDiscourseIntegrationLoading = () => ({
  type: SET_DISCOURSE_INTEGRATION_LOADING,
});

export const discourseIntegrationPostAction = (values, communityId) => async () => {
  return await Api.post(discourseIntegrationPostApiUrl(communityId), values);
};

export const freshdeskIntegrationPostAction = (values, communityId) => async () => {
  return await Api.post(freshdeskIntegrationPostApiUrl(communityId), values);
};

export const getLastSyncDetailsAction = (communityId, addToast) => (dispatch) => {
  Api.get(getLastSyncDetails(communityId))
    .then((res) => {
      dispatch({ type: SET_LAST_SYNC_TIME, payload: res });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const getDiscordOnboardingForm =
  (communityId, setFieldValue, setFormColor, setOrgName, setLogo, addToast) => (dispatch) => {
    Api.get(getDiscordOnboardingFormURL(communityId))
      .then((res) => {
        setFieldValue('tncUrl', res?.onBoardingForm?.tncUrl);
        setFormColor(res?.onBoardingForm?.buttonColor || '');
        setOrgName(res?.organization.name);
        setLogo(res?.organization?.logo);
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const postDiscordOnboardingForm = (communityId, dataPayload, addToast) => (dispatch) => {
  Api.post(getDiscordOnboardingFormURL(communityId), dataPayload)
    .then((res) => {
      addToast('Discord Onboarding Form preference Saved!', { appearance: 'success' });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const getSlackConnectDetails =
  (communityId, setSlackConnectData, addToast) => (dispatch) => {
    Api.get(slackConnectUrl(communityId))
      .then((res) => {
        setSlackConnectData(res || []);
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

/**
 * Get selected users based on providers
 *
 * @param communityId
 * @param selectedProvider
 * @param setSlackConnectData
 * @param addToast
 * @returns
 */
export const getConnectUsersByProviderAction =
  (communityId, selectedProvider, setSlackConnectData, addToast, source, callback) =>
    (dispatch) => {
      Api.get(getConnectUsersByProvider(communityId, selectedProvider))
        .then((res) => {
          if (selectedProvider === 'SLACK' && source === 'BULK_MESSAGE') {
            setSlackConnectData(removeBotsFromConnectedUser(res));
          } else {
            setSlackConnectData(res || []);
          }
        })
        .catch(() => {
          addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
        })
        .finally(() => {
          callback && callback();
        });
    };

export const getDiscordConnectDetails =
  (communityId, setDiscordConnectData, addToast) => (dispatch) => {
    Api.get(discordConnectUrl(communityId))
      .then((res) => {
        setDiscordConnectData(res?.[0] || null);
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const getDiscordOnboardingOrganizationDetails = (communityId, addToast) => (dispatch) => {
  Api.get(getDiscordOnboardingFormURL(communityId))
    .then((res) => {
      dispatch({
        type: GET_DISCORD_ONBOARDING_ORGANIZATION_DETAILS,
        payload: res?.organization,
      });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const getDiscordCustomBotDetails =
  (integrationId, addToast, setRefreshing) => (dispatch) => {
    Api.get(discordCustomBotUrl(integrationId))
      .then((res) => {
        dispatch({
          type: SET_DISCORD_CUSTOM_BOT,
          payload: res,
        });
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      })
      .finally(() => {
        setRefreshing && setRefreshing(false);
      });
  };

export const getdiscordInteractionUrl =
  (integrationId, setInteractionUrl, addToast) => (dispatch) => {
    Api.get(discordInteractionUrl(integrationId))
      .then((res) => {
        setInteractionUrl(res);
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const postDiscordCustomBotCreateApp =
  (integrationId, dataPayload, addToast, setCurrentStep, setEdit) => (dispatch) => {
    Api.post(discordCustomBotStep1(integrationId), dataPayload)
      .then((res) => {
        dispatch({
          type: SET_DISCORD_CUSTOM_BOT,
          payload: res,
        });
        if (!res.step1?.appId) {
          setEdit(false);
          addToast('Your AppId or Public Key is invalid. Please try with new one!', {
            appearance: 'error',
          });
        } else {
          setCurrentStep(2);
          addToast(`Successfully saved application details!`, { appearance: 'success' });
        }
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const postDiscordCustomBotDetails =
  (integrationId, dataPayload, addToast, setCurrentStep, appId, refreshNow) => (dispatch) => {
    Api.post(discordCustomBotStep2(integrationId), dataPayload)
      .then((res) => {
        dispatch({
          type: SET_DISCORD_CUSTOM_BOT,
          payload: res,
        });
        if (!res.step2?.botName) {
          addToast('Your Bot token is invalid. Please try with new one!', { appearance: 'error' });
        } else {
          if (!res.step3?.isBotAdded) {
            refreshNow();
            setTimeout(() => {
              appId && window.open(DISCORD_CONNECT_TO_SERVER(appId), '_blank');
            }, 3000);
            addToast(
              `Successfully connected the bot. You will be redirected to discord to complete installation`,
              { appearance: 'success' },
            );
          } else {
            addToast(`Successfully connected the bot.`, { appearance: 'success' });
          }
        }
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const deleteDiscordCustomBotDetails =
  (integrationId, setCurrentStep, setPublicKey, setAppId, addToast) => (dispatch) => {
    Api.delete(discordCustomBotUrl(integrationId))
      .then((res) => {
        dispatch({
          type: SET_DISCORD_CUSTOM_BOT,
          payload: { botName: null, avatarId: null },
        });
        setCurrentStep(1);
        setPublicKey('');
        setAppId('');
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const getSignupCommunityDetails = (communityId, organisationId, addToast) => (dispatch) => {
  Api.get(getCommunityDetails(communityId, organisationId))
    .then((res) => {
      dispatch({
        type: SET_COMMUNITY_DETAILS,
        payload: res,
      });
      dispatch({
        type: SET_SIGNUP_COMMUNITY_DETAILS,
        payload: res,
      })
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};


export const getPreferenceDetails = (organisationId, addToast) => (dispatch) => {
  Api.get(getCommunityPreferenceApiUrl(organisationId))
    .then((res) => {
      dispatch({
        type: SET_SIGNUP_COMMUNITY_DETAILS,
        payload: res,
      })
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};



export const updatePreferenceDetails = (organisationId, payload, successCallBack, failureCallBack) => (dispatch) => {
  Api.post(updateCommunityPreferenceApiUrl(organisationId), payload)
    .then((res) => {

      dispatch({
        type: SET_SIGNUP_COMMUNITY_DETAILS,
        payload: res,
      })

      successCallBack && successCallBack(res);
    })
    .catch((error) => {
      failureCallBack && failureCallBack(error);
    });
};

export const setDateFilter = (dateFilter) => (dispatch) => {
  dispatch({
    type: UPDATE_DATE_FILTER,
    payload: dateFilter,
  });
};


export const getTimezoneList = (setTimezoneListLoading, setTimezoneList, addToast) => () => {
  setTimezoneListLoading(true);
  Api.get(getTimezoneListApiUrl())
    .then((res) => {
      setTimezoneList(res);
    })
    .catch((err) => {
      addToast('Error in fetching timezone list! Try again later', { appearance: 'error' });
    })
    .finally(() => {
      setTimezoneListLoading(false);
    });
};

export const getSlackCustomBotDetails =
  (integrationId, addToast, setRefreshing, setBotUserOAuthToken) => (dispatch) => {
    Api.get(slackCustomBotUrl(integrationId))
      .then((res) => {
        dispatch({
          type: SET_SLACK_CUSTOM_BOT,
          payload: res,
        });
        setBotUserOAuthToken && setBotUserOAuthToken('');
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      })
      .finally(() => {
        setRefreshing && setRefreshing(false);
      });
  };

export const postSlackCustomBotDetails = (dataPayload, addToast, setIsLoading) => (dispatch) => {
  setIsLoading && setIsLoading(true);
  Api.post(postSlackCustomBotDataUrl(), dataPayload)
    .then((res) => {
      dispatch({
        type: SET_SLACK_CUSTOM_BOT,
        payload: res,
      });
      if (!res.success) {
        addToast(res.reason, {
          appearance: 'error',
        });
      } else if (!res.step2?.botName) {
        addToast('Your OAuth token is invalid. Please try with new one!', {
          appearance: 'error',
        });
      } else {
        addToast('Successfully created your custom slack bot!', {
          appearance: 'success',
        });
      }
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    })
    .finally(() => {
      setIsLoading && setIsLoading(false);
    });
};

export const getRefreshSlackCustomBotDetails =
  (integrationId, addToast, setRefreshing) => (dispatch) => {
    setRefreshing && setRefreshing(true);
    Api.get(refreshSlackCustomBotUrl(integrationId))
      .then((res) => {
        dispatch({
          type: SET_SLACK_CUSTOM_BOT,
          payload: res,
        });
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      })
      .finally(() => {
        setRefreshing && setRefreshing(false);
      });
  };

export const deleteSlackCustomBotAction =
  (integrationId, addToast, isLoading, setBotUserOAuthToken, setDeleteOpen) => (dispatch) => {
    isLoading && isLoading(true);
    Api.delete(slackCustomBotUrl(integrationId))
      .then(() => {
        dispatch(
          getSlackCustomBotDetails(integrationId, addToast, isLoading, setBotUserOAuthToken),
        );
        addToast('Successfully deleted your custom slack bot!', { appearance: 'success' });
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      })
      .finally(() => {
        setDeleteOpen && setDeleteOpen(false);
      });
  };

export const getAiModelSettingsAction =
  (communityId, addToast, setIsLoading, aiModelType) => (dispatch) => {
    setIsLoading && setIsLoading(true);
    Api.get(getAiModelSettingsApiUrl(communityId, aiModelType))
      .then((res) => {
        switch (aiModelType) {
          case 'GENERATIVE':
            dispatch({
              type: SET_AI_MODEL_SETTINGS,
              payload: res,
            });
            break;
          case 'REASONING':
            dispatch({
              type: SET_AI_REASONING_MODEL_SETTINGS,
              payload: res,
            });
            break;
        }
      })
      .catch(() => {
        addToast('Facing some issues while fetching model details from our end. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading && setIsLoading(false);
      });
  };

export const getAiModelsListAction =
  (communityId, addToast, setIsLoading, aiModelType) => (dispatch) => {
    setIsLoading(true);
    Api.get(getAiModelsListApiUrl(aiModelType))
      .then((res) => {
        switch (aiModelType) {
          case 'GENERATIVE':
            dispatch({
              type: GET_AI_MODELS_LIST,
              payload: res,
            });
            break;
          case 'REASONING':
            dispatch({
              type: GET_AI_REASONING_MODELS_LIST,
              payload: res,
            });
            break;
        }
      })
      .catch(() => {
        addToast('Facing some issues while fetching model details from our end. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const setAiModelSelectAction =
  (communityId, payload, addToast, setIsLoading, handleClose) => (dispatch) => {
    setIsLoading(true);
    Api.post(saveAiModelApiUrl(communityId), payload)
      .then((res) => {
        dispatch(getAiModelSettingsAction(communityId, addToast, () => null, payload.aiModelType));
        addToast(`You have successfully connected your ${payload.modelName} account`, {
          appearance: 'success',
        });
        handleClose();
      })
      .catch(() => {
        addToast('Facing some issues while saving your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const setEditAiModelAction =
  (communityId, aiModelId, payload, addToast, setIsLoading, handleClose) => (dispatch) => {
    setIsLoading(true);
    Api.post(saveEditModelApiUrl(communityId, aiModelId), payload)
      .then((res) => {
        dispatch(getAiModelSettingsAction(communityId, addToast, () => null, payload.aiModelType));
        addToast(`You have successfully edited your ${payload.modelName} model`, {
          appearance: 'success',
        });
        handleClose();
      })
      .catch(() => {
        addToast('Facing some issues while saving your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const deleteAiModelAccountAction =
  (
    communityId,
    aiModelId,
    modelName,
    addToast,
    handleClose,
    setIsLoading,
    selectAiModelFormik,
    aiModelType,
  ) =>
  (dispatch) => {
    setIsLoading(true);
    Api.delete(deleteAiModelAccountApiUrl(communityId, aiModelId))
      .then((res) => {
        dispatch(getAiModelSettingsAction(communityId, addToast, setIsLoading, aiModelType));
        addToast(`You have successfully removed your ${modelName} account`, {
          appearance: 'success',
        });
        selectAiModelFormik.setFieldValue('apiKey', ''),
          selectAiModelFormik.setFieldValue('apiEndpoint', '');
        selectAiModelFormik.setFieldValue('deploymentName', '');
        handleClose();
      })
      .catch(() => {
        addToast('Facing some issues while removing your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const selectAiModelChangeAction =
  (
    communityId,
    payload,
    addToast,
    setRadioModelVal,
    handleSwitchModelPopupClose,
    setSwitchingLoading,
  ) =>
  (dispatch) => {
    setSwitchingLoading(true);
    Api.post(selectAiModelChangeApiUrl(communityId), payload)
      .then((res) => {
        setRadioModelVal(payload?.modelName);
        addToast(`You have successfully selected your AI model to ${payload?.modelName}`, {
          appearance: 'success',
        });
        dispatch(getAiModelSettingsAction(communityId, addToast, () => null, payload.aiModelType));
        handleSwitchModelPopupClose();
      })
      .catch(() => {
        addToast('Facing some issues while selecting your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setSwitchingLoading(false);
      });
  };

export const getEmbeddingAiModelSettingsAction =
  (communityId, addToast, setIsLoading) => (dispatch) => {
    setIsLoading && setIsLoading(true);
    Api.get(getEmbeddingAiModelSettingsApiUrl(communityId))
      .then((res) => {
        dispatch({
          type: SET_EMBEDDING_AI_MODEL_SETTINGS,
          payload: res,
        });
      })
      .catch(() => {
        addToast('Facing some issues while fetching model details from our end. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading && setIsLoading(false);
      });
  };

export const getEmbeddingAiModelsListAction =
  (communityId, addToast, setIsLoading) => (dispatch) => {
    setIsLoading(true);
    Api.get(getEmbeddingAiModelsListApiUrl(communityId))
      .then((res) => {
        dispatch({
          type: GET_EMBEDDING_AI_MODELS_LIST,
          payload: res,
        });
      })
      .catch(() => {
        addToast('Facing some issues while fetching model details from our end. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const setEmbeddingAiModelSelectAction =
  (communityId, payload, addToast, setIsLoading, handleClose) => (dispatch) => {
    setIsLoading(true);
    Api.post(saveEmbeddingAiModelApiUrl(communityId), payload)
      .then((res) => {
        dispatch(getEmbeddingAiModelSettingsAction(communityId, addToast));
        addToast(`You have successfully connected your ${payload.modelName} account`, {
          appearance: 'success',
        });
        handleClose();
      })
      .catch(() => {
        addToast('Facing some issues while saving your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const setEditEmbeddingAiModelAction =
  (communityId, aiModelId, payload, addToast, setIsLoading, handleClose) => (dispatch) => {
    setIsLoading(true);
    Api.post(saveEditEmbeddingAiModelApiUrl(communityId, aiModelId), payload)
      .then((res) => {
        dispatch(getEmbeddingAiModelSettingsAction(communityId, addToast));
        addToast(`You have successfully edited your ${payload.modelName} model`, {
          appearance: 'success',
        });
        handleClose();
      })
      .catch(() => {
        addToast('Facing some issues while saving your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const deleteEmbeddingAiModelAccountAction =
  (communityId, aiModelId, modelName, addToast, handleClose, setIsLoading, selectAiModelFormik) =>
  (dispatch) => {
    setIsLoading(true);
    Api.delete(deleteEmbeddingAiModelAccountApiUrl(communityId, aiModelId))
      .then((res) => {
        dispatch(getEmbeddingAiModelSettingsAction(communityId, addToast, setIsLoading));
        addToast(`You have successfully removed your ${modelName} account`, {
          appearance: 'success',
        });
        selectAiModelFormik.setFieldValue('apiKey', ''),
          selectAiModelFormik.setFieldValue('apiEndpoint', '');
        selectAiModelFormik.setFieldValue('deploymentName', '');
        handleClose();
      })
      .catch(() => {
        addToast('Facing some issues while removing your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const selectEmbeddingAiModelChangeAction =
  (
    communityId,
    payload,
    addToast,
    setRadioModelVal,
    handleSwitchModelPopupClose,
    setSwitchingLoading,
  ) =>
  (dispatch) => {
    setSwitchingLoading(true);
    Api.post(selectEmbeddingAiModelChangeApiUrl(communityId), payload)
      .then((res) => {
        setRadioModelVal(payload?.modelName);
        addToast(
          `You have successfully selected your Embedding AI model to ${payload?.modelName}`,
          {
            appearance: 'success',
          },
        );
        dispatch(getEmbeddingAiModelSettingsAction(communityId, addToast));
        handleSwitchModelPopupClose();
      })
      .catch(() => {
        addToast('Facing some issues while selecting your model. Try again Later!', {
          appearance: 'error',
        });
      })
      .finally(() => {
        setSwitchingLoading(false);
      });
  };