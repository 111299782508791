// Types for getting ticketId and verification if the platform is valid
export const IS_VALID_TICKET_PLATFORM = 'IS_VALID_TICKET_PLATFORM';
export const SET_TICKET_ID = 'SET_TICKET_ID';
export const GET_TICKET_ID = 'GET_TICKET_ID';
export const GET_TICKET_ID_LOADING = 'GET_TICKET_ID_LOADING';
export const GET_TICKET_ID_FAILED = 'GET_TICKET_ID_FAILED';

// Check for new questions in the ticket from the user
export const IS_NEW_QUESTION_AVAILABLE = 'IS_NEW_QUESTION_AVAILABLE';
export const IS_NEW_QUESTION_AVAILABLE_LOADING = 'IS_NEW_QUESTION_AVAILABLE_LOADING';
export const IS_NEW_QUESTION_AVAILABLE_FAILED = 'IS_NEW_QUESTION_AVAILABLE_FAILED';


// Is the current URL a valid platform
export const IS_CURRENT_URL_VALID_PLATFORM = 'IS_CURRENT_URL_VALID_PLATFORM';
export const IS_CURRENT_URL_VALID_PLATFORM_LOADING = 'IS_CURRENT_URL_VALID_PLATFORM_LOADING';
export const IS_CURRENT_URL_VALID_PLATFORM_FAILED = 'IS_CURRENT_URL_VALID_PLATFORM_FAILED';

// Get option
export const IS_OPTION_LOADING = 'IS_OPTION_LOADING';
export const IS_OPTION = 'IS_OPTION';
export const IS_OPTION_FAILED = 'IS_OPTION_FAILED';

// Refine - Set Action POST api call
export const SET_REFINE_ACTION_ID = 'SET_REFINE_ACTION_ID';
export const SET_REFINE_ACTION_ID_LOADING = 'SET_REFINE_ACTION_ID_LOADING';
export const SET_REFINE_ACTION_ID_FAILED = 'SET_REFINE_ACTION_ID_FAILED';
export const RESET_REFINE_ACTION_ID = 'RESET_REFINE_ACTION_ID';

// Setting Tone
export const SET_TONE_PREFERENCES = 'SET_TONE_PREFERENCES';
export const SET_TONE_PREFERENCES_LOADING = 'SET_TONE_PREFERENCES_LOADING';
export const SET_TONE_PREFERENCES_FAILED = 'SET_TONE_PREFERENCES_FAILED';


// Ask page
export const GET_PREVIOUS_ASK_CHAT_HISTORY_LOADING = 'GET_PREVIOUS_ASK_CHAT_HISTORY_LOADING';
export const GET_PREVIOUS_ASK_CHAT_HISTORY = 'GET_PREVIOUS_ASK_CHAT_HISTORY';
export const GET_PREVIOUS_ASK_CHAT_HISTORY_FAILED = 'GET_PREVIOUS_ASK_CHAT_HISTORY_FAILED';

// Posting feedback
export const SET_MESSAGE_FEEDBACK_LOADING = 'SET_MESSAGE_FEEDBACK_LOADING';
export const GET_MESSAGE_FEEDBACK = 'GET_MESSAGE_FEEDBACK';
export const SET_MESSAGE_FEEDBACK_FAILED = 'SET_MESSAGE_FEEDBACK_FAILED';
export const RESET_FEEDBACK_MESSAGE = 'RESET_FEEDBACK_MESSAGE';
export const SET_PREVIOUS_ASK_CHAT_HISTORY_LOCALLY = 'SET_PREVIOUS_ASK_CHAT_HISTORY_LOCALLY';