import {
  GET_RETENTION_DATA,
  HANDLE_RETENTION_ERROR,
  LOADING_RETENTION,
  UPDATE_INTEGRATION,
} from '../types';

export const initialState = {
  retentionRawData: null,
  toastMessage: '',
  retentionLoading: false,
  currentProvider: '',
  retentionFetchFail: false,
};

export const retentionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RETENTION_DATA: {
      return {
        ...state,
        retentionRawData: payload,
        toastMessage: 'Retention Analytics fetched success',
        retentionLoading: false,
        retentionFetchFail: false,
      };
    }
    case LOADING_RETENTION: {
      return {
        ...state,
        toastMessage: 'Retention Analytics fetching data',
        retentionLoading: true,
        retentionFetchFail: false,
      };
    }
    case HANDLE_RETENTION_ERROR: {
      return {
        ...state,
        toastMessage: payload,
        retentionRawData: null,
        retentionLoading: false,
        retentionFetchFail: true,
      };
    }
    case UPDATE_INTEGRATION: {
      return {
        ...state,
        currentProvider: payload,
      };
    }
    default:
      return state;
  }
};
