import { CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';

const LoadingComponent = ({ isLoading, children }) => {
  if (isLoading) {
    return <CircularProgress />;
  }
  return children;
};

export default LoadingComponent;
