import React, { FC } from 'react';
import { Skeleton } from '@material-ui/core';

const PaymentsModalPriceSkeletonLoader = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Skeleton
        style={{ marginTop: '16px', borderRadius: '4px' }}
        variant="rectangular"
        animation="wave"
        height={34}
        width={100}
      />
      <Skeleton animation="wave" width={150} height={16} style={{ marginTop: '10px' }} />
    </div>
  );
};

export default PaymentsModalPriceSkeletonLoader;
