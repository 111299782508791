export const THREADO_PLAN_TYPES = {
  FREE_PLAN: 'free-plan',
  BASIC_PLAN: 'threado-pro-basic',
  PRO_PLAN: 'threado-pro',
  // MAX_PLAN: 'threado-max',
  ENTERPRISE_PLAN: 'threado-enterprise',
};

export const THREADO_BOT_PLAN_TYPES = {
  FREE_PLAN: 'ai-free-plan',
  // BASIC_PLAN: 'threado-chatbot-basic',
  PRO_PLAN: 'threado-chatbot-pro',
  ENTERPRISE_PLAN: 'ai-threado-enterprise',
};

export const paymentPlansDetailsMapper = {
  'free-plan': {
    key: THREADO_PLAN_TYPES.FREE_PLAN,
    title: 'FREE',
    subTitle: 'Kick-start your community ',
    subTextInfo: 'Free forever',
    price: 0,
    currency: 'usd',
    ctaText: 'Select Free',
    ctaTextDowngrade: 'Contact us to downgrade',
    features: [
      {
        feature: '<b>1</b> Seat',
        tooltip: 'No. of users who can access Threado account',
      },
      {
        feature: '<b>1</b> Community',
      },
      {
        feature: 'Essential Integrations',
        tooltip: 'Connect your Community members data to manager from Threado',
      },
      {
        feature: 'Community support',
        tooltip: 'Dedicated Customer Success Manager',
      },
    ],
  },

  'threado-pro-basic': {
    title: 'BASIC',
    subTitle: 'Engage with your community',
    key: THREADO_PLAN_TYPES.BASIC_PLAN,
    ctaText: 'Select Basic',
    ctaTextDowngrade: 'Contact us to downgrade',
    subText: 'Includes everything in Free +',
    features: [
      {
        feature: '<b>1k</b> Members engaged',
        tooltip:
          'Unique members engaged per month via Threado through automated workflows, DMs, and outreach',
      },
      {
        feature: '<b>2</b> Users',
        tooltip: 'Number of people in your company who can access Threado',
      },
      {
        feature: '<b>2</b> Workflows',
        tooltip: 'Automate your manual workflows using Threado’s powerful and flexible automations',
      },
      {
        feature: '1 Community',
      },
      {
        feature: '<b>24/7</b> Email support',
        tooltip: 'Dedicated Customer Success Manager',
      },
    ],
  },
  'threado-pro': {
    title: 'PRO',
    subTitle: 'For fast growing communities',
    isMostPopular: false,
    key: THREADO_PLAN_TYPES.PRO_PLAN,
    ctaText: 'Select Pro',
    subText: 'Includes everything in Free +',
    ctaTextUpgrade: 'Contact us to upgrade',
    ctaTextDowngrade: 'Contact us to downgrade',
    features: [
      {
        feature: '<b>5</b> Seats',
        featureSubText: '<b style="color: #6A6A6A">$12</b> per additional seat / month',
        tooltip: 'No. of users who can access Threado account',
      },
      {
        feature: '<b>5000</b> Members engaged',
        tooltip:
          'Unique members engaged per month via Threado through automated workflows, DMs, and outreach',
      },
      {
        feature: '<b>5</b> Automation workflows',
        tooltip: 'Automate your manual workflows using Threado’s powerful and flexible automations',
      },
      {
        feature: '<b>3</b> Communities',
      },
      {
        feature: '<b>Unlimited</b> integrations',
      },
      {
        feature: '<b>Premium support</b>',
      },
    ],
  },
  'threado-max': {
    title: 'MAX',
    subTitle: 'Scale your community',
    isMostPopular: false,
    key: THREADO_PLAN_TYPES.MAX_PLAN,
    ctaText: 'Select Max',
    subText: 'Includes everything in Pro +',
    ctaTextUpgrade: 'Contact us to upgrade',
    features: [
      {
        feature: '<b>10k</b> Members engaged',
        tooltip:
          'Unique members engaged per month via Threado through automated workflows, DMs, and outreach',
      },
      {
        feature: '<b>8</b> Users',
        tooltip: 'Number of people in your company who can access Threado',
      },
      {
        feature: '<b>10</b> Workflows',
        tooltip: 'Automate your manual workflows using Threado’s powerful and flexible automations',
      },
      {
        feature: '<b>8</b> Communities',
      },
      {
        feature: 'Dedicated <b>CSM</b>',
        tooltip: 'Dedicated Customer Success Manager ',
      },
    ],
  },
  'threado-enterprise': {
    title: 'ENTERPRISE',
    subTitle: '&nbsp;',
    ctaText: 'Upgrade to enterprise',
    key: THREADO_PLAN_TYPES.ENTERPRISE_PLAN,
    subText: 'Includes everything in Pro +',
    features: [
      {
        feature: '<b>Unlimited</b> Seats ',
        tooltip: 'Number of people in your company who can access Threado',
      },
      {
        feature: '<b>Unlimited</b> Automation workflows',
        tooltip: 'Automate your manual workflows using Threado’s powerful and flexible automations',
      },
      {
        feature: '<b>Unlimited</b> members engaged',
        tooltip:
          'Unique members engaged per month via Threado through automated workflows, DMs, and outreach',
      },
      {
        feature: '<b>Unlimited</b> communities',
      },
      {
        feature: 'Dedicated <b>CSM</b>',
        tooltip: 'Dedicated Customer Success Manager',
      },
    ],
  },
};

export const botPaymentPlansDetailsMapper = {
  'ai-free-plan': {
    key: THREADO_BOT_PLAN_TYPES.FREE_PLAN,
    title: 'FREE',
    subTitle: 'Ideal for personal use ',
    subTextInfo: 'Free forever',
    price: 0,
    currency: 'usd',
    ctaText: 'Select Free',
    ctaTextUpgrade: 'Contact us to upgrade',
    ctaTextDowngrade: 'Contact us to downgrade',
    features: [
      {
        feature: '<i style="color: #6A6A6A">Bot training</i>',
        featureSubText: 'PDFs, Websites',
        tooltip: 'Bot training - Train your bot on your custom data',
      },
      // {
      //   feature: '<b>1</b> Seat',
      //   tooltip: 'No. of users who can access Threado account',
      // },
    ],
  },
  'threado-chatbot-pro': {
    title: 'PRO',
    subTitle: 'For early-stage startups',
    key: THREADO_BOT_PLAN_TYPES.PRO_PLAN,
    ctaText: 'Select Pro',
    ctaTextDowngrade: 'Contact us to downgrade',
    subText: 'Includes Threado AI free plan +',
    isMostPopular: false,
    features: [
      // {
      //   feature: '<b>5</b> Seats',
      //   featureSubText: '<b style="color: #6A6A6A">$12</b> per additional seat / month',
      //   tooltip: 'No. of users who can access Threado account',
      // },
      {
        feature: '<b>1000</b> Queries answered / month',
        // featureSubText: '<b style="color: #6A6A6A">$10 / 50</b> queries/ month',
        tooltip:
          'Only valid responses by the bot are counted. Downvoted and unanswerable responses are excluded. Contact us for custom limits',
      },
      {
        feature: '<i style="color: #6A6A6A">Bot training</i>',
        featureSubText: 'Zendesk/Freshdesk Articles, Confluence, Notion, Google Drive',
        tooltip: 'Bot training - Train your bot on your custom data',
      },
      {
        feature: 'Embeddable widget',
        tooltip: 'Customizable chat embed for your website',
      },
      {
        feature: 'Analytics and Feed',
        tooltip: `Measure your bot's performance and provide prompt replies`,
      },
      {
        feature: 'Priority support',
        tooltip: 'Dedicated customer success manager',
      },
    ],
  },
  'ai-threado-enterprise': {
    title: 'ENTERPRISE',
    subTitle: 'For managing large volumes of queries',
    ctaText: 'Upgrade to enterprise',
    key: THREADO_BOT_PLAN_TYPES.ENTERPRISE_PLAN,
    subText: 'Includes everything in Pro +',
    features: [
      // {
      //   feature: '<b>Custom</b> Seats limit',
      // },
      {
        feature: '<b>Custom</b> bot persona',
      },
      {
        feature: '<b>Custom</b> # of Queries answered / month',
      },
      {
        feature: '<i style="color: #6A6A6A">Bot training</i>',
        featureSubText: 'Zendesk/Freshdesk Tickets(coming soon), Slack/Discord conversations',
      },
      {
        feature: 'Customer success manager',
      },
    ],
  },
};
