import { Paper } from '@mui/material';
import Mbgm from 'assets/images/media/maintenanceBgm.svg';
import pageLost from 'assets/images/media/pageLost.svg';
import React from 'react';
import { PrimaryButton } from 'styledComponents/commonStyled';
import { Image } from 'styledComponents/commonStyled';
const ErrorPageClient = () => {
  return (
    <Paper>
      <div
        style={{
          backgroundImage: `url(${Mbgm})`,
          backgroundColor: '#FFFFFF',
          backgroundRepeat: 'repeat',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div
          style={{
            paddingTop: '19px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div>
            <Image src={pageLost} />
          </div>

          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '38px',
              textAlign: 'center',
              color: '#191919',
              display: 'block',
              marginBottom: '20px',
              marginTop: '50px',
            }}>
            Oops! Something went wrong. Please refresh
          </div>
          <PrimaryButton padding="10px 20px" onClick={() => window?.location?.reload()}>
            Refresh
          </PrimaryButton>
        </div>
      </div>
    </Paper>
  );
};

export default ErrorPageClient;
