import React, { useEffect, useState } from 'react';
import {
  CustomDialog,
  DialogCloseButton,
  DialogContainer,
  Image,
  PrimaryButton,
  SecondaryButton,
} from 'styledComponents/commonStyled';
import { AccountText, PurpleText } from '../Login/LoginStyled';
import { paymentPlansDetailsMapper } from './utils';
import {
  PaymentsFeatureItem,
  PaymentsFeaturesBox,
  PaymentsFeaturesListContainer,
  PaymentsGreyText,
  PaymentsModalBottomContainer,
  PaymentsModalTopContainer,
  PlanCardTooltipText,
} from 'styledComponents/paymentsStyled';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetCurrentCommunity,
  useGetPaymentsPlanList,
  useGetPaymentsPlanListLoading,
  useGetUpgradeNowEventSource,
  useGetUserInfo,
} from 'context/hooks';
import { PAYMENTS_REDIRECTION_URL } from '../../constant/urlConstants';
import { getPaymentsPlanListAction } from '../../redux/paymentsProvider/actions/paymentsActions';
import { colorToken } from '../../theme/designToken';
import { trackUserEvent } from 'utils/segment';
import CreateTabs from '../../commonComponents/CreateTabs';
import { useDataState } from 'utils/dataStateHook';
import { paymentsPlanModalTabData } from 'constant/tabsData';
import {
  DisplayFlexBox,
  PaymentCardInnerBoxOne,
  PaymentCardPrice,
  PlanDetailsCardSubTitle,
} from 'styledComponents/settingsStyled';
import { isEmpty } from 'lodash';
import { MessageText } from 'styledComponents/activityStyled';
import PaymentsModalPriceSkeletonLoader from './PaymentsModalSkeletonLoader';
import { Fade } from 'react-awesome-reveal';
import { IconButton } from '@material-ui/core';
import { ToolTipCustom } from 'atoms/Tooltip';
import closeIcon from 'assets/images/icons/closeIcon.svg';
import proPlanFeaturesNew from 'assets/images/icons/proPlanFeaturesNew.svg';
import tooltipIcon from 'assets/images/icons/tooltipIcon.svg';
import redirectIcon from 'assets/images/icons/redirectIcon.svg';
import surpriseGiftImage from 'assets/images/media/surprise_gift.png';

const PaymentsModal = ({ openPaymentsModal, handleClose, defaultPlanIndex = 0 }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(useGetUserInfo);
  const communityData = useSelector(useGetCurrentCommunity);
  const paymentsPlanList = useSelector(useGetPaymentsPlanList);
  const paymentsPlanListLoading = useSelector(useGetPaymentsPlanListLoading);
  const upgradeEventSource = useSelector(useGetUpgradeNowEventSource);
  const [planFrequency, setPlanFrequency] = useState('ANNUAL'); // TO Make default call for Annual plan
  const tab = useDataState('');
  const [currenTabPlanData, setCurrentTabPlanData] = useState(null);

  useEffect(() => {
    if (isEmpty(paymentsPlanList)) {
      return;
    }
    if (isEmpty(currenTabPlanData)) {
      const selectDefaultPlan = paymentsPlanList?.[defaultPlanIndex];
      if (selectDefaultPlan) {
        const defaultPlanId = selectDefaultPlan.productIdName;

        paymentsPlanModalTabData.forEach((tabData) => {
          if (tabData.productIdName === defaultPlanId) {
            tab.set(tabData.tabName);
          }
        });
      }
    } else {
      const selectedPlanId = currenTabPlanData.productIdName;
      paymentsPlanModalTabData.forEach((tabData) => {
        if (tabData.productIdName === selectedPlanId) {
          tab.set(tabData.tabName);
        }
      });
      paymentsPlanList?.forEach((plan) => {
        if (selectedPlanId === plan.productIdName) {
          setCurrentTabPlanData(plan);
        }
      });
    }
  }, [paymentsPlanList, setCurrentTabPlanData]);

  useEffect(() => {
    if (isEmpty(paymentsPlanList) && tab.value) {
      return;
    }
    paymentsPlanModalTabData.forEach((tabData) => {
      if (tabData.tabName === tab.value) {
        const getCurrenPlanId = tabData.productIdName;
        paymentsPlanList?.forEach((plan) => {
          if (getCurrenPlanId === plan.productIdName) {
            setCurrentTabPlanData(plan);
          }
        });
      }
    });
  }, [tab.value, setCurrentTabPlanData, currenTabPlanData]);

  useEffect(() => {
    if (!communityData || !communityData?.org?.id || !userInfo) {
      return;
    }
    dispatch(getPaymentsPlanListAction(communityData?.org?.id, planFrequency));
  }, [userInfo, communityData, planFrequency]);

  return (
    <CustomDialog
      open={openPaymentsModal}
      onClose={handleClose}
      sx={{
        backdropFilter: 'blur(5px)',
      }}>
      <DialogContainer>
        <PaymentsModalTopContainer>
          <DialogCloseButton onClick={handleClose} style={{ top: '15px', right: '5px' }}>
            <IconButton>
              <Image src={closeIcon} alt="closeCircle" width={26} height={26} />
            </IconButton>
          </DialogCloseButton>
          <Image src={surpriseGiftImage} width={72} height={72} alt="gift" />
          <AccountText style={{ fontWeight: 700, fontSize: '22px' }}>
            Get started with the{' '}
            <PurpleText style={{ fontWeight: 700, fontSize: '22px' }}>
              {currenTabPlanData?.productName}
            </PurpleText>
          </AccountText>
          <PaymentsGreyText style={{ marginTop: '12px', marginBottom: '8px' }}>
            5X better way to grow, engage & retain your members using{' '}
            {currenTabPlanData?.productName}
          </PaymentsGreyText>
          <></>
        </PaymentsModalTopContainer>
        <PaymentsModalBottomContainer>
          <PaymentsFeaturesBox>
            <CreateTabs
              hideTabs={[]}
              fullWidth={true}
              tabs={paymentsPlanModalTabData}
              setTab={tab.set}
              currentTab={tab.value || ''}
              noPadding={true}
              innertextpadding="6px 5px"
            />
            <div style={{ width: '225px', margin: '0 auto' }}>
              {paymentsPlanListLoading ? (
                <>
                  <PaymentsModalPriceSkeletonLoader />
                </>
              ) : (
                <Fade duration={300}>
                  <PaymentCardPrice style={{ marginTop: '16px' }}>
                    <span>{currenTabPlanData?.currency === 'usd' ? '$' : '$'}</span>
                    {currenTabPlanData?.recurring === 'year' ? (
                      <span>
                        {currenTabPlanData?.amount
                          ? Math.floor(currenTabPlanData.amount / 12)
                          : '0'}
                      </span>
                    ) : (
                      <span>{currenTabPlanData?.amount ? currenTabPlanData.amount : '0'}</span>
                    )}
                  </PaymentCardPrice>
                  <PlanDetailsCardSubTitle style={{ fontStyle: 'italic' }}>
                    {currenTabPlanData?.recurring === 'month'
                      ? 'per month'
                      : currenTabPlanData?.recurring === 'year'
                      ? 'per month, billed annually'
                      : ''}
                  </PlanDetailsCardSubTitle>
                </Fade>
              )}
              <div style={{ padding: '0 16px', marginTop: '10px' }}>
                <PaymentCardInnerBoxOne>
                  {paymentPlansDetailsMapper[currenTabPlanData?.productIdName]?.subText}
                </PaymentCardInnerBoxOne>
              </div>
              <PaymentsFeaturesListContainer style={{ minheight: '300px', padding: '16px 20px' }}>
                {paymentPlansDetailsMapper[currenTabPlanData?.productIdName]?.features.map(
                  (item, idx) => (
                    <PaymentsFeatureItem
                      key={`feature--${idx}`}
                      style={{ lineHeight: '16px', marginBottom: '10px' }}>
                      <Image src={proPlanFeaturesNew} alt="check-icon" width={18} height={18} />
                      <MessageText
                        style={{
                          marginTop: '0',
                          marginLeft: '12px',
                          color: '#191919',
                          lineHeight: '16px',
                        }}
                        key={`text-${idx}`}
                        dangerouslySetInnerHTML={{ __html: item.feature }}
                      />
                      {item.tooltip && (
                        <ToolTipCustom
                          arrow
                          title={<PlanCardTooltipText>{item.tooltip}</PlanCardTooltipText>}>
                          <IconButton
                            style={{
                              color: 'unset',
                              padding: '1px',
                              marginTop: '1px',
                              marginLeft: '8px',
                            }}>
                            <Image src={tooltipIcon} alt="info" width={14} height={14} />
                          </IconButton>
                        </ToolTipCustom>
                      )}
                    </PaymentsFeatureItem>
                  ),
                )}
              </PaymentsFeaturesListContainer>
            </div>
          </PaymentsFeaturesBox>
          {/* Uncomment below code to enable restriction for payment redirection link on stripe */}
          {/* {communityData?.role !== 'OWNER' ? (
            <NoOwnerDisplay>
              <Image src={infoIcon} alt="info" height={16} width={16} />
              &nbsp;&nbsp;Contact your community owner to upgrade
            </NoOwnerDisplay>
          ) : ( */}
          <div style={{ position: 'absolute', bottom: '15px' }}>
            <DisplayFlexBox style={{ marginBottom: '10px' }}>
              <SecondaryButton
                style={{
                  textTransform: 'unset',
                  border: '1px solid #D5D5D5',
                  color: colorToken.colors.purpleText,
                  backgroundColor: colorToken.colors.white,
                  height: '36px',
                  width: '160px',
                  marginRight: '30px',
                }}
                onClick={handleClose}>
                Skip for now
              </SecondaryButton>
              <a
                href={PAYMENTS_REDIRECTION_URL(
                  communityData?.org?.id || 0,
                  currenTabPlanData?.productIdName,
                  currenTabPlanData?.priceId,
                )}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  trackUserEvent('Clicked Continue to Upgrade', { source: upgradeEventSource })
                }>
                <PrimaryButton style={{ height: '34px', width: '160px' }}>
                  <span style={{ marginRight: '12px' }}>
                    {paymentPlansDetailsMapper[currenTabPlanData?.productIdName]?.ctaText}
                  </span>
                  <Image src={redirectIcon} alt="redirect" height={13} width={13} />
                </PrimaryButton>
              </a>
            </DisplayFlexBox>
            <AccountText
              style={{
                fontWeight: 500,
                fontSize: '12px',
                color: '#575757',
                marginBottom: '5px',
              }}>
              You will be redirected to
              <PurpleText
                style={{
                  fontWeight: 400,
                  cursor: 'pointer',
                  fontSize: '12px',
                  lineHeight: '20px',
                }}
                href="https://stripe.com/"
                target="_blank">
                {' '}
                stripe.com{' '}
              </PurpleText>
              to complete the checkout.
            </AccountText>
            <AccountText style={{ fontWeight: 400, fontSize: '12px', color: '#575757' }}>
              You can cancel the subscription anytime.
            </AccountText>
          </div>
          {/* )} */}
        </PaymentsModalBottomContainer>
      </DialogContainer>
    </CustomDialog>
  );
};

export default PaymentsModal;
