import {
  APP_FEATURE_DISCORD_INTEGRATION,
  APP_FEATURE_DISCOURSE_INTEGRATION,
  APP_FEATURE_SLACK_INTEGRATION,
  APP_FEATURE_GITHUB_INTEGRATION,
  APP_FEATURE_NOTION_INTEGRATION,
  APP_FEATURE_FRESHDESK_INTEGRATION,
  APP_FEATURE_GOOGLE_DRIVE_INTEGRATION

} from './features';

import slack from 'assets/images/icons/slack.svg';
import discord from 'assets/images/icons/discord.svg';
import discourse from 'assets/images/icons/discourse.svg';
import github from 'assets/images/icons/github.svg';
import webTabIcon from 'assets/images/icons/webTabIcon.svg';
import zendeskLogo from 'assets/images/icons/zendesk.svg';
import confluenceLogo from 'assets/images/icons/confluence.svg';
import notionLogo from 'assets/images/icons/notion.svg';
import freshdeskLogo from 'assets/images/icons/freshdesk.svg';
import googleDrive from 'assets/images/icons/googleDrive.svg';

import {
  APP_FEATURE_CONFLUENCE_INTEGRATION,
  APP_FEATURE_ZENDESK_INTEGRATION,
} from 'constant/features';

export const ACTIVITY_FREQUENCY = [
  {
    name: 'Daily',
    value: 'DAILY',
  },
  {
    name: 'Weekly',
    value: 'WEEKLY',
  },
  {
    name: 'Monthly',
    value: 'MONTHLY',
  },
];

export const ACTIVITY_TYPE = [
  {
    name: 'By activity type',
    value: 'MESSAGE',
  },
  {
    name: 'Member type',
    value: 'MEMBER',
  },
];

export const AnalyticsDateRange = [
  {
    name: 'Last 7 Days',
    value: 'LAST_7_DAYS',
  },
  {
    name: 'Last 15 Days',
    value: 'LAST_15_DAYS',
  },
  {
    name: 'Last 30 Days',
    value: 'LAST_30_DAYS',
  },
  {
    name: 'Last 60 Days',
    value: 'LAST_60_DAYS',
  },
  {
    name: 'Last 90 Days',
    value: 'LAST_90_DAYS',
  },
  {
    name: 'Last 180 Days',
    value: 'LAST_180_DAYS',
  },
  {
    name: 'Custom Date',
    value: 'CUSTOM',
  },
];

export const RESPONSIVENESS_TYPE = [
  {
    name: 'Response rate',
    value: 'RESPONSE_RATE',
  },
  {
    name: 'First response time',
    value: 'FIRST_RESPONSE',
  },
];

export const gridTypeToItemMap = {
  'Message Type': ['Total', 'Posts', 'Replies', 'Reactions'],
  'Message Type GITHUB': [
    'total',
    'forkCount',
    'issueClosedCount',
    'issueOpenCount',
    'prMergedCount',
    'prOpenCount',
  ],
  'Member Type': ['Total', 'Member', 'TeamMate'],
  'Sentiment Type': ['Total', 'Positive', 'Neutral', 'Negative'],
  'New member': ['Total'],
  'Total member': ['Total', 'Active', 'Inactive'],
  FIRST_RESPONSE: ['posts'],
  RESPONSE_RATE: ['responseRate'],
};

export const gitKeysToDisplayKeys = {
  total: 'Total',
  forkCount: 'Fork Count',
  issueClosedCount: 'Issue Closed Count',
  issueOpenCount: 'Issue Open Count',
  prMergedCount: 'PR Merged Count',
  prOpenCount: 'PR Open Count',
};

export const responsivenessDisplayKeys = {
  totalResponses: 'Total Responses',
  responseRate: 'Response Rate',
  posts: 'Posts',
};

export const sentimentGraphColorCoding = [
  {
    value: `#B5B6D3`,
    displayData: 'Total',
  },
  {
    value: `#49A65D`,
    displayData: 'Positive',
  },
  {
    value: `#FEE4C0`,
    displayData: 'Neutral',
  },
  {
    value: `#E6484E`,
    displayData: 'Negative',
  },
];

export const responsivenessResponseRateGraphColorCoding = [
  {
    value: `#B897FF`,
    displayData: 'responseRate',
  },
];

export const responsivenessPostGraphColorCoding = [
  {
    value: `#B897FF`,
    displayData: 'posts',
  },
];

export const newMemberGraphColorCoding = [
  {
    value: `#B897FF`,
    displayData: 'New member',
  },
];

export const totalMemberGraphColorCoding = [
  {
    value: '#B5B6D3',
    displayData: 'Total',
  },
  {
    value: `#8E7BFF`,
    displayData: 'Active',
  },
  {
    value: `#E6E3FA`,
    displayData: 'Inactive',
  },
  // INFO : ENG-5185
  // {
  //   value: `#CEC9EF`,
  //   displayData: 'Lurker',
  // },
];

export const tableColor = {
  Positive: `#49A65D`,
  Neutral: `#FEE4C0`,
  Negative: `#E6484E`,
  Member: '#8E7BFF',
  TeamMate: '#CEC9EF',
  Posts: '#8E7BFF',
  Replies: '#CEC9EF',
  Reactions: '#E6E3FA',
  Active: '#8E7BFF',
  Lurker: '#CEC9EF',
  Inactive: '#E6E3FA',
  'Fork Count': '#7751ED',
  'Issue Closed Count': '#836CF6',
  'Issue Open Count': '#9581FA',
  'PR Merged Count': '#B0A4FF',
  'PR Open Count': '#DBD7FF',
  'Response Rate': '#8E7BFF',
};

export const actualTypeToDataSet = {
  MEMBER_GITHUB: [
    {
      value: '#7751ED',
      displayData: 'forkCount',
      viewData: 'Fork Count',
      percentageKey: 'forkCountPercentage',
    },
    {
      value: '#836CF6',
      displayData: 'issueClosedCount',
      viewData: 'Issue Closed Count',
      percentageKey: 'issueClosedCountPercentage',
    },
    {
      value: '#9581FA',
      displayData: 'issueOpenCount',
      viewData: 'Issue Open Count',
      percentageKey: 'issueOpenCountPercentage',
    },
    {
      value: '#B0A4FF',
      displayData: 'prMergedCount',
      viewData: 'PR Merged Count',
      percentageKey: 'prMergedCountPercentage',
    },
    {
      value: '#DBD7FF',
      displayData: 'prOpenCount',
      viewData: 'PR Open Count',
      percentageKey: 'prOpenCountPercentage',
    },
  ],
  MEMBER: [
    {
      value: '#ffffff',
      displayData: 'Total',
      viewData: 'Total',
    },
    {
      value: '#8E7BFF',
      displayData: 'Member',
      viewData: 'Members',
      percentageKey: 'memberPercentage',
    },
    {
      value: '#CEC9EF',
      displayData: 'TeamMate',
      viewData: 'Team mates',
      percentageKey: 'teammatePercentage',
    },
  ],
  MESSAGE: [
    {
      value: '#ffffff',
      displayData: 'Total',
      viewData: 'Total',
    },
    {
      value: '#8E7BFF',
      displayData: 'Posts',
      viewData: 'Posts',
      percentageKey: 'postsPercentage',
    },
    {
      value: '#CEC9EF',
      displayData: 'Replies',
      viewData: 'Replies',
      percentageKey: 'repliesPercentage',
    },
    {
      value: '#E6E3FA',
      displayData: 'Reactions',
      viewData: 'Reactions',
      percentageKey: 'reactionsPercentage',
    },
  ],
  BOT: [
    {
      value: '#B5B6D3',
      displayData: 'Answered',
      viewData: 'Answered',
    },
    {
      value: '#8E7BFF',
      displayData: 'Unanswered',
      viewData: 'Unanswered',
    },
    {
      value: '#34BE95',
      displayData: 'Upvotes',
      viewData: 'Upvotes',
    },
    {
      value: '#F28282',
      displayData: 'Downvotes',
      viewData: 'Downvotes',
    },
  ],
  ASSIST_ENGAGEMENT_TREND: [
    {
      value: '#8E7BFF',
      displayData: 'AI Assisted',
      viewData: 'AI Assisted',
      mappingKey: 'aiAssisted',
    },
    {
      value: '#FED87B',
      displayData: 'Resolved',
      viewData: 'Resolved',
      mappingKey: 'total'
    },
  ],
  ASSIST_QUERIES_TREND: [
    {
      value: '#8E7BFF',
      displayData: 'Queries',
      viewData: 'Queries',
      mappingKey: 'queries',
    },
    {
      value: '#FED87B',
      displayData: 'Responses',
      viewData: 'Responses',
      mappingKey: 'responses'
    },
  ],
  ASSIST_QUERIES_TREND_TOOLTIP: [
    {
      value: '#8E7BFF',
      displayData: 'Queries',
      viewData: 'Queries',
      mappingKey: 'queries',
    },
    {
      value: '#FED87B',
      displayData: 'Responses',
      viewData: 'Responses',
      mappingKey: 'responses'
    },
    {
      value: '#999999',
      displayData: 'Response rate',
      viewData: 'percentage',
      mappingKey: 'percentage',
      type: 'percentage'
    },
  ],
  BOT_ANALYTICS_ACCURACY: [
    {
      value: '#696DEC',
      displayData: 'Accuracy',
      viewData: 'Accuracy',
      mappingKey: 'accuracy',
      type: 'percentage',
    },
  ],
  BOT_ANALYTICS_RESPONSE_RATE: [
    {
      value: '#696DEC',
      displayData: 'Training data score',
      viewData: 'Training data score',
      mappingKey: 'responseRate',
      type: 'percentage',
    }
  ]
};

export const actualTypeToDataSetSentiment = {
  SENTIMENT: [
    {
      value: `#ffffff`,
      displayData: 'Total',
    },
    {
      value: `#49A65D`,
      displayData: 'Positive',
      percentageKey: 'positivePercentage',
    },
    {
      value: `#FEE4C0`,
      displayData: 'Neutral',
      percentageKey: 'neutralPercentage',
    },
    {
      value: `#E6484E`,
      displayData: 'Negative',
      percentageKey: 'negativePercentage',
    },
  ],
};

export const actualTypeToDataSetNewMember = {
  NEW_MEMBERS: [
    {
      value: `#B897FF`,
      displayData: 'New member',
      percentageKey: 'New member',
    },
  ],
};

export const actualTypeToDataSetTotalMember = {
  TOTAL_MEMBERS: [
    {
      value: '#ffffff',
      displayData: 'Total',
    },
    {
      value: `#8E7BFF`,
      displayData: 'Active',
      percentageKey: 'activePercentage',
    },
    {
      value: `#E6E3FA`,
      displayData: 'Inactive',
      percentageKey: 'inactivePercentage',
    },
    // INFO : ENG-5185
    // {
    //   value: `#CEC9EF`,
    //   displayData: 'Lurker',
    //   percentageKey: 'lurkerPercentage',
    // },
  ],
};

export const actualTypeToDataSetResponsiveness = {
  RESPONSE_RATE: [
    {
      value: `#B897FF`,
      viewData: 'Response Rate',
      percentageKey: 'responseRate',
    },
  ],

  FIRST_RESPONSE: [
    {
      value: `#B897FF`,
      displayData: 'posts',
      viewData: 'Posts',
    },
  ],
};

export const ANALYTICS_PROVIDER_CONSTANT = [
  {
    tabName: 'Slack',
    tabValue: 'slack',
    feature: APP_FEATURE_SLACK_INTEGRATION,
    image: slack,
  },
  {
    tabName: 'Discord',
    tabValue: 'discord',
    feature: APP_FEATURE_DISCORD_INTEGRATION,
    image: discord,
  },
  {
    tabName: 'GitHub',
    tabValue: 'github',
    feature: APP_FEATURE_GITHUB_INTEGRATION,
    image: github,
  },
  {
    tabName: 'Discourse',
    tabValue: 'discourse',
    feature: APP_FEATURE_DISCOURSE_INTEGRATION,
    image: discourse,
  },
  {
    tabName: 'Web',
    tabValue: 'widget',
    tabImageBot: webTabIcon,
  },
  {
    tabName: 'Zendesk',
    tabValue: 'zendesk',
    feature: APP_FEATURE_ZENDESK_INTEGRATION,
    image: zendeskLogo,
  },
  {
    tabName: 'Confluence',
    tabValue: 'confluence',
    feature: APP_FEATURE_CONFLUENCE_INTEGRATION,
    image: confluenceLogo,
  },
  {
    tabName: 'Notion',
    tabValue: 'notion',
    feature: APP_FEATURE_NOTION_INTEGRATION,
    image: notionLogo,
  },
  {
    tabName: 'Google Drive',
    tabValue: 'google_drive',
    feature: APP_FEATURE_GOOGLE_DRIVE_INTEGRATION,
    image: googleDrive,
  },
  {
    tabName: 'Freshdesk',
    tabValue: 'freshdesk',
    feature: APP_FEATURE_FRESHDESK_INTEGRATION,
    image: freshdeskLogo,
  },
];



// List of platform to not display on dashboard summary page
export const HIDE_ANALYTICS_PROVIDER_CONSTANT = ['notion', 'freshdesk', 'confluence', 'zendesk', 'widget', 'google_drive'];

// CHARTJS TOOLTIP
export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(255, 255, 255, 1)';
    tooltipEl.style.borderRadius = '5.55629px';
    tooltipEl.style.boxShadow = '0px 16.6689px 50.0066px rgba(121, 142, 157, 0.17)';
    tooltipEl.style.color = 'black';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.zIndex = '100';
    const table = document.createElement('table');
    table.style.margin = '0px';
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};

export const externalTooltipHandler = (context, keyToMetaDataMapping) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const tableHead = document.createElement('thead');
    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      tr.style.fontWeight = 600;
      tr.style.fontSize = '11px';
      tr.style.lineHeight = '13px';
      tr.style.textAlign = 'left';
      tr.style.color = '#8B8B8B';
      tr.style.whiteSpace = 'nowrap';
      tr.style.borderWidth = 0;
      const th = document.createElement('th');
      th.style.borderWidth = 0;
      const text = document.createTextNode(keyToMetaDataMapping[title]?.displayTooltip);
      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });
    const tableBody = document.createElement('tbody');
    keyToMetaDataMapping[Object.keys(keyToMetaDataMapping)[0]].color.forEach((item, index) => {
      if (item === '') {
        return;
      }
      const span = document.createElement('span');
      span.style.background = `${keyToMetaDataMapping[tooltip.title[0]].color[index]}`;
      span.style.borderColor = `${keyToMetaDataMapping[tooltip.title[0]].color[index]}`;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';
      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;
      const td1 = document.createElement('td');
      const td2 = document.createElement('td');
      td1.style.borderWidth = 0;
      td2.style.borderWidth = 0;
      const spanText1 = document.createElement('span');
      spanText1.innerText = `${keyToMetaDataMapping[tooltip.title[0]].displayData[index]}`;
      spanText1.style.fontStyle = 'normal';
      spanText1.style.fontWeight = '400';
      spanText1.style.fontSize = '10.7249px';
      spanText1.style.lineHeight = '13px';
      spanText1.style.color = '#191919';
      const spanText2 = document.createElement('span');
      spanText2.innerText = `${keyToMetaDataMapping[tooltip.title[0]].value[index]}`;
      spanText2.style.fontWeight = '700';
      spanText2.style.marginLeft = '20px';
      spanText2.style.fontSize = '14.2999px';
      spanText2.style.lineHeight = '17px';
      spanText2.style.textAlign = 'right';
      spanText2.style.color = '#191919';
      const spanText3 = document.createElement('span');
      spanText3.innerText = `${keyToMetaDataMapping[tooltip.title[0]].percentageValue[index]}%`;
      spanText3.style.width = '21px';
      spanText3.style.height = '12px';
      spanText3.style.fontStyle = 'normal';
      spanText3.style.fontWeight = '600';
      spanText3.style.fontSize = '10px';
      spanText3.style.lineHeight = '12px';
      spanText3.style.alignItems = 'center';
      spanText3.style.color = '#0FBA87';
      spanText3.style.flex = 'none';
      spanText3.style.order = '0';
      spanText3.style.flexGrow = '0';
      spanText3.style.marginLeft = '7px';
      td1.appendChild(span);

      let validPoint1 =
        !spanText1.innerText?.includes('undefined') && !spanText1.innerText?.includes('null');

      let validPoint2 =
        !spanText2.innerText?.includes('undefined') && !spanText2.innerText?.includes('null');

      let validPoint3 =
        !spanText3.innerText?.includes('undefined') && !spanText3.innerText?.includes('null');

      validPoint1 && td1.appendChild(spanText1);
      validPoint2 && td2.appendChild(spanText2);
      validPoint3 && td2.appendChild(spanText3);
      tr.appendChild(td1);
      tr.appendChild(td2);

      validPoint1 && (validPoint2 || validPoint3) && tableBody.appendChild(tr);
    });
    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.style.width = 'max-content';
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export const lineChartOption = {
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 5,
      borderWidth: 3,
      pointStyle: 'circle',
      hoverRadius: 5,
      fill: true,
      backgroundColor: 'rgba(0,0,0,0.1)',
      pointBackgroundColor: 'white',
      pointBorderWidth: 3,
      hoverBorderWidth: 3,
    },
  },
  animation: {
    duration: 0, // general animation time
  },
  hover: {
    animationDuration: 0, // duration of animations when hovering an item
  },
  responsiveAnimationDuration: 0, // animation duration after a resize
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    legend: {
      position: 'top',
      align: 'end',
    },
    title: {
      display: true,
      text: `All date-time calculations are in PST timezone`,
      position: 'bottom',
      align: 'end',
      font: {
        weight: '400',
        size: 11,
        style: 'italic',
      },
    },
  },
  responsive: true,
  tension: 0.4,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: false,
      ticks: {
        count: 5,
      },
      grid: {
        display: true,
      },
    },
  },
};

export const barChartOption = {
  maintainAspectRatio: false,
  animation: {
    duration: 0, // general animation time
  },
  hover: {
    animationDuration: 0, // duration of animations when hovering an item
  },
  responsiveAnimationDuration: 0, // animation duration after a resize
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    legend: {
      position: 'top',
      align: 'end',
    },
    title: {
      display: true,
      text: `All date-time calculations are in PST timezone`,
      position: 'bottom',
      align: 'end',
      font: {
        weight: '400',
        size: 11,
        style: 'italic',
      },
    },
  },
  responsive: true,
  tension: 0.4,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        count: 5,
      },
      grid: {
        display: true,
      },
    },
  },
};

export const dataToTooltipDataMapper = (data, sampleSet, type, tooltipValueKey) => {
  return data?.results.reduce((acc, iter) => {
    acc[iter.displayDate] = {
      percentageValue: sampleSet[type].map((iterator) => {
        return iter[iterator.percentageKey];
      }),
      displayTooltip: iter.displayTooltip || iter.date,
      color: sampleSet[type].map((iterator) => {
        return iterator.value;
      }),
      displayData: sampleSet[type].map((iterator) => {
        if (
          type === 'MEMBER' ||
          type === 'MEMBER_GITHUB' ||
          type === 'RESPONSE_RATE' ||
          type === 'FIRST_RESPONSE'
        ) {
          return iterator.viewData;
        }
        return iterator.displayData;
      }),
      value: sampleSet[type].map((iterator) => {
        if (type === 'ASSIST_ENGAGEMENT_TREND' || type === 'BOT_ANALYTICS_RESPONSE_RATE') {
          return iter[iterator.mappingKey] !== undefined
            ? iterator?.type === 'percentage'
              ? `${iter?.[iterator.mappingKey]}%`
              : iter?.[iterator.mappingKey]
            : '';
        }
        // tooltipValueKey introduced here to allow using custom keys which are not in API
        if (type === 'MEMBER_GITHUB' || type === 'RESPONSE_RATE' || type === 'FIRST_RESPONSE') {
          return iter[iterator.displayData] !== undefined
            ? iterator?.type === 'percentage'
              ? `${iter?.[iterator.displayData]}%`
              : iter?.[iterator.displayData]
            : iterator?.type === 'percentage'
            ? `${iter?.[tooltipValueKey]}%`
            : iter?.[tooltipValueKey];
        } else {
          return iter[iterator.displayData.toLowerCase()] !== undefined
            ? iterator?.type === 'percentage'
              ? `${iter[iterator.displayData.toLowerCase()]}%`
              : iter[iterator.displayData.toLowerCase()]
            : iterator?.type === 'percentage'
            ? `${iter?.[tooltipValueKey?.toLowerCase()]}%`
            : iter?.[tooltipValueKey?.toLowerCase()];
        }
      }),
    };
    return acc;
  }, {});
};

export const dataToTooltipDataMapperRetention = (data) => {
  let finalObject = data?.results.reduce((acc, iter) => {
    if (acc[iter.frequencySequence]) {
      acc[iter.frequencySequence].displayData = [
        ...acc[iter.frequencySequence]?.displayData,
        iter.displayTooltip,
      ];
      acc[iter.frequencySequence].value = [
        ...acc[iter.frequencySequence]?.value,
        iter.joinedMemberCount,
      ];
      acc[iter.frequencySequence].joinedMember = [
        ...acc[iter.frequencySequence]?.joinedMember,
        iter.joinedMemberCount,
      ];
      acc[iter.frequencySequence].percentageValue = [
        ...acc[iter.frequencySequence]?.percentageValue,
        iter.activePercentage,
      ];
    } else {
      acc[iter.frequencySequence] = {
        displayTooltip: iter.frequencySequence,
        displayData: [iter.displayTooltip],
        value: [iter.joinedMemberCount],
        joinedMember: [iter.joinedMemberCount],
        percentageValue: [iter.activePercentage],
      };
    }
    return acc;
  }, {});

  Object.keys(finalObject).forEach((inst) => {
    finalObject[inst].displayData.splice(0, 0, finalObject[inst].displayData.pop());
    finalObject[inst].value = [];
    finalObject[inst].joinedMember.splice(0, 0, finalObject[inst].joinedMember.pop());
    finalObject[inst].color = ['#EBC057'];
    finalObject[inst].percentageValue.splice(0, 0, finalObject[inst].percentageValue.pop());
  });
  return finalObject;
};

export const legendsCallback = (chart) => {
  return chart?.data?.datasets?.map((dataset, i) => ({
    datasetIndex: i,
    text: dataset.label,
    fillStyle: dataset.backgroundColor,
    strokeStyle: dataset.borderColor,
    hidden: !chart.getDatasetMeta(i)?.visible,
  }));
};
