import { AnyAction } from 'redux';
import { SET_YEAR_END_REVIEW_DATA_LOADING } from 'redux/yearEndReviewProvider/types';
import {
  ACTIVITY_ERROR_HANDLE,
  ACTIVITY_SUMMARY_INSIGHTS_ERROR_HANDLE,
  ACTIVITY_MEMBERS_HELPFUL_ERROR_HANDLE,
  ACTIVITY_MEMBERS_DISCUSSION_ERROR_HANDLE,
  ACTIVITY_MEMBERS_NEW_ERROR_HANDLE,
  GET_ACTIVITY_MESSAGES_POSTED_DATA,
  GET_ACTIVITY_SUMMARY_DATA,
  GET_ACTIVITY_SUMMARY_INSIGHTS_DATA,
  GET_ACTIVITY_TOP_CHANNELS_DATA,
  GET_ACTIVITY_TOP_MESSAGES_DATA,
  GET_DAILY_ACTIVE_CHANNELS_DATA,
  GET_DAILY_ACTIVE_MEMBERS_DATA,
  GET_ACTIVITY_MEMBERS_HELPFUL_DATA,
  GET_ACTIVITY_MEMBERS_DISCUSSION_DATA,
  GET_ACTIVITY_MEMBERS_NEW_DATA,
  SET_ACTIVITY_TOP_MEMBERS_DATA,
  SET_ACTIVITY_LOADING,
  SET_ACTIVITY_HELPFUL_MEMBERS_LOADING,
  SET_ACTIVITY_DISCUSSION_MEMBERS_LOADING,
  SET_ACTIVITY_MEMBERS_NEW_LOADING,
  SET_SELECTED_ACTIVITY_CHANNEL,
  SET_SELECTED_ACTIVITY_MESSAGE_INTEGRATION,
  UPDATE_ACTIVITY_INTEGRATION,
  SET_ACTIVITY_TOP_MESSAGES_DATA,
  SET_ACTIVITY_SUMMARY_INSIGHTS_LOADING,
  MEMBERS_ERROR_HANDLE,
  MEMBERS_TOAST_HANDLE,
  GET_YEAR_END_REVIEW_BANNER_DATA,
  ERROR_HANDLE_YEAR_END_REVIEW_BANNER,
  SET_SIDEBAR_TOGGLE_STATE,
} from '../types';

const initialState = {
  activitySummary: [],
  activitySummaryInsights: {},
  activityMembersHelpful: [],
  activityMembersDiscussion: [],
  activityMembersNew: [],
  activityProvider: [],
  activityError: '',
  activityLoading: false,
  activityChannels: [],
  activityMessagesPosted: [],
  activityTopChannels: [],
  activityTopMembers: [],
  selectedActivityChannel: [-1],
  selectedActivityMessageIntegration: ['none'],
  activityTopMessages: undefined,
  currentProvider: '',
  activitySummaryInsightsFetchFail: false,
  activityMembersHelpfulFetchFail: false,
  activityMembersDiscussionFetchFail: false,
  activityMembersNewFetchFail: false,
  activityMembersHelpfulLoading: false,
  activityMembersDiscussionLoading: false,
  activityMembersNewLoading: false,
  activityMembersTopMessagesLoading: false,
  activityMembersTopMessagesFetchFail: false,
  activitySummaryInsightLoading: false,
  yearEndReviewBanner: null,
  isYearEndReviewLoading: false,
  errorYearEndReview: false,
  sidebarToggleState: localStorage.getItem('_sidebarToggleState') === '0' ? false : true,
};

const activityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ACTIVITY_SUMMARY_DATA:
      return {
        ...state,
        activitySummary: payload,
        activityLoading: false,
      };
    case GET_ACTIVITY_SUMMARY_INSIGHTS_DATA:
      return {
        ...state,
        activitySummaryInsights: payload,
        activitySummaryInsightLoading: false,
        activitySummaryInsightsFetchFail: false,
      };
    case SET_ACTIVITY_SUMMARY_INSIGHTS_LOADING:
      return {
        ...state,
        activitySummaryInsightLoading: true,
        activitySummaryInsightsFetchFail: false,
        activityMembersHelpfulFetchFail: false,
        activityMembersDiscussionFetchFail: false,
        activityMembersNewFetchFail: false,
      };
    case GET_DAILY_ACTIVE_MEMBERS_DATA:
      return {
        ...state,
        activityProvider: payload,
        activityLoading: false,
      };
    case GET_ACTIVITY_MESSAGES_POSTED_DATA:
      return {
        ...state,
        activityMessagesPosted: payload,
        activityLoading: false,
      };
    case SET_ACTIVITY_TOP_MESSAGES_DATA:
      return {
        ...state,
        activityMembersTopMessagesLoading: true,
        activityMembersTopMessagesFetchFail: false,
      };
    case GET_ACTIVITY_TOP_MESSAGES_DATA:
      return {
        ...state,
        activityTopMessages: payload,
        activityLoading: false,
        activityMembersTopMessagesLoading: false,
        activityMembersTopMessagesFetchFail: false,
      };
    case GET_DAILY_ACTIVE_CHANNELS_DATA:
      return {
        ...state,
        activityChannels: payload,
        activityLoading: false,
      };
    case GET_ACTIVITY_TOP_CHANNELS_DATA:
      return {
        ...state,
        activityTopChannels: payload,
        activityLoading: false,
      };
    case GET_ACTIVITY_MEMBERS_HELPFUL_DATA:
      return {
        ...state,
        activityMembersHelpful: payload,
        activityLoading: false,
        activityMembersHelpfulLoading: false,
        activityMembersHelpfulFetchFail: false,
      };
    case GET_ACTIVITY_MEMBERS_DISCUSSION_DATA:
      return {
        ...state,
        activityMembersDiscussion: payload,
        activityLoading: false,
        activityMembersDiscussionLoading: false,
        activityMembersDiscussionFetchFail: false,
      };
    case GET_ACTIVITY_MEMBERS_NEW_DATA:
      return {
        ...state,
        activityMembersNew: payload,
        activityLoading: false,
        activityMembersNewLoading: false,
        activityMembersNewFetchFail: false,
      };
    case SET_ACTIVITY_TOP_MEMBERS_DATA:
      return {
        ...state,
        activityMembersNewLoading: true,
        activityMembersNewFetchFail: false,
      };
    case ACTIVITY_ERROR_HANDLE:
      return {
        ...state,
        activityError: payload,
        activityLoading: false,
      };
    case ACTIVITY_SUMMARY_INSIGHTS_ERROR_HANDLE:
      return {
        ...state,
        activityError: payload,
        activityLoading: false,
        activitySummaryInsightLoading: false,
        activitySummaryInsightsFetchFail: true,
      };
    case ACTIVITY_MEMBERS_HELPFUL_ERROR_HANDLE:
      return {
        ...state,
        activityError: payload,
        activityLoading: false,
        activityMembersHelpfulFetchFail: true,
      };
    case ACTIVITY_MEMBERS_DISCUSSION_ERROR_HANDLE:
      return {
        ...state,
        activityError: payload,
        activityLoading: false,
        activityMembersDiscussionFetchFail: true,
      };
    case ACTIVITY_MEMBERS_NEW_ERROR_HANDLE:
      return {
        ...state,
        activityError: payload,
        activityLoading: false,
        activityMembersNewFetchFail: true,
      };
    case SET_SELECTED_ACTIVITY_CHANNEL:
      return {
        ...state,
        selectedActivityChannel: payload,
      };
    case SET_SELECTED_ACTIVITY_MESSAGE_INTEGRATION:
      return {
        ...state,
        selectedActivityMessageIntegration: payload,
      };
    case SET_ACTIVITY_LOADING:
      return {
        ...state,
        activityLoading: true,
        activitySummaryInsightsFetchFail: false,
        activityMembersHelpfulFetchFail: false,
        activityMembersDiscussionFetchFail: false,
        activityMembersNewFetchFail: false,
      };
    case SET_ACTIVITY_HELPFUL_MEMBERS_LOADING:
      return {
        ...state,
        activityMembersHelpfulLoading: true,
        activityMembersHelpfulFetchFail: false,
      };
    case SET_ACTIVITY_DISCUSSION_MEMBERS_LOADING:
      return {
        ...state,
        activityMembersDiscussionLoading: true,
        activityMembersDiscussionFetchFail: false,
      };
    case SET_ACTIVITY_MEMBERS_NEW_LOADING:
      return {
        ...state,
        activityMembersNewLoading: true,
        activityMembersNewFetchFail: false,
      };
    case UPDATE_ACTIVITY_INTEGRATION: {
      return {
        ...state,
        currentProvider: payload,
      };
    }
    case GET_YEAR_END_REVIEW_BANNER_DATA: {
      return {
        ...state,
        yearEndReviewBanner: payload,
        isYearEndReviewLoading: false,
        errorYearEndReview: false,
      };
    }
    case SET_YEAR_END_REVIEW_DATA_LOADING: {
      return {
        ...state,
        isYearEndReviewLoading: true,
        errorYearEndReview: false,
      };
    }
    case ERROR_HANDLE_YEAR_END_REVIEW_BANNER: {
      return {
        ...state,
        isYearEndReviewLoading: false,
        errorYearEndReview: true,
      };
    }
    case SET_SIDEBAR_TOGGLE_STATE: {
      return {
        ...state,
        sidebarToggleState: payload,
      };
    }
    default:
      return state;
  }
};

export default activityReducer;
