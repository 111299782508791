import axios, { AxiosResponse, CancelToken } from 'axios';
import { loadCommunityIdFromLocalStorage } from 'utils/segment';

const ContentSecurityPolicy = `default-src * 'unsafe-inline' 'unsafe-eval'; script-src * 'unsafe-inline' 'unsafe-eval'; connect-src * 'unsafe-inline'; img-src * data: blob: 'unsafe-inline'; frame-src *; style-src * 'unsafe-inline'`;

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

const getFileNameFromBlobResponse = (response) => {
  const disposition = response.headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches && matches[1]) {
      return matches[1]?.replace(/['"]/g, '');
    }
  }
  return null;
};

const globalResponseErrorHandlers = [];
let communityConfig = null;
if (typeof window !== 'undefined') {
  // Perform localStorage action
  communityConfig = loadCommunityIdFromLocalStorage();
  // communityConfig = {
  //   userId: 602,
  //   communityId: 52,
  //   communityName: 'Alpha-Community',
  //   orgId: 202,
  //   orgName: 'fthreadoincoksag',
  // };
}

export const addGlobalResponseErrorHandler = (handler) => {
  if (typeof handler !== 'function') {
    console.error('handler is not a function', handler);
    return;
  }

  globalResponseErrorHandlers.push(handler);
};

axios.interceptors.response.use(
  (response) => {
    if (!response.config?.customApiCall) {
      return response;
    }

    if (response.request.responseType === 'blob') {
      return {
        blob: response.data,
        fileName: getFileNameFromBlobResponse(response),
      };
    }
    return response?.data;
  },
  (error) => {
    if (error.response?.status === 403 || error.response?.status === 401) {
      console.error(`Bad response ${error.response.status}`);
      globalResponseErrorHandlers.forEach((handler) => handler(error));
    }
    return Promise.reject(error);
  },
);

class Api {
  static defaultConfig() {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
        'X-Frame-Options': 'SAMEORIGIN',
        'Permissions-Policy': '',
        'X-XSS-Protection': '1; mode=block',
        'Content-Security-Policy': ContentSecurityPolicy.replace(/\s{2,}/g, ' ').trim(),
        'X-Content-Security-Policy': ContentSecurityPolicy.replace(/\s{2,}/g, ' ').trim(),
        'Threado-Org': communityConfig?.orgId,
      },
    };
  }

  /**
   * Fetch a url from the API
   * @param {string} url
   * @param config
   * @param fullURL
   * @returns {Promise<*>}
   */
  static get(url, config = {}, fullURL = '') {
    return this.xhr('get', url, {}, config, fullURL);
  }

  static post(url, data = {}, config = {}, fullURL = '') {
    return this.xhr('post', url, data, config, fullURL);
  }

  static put(url, data = {}, config = {}, fullURL = '') {
    return this.xhr('put', url, data, config, fullURL);
  }

  static patch(url, data = {}, config = {}, fullURL = '') {
    return this.xhr('patch', url, data, config, fullURL);
  }

  static delete(url, data = {}, config = {}, fullURL = '') {
    return this.xhr('delete', url, data, config, fullURL);
  }

  static getApiURL() {
    // return `https://new-alpha-us-east-2-api.threado.com/api`;
    return `${process.env.REACT_APP_API_BASE_URL}/api`;
  }

  /**
   * Base fetch method. Should not be used directly, but through above functions.
   * @param method
   * @param url
   * @param data?
   * @param config?
   * @returns {Promise<*>}
   */
  static async xhr(method, url, data = {}, config = {}, fullURL) {
    // @TODO implement config if needed
    const API_URL = this.getApiURL();
    const setUrl = fullURL ? url : `${API_URL}/${url}`;
    const defaultConfig = this.defaultConfig();
    const options = {
      ...this.defaultConfig(),
      method,
      data,
      withCredentials: true,
      url: setUrl,
      crossDomain: true,
      mode: 'cors',
      ...config,
      headers: {
        ...defaultConfig.headers,
        ...config.headers,
        // ...config.headers,
      },
      customApiCall: true,
    };
    // @TODO Add handlers
    const xhrResult = await axios(options);
    return xhrResult;
  }
}

export default Api;
