import React, { useEffect, useState } from 'react';
import { AvatarButton, AvatarButtonSidebar } from './HeaderStyle';
import { UseCommunityMenu } from './useCommunityMenu';
import CommunitySelecter from './CommunitySelecter';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetCommunities,
  useGetCurrentCommunity,
  useGetSidebarToggleState,
} from '../../context/hooks';
import {
  selectCommunityAction,
  switchWorkspacePostAction,
} from 'redux/communityProvider/actions/communityAction';
import { useGetUserInfo } from '../../context/hooks';
import { useBooleanState, useDataState } from 'utils/dataStateHook';
import { MainContent } from 'styledComponents/commonStyled';
import { useHistory } from 'react-router';
import { useQuery } from 'utils/useQuery';
import { colorToken } from 'theme/designToken';
import CreateCommunityModal from '../Community/CreateCommunityModal';
import { trackUserEvent } from 'utils/segment';
import { Image } from 'styledComponents/commonStyled';
import downwardArrow from 'assets/images/icons/downwardArrow.svg';
import {
  getCommunitySignupType,
  isExcludedPath,
  redirectUrlMapperBasedOnSignupType,
  urlsTypeListExcludeForCommunitySwitch,
} from 'utils/common';

export default function CommunityMenu() {
  const modal = useBooleanState();
  const anchorEl = useDataState(null);
  const open = Boolean(anchorEl.value);
  const router = useHistory();
  const pageInitialConfiguration = useQuery();
  const sidebarToggleState = useSelector(useGetSidebarToggleState);
  const handleClick = (event) => {
    anchorEl.set(event.currentTarget);
  };
  const handleClose = () => {
    anchorEl.set(null);
  };
  const communities = useSelector(useGetCommunities);
  const userInfo = useSelector(useGetUserInfo);
  const communityData = useSelector(useGetCurrentCommunity);
  const dispatch = useDispatch();
  const colors = [
    '#146152', // (RGB: 20, 97, 82)
    '#44803F', // (RGB: 68, 128, 63)
    '#B4CF66', // (RGB: 180, 207, 102)
    '#FFECC5', // Corrected from #FFECSC (RGB: 255, 236, 92)
    '#FF5A33', // Corrected from #FFSA33 (RGB: 255, 90, 51)
  ];

  const [backgroundColor, setBackgroundColor] = useState(colors[0]);

  // Effect to set a random color when the component mounts
  useEffect(() => {
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    setBackgroundColor(randomColor);
  }, []); // Empty dependency array means this will only run once, on mount

  useEffect(() => {
    if (
      pageInitialConfiguration &&
      pageInitialConfiguration?.get?.('community') &&
      typeof pageInitialConfiguration?.get?.('community') === 'string'
    ) {
      selectCommunity(parseInt(pageInitialConfiguration?.get?.('community')));
    }
  }, [pageInitialConfiguration]);
  
  const selectCommunity = (id) => {
    if (communityData && communityData.id != id && userInfo) {
      let nextCommunity = communities.find((uc) => uc.id === id);
      switchWorkspacePostAction(nextCommunity)
        .then(() => {})
        .catch((err) => {
          console.warn('Failed to switch community!');
        });
      dispatch(selectCommunityAction(nextCommunity, userInfo));
      // There is a pre-existing, issue here, when we switch the community
      // ignore that as of now and adding code to re-direct to dashboard
      // when community is switched from /accounts page
      const pathname = router.location.pathname;
      const isExcludedPathUrl = isExcludedPath(pathname, urlsTypeListExcludeForCommunitySwitch);

      if (
        getCommunitySignupType(communityData) === 'COMMUNITY_AND_THREADO_AI' &&
        getCommunitySignupType(nextCommunity) === 'THREADO_AI' &&
        !isExcludedPathUrl
      ) {
        router.push({
          pathname: redirectUrlMapperBasedOnSignupType.THREADO_AI.defaultThreadoAIUrl,
        });
      }
      window.location.reload();
    }
    handleClose();
  };

  if (!communityData) {
    return <div />;
  }

  return (
    <>
      <MainContent
        style={{
          flexGrow: '0',
          display: 'flex',
          flexFlow: 'column',
          padding: '0px',
          justifyContent: 'flex-start',
          marginLeft: '-20px',
          height: '50px',
        }}>
        {sidebarToggleState ? (
          <div
            id="avatarButton"
            aria-controls="avatarButton-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            show_background="true"
            style={{
              marginLeft: '0px',
              marginRight: '0px',
              marginBottom: '25px',
              cursor: 'pointer',
            }}>
            <div className="sidebarMenuChangeAndIconWrapper">
              <div className="sidebarCommunityButtonWrapper">
                <div className="sidebarCommunityButtonInnerWrapper">
                  <span
                    style={{ backgroundColor: backgroundColor }}
                    className="sidebarCommunityButtonWrapperSpan">
                    {communityData?.name.substring(0, 1)}
                  </span>
                </div>
              </div>

              <span className="menuNameEllipsesWrapper">{communityData.name}</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  marginRight: '8px',
                }}>
                <Image src={downwardArrow} height={5} width={8} />
              </div>
            </div>
          </div>
        ) : (
          <div
            id="avatarButton"
            aria-controls="avatarButton-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            show_background="true"
            style={{ marginLeft: '0px', marginRight: '0px', cursor: 'pointer' }}>
            <div
              style={{
                background: 'rgb(64, 54, 81)',
                borderRadius: '5px',
                height: '38px',
                width: '38px',
                display: 'flex',
                placeContent: 'center',
                flexFlow: 'column nowrap',
                alignItems: 'center',
                paddingTop: '0px',
                marginBottom: '25px',
              }}>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 'bolder',
                  color: '#FFFFFF',
                }}>
                {communityData.name.substring(0, 1)}
              </span>
            </div>
          </div>
        )}

        <UseCommunityMenu
          id="basic-menu"
          anchorEl={anchorEl.value ? anchorEl.value : null}
          open={open}
          onClose={handleClose}
          sx={{
            left: sidebarToggleState ? '13px !important' : '9px !important',
            marginTop: sidebarToggleState ? '8px !important' : '32px !important',
            // border: `none !important`
          }}
          MenuListProps={{
            'aria-labelledby': 'avatarButton',
          }}>
          <div>
            <span style={{ marginBottom: '12px !important' }} className="navigation-workspace-text">
              WORKSPACES
            </span>
            {communities.map((community) => (
              <CommunitySelecter
                key={community.id}
                name={community.name}
                role={community.role}
                integrations={Object.keys(community.integrations)}
                id={community.id}
                selectCommunity={selectCommunity}
                selected={community.id === communityData.id}
                community={community}
              />
            ))}
            <hr className="navigation-community-divider" />

            <div>
              <div
                role="none"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  trackUserEvent('Create Workspace', {});
                  modal.setTrue();
                  handleClose();
                }}>
                <span className="navigation-workspace-text">+</span>
                <span
                  className="navigation-workspace-text"
                  style={{ marginLeft: '6px !important' }}>
                  Add Workspace
                </span>
              </div>
            </div>
            {/* <div>
              <div
                role="none"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  trackUserEvent('Manage Workspace', {});
                  router.push({
                    pathname: `/settings/community`,
                  });
                  handleClose();
                  // modal.setTrue();
                }}>
                <span
                  className="navigation-workspace-text"
                  style={{ marginLeft: '6px !important' }}>
                  Manage workspace
                </span>
              </div>
            </div> */}
          </div>
        </UseCommunityMenu>
      </MainContent>
      <CreateCommunityModal
        open={modal.value}
        handleClose={() => modal.setFalse()}
        communityData={communityData}
        isFromCreate={true}
      />
    </>
  );
}

// export default CommunityMenu;
