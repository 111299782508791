// STEP 1: Get Session Tokens
export const GET_SESSION_TOKEN_FOR_CURRENT_TRAINING = 'GET_SESSION_TOKEN_FOR_CURRENT_TRAINING';
export const GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_LOADING =
  'GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_LOADING';
export const GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_FAILED =
  'GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_FAILED';

// STEP 2: Check if gocobalt and added training provider successfully
export const INTEGRATION_VIA_COBALT_SUCCESSFUL = 'INTEGRATION_VIA_COBALT_SUCCESSFUL';
export const INTEGRATION_VIA_COBALT_FAILED = 'INTEGRATION_VIA_COBALT_FAILED';
// STEP 3: Initailize or re-initialize cobalt based on session token
export const CONNECT_INTEGRATION_WITH_SERVER_LOADING = 'CONNECT_INTEGRATION_WITH_SERVER_LOADING';
export const CONNECT_INTEGRATION_WITH_SERVER = 'CONNECT_INTEGRATION_WITH_SERVER';
export const CONNECT_INTEGRATION_WITH_SERVER_FAILED = 'CONNECT_INTEGRATION_WITH_SERVER_FAILED';
