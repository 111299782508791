import React, { useState } from 'react';
import {
  CustomDialog,
  DialogContainer,
  DialogHeader,
  DialogCloseButton,
  DeleteDialogText,
  PrimaryButton,
  Image,
} from 'styledComponents/commonStyled';
import { DisplayFlexBox } from 'styledComponents/settingsStyled';
import { platformsMapper } from 'constant/platformsMapper';
import {
  CustomBotInfoHelpText,
  StyledMatTextFieldWrapperUrl,
} from 'styledComponents/chatbotStyled';
import questionIcon from 'assets/images/icons/questionIconBot.svg';
import crossIcon from 'assets/images/icons/crossIcon.svg';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import { freshdeskConnectValidationSchema } from 'components/Chatbot/utils';
import { GlobalStyles } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCurrentCommunity, useGetUserInfo } from 'context/hooks';
import { isEmpty } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from 'context/auth/AuthContext';
import { useHistory } from 'react-router-dom';
import { submitFormFreshdeskAction } from 'components/Community/util';
import { CancelButton } from 'styledComponents/communityStyled';

const FreshdeskConnectPopup = ({ open, handleClose, isFromCreate }) => {
  const userInfo = useSelector(useGetUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const communityData = useSelector(useGetCurrentCommunity);
  const { reloadUser } = useAuth();
  const dispatch = useDispatch();
  const router = useHistory();

  const freshdeskUrlFormik = useFormik({
    initialValues: { indexURL: '', apiToken: '' },
    validationSchema: freshdeskConnectValidationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const redirectIntegrationSuccessPopup = () => {
    const searchParams = new URLSearchParams({ integration: 'success', provider: 'Freshdesk' });
    router.push({
      pathname: router.location.pathname,
      search: `?${searchParams.toString()}`,
    });
  };
  const submitForm = (subdomain, apiToken, name) => {
    const payload = {
      apiKey: apiToken,
      domain: subdomain,
      apiUsername: name || 'apiUsername',
    };
    if (!isEmpty(freshdeskUrlFormik.errors)) {
      return;
    }
    setIsLoading(true);
    submitFormFreshdeskAction(payload, dispatch, communityData.id)
      .then(() => {
        reloadUser();
        handleClose();
        if (!isFromCreate) {
          redirectIntegrationSuccessPopup();
        }
      })
      .catch((err) => {
        console.error({ err });
        handleClose();
        addToast(err?.response?.data?.message || 'An error occurred integrating freshdesk', {
          appearance: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <GlobalStyles
        styles={{
          '.MuiFormHelperText-root.Mui-error': {
            position: 'absolute',
            bottom: '-20px',
          },
        }}
      />
      <CustomDialog open={open} style={{ zIndex: 1999 }} onClose={handleClose}>
        <DialogContainer>
          <DialogHeader style={{ padding: '2px' }}>
            <DialogCloseButton style={{ top: '10px' }} onClick={handleClose}>
              <Image src={crossIcon} alt="closeCircle" width={10} height={10} />
            </DialogCloseButton>
          </DialogHeader>
        </DialogContainer>
        <div style={{ padding: '10px 30px' }}>
          <DisplayFlexBox style={{ gap: '12px', marginRight: '30px', marginTop: '10px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '6px',
                background: '#FFEFCB',
              }}>
              <Image src={platformsMapper['freshdesk']} width={17} height={21} />
            </div>

            <DeleteDialogText
              style={{ textAlign: 'left' }}>{`Connect your Freshdesk account`}</DeleteDialogText>
            <CustomBotInfoHelpText
              style={{ color: '#696dec' }}
              href="https://help.threado.com/en/articles/8600865-how-to-integrate-and-train-the-bot-on-freshdesk"
              target="_blank"
              rel="noreferrer">
              <Image src={questionIcon} height={13} width={13} alt="help" /> How to connect with
              Freshdesk?{' '}
            </CustomBotInfoHelpText>
          </DisplayFlexBox>
          <div style={{ width: '90%', marginLeft: 'auto' }}>
            <div
              style={{
                color: '#191919',
                fontSize: '13px',
                fontWeight: 600,
                letterSpacing: '-0.13px',
                margin: '20px 10px 10px 0',
              }}>
              Enter account subdomain
            </div>
            <DisplayFlexBox style={{ alignItems: 'center', gap: '5px' }}>
              <div
                style={{
                  color: '#191919',
                  fontSize: '14px',
                  fontWeight: 700,
                  letterSpacing: '-0.14px',
                }}>
                https://
              </div>
              <StyledMatTextFieldWrapperUrl
                name={`indexURL`}
                formik={freshdeskUrlFormik}
                variant="outlined"
                size={'small'}
                fullWidth
                inputProps={{ maxLength: 256 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {freshdeskUrlFormik.values.indexURL ? (
                        <></>
                      ) : (
                        <span
                          style={{
                            color: '#454545',
                            fontSize: '13px',
                            fontWeight: '400',
                            letterSpacing: '-0.13px',
                            opacity: '0.6',
                          }}>
                          threado-support
                        </span>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  color: '#191919',
                  fontSize: '14px',
                  fontWeight: 700,
                  letterSpacing: '-0.14px',
                }}>
                .freshdesk.com
              </div>
            </DisplayFlexBox>

            <div style={{ marginTop: '60px' }}>
              <div
                style={{
                  color: '#191919',
                  fontSize: '13px',
                  fontWeight: 600,
                  letterSpacing: '-0.13px',
                  margin: '20px 10px 10px 0',
                }}>
                Enter API token
              </div>
              <div
                style={{
                  color: '#959595',
                  fontSize: '12px',
                  fontWeight: 400,
                  marginBottom: '10px',
                }}>
                Admin can get token from Freshdesk account by going to their Profile Settings &gt;
                View API Key
              </div>
              <StyledMatTextFieldWrapperUrl
                name={`apiToken`}
                formik={freshdeskUrlFormik}
                variant="outlined"
                size={'small'}
                fullWidth
                inputProps={{ maxLength: 256 }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                margin: '30px 0 20px 0',
                justifyContent: 'flex-end',
                gap: '12px',
              }}>
              <CancelButton onClick={handleClose} style={{ margin: '0', height: '38px' }}>
                Cancel
              </CancelButton>
              <PrimaryButton
                disabled={
                  freshdeskUrlFormik['errors'].indexURL || freshdeskUrlFormik['errors'].apiToken
                }
                is_loading={isLoading}
                onClick={() =>
                  submitForm(
                    freshdeskUrlFormik.values.indexURL,
                    freshdeskUrlFormik.values.apiToken,
                    userInfo.name,
                  )
                }
                style={{ padding: '10px 40px', height: '38px' }}
                texttransform="unset">
                {'Connect'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default FreshdeskConnectPopup;
