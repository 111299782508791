import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import MatTextFieldWrapper from 'atoms/TextField';
import { SecondaryButton } from './commonStyled';
import ReactMarkdown from 'react-markdown';

export const DataSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 700px;
  min-width: 700px;
  margin-top: 20px;
`;

export const DataSourceItemContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
`;

export const DataSourceItemContent = styled.div`
  padding: 16px;
`;

export const DataSourceItemCtaContainer = styled.div`
  padding: 12px 16px;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.03);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const DataSourceItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DataSourceItemTitle = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #434343;
`;

export const DataSourceItemSubTitle = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d7d;
`;

export const PlatformConnectBox = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  min-width: 270px;
  width: 100%;
`;

export const PlatformNameTextContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const PlatformNameText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #191919;
`;

export const PlatformSourceTitle = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #8d8d8d;
  margin-bottom: 8px;
`;

export const PlatformSourceName = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #191919;
`;

export const AddUrlSidebarTitle = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #35354e;
`;

export const StyledMatTextFieldWrapperUrl = styled(MatTextFieldWrapper)`
  & .MuiOutlinedInput-notchedOutline {
    // border: none;
  }
  & .MuiOutlinedInput-input {
    font-size: 12px;
    font-weight: 700;
  }
`;

export const UrlDisplayContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0;
`;

export const UrlTextBox = styled.div`
  padding: 6px 8px;
  border: 1px solid #ebebeb;
  border-radius: 4px 0px 0px 4px;
  background: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(69 101 155);
  display: flex;
  align-items: center;
  gap: 6px;
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const UrlCountBox = styled.div`
  padding: 6px 8px;
  background-color: #ffffff;
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #3e5273;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const RemoveUrlBox = styled.div`
  background-color: #ffffff;
  padding: 6px 8px;
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-radius: 0px 4px 4px 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3e5273;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const TestBotContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 650px;
  min-width: 650px;
  margin-top: 20px;
`;

export const ChatBotBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 7.90059px 40px rgba(0, 0, 0, 0.06);
  border-radius: 18.9614px;
  padding: 12px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  height: 85vh;
  max-height: 800px;
`;

export const ChatBotName = styled(Typography)`
  font-weight: 700;
  font-size: 15.8833px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const CustomizeBoxContainer = styled.div`
  display: flex;
  margin-top: 20px;
  padding: 0 20px;
`;

export const EditorContainer = styled.div`
  background: #ffffff;
  // box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 0px 0px 15px 0px;
  // width: 60%;
  height: fit-content;
`;

export const PreviewContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
`;

export const SlackDisplayBox = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 300px;
  min-height: 450px;
  position: relative;

  border-radius: 8px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: 1600px) {
    width: 350px;
  }
`;

export const DiscordDisplayBox = styled.div`
  background: #313237;
  border-radius: 8px;
  width: 300px;
  min-height: 240px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: 1600px) {
    width: 350px;
  }
`;

export const SlackDislayHeader = styled.div`
  background: #3b133f;
  display: flex;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  gap: 10px;
`;
export const DiscordDislayHeader = styled.div`
  background: #383a3f;
  display: flex;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  gap: 10px;
  height: 39px;
`;

export const SlackHeaderText = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
`;

export const SlackDisplayContent = styled.div``;

export const DiscordDisplayContent = styled.div`
  background: #313237;
`;

export const SlackDisplayEditor = styled.div`
  display: flex;
`;

export const DiscordDisplayEditor = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SyncInProgress = styled.div`
  color: #31303d;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.12px;
  border-radius: 4px;
  background: #fef4d3;
  padding: 4px 8px;
`;

export const LinkButtonDataSource = styled(SecondaryButton)`
  height: 30px;
  font-size: 13px;
  font-weight: 500;
  background: #f6f7fb;
  border: 1px solid #f6f7fb;
  color: #696dec;
  &:hover {
    border: 1px solid #d4daef;
  }
  &:focus {
    border: 1px solid #696dec;
  }
  &:active {
    border: 1px solid #696dec;
  }
`;

export const CheckBoxLabel = styled(Typography)`
  color: #333;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.12px;
`;

export const WidgetPositionItem = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 20px 20px;
  cursor: pointer;
  border: 1px solid ${(props) => (props.selected ? '#696DEC' : '#dbdbdb')};
  background-color: ${(props) => (props.selected ? '#eeedfd' : '#ffffff')};
  border-right: ${(props) => (props.selected ? '1px solid #696DEC' : 'none')};
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid ${(props) => (props.selected ? '#696DEC' : '#dbdbdb')};
  }
  &:hover {
    border: 1px solid #696dec;
    background-color: #eeedfd;
  }
`;

export const InstallBotSwitcherContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #e2e4ee;
  background: #fff;
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const WidgetEmbedTitleText = styled(Typography)`
  color: #39384e;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;

export const WidgetEmbedSubTitleText = styled(Typography)`
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;

export const WidgetPositionPreviewBox = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: ${(props) =>
    props.position === 'CENTRE_LEFT' || props.position === 'BOTTOM_LEFT'
      ? '-20%'
      : props.position === 'CENTRE_RIGHT' || props.position === 'BOTTOM_RIGHT'
      ? '105%'
      : '0'};
  top: ${(props) =>
    props.position === 'CENTRE_LEFT' || props.position === 'CENTRE_RIGHT'
      ? '50%'
      : props.position === 'BOTTOM_LEFT' || props.position === 'BOTTOM_RIGHT'
      ? '88%'
      : '0'};
`;

export const MessageTextBotWithMarkdown = styled(ReactMarkdown)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding: 12px;
  padding-bottom: 2px;

  pre {
    margin: 0;
    word-break: break-all;
    white-space: break-spaces;
  }

  p {
    margin: 0;
    word-break: unset;
    white-space: break-spaces;
    font-size: 12px;
    margin: 0px 0px 10px 0px;
  }

  ol {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }

  code {
    display: inline-block;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid rgba(224, 231, 241, 1);
    background: #efefef;
    margin: 0;
    margin-top: 5px;
    word-break: break-all;
    white-space: break-spaces;
    font-size: 12px;
  }

  a {
    color: #1876d1;
    cursor: pointer;
    word-break: break-all;
  }

  li::marker {
    font-size: 12px;
  }
`;

// export const CustomBotInfoContainer = styled.div`
//   border-radius: 8px;
//   border: 1px solid #dee1ee;
//   background: ${(props) =>
//     props.is_slack_integrated ? 'linear-gradient(180deg, #dde2f6 0%, #f2f5ff 100%)' : '#ffffff'};
//   box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.03);
//   padding: 16px;
// `;

export const CustomBotInfoContainer = styled.div``;

export const CustomBotInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const CustomBotInfoHeadText = styled(Typography)`
  color: #434343;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 183.333% */
  letter-spacing: -0.12px;
`;

export const CustomBotInfoHelpText = styled.a`
  color: #686984;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CustomBotInfoBotNameTitle = styled(Typography)`
  color: #949494;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
`;

export const CustomBotInfoBotName = styled(Typography)`
  color: #191919;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  margin-top: 5px;
`;

export const AgentAssistSecondaryHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefff;
  padding: 10px;
`;

export const InstallBotText = styled.div`
  color: #474859;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
  margin-right: 0px;
  margin-left: 8px;
`;

export const CircularDiv = styled.div`
  background-color: #d2d4fa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
`;

export const CustomizeBotTabContentContainer = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 7.901px 40px 0px rgba(0, 0, 0, 0.06);
  padding: 12px;
  margin: 0 12px;
`;

export const CustomizeContainer = styled.div`
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  width: 550px;
  overflow: hidden;
`;

export const TextBrandVoice = styled.div`
  color: #191919;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.14px;
`;

export const SubTextBrandVoice = styled.div`
  color: #7d7d7d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.36px;
  margin-top: 8px;
`;

export const BrandVoiceContentText = styled.div`
  color: #191919;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-bottom: 12px;
`;

export const ToneText = styled.div`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
  margin: 12px 0;
`;

export const ToneItem = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 5px 10px;
  cursor: pointer;
  width: fit-content;
  border: ${(props) => (props.selected ? '1px solid #696dec' : '1px solid #d2d2d2')};
  &: ${(props) =>
    !props.selected
      ? `hover {
        border: 1px solid rgba(105, 109, 236, 0.5);`
      : ``};
`;

export const ToneItemText = styled.div`
  color: ${(props) => (props.selected ? '#696dec' : '#000')};
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  font-size: 12px;
  line-height: normal;
  letter-spacing: -0.24px;
`;

export const InstallPageInviteTitleText = styled.div`
  color: #191919;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.22px;
`;

export const InstallPageInviteSubTitleText = styled.div`
  color: #64748b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.12px;
`;

export const SelectionTypetext = styled.div`
  color: #aeaeae;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0;
`;

export const InviteAgentSidebarFooter = styled.div`
  background: #fff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.12);
  padding: 12px;
  padding-left: 30px;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const InviteAgentSecondaryHeader = styled.div`
  background: #efefff;
  padding: 4px 8px;
  display: flex;
  margin: 10px 0;
  align-items: center;
`;

export const BulkImportInviteText = styled.div`
  color: #474859;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
  margin-left: 10px;
`;

export const AssistAnalyticsBoxContainer = styled.div`
  background: #fff;
  stroke-width: 0.82px;
  stroke: #ebebeb;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.04));
  padding: 16px;
  border-radius: 8px;
  flex: 1 1 0%;
`;

export const NoTicketingSystemIntegratedBox = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 7.901px 40px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  padding-top: 50px;
  height: calc(100vh - 180px);
`;

export const NoTicketIntegrationTextTitle = styled.div`
  color: #39384e;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.54px;
`;

export const NoTicketIntegrationTextSubTitle = styled.div`
  color: #7d7d7d;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const SelectAiModelBox = styled.div`
  background: #fff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.03);
  padding: 30px;
`;

export const ModelTypeText = styled.div`
  color: #191919;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModelNameText = styled.div`
  color: #4e4e4e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ModelInfoDescription = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 12px 0;
`;

export const BotAnalyticsAccuracyContainer = styled.div`
  background: #fff;
  border-right: 1px solid rgba(224, 224, 224, 0.6);
  background-color: #ffffff;
  width: 215px;
  min-width: 215px;
  margin-top: 20px;
`;

export const BotAnalyticsAccuracyTitleText = styled.div`
  color: #848484;
  font-size: 13px;
  font-weight: 400;
  line-height: 167.336%;
  letter-spacing: -0.39px;
`;

export const BotAccuracyCount = styled.div`
  color: #000;
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 16px;
`;

export const BotAccuracyInsightText = styled.div`
  color: #848484;
  font-size: 12px;
  font-weight: 400;
  line-height: 167.336%;
  letter-spacing: -0.36px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const BotAccuracyInsightCount = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  color: ${(props) => (props.clickable ? '#0070E0' : '#000')};
  &: ${(props) =>
    props.clickable
      ? `hover {
        text-decoration: underline;`
      : ``};
`;


export const BotAccuracyInsightCountPercent = styled.div`
  color: #848484;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
`;

export const BotAccuracyStatusBox = styled.div`
  border-radius: 100px;
  color: #191919;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  margin-left: auto;
  height: 31px;
  background: ${(props) =>
    props.status === 'GOOD'
      ? 'rgba(30, 177, 242, 0.08)'
      : props.status === 'NOT_GOOD'
      ? 'rgba(224, 24, 24, 0.05)'
      : 'rgba(40, 224, 24, 0.08)'};
`;

export const AnalyticsBoxContentContainer = styled.div`
  background: #fff;
  filter: drop-shadow(0px 1.789px 5.368px rgba(0, 0, 0, 0.06));
  padding: 16px;
  margin: 16px;
  border-radius: 16px;
`;

export const AnalyticsBoxContainerTitle = styled.div`
  color: #191919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.32px;
`;
export const AnalyticsBoxContentSubtext = styled.div`
  color: #7d7d7d;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-top: 10px;
`;

export const NoTrainingDataText = styled.div`
  color: #363a57;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 173.5%;
`;

export const EncryptMaskedBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.color || '#0EA02E'};
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  padding: 3px 12px;
  border-radius: 100px;
  background-color: ${(props) => props.backgroundColor || 'rgba(105, 109, 236, 0.10)'};
`;

export const MsTeamsDisplayBox = styled.div`
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  width: 375px;
  min-height: 240px;
  max-height: 600px;
  position: relative;
  overflow: scroll;
  @media screen and (min-width: 1600px) {
    width: 375px;
  }
`;
