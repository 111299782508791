import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FilterOption, FilterOptionLabel } from 'styledComponents/memberStyled';
import { channelOptions } from '../../../constant/filterMenuData';
import { CheckIfFeatureActive } from 'context/useFeature';
import { CheckIfIntegrationActive } from 'context/useIntegration';
import { useSelector } from 'react-redux';
import { useGetCurrentCommunity } from 'context/hooks';
import { colorToken } from '../../../theme/designToken';
import { Image } from 'styledComponents/commonStyled';
import checkedBox from 'assets/images/icons/checkedBox.svg';
import dashUnchecked from 'assets/images/icons/dashUnchecked.svg';
import uncheckedBox from 'assets/images/icons/uncheckedBox.svg';

const IntegrationOptions = ({ channelsFilter, setChannelsFilter, setChannelFilterCallback }) => {
  const [state, setState] = useState({
    discord: false,
    slack: false,
    twitter: false,
    zoom: false,
    github: false,
    discourse: false,
  });
  const [allChannel, setAllChannel] = useState(true);
  const [filterShowLimit, setFilterShowLimit] = useState(3);
  const communityData = useSelector(useGetCurrentCommunity);

  const setFilterLimit = (filterLimit) => {
    if (filterLimit) {
      setFilterShowLimit(filterLimit);
    }
  };

  const handleChange = (event) => {
    if (
      channelsFilter?.length === 1 &&
      !channelsFilter?.includes('all') &&
      !event?.target?.checked
    ) {
      return;
    }
    if (allChannel) {
      setAllChannel(false);
    }
    if (channelsFilter.includes('all')) {
      channelsFilter.splice(channelsFilter.indexOf('all'), 1);
    }
    setState({
      ...state,
      [event.target.value]: event.target.checked,
    });

    if (!event.target.checked) {
      const filteredChannels = channelsFilter.filter((segment) => segment !== event.target.value);
      setChannelsFilter(filteredChannels);
      setChannelFilterCallback && setChannelFilterCallback(filteredChannels);
      return;
    }

    setChannelsFilter([...channelsFilter, event.target.value]);
    setChannelFilterCallback && setChannelFilterCallback([...channelsFilter, event.target.value]);
  };

  const handleAllChannelChange = (event) => {
    if (event.target.checked) {
      setChannelsFilter(['all']);
    }
    setState({
      discord: false,
      slack: false,
      twitter: false,
      zoom: false,
      github: false,
      discourse: false,
    });
    setAllChannel(event.target.checked);
    setChannelFilterCallback && setChannelFilterCallback(['all']);
  };

  useEffect(() => {
    setState({
      discord: channelsFilter.includes('discord'),
      slack: channelsFilter.includes('slack'),
      twitter: channelsFilter.includes('twitter'),
      zoom: channelsFilter.includes('zoom'),
      github: channelsFilter.includes('github'),
      discourse: channelsFilter.includes('discourse'),
    });
    if (channelsFilter.includes('all')) {
      setAllChannel(true);
      return;
    }
    setAllChannel(false);
  }, [channelsFilter]);

  const filteredChannelOptions = channelOptions.filter((option) => {
    return (
      CheckIfFeatureActive(option.feature) &&
      CheckIfIntegrationActive(option.value, communityData?.id)
    );
  });

  return (
    <>
      <FilterOption style={{ paddingBottom: '6px' }}>
        <Checkbox
          style={{
            paddingLeft: '0px',
            paddingRight: '2px',
            paddingTop: '0px',
            paddingBottom: '2px',
          }}
          name="channel"
          checked={allChannel}
          value="allChannel"
          disabled={channelsFilter?.includes('all')}
          onChange={handleAllChannelChange}
          icon={<Image src={dashUnchecked} alt="uncheckedBox" width={16} height={16} />}
          checkedIcon={<Image src={checkedBox} alt="uncheckedBox" width={16} height={16} />}
        />
        <FilterOptionLabel is_selected={allChannel.toString()}>All</FilterOptionLabel>
      </FilterOption>
      {filteredChannelOptions.map((option, index) => (
        <FilterOption key={option.name}>
          <Checkbox
            style={{
              paddingLeft: '0px',
              paddingRight: '2px',
              paddingTop: '0px',
              paddingBottom: '2px',
              display: index < filterShowLimit ? 'block' : 'none',
            }}
            name="channel"
            checked={state[option.value]}
            value={option.value}
            onChange={handleChange}
            icon={<Image src={uncheckedBox} alt="uncheckedBox" width={16} height={16} />}
            checkedIcon={<Image src={checkedBox} alt="uncheckedBox" width={16} height={16} />}
          />
          <div style={{ display: index < filterShowLimit ? 'block' : 'none' }}>
            <Image
              src={state[option.value] ? option.activeIconSrc : option.iconSrc}
              alt="uncheckedBox"
              width={20}
              height={20}
            />
          </div>

          <FilterOptionLabel
            style={{ display: index < filterShowLimit ? 'block' : 'none', marginTop: '-6px' }}
            is_selected={state[option.value].toString()}>
            {option.name}
          </FilterOptionLabel>
        </FilterOption>
      ))}
      {filteredChannelOptions.length <= 3 ? (
        <></>
      ) : filteredChannelOptions.length >= 4 && filterShowLimit === 3 ? (
        <span
          role="none"
          style={{
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '24px',
            alignItems: 'flex-end',
            textAlign: 'right',
            textTransform: 'uppercase',
            color: colorToken.colors.purpleText,
            cursor: 'pointer',
          }}
          onClick={() => setFilterLimit(channelOptions?.length)}>
          Show More
        </span>
      ) : (
        <span
          role="none"
          style={{
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '24px',
            alignItems: 'flex-end',
            textAlign: 'right',
            textTransform: 'uppercase',
            color: colorToken.colors.purpleText,
            cursor: 'pointer',
          }}
          onClick={() => setFilterLimit(3)}>
          Show Less
        </span>
      )}
    </>
  );
};

export default IntegrationOptions;
