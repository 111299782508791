import React from 'react';
import SidebarHeader from '../SidebarHeader';
import SidebarMenu from '../SidebarMenu';

const Sidebar = () => {
  return (
    <>
      <div>
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
