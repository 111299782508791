export const conditionParameterOptions = [
  {
    value: 'has_done_activity',
    label: 'Member has posted, replied or reacted',
  },
  {
    value: 'has_messaged',
    label: 'Member has posted messages',
  },
  {
    value: 'has_reacted',
    label: 'Member has posted reactions',
  },
];

export const automationDelayUnit = [
  { value: 'M', label: 'Minute(s)' },
  { value: 'H', label: 'Hour(s)' },
  { value: 'D', label: 'Day(s)' },
];

export const automationConditionUnit = [
  { value: 'gt', label: '> greater than' },
  { value: 'eq', label: '= equals' },
  { value: 'lt', label: '< less than' },
];

export const cohortType = [
  {
    value: 'NEW_MEMBER_JOINED',
    label: 'New Member has joined',
  },
];

export const cohortMetrics = [
  {
    value: 'MESSAGE_POSTED',
    label: 'Message Posted',
  },
  {
    value: 'REACTION_POSTED',
    label: 'Reaction Posted',
  },
];

export const cohortSize = [
  {
    value: 'WEEK',
    label: 'Week',
  },
];

export const cohortTimeRange = [
  {
    value: '6',
    label: 'Last 6 weeks',
  },
];

export const discordBotName = 'Community Admin Bot';

export const defaultRTEText = '<p><br></p>';

export const PREFILL_STRING_HELPFUL_DASHBOARD =
  'Here are some amazing members that I wanted to recognize for being the most helpful contributors in the community: {1} \nThank you for being awesome!! 🙌';

export const PREFILL_STRING_DISCUSSION_DASHBOARD =
  'Here are some amazing members that sparked most discussions in the community: {1} \nThank you for being awesome!! 🙌';

export const timeZoneOptions = [
  { value: 'Pacific/Midway', label: 'Midway Island, Samoa' },
  { value: 'Pacific/Pago_Pago', label: 'Pago Pago' },
  { value: 'Pacific/Honolulu', label: 'Hawaii' },
  { value: 'America/Anchorage', label: 'Alaska' },
  { value: 'America/Vancouver', label: 'Vancouver' },
  { value: 'America/Los_Angeles', label: 'Pacific Time (US and Canada)' },
  { value: 'America/Tijuana', label: 'Tijuana' },
  { value: 'America/Edmonton', label: 'Edmonton' },
  { value: 'America/Denver', label: 'Mountain Time (US and Canada)' },
  { value: 'America/Phoenix', label: 'Arizona' },
  { value: 'America/Mazatlan', label: 'Mazatlan' },
  { value: 'America/Winnipeg', label: 'Winnipeg' },
  { value: 'America/Regina', label: 'Saskatchewan' },
  { value: 'America/Chicago', label: 'Central Time (US and Canada)' },
  { value: 'America/Mexico_City', label: 'Mexico City' },
  { value: 'America/Guatemala', label: 'Guatemala' },
  { value: 'America/El_Salvador', label: 'El Salvador' },
  { value: 'America/Managua', label: 'Managua' },
  { value: 'America/Costa_Rica', label: 'Costa Rica' },
  { value: 'America/Montreal', label: 'Montreal' },
  { value: 'America/New_York', label: 'Eastern Time (US and Canada)' },
  { value: 'America/Indianapolis', label: 'Indiana (East)' },
  { value: 'America/Panama', label: 'Panama' },
  { value: 'America/Bogota', label: 'Bogota' },
  { value: 'America/Lima', label: 'Lima' },
  { value: 'America/Halifax', label: 'Halifax' },
  { value: 'America/Puerto_Rico', label: 'Puerto Rico' },
  { value: 'America/Caracas', label: 'Caracas' },
  { value: 'America/Santiago', label: 'Santiago' },
  { value: 'America/St_Johns', label: 'Newfoundland and Labrador' },
  { value: 'America/Montevideo', label: 'Montevideo' },
  { value: 'America/Araguaina', label: 'Brasilia' },
  { value: 'America/Argentina/Buenos_Aires', label: 'Buenos Aires, Georgetown' },
  { value: 'America/Godthab', label: 'Greenland' },
  { value: 'America/Sao_Paulo', label: 'Sao Paulo' },
  { value: 'Atlantic/Azores', label: 'Azores' },
  { value: 'Canada/Atlantic', label: 'Atlantic Time (Canada)' },
  { value: 'Atlantic/Cape_Verde', label: 'Cape Verde Islands' },
  { value: 'UTC', label: 'Universal Time UTC' },
  { value: 'Etc/Greenwich', label: 'Greenwich Mean Time' },
  { value: 'Europe/Belgrade', label: 'Belgrade, Bratislava, Ljubljana' },
  { value: 'CET', label: 'Sarajevo, Skopje, Zagreb' },
  { value: 'Atlantic/Reykjavik', label: 'Reykjavik' },
  { value: 'Europe/Dublin', label: 'Dublin' },
  { value: 'Europe/London', label: 'London' },
  { value: 'Europe/Lisbon', label: 'Lisbon' },
  { value: 'Africa/Casablanca', label: 'Casablanca' },
  { value: 'Africa/Nouakchott', label: 'Nouakchott' },
  { value: 'Europe/Oslo', label: 'Oslo' },
  { value: 'Europe/Copenhagen', label: 'Copenhagen' },
  { value: 'Europe/Brussels', label: 'Brussels' },
  { value: 'Europe/Berlin', label: 'Amsterdam, Berlin, Rome, Stockholm, Vienna' },
  { value: 'Europe/Helsinki', label: 'Helsinki' },
  { value: 'Europe/Amsterdam', label: 'Amsterdam' },
  { value: 'Europe/Rome', label: 'Rome' },
  { value: 'Europe/Stockholm', label: 'Stockholm' },
  { value: 'Europe/Vienna', label: 'Vienna' },
  { value: 'Europe/Luxembourg', label: 'Luxembourg' },
  { value: 'Europe/Paris', label: 'Paris' },
  { value: 'Europe/Zurich', label: 'Zurich' },
  { value: 'Europe/Madrid', label: 'Madrid' },
  { value: 'Africa/Bangui', label: 'West Central Africa' },
  { value: 'Africa/Algiers', label: 'Algiers' },
  { value: 'Africa/Tunis', label: 'Tunis' },
  { value: 'Africa/Harare', label: 'Harare, Pretoria' },
  { value: 'Africa/Nairobi', label: 'Nairobi' },
  { value: 'Europe/Warsaw', label: 'Warsaw' },
  { value: 'Europe/Prague', label: 'Prague Bratislava' },
  { value: 'Europe/Budapest', label: 'Budapest' },
  { value: 'Europe/Sofia', label: 'Sofia' },
  { value: 'Europe/Istanbul', label: 'Istanbul' },
  { value: 'Europe/Athens', label: 'Athens' },
  { value: 'Europe/Bucharest', label: 'Bucharest' },
  { value: 'Asia/Nicosia', label: 'Nicosia' },
  { value: 'Asia/Beirut', label: 'Beirut' },
  { value: 'Asia/Damascus', label: 'Damascus' },
  { value: 'Asia/Jerusalem', label: 'Jerusalem' },
  { value: 'Asia/Amman', label: 'Amman' },
  { value: 'Africa/Tripoli', label: 'Tripoli' },
  { value: 'Africa/Cairo', label: 'Cairo' },
  { value: 'Africa/Johannesburg', label: 'Johannesburg' },
  { value: 'Europe/Moscow', label: 'Moscow' },
  { value: 'Asia/Baghdad', label: 'Baghdad' },
  { value: 'Asia/Kuwait', label: 'Kuwait' },
  { value: 'Asia/Riyadh', label: 'Riyadh' },
  { value: 'Asia/Bahrain', label: 'Bahrain' },
  { value: 'Asia/Qatar', label: 'Qatar' },
  { value: 'Asia/Aden', label: 'Aden' },
  { value: 'Asia/Tehran', label: 'Tehran' },
  { value: 'Africa/Khartoum', label: 'Khartoum' },
  { value: 'Africa/Djibouti', label: 'Djibouti' },
  { value: 'Africa/Mogadishu', label: 'Mogadishu' },
  { value: 'Asia/Dubai', label: 'Dubai' },
  { value: 'Asia/Muscat', label: 'Muscat' },
  { value: 'Asia/Baku', label: 'Baku, Tbilisi, Yerevan' },
  { value: 'Asia/Kabul', label: 'Kabul' },
  { value: 'Asia/Yekaterinburg', label: 'Yekaterinburg' },
  { value: 'Asia/Tashkent', label: 'Islamabad, Karachi, Tashkent' },
  { value: 'Asia/Calcutta', label: 'India' },
  { value: 'Asia/Kathmandu', label: 'Kathmandu' },
  { value: 'Asia/Novosibirsk', label: 'Novosibirsk' },
  { value: 'Asia/Almaty', label: 'Almaty' },
  { value: 'Asia/Dacca', label: 'Dacca' },
  { value: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk' },
  { value: 'Asia/Dhaka', label: 'Astana, Dhaka' },
  { value: 'Asia/Bangkok', label: 'Bangkok' },
  { value: 'Asia/Saigon', label: 'Vietnam' },
  { value: 'Asia/Jakarta', label: 'Jakarta' },
  { value: 'Asia/Irkutsk', label: 'Irkutsk, Ulaanbaatar' },
  { value: 'Asia/Shanghai', label: 'Beijing, Shanghai' },
  { value: 'Asia/Hong_Kong', label: 'Hong Kong' },
  { value: 'Asia/Taipei', label: 'Taipei' },
  { value: 'Asia/Kuala_Lumpur', label: 'Kuala Lumpur' },
  { value: 'Asia/Singapore', label: 'Singapore' },
  { value: 'Australia/Perth', label: 'Perth' },
  { value: 'Asia/Yakutsk', label: 'Yakutsk' },
  { value: 'Asia/Seoul', label: 'Seoul' },
  { value: 'Asia/Tokyo', label: 'Osaka, Sapporo, Tokyo' },
  { value: 'Australia/Darwin', label: 'Darwin' },
  { value: 'Australia/Adelaide', label: 'Adelaide' },
  { value: 'Asia/Vladivostok', label: 'Vladivostok' },
  { value: 'Pacific/Port_Moresby', label: 'Guam, Port Moresby' },
  { value: 'Australia/Brisbane', label: 'Brisbane' },
  { value: 'Australia/Sydney', label: 'Canberra, Melbourne, Sydney' },
  { value: 'Australia/Hobart', label: 'Hobart' },
  { value: 'Asia/Magadan', label: 'Magadan' },
  { value: 'SST', label: 'Solomon Islands' },
  { value: 'Pacific/Noumea', label: 'New Caledonia' },
  { value: 'Asia/Kamchatka', label: 'Kamchatka' },
  { value: 'Pacific/Fiji', label: 'Fiji Islands, Marshall Islands' },
  { value: 'Pacific/Auckland', label: 'Auckland, Wellington' },
  { value: 'Asia/Kolkata', label: 'Mumbai, Kolkata, New Delhi' },
  { value: 'Europe/Kiev', label: 'Kiev' },
  { value: 'America/Tegucigalpa', label: 'Tegucigalpa' },
  { value: 'Pacific/Apia', label: 'Independent State of Samoa' },
];

export const countries = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Mcdonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: "Korea, Democratic People's Republic of" },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: "Lao People's Democratic Republic" },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const daysListOptions = [
  {
    name: 'S',
    value: 'Sun',
  },
  {
    name: 'M',
    value: 'Mon',
  },
  {
    name: 'T',
    value: 'Tue',
  },
  {
    name: 'W',
    value: 'Wed',
  },
  {
    name: 'T',
    value: 'Thu',
  },
  {
    name: 'F',
    value: 'Fri',
  },
  {
    name: 'S',
    value: 'Sat',
  },
];

export const selectLanguageTypeConstant = [
  {
    typeName: 'Choosen language',
    badgeText: 'Recommended, faster',
    descriptionText: 'Choose this if the user and bot conversation happens in just one language',
    value: 'CUSTOM_SELECT',
    tooltip: 'Manually select your preferred language',
  },
  {
    typeName: 'All languages',
    value: 'AUTO_DETECT',
    badgeText: 'Slower',
    descriptionText:
      'Choose this if your users can ask questions in any language and the bot should respond in their respective language',
    tooltip: 'Auto detect the language',
  },
];

export const selectChannelForSidebarSetupPage = [
  {
    typeName: 'Auto-respond',
    badgeText: '',
    descriptionText: 'Bot will auto-respond on selecting the option',
    value: 'AUTO_RESPONDE',
    tooltip: 'Bot will auto-respond on selecting the option',
  },
  {
    typeName: 'On-tagging',
    value: 'ON-TAGGING',
    badgeText: '',
    descriptionText: 'Bot will respond only on tagging',
    tooltip: 'Bot will respond only on tagging',
  },
];


export const botResponseLengthOptions = [
  {
    name: 'Default',
    value: 'DEFAULT',
    descriptionText: 'Bot will decide how many words it wants to answer in',
  },
  {
    name: '100-150 words',
    value: 'WORDS_100_150',
  },
  {
    name: '200-250 words',
    value: 'WORDS_200_250',
  },
  {
    name: '300-350+ words',
    value: 'WORDS_300_350',
  },
  {
    name: 'Summary+Details',
    value: 'SUMMARY_DETAILS',
    descriptionText: 'Bot will divide the answer into two sections - summary and details',
  },
];