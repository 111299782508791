import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetAutomationReportIsSidebarOpen,
  useGetCurrentCommunity,
  useGetPaymentStatus,
  useGetPaymentsModalOpen,
  useGetSupportBotPaymentStatus,
  useGetUserInfo,
  useGetChromeInstallStatusNudge,
} from 'context/hooks';
import { BannerMessage, BannerOfflineContainer } from 'styledComponents/launchBannerStyled';

// Internet Disconnect
import { ServerNotConnectedToInternet } from '../../constant/commonText';
import {
  getPaymentStatusAction,
  getSupportBotPaymentStatusAction,
  setPaymentsModalOpenAction,
} from '../../redux/paymentsProvider/actions/paymentsActions';
import PaymentsModal from '../Payments/PaymentsModal';
import { SubscriptionStatusType } from 'components/Settings/BillDetails/utils';
import LaunchBannerFreePlan from './LaunchBannerFreePlan';
import LaunchBannerCancelledPlan from './LaunchBannerCancelledPlan';
import LaunchBannerTrialPlan from './LauncBannerTrialPlan';
import { generateSegmentUserId, loadCommunityIdFromLocalStorage } from '../../utils/segment';
import { useLocation } from 'react-router';
import BotLaunchBannerFreePlan from './BotLaunchBanner/BotLaunchBannerFreePlan';
import BotLaunchBannerCancelledPlan from './BotLaunchBanner/BotLauncBannerCancelledPlan';
import BotLaunchBannerTrialPlan from './BotLaunchBanner/BotLaunchBannerTrialPlan';
import BotLaunchBannerLimitReach from './BotLaunchBanner/BotLaunchBannerLimiReach';
// import { useIntercom } from 'react-use-intercom';
import { workspaceValueRoleMapper } from 'constant/staffRoleData';
import SupportAgentInstallBanner from './SupportAgentInstallBanner';
import { getInstallChromAppNudgeStatusAction } from 'redux/agentAssistProvider/actions/agentAssistActions';
import { useToasts } from 'react-toast-notifications';

const LaunchBanner = ({ children }) => {
  // const { boot } = useIntercom();
  const [showPaymentsBanner, setShowPaymentsBanner] = React.useState(false);
  const [showDemoButton] = React.useState(true);
  const [openPaymentsModal, setOpenPaymentsModal] = React.useState(false);
  const openPaymentsModalHook = useSelector(useGetPaymentsModalOpen);
  const communityData = useSelector(useGetCurrentCommunity);
  const userInfo = useSelector(useGetUserInfo);
  const dispatch = useDispatch();
  const paymentsPlanStatus = useSelector(useGetPaymentStatus);
  const botPaymentsPlanStatus = useSelector(useGetSupportBotPaymentStatus);
  const isAutomationReportSidebarOpen = useSelector(useGetAutomationReportIsSidebarOpen);
  const { addToast } = useToasts();
  const isChromeInstallNudge = useSelector(useGetChromeInstallStatusNudge);

  const location = useLocation();

  useEffect(() => {
    if (!userInfo || !userInfo?.id) {
      return;
    }
    // Intercom initialized
    const communityConfig = loadCommunityIdFromLocalStorage();
    const communityId = communityConfig?.communityId;
    const userId = userInfo?.id;
    if (!(!communityId || !userId)) {
      const segmentUserId = generateSegmentUserId(communityId, userId);
      // boot({ name: userInfo.name, userId: segmentUserId, email: userInfo.email });
      const payload = {
        userDetails: {
          email: userInfo.email,
          name: userInfo.name,
          userId: segmentUserId,
        },
      };
      window._hw && window._hw('userBoot', payload);
    }
  }, [userInfo?.id, userInfo?.email, userInfo?.name]);

  const [isOnline, setOnlineStatus] = useState(true);

  useEffect(() => {
    window.addEventListener('offline', () => {
      console.warn(`You are currently offine`);
      setOnlineStatus(false);
    });
    window.addEventListener('online', () => {
      setOnlineStatus(true);
    });
  }, []);

  // TODO: remove this after year end review
  useEffect(() => {
    if (location?.pathname?.startsWith('/community-roundup22')) {
      setShowPaymentsBanner(false);
    }
  }, [location?.pathname]);

  //Effect for payments status
  useEffect(() => {
    if (!communityData || !communityData?.org?.id || !userInfo || !userInfo?.id) {
      return;
    }
    if (
      location?.pathname?.startsWith('/settings/integrations/create') ||
      location?.pathname?.startsWith('/settings/integrations/select')
    ) {
      setShowPaymentsBanner(false);
      return;
    }
    dispatch(getPaymentStatusAction(communityData?.org?.id));
    dispatch(getSupportBotPaymentStatusAction(communityData?.org?.id));
    dispatch(getInstallChromAppNudgeStatusAction(communityData?.id, addToast));
  }, [userInfo, communityData, location?.pathname]);

  //Effect for payments banner
  useEffect(() => {
    if (!paymentsPlanStatus || !userInfo || !userInfo?.id) {
      setShowPaymentsBanner(false);
      return;
    }
    if (
      paymentsPlanStatus?.status === SubscriptionStatusType.TRIAL ||
      paymentsPlanStatus?.status === SubscriptionStatusType.FREE ||
      paymentsPlanStatus?.status === SubscriptionStatusType.CANCELLED ||
      botPaymentsPlanStatus?.status === SubscriptionStatusType.TRIAL ||
      botPaymentsPlanStatus?.status === SubscriptionStatusType.FREE ||
      botPaymentsPlanStatus?.status === SubscriptionStatusType.CANCELLED ||
      botPaymentsPlanStatus?.isLimitReached ||
      isChromeInstallNudge
    ) {
      setShowPaymentsBanner(true);
    } else {
      setShowPaymentsBanner(false);
    }
  }, [paymentsPlanStatus, botPaymentsPlanStatus, userInfo]);

  return (
    <div
      style={{
        paddingTop: showPaymentsBanner ? '0px' : '0',
      }}>
      {showPaymentsBanner && !isAutomationReportSidebarOpen && (
        <>
          {communityData?.org?.signupType === 'THREADO_AI' ? (
            <>
              {communityData?.role === workspaceValueRoleMapper.WORKSPACE_SUPPORT_AGENT ? (
                <SupportAgentInstallBanner />
              ) : botPaymentsPlanStatus?.isLimitReached ? (
                <BotLaunchBannerLimitReach paymentsPlanStatus={botPaymentsPlanStatus} />
              ) : botPaymentsPlanStatus?.status === SubscriptionStatusType.FREE ? (
                <BotLaunchBannerFreePlan
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  communityRole={communityData?.role}
                />
              ) : botPaymentsPlanStatus?.status === SubscriptionStatusType.CANCELLED ? (
                <BotLaunchBannerCancelledPlan
                  paymentsPlanStatus={botPaymentsPlanStatus}
                  communityRole={communityData?.role}
                />
              ) : botPaymentsPlanStatus?.status === SubscriptionStatusType.TRIAL ? (
                <BotLaunchBannerTrialPlan
                  paymentsPlanStatus={botPaymentsPlanStatus}
                  showDemoButton={showDemoButton}
                  communityRole={communityData?.role}
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  showUpgrade={true}
                />
              ) : (
                <></>
              )}
            </>
          ) : communityData?.org?.signupType === 'COMMUNITY_AND_THREADO_AI' ? (
            <>
              {communityData?.role === workspaceValueRoleMapper.WORKSPACE_SUPPORT_AGENT ? (
                <SupportAgentInstallBanner />
              ) : botPaymentsPlanStatus?.isLimitReached ? (
                <BotLaunchBannerLimitReach paymentsPlanStatus={botPaymentsPlanStatus} />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.TRIAL &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType.TRIAL &&
                location?.pathname?.includes('/support-bot') ? (
                <BotLaunchBannerTrialPlan
                  paymentsPlanStatus={botPaymentsPlanStatus}
                  showDemoButton={showDemoButton}
                  communityRole={communityData?.role}
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  showUpgrade={true}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.TRIAL &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType.TRIAL &&
                !location?.pathname?.includes('/support-bot') ? (
                <LaunchBannerTrialPlan
                  paymentsPlanStatus={paymentsPlanStatus}
                  showDemoButton={showDemoButton}
                  communityRole={communityData?.role}
                  setOpenPaymentsModal={setOpenPaymentsModal}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.FREE &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType.FREE &&
                location?.pathname?.includes('/support-bot') ? (
                <BotLaunchBannerFreePlan
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  communityRole={communityData?.role}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.FREE &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType.FREE &&
                !location?.pathname?.includes('/support-bot') ? (
                <LaunchBannerFreePlan
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  communityRole={communityData?.role}
                  showDemoButton={showDemoButton}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.PAID &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType?.TRIAL ? (
                <BotLaunchBannerTrialPlan
                  paymentsPlanStatus={botPaymentsPlanStatus}
                  showDemoButton={showDemoButton}
                  communityRole={communityData?.role}
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  showUpgrade={false}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.TRIAL &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType?.PAID ? (
                <LaunchBannerTrialPlan
                  paymentsPlanStatus={paymentsPlanStatus}
                  showDemoButton={showDemoButton}
                  communityRole={communityData?.role}
                  setOpenPaymentsModal={setOpenPaymentsModal}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.PAID &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType.FREE &&
                location?.pathname?.includes('/support-bot') ? (
                <BotLaunchBannerFreePlan
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  communityRole={communityData?.role}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.FREE &&
                botPaymentsPlanStatus?.status === SubscriptionStatusType?.PAID &&
                !location?.pathname?.includes('/support-bot') ? (
                <LaunchBannerFreePlan
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  communityRole={communityData?.role}
                  showDemoButton={showDemoButton}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {paymentsPlanStatus?.status === SubscriptionStatusType.FREE ? (
                <LaunchBannerFreePlan
                  setOpenPaymentsModal={setOpenPaymentsModal}
                  communityRole={communityData?.role}
                  showDemoButton={showDemoButton}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.CANCELLED ? (
                <LaunchBannerCancelledPlan
                  paymentsPlanStatus={paymentsPlanStatus}
                  communityRole={communityData?.role}
                />
              ) : paymentsPlanStatus?.status === SubscriptionStatusType.TRIAL ? (
                <LaunchBannerTrialPlan
                  paymentsPlanStatus={paymentsPlanStatus}
                  showDemoButton={showDemoButton}
                  communityRole={communityData?.role}
                  setOpenPaymentsModal={setOpenPaymentsModal}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
      {!isOnline && (
        <BannerOfflineContainer scale={!isOnline ? 'yes' : ''} style={{ zIndex: 1500 }}>
          <BannerMessage>{ServerNotConnectedToInternet}</BannerMessage>
        </BannerOfflineContainer>
      )}
      {openPaymentsModal && (
        <PaymentsModal
          openPaymentsModal={openPaymentsModal}
          handleClose={() => setOpenPaymentsModal(false)}
        />
      )}
      {openPaymentsModalHook && (
        <PaymentsModal
          openPaymentsModal={openPaymentsModalHook}
          handleClose={() => dispatch(setPaymentsModalOpenAction(false))}
        />
      )}
      {children}
    </div>
  );
};

export default LaunchBanner;
