// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import { menuDataForBot, menuDataNew, metaDataMenu } from '../../utils/menuData';
import { useHistory, useLocation } from 'react-router';
import { CheckIfFeatureActiveForMenuLink } from '../../context/useFeature';
import adminMenu from 'assets/images/icons/adminMenu.svg';
import chevronRightIcon from '../../assets/images/icons/chevronRightIcon.svg';
import chevronButtomIcon from '../../assets/images/icons/chevronButtomIcon.svg';
import { GetMenuLink } from '../SidebarMenuItem';
import CommunityMenu from 'components/Header/CommunityMenu';
import { useDispatch } from 'react-redux';
import { colorToken } from 'theme/designToken';
import {
  useGetActiveFeature,
  // useGetActivityBannerYearReviewData,
  useGetCurrentCommunity,
  useGetUserInfo,
  useGetSidebarToggleState,
  useGetUserPermissions,
  useGetSignupCommunityDetails,
} from 'context/hooks';
import { setSidebarToggleState } from 'redux/activityProvider/actions/activityActions';
import { Image } from 'styledComponents/commonStyled';
import { getMenuDataWithPermissions } from 'utils/menuDataWithPermissions';
import { isEmpty } from 'lodash';
import 'molecules/SidebarMenu/sideMenu.css';

export const drawerWidth = 250;
export const drawerMargin = 5;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: '#FFFFFF',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const TreeMenuItem = ({ item }) => {
  const router = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (item.hasTreeMenu && item.treeMenu) {
      const shouldOpen = item.treeMenu.some((subItem) =>
        router?.location?.pathname?.startsWith(subItem.redirectionUrl || subItem.url),
      );
      setIsOpen(shouldOpen);
    }
  }, [router.location, item]);

  return (
    <div>
      <div onClick={toggleOpen} style={{ cursor: 'pointer' }}>
        {item.hasTreeMenu ? (
          <>
            <GetMenuLink menu={item} />
          </>
        ) : (
          <>
            <Link to={item.url || ''}>
              <GetMenuLink menu={item} />
            </Link>
          </>
        )}
      </div>
      <div className={`tree-submenu ${isOpen ? 'open' : 'close'}`}>
        {isOpen && item.hasTreeMenu && item.treeMenu && (
          <div style={{ paddingLeft: '20px' }}>
            {item.treeMenu?.map((subItem, index) => (
              <Link
                key={index}
                to={(subItem.hasSubMenu ? subItem.redirectionUrl : subItem.url) || ''}>
                <GetMenuLink menu={subItem} />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const TreeMenu = ({ data }) => {
  return <TreeMenuItem item={data} />;
};

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRight: `${colorToken.menuSidebar.primarySidebar.border.right.width} ${colorToken.menuSidebar.primarySidebar.border.right.type} ${colorToken.menuSidebar.primarySidebar.border.right.color}`,
  overflowX: 'hidden',
  backgroundColor: colorToken.menuSidebar.primarySidebar.backgroundColor,
  width: '68px',
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      '& .MuiDrawer-paper': {
        height: '90%',
        margin: drawerMargin,
        borderRadius: 14,
        overflowX: 'hidden',
        gap: 6,
      },
    },
    accordionExpand: {
      margin: '0 !important',
    },
  }),
);

const SidebarMenu = (props) => {
  const router = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [menuData, setMenuData] = useState([]);
  const signupCommunityData = useSelector(useGetSignupCommunityDetails);
  const [communityMenuData, setCommunityMenuData] = useState([]);
  const [supportMenuData, setSupportMenuData] = useState([]);

  const communityData = useSelector(useGetCurrentCommunity);
  // const yearEndReviewBannerData = useSelector(useGetActivityBannerYearReviewData);
  const activeFeatureList = useSelector(useGetActiveFeature);
  const userInfo = useSelector(useGetUserInfo);
  const sidebarToggleState = useSelector(useGetSidebarToggleState);
  const history = useHistory();
  const [communityEnabled, setCommunityEnabled] = useState();
  const [threadoAiEnabled, setThreadoAiEnabled] = useState();
  // New sidebar
  // Example state structure: { bot: { isOpen: true, subMenus: { 'Agent assist bot': false } } }
  const [isCommunityToggleOpen, setCommunityToggleOpen] = useState(false);
  const [isBotToggleOpen, setBotToggleOpen] = useState(true);

  const [userInfoPermissions, setUserInfoPermissions] = useState(null);

  // Permissions
  const userPermissions = useSelector(useGetUserPermissions);

  useEffect(() => {
    if (!signupCommunityData) {
      return null;
    }
    if (communityData?.org?.signupType === 'COMMUNITY_AND_THREADO_AI') {
      setCommunityEnabled(true);
      setThreadoAiEnabled(true);
    } else if (communityData?.org?.signupType === 'COMMUNITY') {
      setCommunityEnabled(true);
    } else if (communityData?.org?.signupType === 'THREADO_AI') {
      setThreadoAiEnabled(true);
    }
  }, [signupCommunityData]);

  useEffect(() => {
    if (!communityData) {
      return;
    }
    if (!userPermissions) {
      return;
    }
    if (router?.location?.pathname?.startsWith('/support-bot')) {
      setBotToggleOpen(true);
    } else {
      setCommunityToggleOpen(true);
    }
  }, [userInfoPermissions, communityData, location]);

  // Effect for fetching permissions
  useEffect(() => {
    if (!communityData) {
      return;
    }
    if (!userPermissions) {
      return;
    }
    if (userPermissions) {
      if (!isEmpty(userPermissions)) {
        setUserInfoPermissions(userPermissions);
      }
    }
  }, [userPermissions, communityData]);
  useEffect(() => {
    if (!communityData || !userInfoPermissions) {
      return;
    }
    setSupportMenuData(getMenuDataWithPermissions(menuDataForBot, userInfoPermissions));
    setCommunityMenuData(getMenuDataWithPermissions(menuDataNew, userInfoPermissions));
  }, [communityData, userInfoPermissions]);

  const redirectToHome = () => {
    router.push(`/activity/summary`);
  };

  const [toggleSidebar, setToggleSideBar] = useState(false);
  const toggleSetter = (toggleValue) => {
    dispatch(setSidebarToggleState(toggleValue));
    setToggleSideBar(toggleValue);
  };

  return (
    <>
      <Box sx={{ display: 'flex', zIndex: 1300 }}>
        <Drawer className="sidebarDrawer" variant="permanent" open={sidebarToggleState}>
          <>
            <CommunityMenu />
            {/* <MainContent
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 0,
                paddingBottom: 10,
                paddingTop: 5,
                width: '100%',
                padding: '22px 16px 23px',
              }}>
              {!sidebarToggleState ? (
                <AvatarButton
                  id="avatarButton"
                  aria-controls="avatarButton-menu"
                  aria-haspopup="false"
                  style={{ marginLeft: '0px !important', marginTop: '-4px' }}>
                  <div
                    style={{
                      width: '50px',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <div
                      role="none"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignContent: 'flex-end',
                        justifyContent: 'flex-end',
                        alignItems: 'stretch',
                        marginLeft: '4px',
                        cursor: 'pointer',
                        marginTop: '1px',
                      }}
                      onClick={() => {
                        redirectToHome();
                      }}>
                      <img src={threadoLogo} alt="Threado-Logo" width={28} height={20} />
                    </div>
                    <div
                      role="none"
                      onClick={() => {
                        toggleSetter(!toggleSidebar);
                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        alignContent: 'flex-end',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginTop: '4px',
                      }}>
                      <img src={sidebarToggle} alt="sidebar-toggle" width={21} height={14} />
                    </div>
                  </div>
                </AvatarButton>
              ) : (
                <div
                  id="avatarButton"
                  aria-controls="avatarButton-menu"
                  aria-haspopup="false"
                  style={{ marginLeft: '0px !important', width: '100%' }}>
                  <div
                    style={{
                      width: '50px',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      cursor: 'pointer',
                    }}>
                    <div
                      role="none"
                      style={{ marginTop: '2px' }}
                      onClick={() => {
                        redirectToHome();
                      }}>
                      <img src={threadoFullLogo} alt="Threado-Logo" width={80} height={16} />
                    </div>
                    <div
                      role="none"
                      onClick={() => toggleSetter(!toggleSidebar)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        alignContent: 'flex-end',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginTop: '4px',
                        transform: 'rotate(-180deg)',
                        cursor: 'pointer',
                      }}>
                      <img src={sidebarToggle} alt="sidebar-toggle" width={21} height={14} />
                    </div>
                  </div>
                </div>
              )}
            </MainContent> */}
          </>

          {threadoAiEnabled ? (
            <div
              style={{
                marginTop: `${!(communityEnabled && threadoAiEnabled) ? '24px' : '0px'}`,
              }}>
              {communityEnabled && threadoAiEnabled ? (
                <div
                  onClick={() => setBotToggleOpen(!isBotToggleOpen)}
                  className="sidebarMenuSpanWrapper">
                  {isBotToggleOpen ? (
                    <Image src={chevronButtomIcon} height={16} width={16} />
                  ) : (
                    <Image src={chevronRightIcon} height={16} width={16} />
                  )}
                  <span className="sidebarMenuSpan">Support</span>
                </div>
              ) : (
                <></>
              )}
              <div className={`tree-submenu ${isBotToggleOpen ? 'open' : 'close'}`}>
                {isBotToggleOpen &&
                  supportMenuData.length > 0 &&
                  supportMenuData.map((menu, index) =>
                    menu.restricted ? (
                      CheckIfFeatureActiveForMenuLink(menu.feature, activeFeatureList) && (
                        <Link
                          key={index}
                          to={(menu.hasSubMenu ? menu.redirectionUrl : menu.url) || ''}>
                          <GetMenuLink menu={menu} />
                        </Link>
                      )
                    ) : menu.hasTreeMenu ? (
                      <TreeMenu data={menu} />
                    ) : (
                      <Link
                        key={index}
                        to={(menu.hasSubMenu ? menu.redirectionUrl : menu.url) || ''}>
                        <GetMenuLink menu={menu} />
                      </Link>
                    ),
                  )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {communityEnabled ? (
            <div
              style={{
                marginTop: `${!(communityEnabled && threadoAiEnabled) ? '24px' : '0px'}`,
              }}>
              {communityEnabled && threadoAiEnabled ? (
                <div
                  onClick={() => setCommunityToggleOpen(!isCommunityToggleOpen)}
                  className="sidebarMenuSpanWrapper">
                  {isCommunityToggleOpen ? (
                    <Image src={chevronButtomIcon} height={16} width={16} />
                  ) : (
                    <Image src={chevronRightIcon} height={16} width={16} />
                  )}
                  <span className="sidebarMenuSpan">Community</span>
                </div>
              ) : (
                <></>
              )}

              <div className={`tree-submenu ${isCommunityToggleOpen ? 'open' : 'close'}`}>
                {isCommunityToggleOpen &&
                  communityMenuData.length > 0 &&
                  communityMenuData.map((menu, index) =>
                    menu.restricted ? (
                      CheckIfFeatureActiveForMenuLink(menu.feature, activeFeatureList) && (
                        <Link
                          key={index}
                          to={(menu.hasSubMenu ? menu.redirectionUrl : menu.url) || ''}>
                          <GetMenuLink menu={menu} />
                        </Link>
                      )
                    ) : menu.name === 'Add teammates' ? (
                      <Link
                        style={{
                          paddingLeft: '22px !important',
                          flexDirection: 'column',
                          display: 'inline-flex',
                          justifyContent: 'flex-end',
                          cursor: 'pointer',
                          margin: '4px 0px 0px',
                          top: '18%',
                          height: '100%',
                          alignItems: 'center',
                          pointerEvents: 'none',
                        }}
                        key={index}
                        to={menu.url}>
                        <GetMenuLink menu={menu} />
                      </Link>
                    ) : menu.name === 'Join community' ? (
                      <Link
                        onClick={() => {
                          window.open(menu.url, '_blank');
                        }}
                        key={index}>
                        <GetMenuLink menu={menu} />
                      </Link>
                    ) : menu.identifier === 'YEARENDREVIEW' ? (
                      <a
                        href={`${window.location.origin}/community-roundup22/${communityData?.name}/${communityData?.id}`}
                        target="_blank"
                        rel="noreferrer">
                        <GetMenuLink menu={menu} />
                      </a>
                    ) : (
                      <Link
                        key={index}
                        to={(menu.hasSubMenu ? menu.redirectionUrl : menu.url) || ''}>
                        <GetMenuLink menu={menu} />
                      </Link>
                    ),
                  )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {metaDataMenu.length > 0 &&
            metaDataMenu.map((menu, index) =>
              menu.restricted ? (
                CheckIfFeatureActiveForMenuLink(menu.feature, activeFeatureList) && (
                  <Link key={index} to={(menu.hasSubMenu ? menu.redirectionUrl : menu.url) || ''}>
                    <GetMenuLink menu={menu} />
                  </Link>
                )
              ) : menu.name === 'Add teammates' ? (
                <Link
                  style={{
                    flexDirection: 'column',
                    display: 'inline-flex',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                    margin: '4px 0px 0px',
                    top: '18%',
                    height: '100%',
                    alignItems: 'center',
                    pointerEvents: 'none',
                  }}
                  key={index}
                  to={menu.url}>
                  <GetMenuLink menu={menu} />
                </Link>
              ) : menu.name === 'Join community' ? (
                <Link
                  onClick={() => {
                    window.open(menu.url, '_blank');
                  }}
                  key={index}>
                  <GetMenuLink menu={menu} />
                </Link>
              ) : menu.identifier === 'YEARENDREVIEW' ? (
                <a
                  href={`${window.location.origin}/community-roundup22/${communityData?.name}/${communityData?.id}`}
                  target="_blank"
                  rel="noreferrer">
                  <GetMenuLink menu={menu} />
                </a>
              ) : (
                <Link key={index} to={(menu.hasSubMenu ? menu.redirectionUrl : menu.url) || ''}>
                  <GetMenuLink menu={menu} />
                </Link>
              ),
            )}

          <div style={{ paddingTop: '0px', paddingBottom: '4px', cursor: 'pointer' }}>
            <div
              role="none"
              onClick={() => {
                history.push('/accounts/profile');
              }}
              style={{
                paddingBottom: '4px',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  placeContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                  background:
                    router.location.pathname === '/accounts/profile'
                      ? 'rgb(244, 245, 246)'
                      : 'none',
                  borderRadius: '8px',
                  height: '32px',
                  width: '100%',
                  boxSizing: 'border-box',
                  marginTop: '5px',
                  marginRight: '35px',
                  marginLeft: '7px',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                }}>
                <div style={{ display: 'flex' }}>
                  <Image src={adminMenu} height={25} width={25} />
                </div>
                {sidebarToggleState ? (
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'column nowrap',
                      placeContent: 'center',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      alignContent: 'center',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <span
                      className="sidebar-open-menu-style"
                      style={{
                        color: '#000000',
                        minWidth: '90px',
                        alignItems: 'center',
                        width: '20ch',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginRight: '13px',
                        paddingLeft: '8px',
                      }}>
                      {userInfo.name}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </Box>
    </>
  );
};

export default SidebarMenu;
