import { AnyAction } from 'redux';
import {
  CLOSE_SIDEBAR,
  CREATE_WORKFLOW,
  DELETE_FIRST_NODE,
  ERROR_CREATE_WORKFLOW,
  GET_AUTOMATION_CRITERIA,
  GET_WORKFLOW_LIST_DATA,
  OPEN_SIDEBAR,
  REMOVE_NEW_NODE_TYPE,
  RESET_TOASTER,
  SET_AUTOMATION_CRITERIA,
  SET_AUTOMATION_SIDE_BAR,
  SET_CURRENT_NODE,
  SET_FIRST_NODE,
  SET_HOVERED_ROW_ID,
  SET_NEW_NODE_TYPE,
  SET_SIDEBAR_ACTION,
  SET_WORKFLOW_LIST_LOADING,
  SUCCESS_CREATE_WORKFLOW,
  SUCCESS_UPDATED_WORKFLOW,
  UPDATE_FLOW_CHART,
  UPDATE_WORKFLOW_ID,
  workflowListDataType,
  SET_FIRST_NODE_DESCRIPTION,
  SET_AUTOMATION_MENU_BAR_OPEN,
  SET_AUTOMATION_MENU_BAR_NODE,
  SET_CURRENT_WORKFLOW_STATUS,
  SET_TOASTER_MESSAGE,
  SET_UPDATE_TRIGGER_EVENT,
  GET_WORKFLOW_SURVEY_LIST_DATA,
  SET_WORKFLOW_LIST_ROW_STATUS,
  SET_WORKFLOW_LIST_DELETED_ROWS,
  SET_IS_ANY_NODE_EDITED,
  SET_SURVEY_NAME_LOCALLY,
  SUCCESS_CREATE_WORKFLOW_NEW,
  SET_AUTOMATION_REPORT_MODE,
  SET_REPORT_NEW_NODE_TYPE,
  GET_AUTOMATION_REPORT_BY_BLOCK,
  GET_AUTOMATION_REPORT_LOADING,
  ERROR_GET_AUTOMATION_REPORT,
  SET_AUTOMATION_REPORT_CURRENT_NODE,
  OPEN_AUTOMATION_REPORT_SIDEBAR,
  CLOSE_AUTOMATION_REPORT_SIDEBAR,
  GET_AUTOMATION_REPORT_BLOCK_STATUS,
  GET_AUTOMATION_REPORT_BLOCK_STATUS_LOADING,
  ERROR_GET_AUTOMATION_REPORT_BLOCK_STATUS,
  SET_AUTOMATION_SCHEDULE_CONFIG_DAY_TIME,
  SET_SLACK_INTERNAL_CHANNELS_LIST,
  SET_UPDATE_TRIGGER_EVENT_SUB_TYPE,
  SET_AUTOMATION_BLOCK_VIEW_MODE,
} from '../types';

export const initialState = {
  workflowId: null,
  workflowStatus: '',
  integrationId: null,
  workflowName: '',
  workflowDescription: '',
  isSidebarOpen: false,
  currentNode: null,
  flowchart: null,
  sidebarAction: '',
  newNodeType: '',
  workflowList: [],
  isWorkflowLoading: false,
  firstNode: null,
  triggerEvent: '',
  updatedTriggerEvent: '',
  updatedTriggerEventSubtype: '',
  toastMessage: '',
  toastType: '',
  isEntryConditionSet: false,
  isEntryConditionLoading: false,
  entryCondition: null,
  hoveredRow: null,
  isSideMenuBarOpen: false,
  isAutomationReportSidebarOpen: false,
  firstNodeDescription: '',
  isAutomationMenuBarOpen: false,
  automationMenubarNode: null,
  workflowSurveyList: [],
  workflowListRowStatus: [],
  workflowListDeletedRows: [],
  isAnyNodeEdited: false,
  surveyNameLocally: [],
  isAutomationReportMode: false,
  automationReportNewNodeType: '',
  automationReportCurrentNode: null,
  automationReportByBlock: null,
  automationReportLoading: false,
  automationReportError: false,
  automationReportBlockStatus: null,
  automationReportBlockStatusLoading: false,
  automationReportBlockStatusError: false,
  automationScheduleConfigLocal: {
    days: [],
    fromTime: '',
    toTime: '',
    isSpecificTime: false,
    workflowId: '',
  },
  slackInternalChannelList: [],
  isBlockViewMode: false,
};
export const automationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_WORKFLOW: {
      return {
        ...state,
        flowchart: payload,
      };
    }
    case ERROR_CREATE_WORKFLOW: {
      return {
        ...state,
        toastMessage: 'Workflow not saved!',
      };
    }
    case RESET_TOASTER:
      return {
        ...state,
        toastMessage: '',
        toastType: '',
      };
    case OPEN_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: true,
        triggerEvent: '',
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: false,
      };
    case SET_CURRENT_NODE:
      return {
        ...state,
        currentNode: payload,
      };
    case UPDATE_FLOW_CHART:
      let finalFlowChart = payload;
      if (finalFlowChart?.blocks?.length === 0) {
        finalFlowChart.blocks.push(state.firstNode);
      }
      if (
        finalFlowChart?.blocks?.length > 0 &&
        finalFlowChart.blocks?.[0].type === 'event' &&
        state.updatedTriggerEvent
      ) {
        finalFlowChart.blocks[0].blockName = state.updatedTriggerEvent;
      }
      return {
        ...state,
        flowchart: finalFlowChart,
        workflowId: payload.workflowId,
        isWorkflowLoading: false,
        workflowStatus: payload.workflow?.status ? payload.workflow.status : state.workflowStatus,
        workflowName: payload.workflow?.name ? payload.workflow?.name : state.workflowName,
        workflowDescription: payload.workflow?.description
          ? payload.workflow?.description
          : state.workflowDescription,
      };
    case SET_SIDEBAR_ACTION:
      return {
        ...state,
        sidebarAction: payload,
      };
    case SET_NEW_NODE_TYPE:
      return {
        ...state,
        newNodeType: payload,
      };
    case REMOVE_NEW_NODE_TYPE:
      return {
        ...state,
        newNodeType: '',
      };
    case SET_WORKFLOW_LIST_LOADING:
      return {
        ...state,
        isWorkflowLoading: true,
        toastMessage: '',
      };
    case GET_WORKFLOW_LIST_DATA:
      return {
        ...state,
        workflowList: payload,
        isWorkflowLoading: false,
        triggerEvent: '',
        workflowId: null,
        isEntryConditionSet: false,
        isEntryConditionLoading: false,
        entryCondition: null,
        workflowName: '',
        workflowDescription: '',
        flowchart: null,
      };
    case SET_FIRST_NODE:
      return {
        ...state,
        firstNode: payload,
        triggerEvent: '',
      };
    case DELETE_FIRST_NODE:
      return {
        ...state,
        firstNode: null,
      };
    case UPDATE_WORKFLOW_ID:
      return {
        ...state,
        workflowId: payload,
      };
    case SUCCESS_CREATE_WORKFLOW_NEW:
      const workflowId = payload.id ? payload.id : payload.workflowId;
      return {
        ...state,
        flowchart: payload.component,
        workflowId: workflowId,
        triggerEvent: payload.triggerEvent,
        integrationId: payload.integrationId,
        workflowName: payload.name,
        workflowDescription: payload.description,
      };
    case SUCCESS_CREATE_WORKFLOW:
      return {
        ...state,
        workflowId: payload.id,
        triggerEvent: payload.triggerEvent,
        integrationId: payload.integrationId,
        workflowName: payload.name,
        workflowDescription: payload.description,
      };
    case SUCCESS_UPDATED_WORKFLOW:
      return {
        ...state,
        workflowName: payload.name,
        workflowDescription: payload.description,
      };
    case SET_AUTOMATION_CRITERIA:
      return {
        ...state,
        entryCondition: payload.criteria,
        isEntryConditionSet: !!payload.criteria,
      };
    case GET_AUTOMATION_CRITERIA:
      return {
        ...state,
        entryCondition: payload,
        isEntryConditionSet: !!payload.criteria,
      };
    case SET_HOVERED_ROW_ID:
      return {
        ...state,
        hoveredRow: payload,
      };
    case SET_AUTOMATION_SIDE_BAR:
      return {
        ...state,
        isSideMenuBarOpen: payload,
      };
    case SET_FIRST_NODE_DESCRIPTION:
      return {
        ...state,
        firstNodeDescription: payload,
      };
    case SET_AUTOMATION_MENU_BAR_OPEN:
      return {
        ...state,
        isAutomationMenuBarOpen: payload,
      };
    case SET_AUTOMATION_MENU_BAR_NODE:
      return {
        ...state,
        automationMenubarNode: payload,
      };
    case SET_CURRENT_WORKFLOW_STATUS:
      return {
        ...state,
        workflowStatus: payload,
      };
    case SET_TOASTER_MESSAGE:
      return {
        ...state,
        toastMessage: payload.toastMessage,
        toastType: payload.toastType,
      };
    case SET_UPDATE_TRIGGER_EVENT:
      return {
        ...state,
        updatedTriggerEvent: payload,
      };
    case SET_UPDATE_TRIGGER_EVENT_SUB_TYPE:
      return {
        ...state,
        updatedTriggerEventSubtype: payload,
      };
    case GET_WORKFLOW_SURVEY_LIST_DATA:
      return {
        ...state,
        workflowSurveyList: payload,
      };
    case SET_WORKFLOW_LIST_ROW_STATUS:
      return {
        ...state,
        workflowListRowStatus: [...payload],
      };
    case SET_WORKFLOW_LIST_DELETED_ROWS:
      return {
        ...state,
        workflowListDeletedRows: [...state.workflowListDeletedRows, payload],
      };
    case SET_IS_ANY_NODE_EDITED:
      return {
        ...state,
        isAnyNodeEdited: payload,
      };
    case SET_SURVEY_NAME_LOCALLY:
      return {
        ...state,
        surveyNameLocally: payload,
      };
    case SET_AUTOMATION_REPORT_MODE:
      return {
        ...state,
        isAutomationReportMode: payload,
      };
    case SET_AUTOMATION_BLOCK_VIEW_MODE:
      return {
        ...state,
        isBlockViewMode: payload,
      };
    case SET_REPORT_NEW_NODE_TYPE:
      return {
        ...state,
        automationReportNewNodeType: payload,
      };
    case GET_AUTOMATION_REPORT_BY_BLOCK:
      return {
        ...state,
        automationReportByBlock: payload,
        automationReportLoading: false,
        automationReportError: false,
      };
    case GET_AUTOMATION_REPORT_LOADING:
      return {
        ...state,
        automationReportLoading: true,
        automationReportError: false,
      };
    case ERROR_GET_AUTOMATION_REPORT:
      return {
        ...state,
        automationReportError: true,
        automationReportLoading: false,
      };
    case SET_AUTOMATION_REPORT_CURRENT_NODE:
      return {
        ...state,
        automationReportCurrentNode: payload,
      };
    case OPEN_AUTOMATION_REPORT_SIDEBAR:
      return {
        ...state,
        isAutomationReportSidebarOpen: true,
      };
    case CLOSE_AUTOMATION_REPORT_SIDEBAR:
      return {
        ...state,
        isAutomationReportSidebarOpen: false,
      };
    case GET_AUTOMATION_REPORT_BLOCK_STATUS:
      return {
        ...state,
        automationReportBlockStatus: payload,
        automationReportBlockStatusLoading: false,
        automationReportBlockStatusError: false,
      };
    case GET_AUTOMATION_REPORT_BLOCK_STATUS_LOADING:
      return {
        ...state,
        automationReportBlockStatusLoading: true,
        automationReportBlockStatusError: false,
      };
    case ERROR_GET_AUTOMATION_REPORT_BLOCK_STATUS:
      return {
        ...state,
        automationReportBlockStatusLoading: false,
        automationReportBlockStatusError: true,
      };
    case SET_AUTOMATION_SCHEDULE_CONFIG_DAY_TIME:
      return {
        ...state,
        automationScheduleConfigLocal: { ...payload },
      };
    case SET_SLACK_INTERNAL_CHANNELS_LIST:
      return {
        ...state,
        slackInternalChannelList: payload,
      };
    default:
      return state;
  }
};
