import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useDataState } from 'utils/dataStateHook';
import { tableHeaderColor } from 'theme/colorConstants';
import { createTheme, Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';
import { colorToken } from 'theme/designToken';

const defaultTheme = createTheme();
function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 17,
      height: 17,
      borderRadius: '6px',
      // margin: '20%',
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiCheckbox-root.Mui-checked svg path': {
      display: 'block',
      background: '#FFFFF',
    },
    '& .MuiCheckbox-root.Mui-checked svg': {
      backgroundColor: 'transparent',
      border: 'none',
      borderColor: '#b3b3b3',
      color: '#b3b3b3',
    },
  };
}

export const useMemberDatagridStyles = makeStyles(
  (theme) =>
    createStyles({
      textField: {
        width: 200,
        paddingLeft: '0',
        '& .MuiSvgIcon-root': {
          // marginRight: theme.spacing(0.5),
        },
        background: 'white',
        borderRadius: 4,
        border: '1px solid #e0e7f1',
        // boxShadow: '0px 12px 36px #E5EDF2',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& input': {
          padding: '6px 6px',
        },
      },
      datagrid: {
        border: '0 !important',
        '& .MuiDataGrid-columnsContainer': {
          background: colorToken.tableHeader.primary.color,
          borderRadius: colorToken.tableHeader.border.radius,
          top: '1px',
          margin: '2px',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          color: colorToken.tableHeader.text.primary.color,
        },
        '& .MuiDataGrid-cell': {
          borderRight: 'none !important',
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
          borderBottom: `1px solid #EBEBEB;`,
          whiteSpace: `break-spaces !important`,
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: `${colorToken.tableHeader.primary.color} !important`,
        },
        '& .MuiDataGrid-root .MuiDataGrid-withBorder': {
          borderRight: 'none !important',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          fontSize: 12,
        },
        '& .MuiDataGrid-row': {
          width: 'unset',
          // marginLeft: '4px',
        },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: colorToken.dataGridPro.row.hover.background,
        },
        '& .MuiDataGrid-main': {
          backgroundColor: 'white',
          borderRadius: 10,
          overflow: 'visible',
          padding: 15,
        },
        '& .MuiDataGrid-window': {
          top: '42px !important',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
          color: tableHeaderColor,
          fontWeight: '600 !important',
          textTransform: 'uppercase',
          overflow: 'visible',
          textOverflow: 'unset',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        ...customCheckbox(theme),
      },
    }),
  { defaultTheme },
);

const SearchBox = ({
  requestSearch,
  searchText,
  style,
  placeholder,
  handleKeypress,
  isControlFromSearchText,
}) => {
  const classes = useMemberDatagridStyles();

  const searchTextValue = useDataState(searchText ? searchText : '');
  const searchChange = (searchValue) => {
    requestSearch(searchValue);
    searchTextValue.set(searchValue);
  };
  useEffect(() => {
    if (isControlFromSearchText) {
      searchTextValue.set(searchText);
    }
  }, [searchText]);

  return (
    <TextField
      style={style}
      variant="outlined"
      value={searchTextValue.value}
      color="primary"
      onChange={(event) => searchChange(event.target.value)}
      onKeyDown={(event) => handleKeypress && handleKeypress(event, event.target.value)}
      placeholder={placeholder ? placeholder : 'Search...'}
      size="small"
      className={classes.textField}
      InputProps={{
        startAdornment: (
          <SearchIcon
            fontSize="small"
            style={{ color: colorToken.support.search.icon.color, marginLeft: '-8px' }}
          />
        ),
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{
              visibility: searchTextValue.value !== '' ? 'visible' : 'hidden',
              marginRight: '-8px',
            }}
            onClick={() => searchChange('')}>
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchBox;
