import { AnyAction } from 'redux';
import {
  GET_SESSION_TOKEN_FOR_CURRENT_TRAINING,
  GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_LOADING,
  GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_FAILED,
  INTEGRATION_VIA_COBALT_SUCCESSFUL,
  INTEGRATION_VIA_COBALT_FAILED,
  CONNECT_INTEGRATION_WITH_SERVER_LOADING,
  CONNECT_INTEGRATION_WITH_SERVER,
  CONNECT_INTEGRATION_WITH_SERVER_FAILED,
} from 'redux/gocobaltProvider/types.js';

const initialState = {
  sessionTokenLoadingStatus: false,
  sessionTokenForGocobalt: null,
  sessionTokenFailed: false,
};

const gocobaltIntegrationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_LOADING:
      return {
        ...state,
        sessionTokenLoadingStatus: true,
        sessionTokenForGocobalt: null,
        sessionTokenFailed: false,
      };
    case GET_SESSION_TOKEN_FOR_CURRENT_TRAINING_FAILED:
      return {
        ...state,
        sessionTokenLoadingStatus: false,
        sessionTokenForGocobalt: null,
        sessionTokenFailed: true,
      };
    case GET_SESSION_TOKEN_FOR_CURRENT_TRAINING:
      return {
        ...state,
        sessionTokenLoadingStatus: false,
        sessionTokenForGocobalt: payload,
        sessionTokenFailed: false,
      };

    default:
      return state;
  }
};

export default gocobaltIntegrationReducer;
