import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { colorToken } from 'theme/designToken';

export const ToolTipCustom = withStyles({
  arrow: {
    '&:before': {
      border: 'none',
    },
    color: `${colorToken.tooltip.arrow.color} !important`,
  },
  tooltip: {
    background: colorToken.tooltip.background,
    color: `${colorToken.tooltip.color} !important`,
    borderRadius: '4px',
    maxWidth: '280px',
    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff !important',
    border: '1px solid #EBEBEB',
    fontWeight: '400 !important',
    fontSize: '12px',
    lineHeight: '23px',
  },
})(Tooltip);

export const customTooltipClass = makeStyles(() =>
  createStyles({
    customTooltip: {
      inset: '8px auto auto 6px !important',
      marginLeft: '-6px !important',
      boxShadow: '0px 12px 36px rgba(121, 142, 157, 0.17)',
    },
  }),
);
