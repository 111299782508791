import React from 'react';
import './ButtonLoader.css';
import { Box, CircularProgress } from '@mui/material';

const ButtonLoader = ({ height, width, isLoading, color }) => {
  if (isLoading) {
    return (
      <Box className="ButtonLoaderBox">
        <CircularProgress
          style={{
            alignSelf: 'center',
            width: width,
            height: height,
            color: color,
          }}
        />
      </Box>
    );
  }
  return null;
};

export default ButtonLoader;
