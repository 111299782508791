import { AnyAction } from 'redux';
import {
  GET_RECENT_AUTOMATIONS_LIST_DATA,
  GET_AUTOMATION_TEMPLATES_CATEGORIES_LIST,
  GET_AUTOMATION_TEMPLATES_BY_CATEGORY,
  GET_AUTOMATION_TEMPLATE_BY_ID,
  HANDLE_RECENT_AUTOMATIONS_ERROR,
  HANDLE_AUTOMATION_TEMPLATES_CATGEORIES_LIST_ERROR,
  HANDLE_AUTOMATION_TEMPLATES_BY_CATEGORY_ERROR,
  HANDLE_AUTOMATION_TEMPLATE_BY_ID_ERROR,
  SET_RECENT_AUTOMATIONS_LOADING,
  SET_AUTOMATION_TEMPLATES_CATEGORIES_LIST_LOADING,
  SET_AUTOMATION_TEMPLATES_BY_CATEGORY_LOADING,
  SET_AUTOMATION_TEMPLATE_BY_ID_LOADING,
  SET_USE_TEMPLATE,
  SET_IS_RENDER_FLOWCHART_FROM_AUTOMATION_TEMPLATES,
  SET_TEMPLATES_PLATFORM_FILTER,
} from '../types';

export const initialState = {
  recentAutomationsList: [],
  recentAutomationsLoading: false,
  errorFetchingRecentAutomations: false,
  automationTemplatesCategoriesList: [],
  errorFetchingAutomationTemplatesCategoriesList: false,
  automationTemplatesCategoriesListLoading: false,
  automationTemplatesByCategory: null,
  errorFetchingAutomationTemplatesByCategory: false,
  automationTemplatesByCategoryLoading: false,
  automationTemplateById: null,
  automationTemplateByIdLoading: false,
  errorFetchingAutomationTemplateById: false,
  isRenderFromAutomationTemplates: false,
  templatesPlatformFilters: [''],
};

export const automationTemplatesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECENT_AUTOMATIONS_LIST_DATA:
      return {
        ...state,
        recentAutomationsList: payload.content,
        recentAutomationsLoading: false,
        errorFetchingRecentAutomations: false,
      };
    case SET_RECENT_AUTOMATIONS_LOADING:
      return {
        ...state,
        recentAutomationsLoading: true,
        errorFetchingRecentAutomations: false,
      };
    case HANDLE_RECENT_AUTOMATIONS_ERROR:
      return {
        ...state,
        recentAutomationsLoading: false,
        errorFetchingRecentAutomations: true,
      };

    case GET_AUTOMATION_TEMPLATES_CATEGORIES_LIST:
      return {
        ...state,
        automationTemplatesCategoriesList: payload,
        errorFetchingAutomationTemplatesCategoriesList: false,
        automationTemplatesCategoriesListLoading: false,
      };
    case HANDLE_AUTOMATION_TEMPLATES_CATGEORIES_LIST_ERROR:
      return {
        ...state,
        errorFetchingAutomationTemplatesCategoriesList: true,
        automationTemplatesCategoriesListLoading: false,
      };
    case SET_AUTOMATION_TEMPLATES_CATEGORIES_LIST_LOADING:
      return {
        ...state,
        automationTemplatesCategoriesListLoading: true,
      };
    case GET_AUTOMATION_TEMPLATES_BY_CATEGORY:
      return {
        ...state,
        automationTemplatesByCategory: payload,
        automationTemplatesByCategoryLoading: false,
        errorFetchingAutomationTemplatesByCategory: false,
      };
    case HANDLE_AUTOMATION_TEMPLATES_BY_CATEGORY_ERROR:
      return {
        ...state,
        automationTemplatesByCategoryLoading: false,
        errorFetchingAutomationTemplatesByCategory: true,
      };
    case SET_AUTOMATION_TEMPLATES_BY_CATEGORY_LOADING:
      return {
        ...state,
        automationTemplatesByCategoryLoading: true,
        errorFetchingAutomationTemplatesByCategory: false,
      };
    case GET_AUTOMATION_TEMPLATE_BY_ID:
      return {
        ...state,
        automationTemplateById: payload,
        automationTemplateByIdLoading: false,
        errorFetchingAutomationTemplateById: false,
      };
    case SET_AUTOMATION_TEMPLATE_BY_ID_LOADING:
      return {
        ...state,
        automationTemplateByIdLoading: true,
        errorFetchingAutomationTemplateById: false,
      };
    case HANDLE_AUTOMATION_TEMPLATE_BY_ID_ERROR:
      return {
        ...state,
        automationTemplateByIdLoading: false,
        errorFetchingAutomationTemplateById: true,
      };
    case SET_IS_RENDER_FLOWCHART_FROM_AUTOMATION_TEMPLATES:
      return {
        ...state,
        isRenderFromAutomationTemplates: payload,
      };
    case SET_TEMPLATES_PLATFORM_FILTER:
      return {
        ...state,
        templatesPlatformFilters: payload,
      };
    default:
      return state;
  }
};
