//System Features
export const SYSTEM_FEATURE_NOTIFICATIONS = 'SYSTEM_FEATURE_NOTIFICATIONS';
export const SYSTEM_FEATURE_WORKAROUNDS_ENABLED = 'SYSTEM_FEATURE_WORKAROUNDS_ENABLED';

//Application Features
export const APP_FEATURE_SLACK_INTEGRATION = 'APP_FEATURE_SLACK_INTEGRATION';
export const APP_FEATURE_ZOOM_INTEGRATION = 'APP_FEATURE_ZOOM_INTEGRATION';
export const APP_FEATURE_GITHUB_INTEGRATION = 'APP_FEATURE_GITHUB_INTEGRATION';
export const APP_FEATURE_TWITTER_INTEGRATION = 'APP_FEATURE_TWITTER_INTEGRATION';
export const APP_FEATURE_DISCORD_INTEGRATION = 'APP_FEATURE_DISCORD_INTEGRATION';
export const APP_FEATURE_DISCOURSE_INTEGRATION = 'APP_FEATURE_DISCOURSE_INTEGRATION';
export const APP_FEATURE_CONFLUENCE_INTEGRATION = 'APP_FEATURE_CONFLUENCE_INTEGRATION';
export const APP_FEATURE_ZENDESK_INTEGRATION = 'APP_FEATURE_ZENDESK_INTEGRATION';
export const APP_FEATURE_FRESHDESK_INTEGRATION = 'APP_FEATURE_FRESHDESK_INTEGRATION';
export const APP_FEATURE_NOTION_INTEGRATION = 'APP_FEATURE_NOTION_INTEGRATION';
export const APP_FEATURE_GOOGLE_DRIVE_INTEGRATION = 'APP_FEATURE_GOOGLE_DRIVE_INTEGRATION';

export const APP_FEATURE_WEB_INTEGRATION = 'APP_FEATURE_WEB_INTEGRATION';
export const APP_FEATURE_HUBSPOT_INTEGRATION = 'APP_FEATURE_HUBSPOT_INTEGRATION';
export const APP_FEATURE_CRM_HUBSPOT_INTEGRATION = 'APP_FEATURE_CRM_HUBSPOT_INTEGRATION';
export const APP_FEATURE_SALESFORCE_INTEGRATION = 'APP_FEATURE_SALESFORCE_INTEGRATION';
export const APP_FEATURE_MEMBER_INSIGHTS = 'APP_FEATURE_MEMBER_INSIGHTS';
export const APP_FEATURE_MESSAGES_CONTENT_SCHEDULER = 'APP_FEATURE_MESSAGES_CONTENT_SCHEDULER';
export const APP_FEATURE_MESSAGES_WEEKLY_SUMMARY = 'APP_FEATURE_MESSAGES_WEEKLY_SUMMARY';
export const APP_FEATURE_MESSAGES_INSIGHTS = 'APP_FEATURE_MESSAGES_INSIGHTS';
export const APP_FEATURE_EVENTS = 'APP_FEATURE_EVENTS';
export const APP_FEATURE_CONNECTIONS = 'APP_FEATURE_CONNECTIONS';
export const APP_FEATURE_SETTINGS_GENERAL = 'APP_FEATURE_SETTINGS_GENERAL';
export const APP_FEATURE_SETTINGS_BILLING = 'APP_FEATURE_SETTINGS_BILLING';
export const APP_FEATURE_SETTINGS_LANDING_PAGE = 'APP_FEATURE_SETTINGS_LANDING_PAGE';
export const APP_FEATURE_REWARDS = 'APP_FEATURE_REWARDS';
export const APP_FEATURE_REWARDS_CENTER = 'APP_FEATURE_REWARDS_CENTER';
export const APP_FEATURE_REWARDS_INSIGHTS = 'APP_FEATURE_REWARDS_INSIGHTS';
export const APP_FEATURE_WORKFLOW = 'APP_FEATURE_WORKFLOW';
export const APP_FEATURE_INTERCOM_INTEGRATION = 'APP_FEATURE_INTERCOM_INTEGRATION';
export const APP_FEATURE_MICROSOFT_TEAMS_INTEGRATION = 'APP_FEATURE_MICROSOFT_TEAMS_INTEGRATION';

export const APP_SERVICES = {
  discord: 'discord',
  slack: 'slack',
  zoom: 'zoom',
  twitter: 'twitter',
  github: 'github',
  discourse: 'discourse',
  hubspot: 'hubspot',
};

export const ENGAGEMENT_SERVICES = {
  messages: 'messages',
  reactions: 'reactions',
};

export const SubscriptionPlans = {
  basic: 'basic',
  gold: 'gold',
  goldPlus: 'goldPlus',
  enterprise: 'enterprise',
};

export const discordUrl = 'https://cdn.discordapp.com/avatars/';
