import {
  SET_AT_RISK_MEMBERS_DATA,
  SET_AT_RISK_MEMBERS_DATA_LOADING,
  SET_NEW_MEMBERS_INSIGHT_STATIC_DATA,
  SET_NEW_MEMBERS_INSIGHT_STATIC_DATA_LOADING,
  SET_POTENTIAL_CHAMPIONS_LIST_DATA,
  SET_POTENTIAL_CHAMPIONS_LIST_DATA_LOADING,
  SET_TOP_MEMBERS_INSIGHT_STATIC_DATA,
  SET_TOP_MEMBERS_INSIGHT_STATIC_DATA_LOADING,
  SET_TOP_MEMBERS_LIST_DATA,
  SET_TOP_MEMBERS_LIST_DATA_LOADING,
  SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA,
  SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA_LOADING,
} from '../types';

const initialState = {
  newMembersInsightStaticData: null,
  newMemberStaticDataLoading: false,
  totalMembersInsightStaticData: null,
  totalMembersStaticDataLoading: false,
  topMembersListData: null,
  topMembersListDataLoading: false,
  topMembersInsightStaticData: null,
  topMembersInsightStaticDataLoading: false,
  potentialChampionsListData: null,
  potentialChampionsListDataLoading: false,
  atRiskMembersData: null,
  atRiskMembersDataLoading: false,
};

const memberAnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NEW_MEMBERS_INSIGHT_STATIC_DATA:
      return {
        ...state,
        newMembersInsightStaticData: payload,
        newMemberStaticDataLoading: false,
      };
    case SET_NEW_MEMBERS_INSIGHT_STATIC_DATA_LOADING:
      return {
        ...state,
        newMemberStaticDataLoading: payload,
      };
    case SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA:
      return {
        ...state,
        totalMembersInsightStaticData: payload,
        totalMembersStaticDataLoading: false,
      };
    case SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA_LOADING:
      return {
        ...state,
        totalMembersStaticDataLoading: payload,
      };
    case SET_TOP_MEMBERS_INSIGHT_STATIC_DATA:
      return {
        ...state,
        topMembersInsightStaticData: payload,
        topMembersInsightStaticDataLoading: false,
      };
    case SET_TOP_MEMBERS_INSIGHT_STATIC_DATA_LOADING:
      return {
        ...state,
        topMembersInsightStaticDataLoading: payload,
      };
    case SET_TOP_MEMBERS_LIST_DATA:
      return {
        ...state,
        topMembersListData: payload,
        topMembersListDataLoading: false,
      };
    case SET_TOP_MEMBERS_LIST_DATA_LOADING:
      return {
        ...state,
        topMembersListDataLoading: payload,
      };
    case SET_POTENTIAL_CHAMPIONS_LIST_DATA:
      return {
        ...state,
        potentialChampionsListData: payload,
        potentialChampionsListDataLoading: false,
      };
    case SET_POTENTIAL_CHAMPIONS_LIST_DATA_LOADING:
      return {
        ...state,
        potentialChampionsListDataLoading: payload,
      };
    case SET_AT_RISK_MEMBERS_DATA:
      return {
        ...state,
        atRiskMembersData: payload,
        atRiskMembersDataLoading: false,
      };
    case SET_AT_RISK_MEMBERS_DATA_LOADING:
      return {
        ...state,
        atRiskMembersDataLoading: payload,
      };
    default:
      return state;
  }
};

export default memberAnalyticsReducer;
