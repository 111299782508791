import { FC, useEffect } from 'react';
import EventBus from '../events/EventBus';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from './auth/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCurrentCommunity } from './hooks';
import {
  dataLoadingBannerAction,
  indivisualChannelUpdater,
  loadCommunityNotificationsCountAction,
  hubspotSyncProcessEvent,
} from 'redux/communityProvider/actions/communityAction';

import {
  memberImportProcessedEvent
} from 'redux/memberProvider/actions/membersAction';
import { setTrainingPageDetailsDataByWSEvent } from 'redux/trainingProvider/actions/trainingAction';

const EventBusGenericHandler = ({ children }) => {
  const { addToast } = useToasts();
  const { reloadUser } = useAuth();
  const communityDetail = useSelector(useGetCurrentCommunity);
  const dispatch = useDispatch();

  useEffect(() => {
    //communityNotificationsCountChanged
    const unsubscribeCommunityNotificaitonCountChanged =
      EventBus.communityNotificationsCountChanged.on((message) => {
        dispatch(loadCommunityNotificationsCountAction(message.communityId));
      });

    //communitySimpleMessage
    const unsubscribeCommunitySimpleMessage = EventBus.communitySimpleMessage.on((message) => {
      if (communityDetail && communityDetail.id === message.communityId) {
        localStorage.setItem('_dilp', '100');
        setTimeout(() => {
          dispatch(dataLoadingBannerAction(false));
          localStorage.removeItem('_dilp');
        }, 3000);
        reloadUser();
      }
      addToast(
        <>
          <div className="flex flex-row items-center">
            <span className="text-sm font-bold">Community event</span>
          </div>
          <div>{message.text}</div>
        </>,
        { appearance: message.appearance },
      );
    });

    EventBus.channelDataloadEvent.on((message) => {
      indivisualChannelUpdater(message)(dispatch);

      // loadIntegrationChannelConfigsAction(message.integrationId)(dispatch);
    });

    EventBus.integrationDataLoadedEvent.on((message) => {
      indivisualChannelUpdater(message)(dispatch);
      // loadIntegrationChannelConfigsAction(message.integrationId)(dispatch);
    });

    EventBus.memberImportProcessedEvent.on((message) => {
      memberImportProcessedEvent(message)(dispatch);
    });

    EventBus.hubspotSyncProcessEvent.on((message) => {
      hubspotSyncProcessEvent(message)(dispatch);
    });

    EventBus.trainingStatusForWorkspace.on((message) => {
      dispatch(setTrainingPageDetailsDataByWSEvent(message));
    });
    return () => {
      unsubscribeCommunityNotificaitonCountChanged();
      unsubscribeCommunitySimpleMessage();
    };
  }, []);

  return <>{children}</>;
};

export default EventBusGenericHandler;
