

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_LOADING = 'SET_LOADING';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const SET_AUTH_ACTION_LOADING = 'SET_AUTH_ACTION_LOADING';
export const SET_ACCOUNT_VERIFICATION_STATUS = 'SET_ACCOUNT_VERIFICATION_STATUS';
export const RESET_ACCOUNT_VERIFICATION_STATUS = 'RESET_ACCOUNT_VERIFICATION_STATUS';
export const RESET_SIGN_SUCCESS = 'RESET_SIGN_SUCCESS';
export const RESET_PASSWORD_REFRESH = 'RESET_PASSWORD_REFRESH';
export const SET_LOGIN_WITH_EMAIL = 'SET_LOGIN_WITH_EMAIL';
export const GET_SIGNUP_USER_INFO_FROM_PARAM_TOKEN = 'GET_SIGNUP_USER_INFO_FROM_PARAM_TOKEN';
export const SET_SIGNUP_USER_INFO_FROM_TOKEN_LOADING = 'SET_SIGNUP_USER_INFO_FROM_TOKEN_LOADING';