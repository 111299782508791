/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Api from 'layers/interceptor/interceptor';
import { Dispatch } from 'redux';
import {
  CLEAR_MEMBER_ERROR,
  DELETE_MEMBER,
  GET_MEMBERS_DATA,
  MEMBERS_ERROR_HANDLE,
  MEMBERS_TOAST_HANDLE,
  SET_CHANNELS_FILTER,
  SET_GROUPS_FILTER,
  SET_MEMBERS_LOADING,
  SET_MEMBERS_CHART_LOADING,
  SET_MEMBERS_ROLES,
  SET_MEMBER_SEARCH_FILTER,
  SET_SEGMENTS_FILTER,
  SEND_MEMBERS_TOKENS,
  MEMBERS_DELETE_ERROR_HANDLE,
  CLEAR_DELETE_MEMBER_ERROR,
  segmentSettingsType,
  SET_SEGMENT_SETTINGS_STATUS,
  GET_SEGMENT_SETTINGS_SCORE,
  SET_TOTAL_MEMBER_COUNT,
  SET_CHANNEL_DETAILS,
  SET_DEFAULT_FILTER,
  SET_SURVEY_LIST,
  SET_BULK_MEMBER_USERID,
  ERROR_BULK_MEMBER_USERID,
  LOADING_BULK_MEMBER_USERID,
  RESET_BULK_MEMBER_USERID,
  SET_DATA_GRID_FILTER,
  MEMBER_GRID_ERROR_HANDLER,
  SET_DRAFT_FILTER,
  MEMBER_IMPORT_PROCESS,
  SET_MEMBER_IMPORT_PROCESSING,
  SET_MEMBER_CUSTOM_FIELDS,
  SET_MEMBER_CONFIGURE_COLUMN,
  SET_MEMBER_LIST_FROM_ID,
  SET_UNSUBSCRIBE_MEMBER_LIST,
  SET_V5_LOADING,
} from 'redux/memberProvider/types';
import { transformMemberData, getUtcDate } from 'redux/memberProvider/util';
import { pageSize } from 'constant/paginationConstants';
import {
  getDeleteMemberApiUrl,
  getMemberCsvDownloadApiUrl,
  getMembersApiUrl,
  getMemberSendMessageApiUrl,
  getSendMemberTokensApiUrl,
  getMembersRolesApiUrl,
  getSegmentSettingsScore,
  postContentMessage,
  getCriteria,
  spotCriteria,
  getTotalMemberCountURL,
  addTagtoMembersUrl,
  getChannelDetailsUrl,
  uploadCSVURL,
  getWorkflowSurvey,
  getBulkDMMemberUserIdCount,
  sendBulkTestMessageUrl,
  getSlackChannelDetailsUrl,
  memberImportUpload,
  processMemberImport,
  processingMemberImportCount,
  getDiscordChannelDetailsUrl,
  getCustomFieldsForMemberFilter,
  getMembersApiV5Url,
} from 'constant/apiConstants';
import { AddToast } from 'react-toast-notifications';
import axios from 'axios';

import {
  RESET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
  SET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
} from 'redux/globalProvider/types';

export const getMembersDataAction =
  (
    community,
    page = 0,
    size = pageSize,
    getCurrentToken,
    dataGridFilters = { condition: 'and', predicate: [] },
    sortKey = '',
    sortOrder,
    selectedSearch,
    memberId,
  ) =>
    (dispatch) => {
      dispatch(setMembersLoading(true));
      const { id: communityId, timezone } = community;
      const dataGridJson = JSON.parse(JSON.stringify(dataGridFilters));
      dataGridJson?.predicate?.map((eachPredicate) => {
        if (eachPredicate.value == '') {
          eachPredicate.value = 'IS_EMPTY';
        }
        if (
          eachPredicate.field === 'channel' &&
          (eachPredicate.operator == 'IS_NOT_EMPTY' || eachPredicate.operator == 'IS_EMPTY')
        ) {
          eachPredicate.value = '0';
        }
      });

      if (getCurrentToken && getCurrentToken?.length > 0) {
        getCurrentToken.map((inst) => inst.cancel());
        dispatch({
          type: RESET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
        });
      }

      if (!communityId) return;
      let filters = `communityId=${communityId}&size=${size}&page=${page}`;

      if (selectedSearch || memberId) {
        filters += `&memberId=${memberId ? memberId : selectedSearch.value}`;
      }

      if (dataGridJson) {
        filters += `&criteria=${encodeURIComponent(JSON.stringify(dataGridJson))}`;
      }

      if (sortKey && sortOrder) {
        filters += `&sortKey=${sortKey}&sortOrder=${sortOrder}`;
      }

      const cancelTokenSource = axios.CancelToken.source();

      dispatch({
        type: SET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
        payload: cancelTokenSource,
      });

      Api.get(getMembersApiUrl(communityId, filters), {
        cancelToken: cancelTokenSource.token,
      })
        //eslint-disable-next-line
        .then((res) => {
          dispatch({
            type: GET_MEMBERS_DATA,
            payload: {
              ...res,
              content: transformMemberData(res.content, timezone),
            },
          });
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            //TODO: Need to add back once BE is supported
            // dispatch({
            //   type: MEMBERS_ERROR_HANDLE,
            //   payload: 'Error occurred while fetching members',
            // });
            // dispatch({
            //   type: MEMBER_GRID_ERROR_HANDLER,
            //   payload: 'Error occurred while fetching members',
            // });
          }
        });
    };

export const getMembersDatav5Action =
  (
    community,
    page = 0,
    size = pageSize,
    getCurrentToken,
    memberIds,
    dataGridFilters,
    providerType,
    domainEmail,
    unsubscribed,
  ) =>
    (dispatch) => {
      const { id: communityId, timezone } = community;
      const dataGridJson = dataGridFilters ? JSON.parse(JSON.stringify(dataGridFilters)) : undefined;
      dataGridJson?.predicate?.map((eachPredicate) => {
        if (eachPredicate.value == '') {
          eachPredicate.value = 'IS_EMPTY';
        }
        if (
          eachPredicate.field === 'channel' &&
          (eachPredicate.operator == 'IS_NOT_EMPTY' || eachPredicate.operator == 'IS_EMPTY')
        ) {
          eachPredicate.value = '0';
        }
      });

      if (!communityId) return;
      let filters = `communityId=${communityId}&size=${size}&page=${page}`;

      if (memberIds) {
        filters += `&memberIds=${memberIds}`;
      }

      if (dataGridJson) {
        filters += `&criteria=${encodeURIComponent(JSON.stringify(dataGridJson))}`;
      }

      if (providerType) {
        filters += `&providerName=${providerType}`;
      }

      if (domainEmail) {
        filters += `&domainEmail=${domainEmail}`;
      }

      if (unsubscribed) {
        filters += `&unsubscribe=${unsubscribed}`;
      }

      dispatch({
        type: SET_V5_LOADING,
        payload: true,
      });

      if (getCurrentToken && getCurrentToken?.length > 0) {
        getCurrentToken.map((inst) => inst.cancel());
        dispatch({
          type: RESET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
        });
      }
      const cancelTokenSource = axios.CancelToken.source();

      dispatch({
        type: SET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
        payload: cancelTokenSource,
      });

      Api.get(getMembersApiV5Url(filters), {
        cancelToken: cancelTokenSource.token,
      })
        //eslint-disable-next-line
        .then((res) => {
          if (unsubscribed) {
            dispatch({
              type: SET_UNSUBSCRIBE_MEMBER_LIST,
              payload: {
                ...res,
                content: transformMemberData(res.content, timezone),
              },
            });
          } else {
            dispatch({
              type: SET_UNSUBSCRIBE_MEMBER_LIST,
              payload: null,
            });

            dispatch({
              type: SET_MEMBER_LIST_FROM_ID,
              payload: {
                ...res,
                content: transformMemberData(res.content, timezone),
              },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: SET_V5_LOADING,
            payload: false,
          });
        })
        .finally(() => { });
    };

export const setMemberCountLoading = () => ({
  type: LOADING_BULK_MEMBER_USERID,
});
export const resetMemberCountLoading = () => ({
  type: RESET_BULK_MEMBER_USERID,
});

export const getMembersIdToBulkMessage =
  (communityId, provider, extraParam, extraParamType) => (dispatch) => {
    // encodeURIComponent(JSON.stringify(dataGridFilters))
    dispatch(setMemberCountLoading());
    Api.get(getBulkDMMemberUserIdCount(communityId, provider, extraParam, extraParamType))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: SET_BULK_MEMBER_USERID,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: ERROR_BULK_MEMBER_USERID,
          payload: 'Error occurred while fetching member count',
        });
      });
  };

export const getMembersTableDataAction =
  (community, fromDate = '', toDate = '') =>
    (dispatch) => {
      const { id: communityId, timezone } = community;
      const filters = `fromDate=${fromDate}&toDate=${toDate}`;
      Api.get(getMembersApiUrl(communityId, filters))
        //eslint-disable-next-line
        .then((res) => {
          dispatch({
            type: GET_MEMBERS_DATA,
            payload: {
              ...res,
              content: transformMemberData(res.content.members, timezone),
              segments: res.content.segments,
            },
          });
        })
        .catch(() => {
          dispatch({
            type: MEMBERS_ERROR_HANDLE,
            payload: 'Error occurred while fetching members data.',
          });
        });
    };

export const getMemberRolesAction = (communityId) => (dispatch) => {
  Api.get(getMembersRolesApiUrl(communityId))
    .then((res) => {
      dispatch({
        type: SET_MEMBERS_ROLES,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({
        type: MEMBERS_ERROR_HANDLE,
        payload: 'Error occurred while fetching member roles',
      });
    });
};

export const getMemberRolesWithNewlyCreatedTagAction =
  (
    communityId,
    event,
    multiSelectCheckBoxHandler,
    dataSrcPayload,
    setSubmenuOpen,
    setNoData,
    setIsCreateNewOptionLoader,
  ) =>
    (dispatch) => {
      Api.get(getMembersRolesApiUrl(communityId))
        .then((res) => {
          dispatch({
            type: SET_MEMBERS_ROLES,
            payload: res,
          });
          multiSelectCheckBoxHandler(event, dataSrcPayload);
          setSubmenuOpen && setSubmenuOpen(false);
          setNoData && setNoData(false);
          setIsCreateNewOptionLoader && setIsCreateNewOptionLoader(false);
        })
        .catch(() => {
          dispatch({
            type: MEMBERS_ERROR_HANDLE,
            payload: 'Error occurred while fetching member roles',
          });
          setIsCreateNewOptionLoader && setIsCreateNewOptionLoader(false);
        });
    };

export const getMembersCsvDownloadAction =
  (community, dataGridFilters = { condition: 'and', predicate: [] }) =>
    (dispatch) => {
      if (!community.id) return;
      let filters = `communityId=${community.id}`;

      const dataGridJson = JSON.parse(JSON.stringify(dataGridFilters));
      dataGridJson.predicate.map((eachPredicate) => {
        if (eachPredicate.value == 'CUSTOM_RANGE') {
          eachPredicate.from = getUtcDate(eachPredicate?.from, community.timezone);
          eachPredicate.to = getUtcDate(eachPredicate?.to, community.timezone);
        }
        if (eachPredicate.value == '') {
          eachPredicate.value = 'IS_EMPTY';
        }
        if (
          eachPredicate.field === 'channel' &&
          (eachPredicate.operator == 'IS_NOT_EMPTY' || eachPredicate.operator == 'IS_EMPTY')
        ) {
          eachPredicate.value = '0';
        }
      });

      if (dataGridFilters) {
        filters += `&criteria=${encodeURIComponent(JSON.stringify(dataGridJson))}`;
      }

      Api.get(getMemberCsvDownloadApiUrl(community.id, filters))
        //eslint-disable-next-line
        .then((response) => { })
        .catch(() => {
          dispatch({
            type: MEMBERS_ERROR_HANDLE,
            payload: 'Error occurred while downloading csv',
          });
        });
    };

export const deleteMemberAction = (memberId, communityId) => (dispatch) => {
  Api.delete(getDeleteMemberApiUrl(memberId, communityId))
    .then((response) => {
      if (response.ok) {
        dispatch({
          type: DELETE_MEMBER,
          payload: memberId,
        });
      } else {
        dispatch({
          type: MEMBERS_DELETE_ERROR_HANDLE,
          payload: {
            reason: response.errorReason,
            redirectUrl: response.redirectUrl,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: MEMBERS_ERROR_HANDLE,
        payload: 'Error occurred while deleting member',
      });
    });
};

export const getSendMemberDirectMessageAction =
  (communityId, memberIds, message, isBot, userId, integrationProvider, title) => (dispatch) => {
    // const options = {
    //   integration: 'SLACK',
    //   messageType: 'DM',
    //   segments: [],
    //   fromDateString: '2022-02-20T18:30:00.000Z',
    //   tags: [],
    //   channelExternalIds: [],
    // };

    const dataPayload = {
      message: message,
      title: title && title?.length > 0 ? title : '',
      description: '',
      contentType: 'DIRECT_MESSAGE',
      publishType: 'POST',
      memberIds: memberIds,
      channels: [],
      integrations: integrationProvider ? [integrationProvider] : ['SLACK'],
      segments: [],
      tags: [],
      subject: '',
      sendViaBot: isBot,
      sendersUserId: userId !== -1 ? userId : '',
    };

    Api.post(postContentMessage(communityId), dataPayload)
      .then(() => {
        dispatch({
          type: MEMBERS_TOAST_HANDLE,
          payload: {
            success: true,
            message:
              'Send Bulk DM initiated. Please check the status after a few minutes on Notifications page.',
          },
        });
      })
      .catch(() => {
        dispatch({
          type: MEMBERS_ERROR_HANDLE,
          payload: 'Error occurred while sending direct message',
        });
      });

    // Api.post(getMemberBulkMessageApiUrl(), { memberIds, message, integrationId, ...options })
    //   .then(() => {
    //     dispatch({
    //       type: MEMBERS_TOAST_HANDLE,
    //       payload: { success: true, message: 'Direct Message sent successfully' },
    //     });
    //   })
    //   .catch(() => {

    //   });
  };

export const getSendMemberMessageAction = (userId, message, title) => (dispatch) => {
  Api.post(getMemberSendMessageApiUrl(), { userId, message, title })
    .then(() => {
      dispatch({
        type: MEMBERS_TOAST_HANDLE,
        payload: { success: true, message: 'Message sent successfully' },
      });
    })
    .catch(() => {
      dispatch({
        type: MEMBERS_ERROR_HANDLE,
        payload: 'Error occurred while sending message',
      });
    });
};

export const getSendMemberTokensAction = (memberId, points, reason) => (dispatch) => {
  Api.post(getSendMemberTokensApiUrl(), { memberId, points, reason })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response) => {
      if (response?.success) {
        dispatch({
          type: SEND_MEMBERS_TOKENS,
          payload: { memberId, points },
        });
      }
      dispatch({
        type: MEMBERS_TOAST_HANDLE,
        payload: response,
      });
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch(() => {
      dispatch({
        type: MEMBERS_ERROR_HANDLE,
        payload: 'Error occurred while sending tokens',
      });
    });
};

export const setMembersLoading = (isLoading = false) => ({
  type: SET_MEMBERS_LOADING,
  payload: isLoading,
});

export const setMembersChartLoading = (isLoading = false) => ({
  type: SET_MEMBERS_CHART_LOADING,
  payload: isLoading,
});

export const setSegmentsFilterAction = (filter) => ({
  type: SET_SEGMENTS_FILTER,
  payload: filter,
});

export const setMemberDataGridFilter = (filter) => ({
  type: SET_DATA_GRID_FILTER,
  payload: filter,
});

export const setGroupsFilterAction = (filter) => ({
  type: SET_GROUPS_FILTER,
  payload: filter,
});

export const setChannelsFilterAction = (filter) => ({
  type: SET_CHANNELS_FILTER,
  payload: filter,
});

export const setMembersSearchFilterAction = (filter) => ({
  type: SET_MEMBER_SEARCH_FILTER,
  payload: filter,
});

export const clearMemberToastAction = () => ({
  type: CLEAR_MEMBER_ERROR,
});

export const clearDeleteMemberError = () => ({
  type: CLEAR_DELETE_MEMBER_ERROR,
});

export const setDraftFilter = (payload) => ({
  type: SET_DRAFT_FILTER,
  payload: payload,
});

export const getSegmentScore = (communityId, addToast) => (dispatch) => {
  Api.get(getSegmentSettingsScore(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_SEGMENT_SETTINGS_SCORE,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_SEGMENT_SETTINGS_STATUS,
        payload: 'Error occurred while fetching segment score',
      });
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const setFilters =
  (communityId, saveFilter, addToast, setRefreshFilterData) => (dispatch) => {
    Api.post(getCriteria(communityId), saveFilter)
      //eslint-disable-next-line
      .then((res) => {
        addToast(`${saveFilter.name} is successfully saved!`, { appearance: 'success' });
        setRefreshFilterData(new Date().toString());
        // setDefaultFilter
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const setDefaultFilter = (filterId) => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_FILTER,
    payload: filterId,
  });
};

export const deleteFilters = (communityId, filterId, addToast, setRefreshFilterData) => () => {
  Api.delete(spotCriteria(communityId, filterId))
    //eslint-disable-next-line
    .then((res) => {
      addToast(`Filter is successfully deleted!`, { appearance: 'success' });
      setRefreshFilterData(new Date().toString());
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const getTotalMemberCount = (communityId, addToast) => (dispatch) => {
  Api.get(getTotalMemberCountURL(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: SET_TOTAL_MEMBER_COUNT,
        payload: res.count,
      });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const mapTagstoMembers =
  (
    communityId,
    postParams,
    addToast,
    handleClose,
    setRefreshTable,
    setSelectedDmMembers,
    setSelectedSlackMembers,
    setSelectedMembers,
  ) =>
    (dispatch) => {
      Api.post(addTagtoMembersUrl(communityId), postParams)
        //eslint-disable-next-line
        .then((res) => {
          setSelectedDmMembers && setSelectedDmMembers([]);
          setSelectedSlackMembers && setSelectedSlackMembers([]);
          setSelectedMembers && setSelectedMembers([]);
          handleClose();
          setRefreshTable(new Date().toString());
        })
        .catch(() => {
          addToast('Facing some issues from our end in adding tags. Try again Later!', { appearance: 'error' });
        });
    };

export const removeTagstoMembers =
  (
    communityId,
    postParams,
    addToast,
    handleClose,
    setRefreshTable,
    setSelectedDmMembers,
    setSelectedSlackMembers,
    setSelectedMembers,
  ) =>
    (dispatch) => {
      Api.delete(addTagtoMembersUrl(communityId), postParams)
        //eslint-disable-next-line
        .then((res) => {
          setSelectedDmMembers && setSelectedDmMembers([]);
          setSelectedSlackMembers && setSelectedSlackMembers([]);
          setSelectedMembers && setSelectedMembers([]);
          handleClose();
          setRefreshTable(new Date().toString());
        })
        .catch(() => {
          addToast('Facing some issues from our end in removing tags. Try again Later!', { appearance: 'error' });
        });
    };

export const uploadCSVNow =
  (
    communityId,
    body,
    setSelectedMembersFromFile,
    setTotalMembersUploaded,
    addToast,
    setFileIsRejected,
  ) =>
    (dispatch) => {
      Api.post(
        uploadCSVURL(communityId),
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        },
        '',
      )
        //eslint-disable-next-line
        .then((res) => {
          setSelectedMembersFromFile(res.memberIds);
          setTotalMembersUploaded(res.totalEmailIdsProvided);
          setFileIsRejected(false);
        })
        .catch(() => {
          setFileIsRejected(true);
        });
    };

export const getChannelList = (communityId, addToast) => (dispatch) => {
  Api.get(getChannelDetailsUrl(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: SET_CHANNEL_DETAILS,
        payload: res,
      });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const getSlackChannelList = (communityId, addToast, callback) => (dispatch) => {
  Api.get(getSlackChannelDetailsUrl(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: SET_CHANNEL_DETAILS,
        payload: res,
      });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    })
    .finally(() => {
      callback && callback();
    });
};

export const getDiscordChannelList = (communityId, addToast, callback) => (dispatch) => {
  Api.get(getDiscordChannelDetailsUrl(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: SET_CHANNEL_DETAILS,
        payload: res,
      });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    })
    .finally(() => {
      callback && callback();
    });
};

// export const getSlackChannelList = (communityId, addToast) => (dispatch) => {
//   Api.get(getSlackChannelDetailsUrl(communityId))
//     //eslint-disable-next-line
//     .then((res) => {
//       dispatch({
//         type: SET_CHANNEL_DETAILS,
//         payload: res,
//       });
//     })
//     .catch(() => {
//       addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
//     });
// };

export const getSurveyList = (communityId) => (dispatch) => {
  Api.get(getWorkflowSurvey(communityId, true))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: SET_SURVEY_LIST,
        payload: res,
      });
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((_err) => { });
};

export const setSegmentScore =
  (communityId, SegmentScores, addToast, refreshModal, setIsLoading) => (dispatch) => {
    Api.post(getSegmentSettingsScore(communityId), {
      levels: SegmentScores,
    })
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: SET_SEGMENT_SETTINGS_STATUS,
          payload: 'Segment Settings saved successfully',
        });
        refreshModal.setTrue();
      })
      .catch(() => {
        dispatch({
          type: SET_SEGMENT_SETTINGS_STATUS,
          payload: 'Error occurred while fetching segment score',
        });
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const sendTestMessageBulkDM = (addToast, payload) => async (dispatch) => {
  await Api.post(sendBulkTestMessageUrl(), payload)
    .then(() => {
      addToast('Successfully sent the test message to your slack account', {
        appearance: 'success',
      });
    })
    .catch(() => {
      addToast('An error occurred while sending test message to your slack account', {
        appearance: 'error',
      });
    });
};

export const uploadMemberImportFile =
  (
    communityId,
    body,
    setMemberImportId,
    setFileIsRejected,
    setMembersUploadedCount,
    setColumnHeaders,
    setFileUploadError,
  ) =>
    (dispatch) => {
      Api.post(
        memberImportUpload(communityId),
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        },
        '',
      )
        .then((res) => {
          setMemberImportId(res.memberImportId);
          setMembersUploadedCount(res.memberCount);
          setColumnHeaders(res.headers);
          setFileIsRejected(false);
        })
        .catch((err) => {
          setFileIsRejected(true);
          setFileUploadError(err.response?.data?.reason);
        });
    };

export const requestProcessMemberImport =
  (communityId, payload, setSelectedFile, handleClose) => (dispatch) => {
    Api.post(processMemberImport(communityId), {
      ...payload,
    })
      .then((res) => {
        setSelectedFile(null);
        handleClose();
      })
      .catch(() => { });
  };

export const getMemberImportProcessingCount = (communityId) => (dispatch) => {
  Api.get(processingMemberImportCount(communityId))
    .then((res) => {
      dispatch({
        type: SET_MEMBER_IMPORT_PROCESSING,
        payload: res?.count > 0,
      });
    })
    .catch(() => { });
};

export const memberImportProcessedEvent = (message) => (dispatch) => {
  dispatch({
    type: MEMBER_IMPORT_PROCESS,
    payload: message,
  });
};

export const getMemberCustomFieldsActions = (communityId) => (dispatch) => {
  Api.get(getCustomFieldsForMemberFilter(communityId))
    .then((res) => {
      dispatch({
        type: SET_MEMBER_CUSTOM_FIELDS,
        payload: res,
      });
    })
    .catch(() => { });
};

export const setMemberConfigureColumnAction = (payload) => (dispatch) => {
  dispatch({
    type: SET_MEMBER_CONFIGURE_COLUMN,
    payload: payload,
  });
};
