import { AnyAction } from 'redux';
import {
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  SET_AUTH_ACTION_LOADING,
  SET_LOADING,
  SET_SERVER_ERROR,
  SET_USER_INFO,
  SIGNUP_FAIL,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SET_ACCOUNT_VERIFICATION_STATUS,
  RESET_ACCOUNT_VERIFICATION_STATUS,
  RESET_SIGN_SUCCESS,
  RESET_PASSWORD_REFRESH,
  SET_LOGIN_WITH_EMAIL,
  GET_SIGNUP_USER_INFO_FROM_PARAM_TOKEN,
  SET_SIGNUP_USER_INFO_FROM_TOKEN_LOADING,
} from '../types';

export const authState = {
  isAuthenticated: null,
  isLoading: true,
  serverError: '',
  userInfo: null,
  loginError: null,
  signUpError: null,
  signSuccess: null,
  resetPwdSuccess: false,
  resetPwdError: false,
  resetPwdLoading: false,
  authLoading: false,
  loginFailedCreds: {},
  accountVerified: null,
  accountVerificationMessage: '',
  loginWithEmail: false,
  signupUserInfoFromParamToken: null,
  isSignupUserInfoFromTokenLoading: false,
};

const authReducer = (state = authState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACCOUNT_VERIFICATION_STATUS:
      return {
        ...state,
        accountVerified: payload.verified,
        accountVerificationMessage: payload.reason,
      };
    case RESET_ACCOUNT_VERIFICATION_STATUS:
      return {
        ...state,
        accountVerified: null,
        accountVerificationMessage: null,
      };
    case SET_SERVER_ERROR:
      return {
        ...state,
        serverError: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: payload,
      };
    case LOGIN_START:
      return {
        ...state,
        isAuthenticated: null,
        signSuccess: null,
      };
    case SET_AUTH_ACTION_LOADING:
      return {
        ...state,
        authLoading: payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loginError: false,
        loginFailedCreds: {},
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: null,
        loginFailedCreds: payload.loginFailedCreds,
        loginError: payload.errorText,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: null,
        loginError: null,
        loginFailedCreds: {},
      };
    case SIGNUP_START:
      return {
        ...state,
        isAuthenticated: null,
        signUpError: null,
        loginError: null,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signUpError: null,
        signSuccess: true,
      };
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPwdLoading: payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPwdError: false,
        resetPwdSuccess: true,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPwdError: true,
        resetPwdSuccess: false,
      };
    case RESET_PASSWORD_REFRESH:
      return {
        ...state,
        resetPwdError: false,
        resetPwdSuccess: false,
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        isAuthenticated: null,
        signUpError: payload.message,
      };
    case RESET_SIGN_SUCCESS:
      return {
        ...state,
        signSuccess: null,
      };
    case SET_LOGIN_WITH_EMAIL:
      return {
        ...state,
        loginWithEmail: payload,
      };
    case GET_SIGNUP_USER_INFO_FROM_PARAM_TOKEN:
      return {
        ...state,
        signupUserInfoFromParamToken: payload,
      };
    case SET_SIGNUP_USER_INFO_FROM_TOKEN_LOADING:
      return {
        ...state,
        isSignupUserInfoFromTokenLoading: payload,
      };
    default:
      return state;
  }
};

export default authReducer;