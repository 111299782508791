import styled from '@emotion/styled';
import {
  blueColor,
  boxShadowColor,
  sidebarMenuBoxShadowColor,
  tableCellBlackColor,
  tableCellGreenColor,
  tableCellLightGreyColor,
  tableCellLightRedColor,
  tableCellYellowColor,
  tableHeaderColor,
  tableTagBackground,
  tableTagColor,
  yellowColor,
  SecondaryButtonBgColor,
} from '../theme/colorConstants';
import { Button, MenuItem } from '@material-ui/core';
import { colorToken } from '../theme/designToken';

export const TagBox = styled.div`
  background: ${tableTagBackground};
  border-radius: 4px;
  padding: 5px 10px;
  color: #8c8ac4;
  font-weight: 500;
  font-size: 10px;
  width: fit-content;
  overflow: hidden;
  max-width: 14ch;
  text-overflow: ellipsis;
  line-height: 12px;
`;

export const SegmentBox = styled.div`
  min-width: 70px;
  display: flex;
  justify-content: center;
  background: ${(props) =>
    props.segment_bg ? props.segment_bg : colorToken.badges.team.manager.background.color};
  border-radius: ${colorToken.badges.border.radius};
  padding: 5px 10px;
  color: ${(props) =>
    props.segment_color ? props.segment_color : colorToken.badges.team.manager.text.color};
  font-weight: 500;
  font-size: 12px;
  text-transform: unset;
  white-space: initial;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ActionButtonDelete = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  background: ${tableCellLightRedColor};
  border-radius: 4px;
  padding: 11px;
  cursor: pointer;
`;

export const ActionButtonEnvelope = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  background: ${tableCellYellowColor};
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;
`;

export const CellAvatarContainer = styled.div`
  display: flex;
  line-height: 1;
  gap: 10px;
  width: 90%;
`;

export const AvatarImage = styled.img`
  border-radius: 50%;
`;

export const AvatarImg = styled.img`
  border-radius: 50%;
`;

export const AvatarName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colorToken.avatar.name.color};
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AvatarEmail = styled.div`
  font-size: 12px;
  color: ${colorToken.avatar.email.color};
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CellTagsContainer = styled.div`
  display: flex;
  line-height: 1;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

export const CellIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const CellText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${tableCellBlackColor};
`;

export const BulkMessageButton = styled(Button)`
  background-color: ${yellowColor};
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0px 4px 18px ${sidebarMenuBoxShadowColor};
  padding: 6px 12px;
  text-transform: capitalize;
  gap: 3px;
  color: ${tableCellBlackColor};
  &:hover {
    background-color: ${yellowColor};
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const FilterButton = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 12px 36px ${boxShadowColor};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${tableCellBlackColor};
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 15px;
  gap: 85px;
  &:hover {
    background: #ffffff;
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  border-radius: 4px;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FilterMenuItem = styled(MenuItem)`
  width: ${(props) => (props.is_sidebar_menu == 'true' ? '170px' : '145px')};
  display: ${(props) => (props.is_sidebar_menu == 'true' ? 'flex' : 'unset')};
  justify-content: flex-start;
  font-size: 12px;
  border-radius: 4px;
  border-right: ${(props) =>
    props.is_selected === 'true'
      ? `2px solid ${blueColor}`
      : `1px solid ${tableCellLightGreyColor}`};
  font-size: 12px;
  line-height: 14px;
  color: #3e5273;
  line-height: 20px;
  font-weight: ${(props) => (props.is_selected === 'true' ? 'bold' : 'bold')};
  & .Mui-selected {
    background-color: transparent !important;
  }
  &:active {
    background-color: transparent !important;
  }
  &:hover {
    background: #f5f6fd;
  }
`;

export const ApplyFilterButton = styled(Button)`
  background: ${tableCellGreenColor};
  border-radius: 8px;
  display: flex;
  box-shadow: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: white;
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 15px;
  margin: 20px;
  margin-bottom: 0px;
  &:hover {
    background: ${tableCellGreenColor};
    color: white;
  }
`;

export const FilterButtonContainer = styled.div`
  border-right: 1px solid ${tableCellLightGreyColor};
`;

export const SetDefaultButton = styled(Button)`
  background: transparent;
  display: flex;
  border-radius: 8px;
  box-shadow: none;
  font-size: 12px;
  line-height: 15px;
  color: ${tableHeaderColor};
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 15px;
  margin: 25px;
  margin-bottom: 0px;
  margin-top: 5px;
  &:hover {
    background: transparent;
    color: ${tableHeaderColor};
  }
`;

export const FilterMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FilterOptionLabel = styled.div`
  font-weight: ${(props) => (props.is_selected === 'true' ? 'bold' : 'normal')};
  font-size: 12px;
  line-height: 20px;
  color: #3e5273;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;


export const FilterOptionLabelNew = styled.label`
  font-weight: ${(props) => (props.is_selected === 'true' ? 'bold' : 'normal')};
  font-size: 12px;
  line-height: 20px;
  color: #3e5273;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const FilterOption = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

export const GreyBox = styled.div`
  background: ${SecondaryButtonBgColor};
  border-radius: 8px;
  padding: 5px 10px;
  color: ${tableTagColor};
  font-weight: bold;
  font-size: 10px;
  width: fit-content;
`;

export const MemberFixedContainer = styled.div`
  display: flex;
  height: calc(100vh - ${(props) => props.negateheight});
`;
