import styled from '@emotion/styled';
import {
  Box,
  Button,
  Dialog,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  Menu,
} from '@material-ui/core';
import {
  blueColor,
  borderGreyColor,
  boxShadowColor,
  sidebarMenuBoxShadowColor,
  tableCellBlackColor,
  tableCellGreenColor,
  tableCellGreyColor,
  tableCellLightGreenColor,
  tableCellRedColor,
  tableCellYellowColor,
  tableHeaderColor,
  whiteColor,
  SecondaryButtonBgColor,
  defaultArrowDropdownColor,
} from 'theme/colorConstants';
import { Link } from '@mui/material';
import { colorToken } from '../theme/designToken';
import { lazy } from 'react';
import PrimaryButtonWithLoader from 'atoms/Buttons/PrimaryButtonWithLoader';
import SecondaryButtonWithLoader from 'atoms/Buttons/SecondaryButtonWithLoader';

export const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colorToken.inputFields.text.border.color} !important;
    border-radius: 4px;
  }
  .Mui-focused {
    border-color: ${colorToken.inputFields.text.border.color} !important;
  }
`;

export const CustomTextArea = styled(TextareaAutosize)`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colorToken.inputFields.text.border.color} !important;
    border-radius: 4px;
  }
  .Mui-focused {
    border-color: ${colorToken.inputFields.text.border.color} !important;
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  background: #f8f8f8;
`;

export const MainContent = styled(Box)`
  flex-grow: 1;
  padding: 40px;
  ${(props) => props.custompadding && `padding: ${props.custompadding}`};
`;

export const PageTitle = styled(Typography)`
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: ${tableCellBlackColor};
`;

export const PrimaryButton = styled(PrimaryButtonWithLoader)`
  font-family: Inter;
  background-color: ${(props) =>
    props.color
      ? props.color
      : props.disabled
      ? `#DDDDE7`
      : props.is_loading
      ? '#4A4FDF'
      : colorToken.button.primary.color};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: ${(props) => (props.padding ? props.padding : '10px 12px')};
  text-transform: ${(props) => (props.texttransform ? props.texttransform : 'capitalize')};
  gap: 3px;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &.Mui-disabled {
    color: #9797ad;
  }
  color: ${(props) =>
    props.inverse ? colorToken.button.inverse.text.color : colorToken.button.primary.text.color};
  ${(props) =>
    !props.noHover
      ? `&:hover {
    background-color: #4A4FDF;
    box-shadow: none;
  }`
      : ``};
`;

export const SaveReportButton = styled(PrimaryButtonWithLoader)`
  display: flex;
  margin-right: 8px;
  height: 36px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #696dec;
  background: #696dec;
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  text-transform: ${(props) => (props.texttransform ? props.texttransform : 'capitalize')};
  gap: 3px;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &.Mui-disabled {
    color: #9797ad;
  }
  color: ${(props) =>
    props.inverse ? colorToken.button.inverse.text.color : colorToken.button.primary.text.color};
  ${(props) =>
    !props.noHover
      ? `&:hover {
    background-color: #4A4FDF;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.04);
  }`
      : ``};
`;

export const SetSlackAlertButton = styled(PrimaryButtonWithLoader)`
  display: flex;
  margin-right: 8px;
  height: 38px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: ${(props) => (props.state === 'edit' ? '1px solid #34BE95' : '0.5px solid #e4e4e4')};
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.04);
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  text-transform: ${(props) => (props.texttransform ? props.texttransform : 'capitalize')};
  gap: 3px;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &.Mui-disabled {
    color: #9797ad;
  }
  color: ${(props) =>
    props.inverse ? colorToken.button.inverse.text.color : colorToken.button.primary.text.color};
  ${(props) =>
    !props.noHover
      ? `&:hover {
    background-color: #fff;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.04);
  }`
      : ``};
`;

export const InverseButton = styled(Button)`
  font-family:Inter;
  font-size: 12px;
    line-height: 14px;
    max-height: 34px !important;
    background-color: #FFFFFF;
    border: 1px solid ${colorToken.button.primary.color};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${colorToken.button.primary.color};
  color: ${colorToken.button.primary.color};
  font-weight: 600;
  font-size: 12px;
  padding: ${(props) => (props.padding ? props.padding : '10px 12px')};
  text-transform: ${(props) => (props.texttransform ? props.texttransform : 'capitalize')};
  gap: 3px;
  &.Mui-disabled {
    color: ${colorToken.button.primary.text.color};
  }
  &: hover {
    background-color: ${colorToken.button.primary.color};
    color: #FFFFFF !important;
    box-shadow: none;
`;

export const DeleteButton = styled(Button)`
  background: rgba(240, 70, 70, 0.2);
  color: '#F04646';
  font-weight: 600;
  border-radius: 4px;
  padding: '10px 12px';
  text-transform: 'capitalize';
  gap: 3px;
  font-size: 12px;
  line-height: 14px;
  color: #f04646;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
`;

export const LoadDataButton = styled(Button)`
font-size: 12px;
line-height: 14px;
max-height: 34px !important;
background-color: #FFFFFF;
border: 1px solid ${colorToken.button.primary.color};
box-sizing: border-box;
border-radius: 4px;
color: ${colorToken.button.primary.color};
color: ${colorToken.button.primary.color};
font-weight: 600;
font-size: 12px;
letter-spacing: -0.01em;
padding: ${(props) => (props.padding ? props.padding : '10px 12px')};
text-transform: ${(props) => (props.texttransform ? props.texttransform : 'capitalize')};
gap: 3px;
&.Mui-disabled {
color: ${colorToken.button.primary.text.color};
}
&: hover {
background-color: ${colorToken.button.primary.color};
color: #FFFFFF !important;
box-shadow: none;
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  background: rgb(248, 248, 248);
  z-index: 100;
  padding-top: 25px;
  padding-bottom: 12px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: ${(props) => (props.setMarginBottom ? props.setMarginBottom : '15px')};
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const DateFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: 15px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const HeadTitle = styled(Typography)`
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: ${tableCellBlackColor};
  margin-right: 50px;
  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

export const SubTitle = styled(Typography)`
  color: ${tableCellBlackColor};
  margin-right: 50px;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  text-align: left;

  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

export const IntervalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IntervalText = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${tableHeaderColor};
`;

export const IntervalButton = styled(Button)`
  border: 1px solid ${colorToken.intervalButton.action.hover.backgroundColor};
  margin: 0px 10px;
  margin-right: 0px;
  gap: 2px;
  color: ${(props) =>
    props.selected
      ? colorToken.intervalButton.text.color.selected
      : colorToken.intervalButton.text.color.normal};
  text-transform: capitalize;
  font-size: 12px;
  padding: 6px;
  font-weight: ${(props) => (props.selected ? 'bold' : '400')};
  border-radius: 4px;
  background-color: ${(props) =>
    props.selected ? colorToken.intervalButton.backgroundColor : 'transparent'};
  &:hover {
    border: 1px solid ${colorToken.intervalButton.action.hover.backgroundColor};
    background-color: ${(props) =>
      props.selected ? colorToken.intervalButton.action.hover.backgroundColor : 'transparent'};
  }
`;

export const IntervalButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 740px) {
    justify-content: flex-start;
  }
`;

export const CustomBox = styled(Paper)`
  min-width: ${(props) => (props.minWidth ? props.minWidth : '248px')};
  width: ${(props) => (props.width ? props.width : '24%')};
  padding: ${(props) => (props.padding ? props.padding : '24px')};
  box-shadow: 0px 12px 36px ${boxShadowColor};
  border-radius: 10px;
  margin-right: 0px;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
    justify-content: flex-start;
  }
`;

export const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    z-index: 10000;
    max-width: ${(props) => (props.maxwidth ? props.maxwidth : '638px')};
    border-radius: ${(props) => (props.borderradius ? props.borderradius : '8px')};
  }
`;

export const DialogContainer = styled.div`
  min-width: 520px;
  @media (max-width: 650px) {
    width: auto;
  }
`;

export const DialogHeader = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const DialogContent = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 60px;
`;

export const DialogFieldContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.nomargin ? props.nomargin : '25px')};
`;

export const DialogFieldTitle = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: ${colorToken.colors.greyText};
`;

export const DialogDeleteButton = styled(Button)`
  border-radius: 8px;
  background: transparent;
  align-items: flex-start;
  font-weight: 600;
  font-size: 10px;
  box-shadow: none;
  gap: 5px;
  color: ${tableCellRedColor};
  &:hover {
    background: transparent;
    color: ${tableCellRedColor};
    box-shadow: none;
  }
`;

export const DialogActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const DialogCloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const DeleteDialogText = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${tableCellBlackColor};
`;

export const ErrorDialogText = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${tableCellBlackColor};
`;

export const DialogTitle = styled(Typography)`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: ${tableCellBlackColor};
`;

export const DialogDescriptionText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${tableCellGreyColor};
  margin-top: 6px;
`;

export const NoteText = styled(Typography)`
  font-weight: 600;
  margin-bottom: 20px;
  color: #808080;
  text-transform: none;
  font-size: 10px;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.tabContainerBgColor && `background-color: ${props.tabContainerBgColor}`};
  ${(props) => props.tabContainerPadding && `padding: ${props.tabContainerPadding}`};
  ${(props) => props.tabContainerWidth && `width: ${props.tabContainerWidth}% !important`}
  ${(props) => props.fullWidth && !props.tabContainerWidth && 'width: 100%;'}
  ${(props) =>
    props.isactivitypage &&
    !props.hideborder &&
    `border-bottom: 1px solid ${colorToken.tab.border.color}`}
`;

export const Tab = styled.div`
  border-bottom: ${(props) =>
    props.selected
      ? props.isactivitypage
        ? `0px solid ${colorToken.tab.selected.color}`
        : `4px solid ${colorToken.tab.selected.color}`
      : props.isactivitypage
      ? 'unset'
      : `1px solid ${colorToken.tab.border.color}`};
  padding-bottom: ${(props) => (props.isactivitypage ? '10px' : '12px')};
  cursor: pointer;
  ${(props) => props.fullWidth && 'width: 100%; display: flex; justify-content: center;'}
  ${(props) => props.nopadding && ' padding: 12px 0px;'}
  ${(props) => props.isactivitypage && 'width: 120px; display: flex; justify-content: center;'}
  ${(props) => props.isactivitypage && 'position: relative; top: 1px;'}
  ${(props) =>
    props.tabwidth && `width: ${props.tabwidth}; display: flex; justify-content: center;`}
`;

export const TabText = styled(Typography)`
  font-style: normal;
  line-height: normal;
  font-weight: ${(props) =>
    props.selectedFontWeight
      ? props.selected
        ? props.selectedFontWeight
        : props.fontWeight
        ? props.fontWeight
        : 'bold'
      : 'bold'};
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.innertextpadding ? props.innertextpadding : '6px 54px')};
  color: ${(props) =>
    props.selected ? colorToken.tab.selected.textColor : colorToken.tab.notSelected.textColor};
  ${(props) => props.selected && props.isactivitypage && 'position: relative; top: 1px;'}
  ${(props) => props.isactivitypage && 'width: fit-content;'}
  ${(props) => props.isactivitypage && 'justify-content: center;'}
  ${(props) =>
    props.isactivitypage &&
    props.selected &&
    `
  &::after {
    background: #696DEC;
    border-radius: 0px;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    bottom: -11px;
  }`}
`;

export const TabCountText = styled.span`
  color: ${(props) => (props.selected ? '#696DEC' : '#2F405B')};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  margin-top: 1px;
  margin-left: 6px;
`;

export const SpecialTabText = styled.span`
  padding: 2px 10px;
  background: #fed87b;
  border-radius: 2px;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #191919;
  margin-left: 10px;
`;

export const CustomSelectField = styled(Select)`
  background: ${whiteColor};
  ${(props) =>
    props.customize === 'true' &&
    `border: 1px solid ${colorToken.inputFields.select.border.color};
  height: 34px;`}
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  height: ${(props) => (props.height ? props.height : '40px')};
  color: ${tableCellBlackColor};
  text-transform: capitalize;
  color: ${(props) => (props.toshowplaceholder === 1 ? tableHeaderColor : tableCellBlackColor)};
  font-weight: ${(props) => (props.toshowplaceholder === 1 ? 'normal' : 600)};
  ${(props) =>
    props.placeholder === 'true' &&
    `color: ${tableHeaderColor};
    font-weight: normal;`}
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colorToken.inputFields.select.border.color} !important;
    border-radius: 4px;
  }
  & .MuiOutlinedInput-input {
    padding-top: ${(props) => props.defArrIcon && '10px !important'};
    padding-right: ${(props) => props.defArrIcon && '38px !important'};
  }
  .Mui-focused {
    border-color: ${colorToken.inputFields.select.border.color} !important;
  }
  & .MuiSvgIcon-root {
    display: ${(props) => (props.defArrIcon ? 'unset' : 'none')};
    color: ${defaultArrowDropdownColor} !important;
  }
`;

export const EmptyStateBanner = styled.div`
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 50px 80px;
  display: flex;
  align-items: center;
  gap: 80px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
`;

export const ElementImageContainer = styled.div`
  position: absolute;
  bottom: 8%;
  right: -34px;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const EmptyStateDetail = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  max-width: 400px;
  color: ${tableCellGreyColor};
  margin-top: 14px;
  margin-bottom: 24px;
`;

export const EmptyStateTitle = styled(Typography)`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: ${tableCellBlackColor};
`;

export const CustomDatePickerStyled = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${borderGreyColor};
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
  padding: 1.8px;
  padding-left: 0px;
  justify-content: space-evenly;
  & .DateInput_input {
    padding: 5px;
    padding-bottom: 4px;
    font-size: 12px;
    background-color: transparent;
    font-weight: 600;
    color: ${tableCellBlackColor};
  }
  & .DateRangePickerInput {
    background-color: transparent;
    border: none;
  }
  & .DateInput {
    background: transparent;
    width: 100%;
  }
  & .SingleDatePickerInput__withBorder {
    box-shadow: none;
    border: none;
  }
  & .DateRangePickerInput_arrow {
    margin-bottom: 4px;
    margin-right: 4px;
  }
  & .DateRangePickerInput_clearDates {
    padding-top: 7px;
    padding-right: 3px;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
  & .DateRangePicker_picker {
    left: -200px !important;
    z-index: 10000;
  }
  & .CalendarDay__default {
    border: none;
    color: ${tableCellBlackColor};
    font-size: 12px;
    font-weight: 600;
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
    }
  }
  & .CalendarDay__blocked_out_of_range {
    border: none;
    color: ${borderGreyColor};
    font-size: 12px;
    font-weight: 600;
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
    }
  }
  & .CalendarDay__selected {
    background: ${colorToken.customDateRangeStyled.color};
    box-shadow: 0px 4px 18px ${sidebarMenuBoxShadowColor};
    border-radius: 8px;
    &:hover {
      background: ${colorToken.customDateRangeStyled.color};
    }
    &:focus {
      background: ${colorToken.customDateRangeStyled.color};
    }
  }
  & .CalendarDay__selected_span {
    background: ${tableCellYellowColor};

    &:hover {
      background: ${tableCellYellowColor};
    }
    &:focus {
      background: ${tableCellYellowColor};
    }
  }
  & .DayPicker_weekHeader {
    color: ${tableHeaderColor};
    font-size: 10px !important;
  }
`;

export const OptionLabel = styled.div`
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  font-size: 12px;
  line-height: 20px;
  color: ${tableCellBlackColor};
`;

export const OptionContainer = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

export const ReadMoreText = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${blueColor};
  cursor: pointer;
`;

export const CustomDateRangeStyled = styled.div`
  display: flex;
  align-items: center;
  border: ${(props) =>
    props.onlyDatePicker ? 'none' : '1px solid ' + colorToken.customDateRangeStyled.border.color};
  border-radius: 4px;
  background-color: white;

  // margin-left: 10px;
  & .DateInput_input {
    padding: 5px;
    padding-bottom: 4px;
    font-size: 12px;
    background-color: transparent;
    color: #191919;
    text-align: center;
  }
  & .DateInput_input__disabled {
    font-style: unset;
  }
  & .DateRangePickerInput {
    background-color: transparent;
    border: none;
  }
  & .DateInput {
    background-color: transparent;
  }
  & .DateRangePickerInput_arrow {
    margin-bottom: 4px;
    margin-right: 4px;
    width: 20px;
    text-align: center;
  }
  & .DateRangePickerInput_clearDates {
    padding-top: 7px;
    padding-right: 3px;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
  & .DateRangePicker_picker {
    top: 35px !important;
    left: -11px !important;
    z-index: 10000;
    background: transparent;
  }
  & .DateInput_input__focused {
    border-bottom: 2px solid #696dec;
  }

  & .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #696dec;
    display: none;
  }

  & .customDateAction {
    z-index: 10000;
    position: absolute;
    top: 336px;
    width: 100%;
    left: 0px;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px;
    border: 1px solid #ebeaeb;
    border-top: unset;
    border-radius: 0px 0px 4px 4px;
  }
  & .CalendarDay__default {
    border: none;
    color: ${tableCellBlackColor};
    font-size: 12px;
    font-weight: 500;
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
    }
  }
  & .CalendarDay__selected {
    background: #696dec;
    color: white;
    box-shadow: 0px 4px 18px #696dec5e;
    border-radius: 8px;
    &:hover {
      background: #696dec;
    }
    &:focus {
      background: #696dec;
    }
  }
  & .CalendarDay__hovered_span {
    background: #f5f6fd;
  }
  & .CalendarDay__selected_span {
    background: #f5f6fd;

    &:hover {
      background: #696dec;
      color: white;
    }
    &:focus {
      background: #696dec;
      color: white;
    }
  }
  & .CalendarDay__blocked_out_of_range {
    color: #4d4d4d;
    opacity: 0.2;
  }
  & .DayPicker_weekHeader {
    color: ${tableHeaderColor};
    font-size: 10px !important;
  }
  & .DayPicker__withBorder {
    box-shadow: none;
    border-radius: 0px;
  }
  & .DayPicker_transitionContainer__horizontal {
    border: 1px solid #ebeaeb;
    border-radius: 4px;
    border-bottom: ${(props) => !props.onlyDatePicker && '0px'};
    height: 330px !important;
  }
`;

export const ErrorMsg = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin: 8px;
  text-align: center;
  color: ${tableCellRedColor};
`;

export const ErrorMsgAuth = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: ${colorToken.auths.error.text.color};
  background: ${colorToken.auths.error.background.color};
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

export const SuccessMsg = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin: 8px;
  text-align: center;
  color: ${tableCellGreenColor};
`;

export const FilterBubble = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  padding: 1px 6px 1px 5px;
  text-align: center;
  background: ${tableCellGreenColor};
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: ${whiteColor};
`;

export const FilterBubbleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const PaperContainer = styled(Paper)`
  padding: 24px;
  border-radius: 20px;
  height: 100%;
  margin-top: ${(props) => props.margin_top}px;
  padding-bottom: ${(props) => props.padding_bottom}px;
`;

export const MemberCardContainer = styled(Paper)`
  padding: 24px;
  border-radius: 8px;
  height: 100%;
  ${(props) => !props.isSelected && `transform: scale(0.94) !important;`}
  margin-top: ${(props) => props.margin_top}px;
  padding-bottom: ${(props) => props.padding_bottom}px;
  ${(props) => props.isSelected && `border: 2px solid rgb(43, 197, 150);`}
`;

export const CustomLink = styled(Link)`
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
`;

export const AddConnectorItem = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  color: ${(props) => (props.isAllowed ? '#298CFF' : 'grey')};
  display: flex;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 14px;
  cursor: pointer;
  &:hover {
    background-color: #f3f7fa;
  }
`;

export const SecondaryButton = styled(Button)`
  color: black;
  background-color: ${SecondaryButtonBgColor};
  text-transform: capitalize;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: '5px 12px';
  text-transform: capitalize;
  line-height: 14.32px;
  gap: 3px;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
`;

export const ButtonGreyTextRed = styled(Button)`
  color: rgb(255, 85, 85);
  background-color: rgb(243, 243, 243);
  text-transform: capitalize;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: '5px 12px';
  text-transform: capitalize;
  line-height: 14.32px;
  gap: 3px;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
`;

export const ButtonGreyTextGreen = styled(Button)`
  color: #61c29a;
  background-color: rgb(243, 243, 243);
  text-transform: capitalize;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: '5px 12px';
  text-transform: capitalize;
  line-height: 14.32px;
  gap: 3px;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
`;

export const AutomationTestMessageButton = styled(SecondaryButtonWithLoader)`
  background: #f5f6fd;
  border: 1px solid #d9dbf0;
  color: #3e5273;
  text-transform: capitalize;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 12px;
  text-transform: capitalize;
  line-height: 16px;
  gap: 3px;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
`;

export const ConditionButton = styled.div`
paddingLeft: '10px',
paddingRight: '10px',
display: 'flex',
alignItems: 'center',
color: '#737376',
backgroundColor: '#f1f1f1',
cursor: 'pointer',

&:hover {
  backgroundColor: #6868ef;
  color: '#fff',
}
`;

export const StickyHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${colorToken.background.primary.color};
  z-index: 100;
  padding-top: 25px;
`;

export const MessageNoteText = styled.span`
  font-weight: 600;
  margin-bottom: 20px;
  color: ${colorToken.messages.notes.text.color};
  text-transform: none;
  font-size: 10px;
  display: block;
  max-width: 90%;
`;

export const MessageNoteTextHighlight = styled.span`
  font-weight: 600;
  color: ${colorToken.messages.notes.text.colorHighlight};
`;

export const TextButton = styled(Button)`
  background-color: ${colorToken.colors.white};
  color: ${colorToken.button.text.color};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: ${(props) => (props.padding ? props.padding : '10px 12px')};
  text-transform: ${(props) => (props.texttransform ? props.texttransform : 'capitalize')};
  gap: 3px;
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: ${colorToken.modal.errorText.color};
  margin: 10px 0 10px 5px;
`;

export const AlertBox = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${colorToken.colors.black};
  padding: 10px;
  display: flex;
  background: #fcf3d9;
  border: 1px solid #deaa12;
  border-radius: 4px;
  line-height: 1.2;
`;

export const ActionsMenuStyled = styled(Menu)`
  .MuiList-root {
    padding: 10px 0;
  }
  .MuiMenuItem-root {
    padding: 6px 15px;
    margin-top: 18px;
    font-size: 12px;
  }
  .MuiMenuItem-root:first-of-type {
    margin-top: 0;
  }
  .MuiMenuItem-root span {
    margin-left: 7px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;

export const ActionTextStyled = styled(Typography)`
  margin-left: 7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

export const SecondaryButtonTemplates = styled(Button)`
  color: black;
  background-color: ${SecondaryButtonBgColor};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: '5px 12px';
  text-transform: capitalize;
  line-height: 14.32px;
  border: 1px solid #fff;
  gap: 3px;

  &:hover {
    border: 1px solid #696dec;
  }
`;

export const Image = styled.img`
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
`;

export const IntegrationButton = styled(Button)`
  background: ${(props) => (props.integrated === 'true' ? '#ffffff' : '#696DEC')};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: ${(props) =>
    props.integrated === 'true'
      ? `0px 6px 28px
    ${boxShadowColor}`
      : 'none'};
  padding: 10px 20px;
  text-transform: capitalize;
  color: #ffff;
  min-width: ${(props) => props.mWidth || 'auto'};
  &:hover {
    background: ${(props) => (props.integrated === 'true' ? '#ffffff' : '#696DEC')};

    box-shadow: none;
  }
`;
export const MessageText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${tableCellBlackColor};

  p:not(:first-child) {
    display: ${(props) => (props.fromPage == 'dashboard' ? 'none' : '')};
  }

  pre {
    margin: 0;
    word-break: break-all;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'nowrap' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  p {
    margin: 0;
    word-break: ${(props) => (props.wordBreak ? props.wordBreak : 'break-all')};
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'pre' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  ol {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }

  code {
    display: block;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgba(224, 231, 241, 1);
    background: #efefef;
    margin: 0;
    margin-top: 5px;
    word-break: break-all;
    font-size: 13px;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'nowrap' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  > p {
    word-break: break-word;
  }
`;

export const DiscordMessageText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: white;

  p:not(:first-child) {
    display: ${(props) => (props.fromPage == 'dashboard' ? 'none' : '')};
  }

  pre {
    margin: 0;
    word-break: break-all;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'nowrap' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  p {
    margin: 0;
    word-break: break-all;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'pre' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  ol {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }

  code {
    display: block;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgba(224, 231, 241, 1);
    background: #efefef;
    margin: 0;
    margin-top: 5px;
    word-break: break-all;
    font-size: 13px;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'nowrap' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  > p {
    word-break: break-word;
  }
`;

export const BackNavButton = styled(Button)`
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none;
  border-right: 1px solid ${borderGreyColor};
  margin-right: 24px;
  padding: 6px 18px;
  gap: 6px;
  padding-left: 0px;
  color: ${tableHeaderColor};
  &:hover {
    background: transparent;
    color: ${tableHeaderColor};
    box-shadow: none;
  }
`;

export const AvatarButton = styled(Button)`
  text-transform: capitalize;
  font-size: 12px;
  margin-right: 8px;
  padding: 6px 1px;
  gap: 6px;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
  &:hover {
    background-color: ${(props) =>
      props.show_background ? 'rgba(232, 232, 232, 0.3);' : 'transparent'};
    box-shadow: none;
  }
`;

const RichTextEditor = lazy(() => import('@mantine/rte'));
// const RichTextEditor = lazy(() => import('@mantine/rte@4.1.0'));

export const Editor = styled(RichTextEditor)`
  display: flex;
  width: 100%;
  flex-direction: column;
  & .ql-toolbar {
    border-bottom: none !important;
    background-color: #f8f8f8;
    border: none !important;
    padding: 8px !important;
    margin: 5px;
    border-radius: 12px;
  }
  & .mantine-RichTextEditor-toolbarGroup {
    margin: 4px;
  }
  & .ql-editor img {
    width: auto;
    height: 20px;
    display: inline-flex;
    margin-bottom: auto;
  }
  & .ql-blank::before {
    // margin-left: 25px;
    content: attr(data-placeholder); /* Use the data-placeholder attribute as content */
    color: #b3b3b3 !important; /* Gray color for the placeholder text */
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 167.336% !important; /* Adjusted line height */
    letter-spacing: -0.39px !important;
    pointer-events: none !important; /* Prevents the placeholder from being selectable */
    user-select: none !important; /* Prevents text selection */
  }
  /* Ensure the placeholder is removed when the editor is focused */
  & .ql-blank:focus::before {
    content: none;
  }

  & .ql-container {
    padding: 4px !important;
    border-top: none !important;
    border: none !important;
    min-height: ${(props) => (props.minH ? props.minH : 25)}px;
  }
  border: unset;
  border-radius: 8px;
  background-color: #ffffff;
`;

export const NoViewsCreatedBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3e5273;
  padding: 10px;
  background: #f8fafc;
  border-radius: 4px;
`;

export const PageHeaderSubtitle = styled(Typography)`
  color: #7d7d7d;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.1px;
  padding-bottom: 12px;
  margin-top: -22px;
`;
