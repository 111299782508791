import { Dispatch } from 'redux';
import Api from '../../../layers/interceptor/interceptor';
import {
  getChangePasswordApiUrl,
  getCompleteSignupApiUrl,
  getLoginApirUrl,
  getLogoutApirUrl,
  getMagicLinkURL,
  getResetPasswordApiUrl,
  getSignUpApiUrl,
  getSignupUserInfoFromParamTokenApiUrl,
  getUserIdentifyTrackUrl,
  getUserInfoApirUrl,
  getValidEmailApiUrl,
  sendEmailVerificationApiUrl,
} from '../../../constant/apiConstants';
import {
  accountNotVerified,
  maxRetryAttemptReached,
  unauthorized,
} from '../../../constant/appConstants';
import {
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  registerDataType,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REFRESH,
  SET_LOADING,
  SET_AUTH_ACTION_LOADING,
  SET_SERVER_ERROR,
  SET_USER_INFO,
  SIGNUP_FAIL,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  RESET_PASSWORD_LOADING,
  SET_ACCOUNT_VERIFICATION_STATUS,
  RESET_SIGN_SUCCESS,
  SET_LOGIN_WITH_EMAIL,
  GET_SIGNUP_USER_INFO_FROM_PARAM_TOKEN,
  SET_SIGNUP_USER_INFO_FROM_TOKEN_LOADING,
} from '../types';
import { logConsentApiEndpoint } from 'cookieconsent-config-manager';
import {
  identifyUser,
  loadCommunityIdFromLocalStorage,
  trackUserEventWithoutCommunity,
} from 'utils/segment';
import { SET_PERMISSIONS } from 'redux/permissionsProvider/types';

/**
 * Sends the cookie consent payload to the server for logging.
 * 
 * @param {Object} cookiePayload - The cookie consent payload to be logged.
 * @param {string} logConsentApiEndpoint - The API endpoint for logging consent.
 * @returns {Promise} A Promise that resolves when the logging is complete.
 */
export const savingCookieConsent = async (userId) => {
  let cookieConsentStr = localStorage.getItem('cc_cookie');
  let cookieConsentObj = JSON.parse(cookieConsentStr);
  let cookieConsentPayload = {
    ...cookieConsentObj,
    userId: userId,
  };
  Api.post(logConsentApiEndpoint, cookieConsentPayload)
    .then(() => {
      console.info('Consent Saved');
    })
    .catch((err) => {
      console.info('Cannot Save Consent');
    });
};

export const setVerifiedStatus = (verified, reason) => async (dispatch) => {
  dispatch({
    type: SET_ACCOUNT_VERIFICATION_STATUS,
    payload: {
      verified,
      reason,
    },
  });
};

export const setServerErrorAction = (serverError) => async (dispatch) => {
  dispatch({
    type: SET_SERVER_ERROR,
    payload: serverError,
  });
};

export const setUserInfoAction = (userInfo) => async (dispatch) => {
  dispatch({
    type: SET_USER_INFO,
    payload: userInfo,
  });
  dispatch({
    type: SET_PERMISSIONS,
    payload: userInfo?.permissions,
  });
  identifyUser(userInfo);
  if (userInfo?.id) {
    try {
      await savingCookieConsent(userInfo?.id);
    } catch (error) {
      console.error('Error while saving cookie consent:', error);
      // Handle the error or propagate it further as needed
    }
  }
  const communityConfig = loadCommunityIdFromLocalStorage();
  const communityId = communityConfig?.communityId;
  const userId = communityConfig?.userId;
  if (!communityId || !userId) return;

  try {
    await Api.post(getUserIdentifyTrackUrl(), {
      userId,
      communityId,
    });
  } catch (error) {
    console.error('Error while identifying user:', error);
    // Handle the error or propagate it further as needed
  }
};

export const setLoadingAction = (isLoading) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: isLoading,
  });
};

export const setResetLoadingAction = (isLoading) => async (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_LOADING,
    payload: isLoading,
  });
};

export const logoutAction = () => async () => {
  return Api.post(getLogoutApirUrl());
};

export const loadUserInfoAction = async () => {
  return Api.get(getUserInfoApirUrl());
};

export const loginAction = (email, password) => async (dispatch) => {
  dispatch({
    type: LOGIN_START,
  });
  dispatch({
    type: SET_AUTH_ACTION_LOADING,
    payload: true,
  });
  await Api.post(getLoginApirUrl(), {
    email,
    password,
  })
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res,
      });
      document.cookie = 'lastLoginEmail=' + email;
      trackUserEventWithoutCommunity('User Logged In', {
        email,
      });
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          loginFailedCreds: { email, password },
          errorText:
            err?.response?.status === 401
              ? err.response.data?.reason === accountNotVerified
                ? 'You have not verified your email yet. Please check for the verification email from Threado in your mailbox.'
                : err.response.data?.reason === unauthorized ||
                  err.response.data?.reason === maxRetryAttemptReached
                ? err.response.data?.errorMsg
                : 'Incorrect email or password'
              : 'Server error',
        },
      });
    })
    .finally(() =>
      dispatch({
        type: SET_AUTH_ACTION_LOADING,
        payload: false,
      }),
    );
};

export const signupAction = (values, router) => async (dispatch) => {
  dispatch({
    type: SIGNUP_START,
  });
  dispatch({
    type: SET_AUTH_ACTION_LOADING,
    payload: true,
  });
  try {
    const response = await Api.post(getSignUpApiUrl(), values);
    // router.push('/accounts/login');
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_AUTH_ACTION_LOADING,
      payload: false,
    });
    trackUserEventWithoutCommunity('User Signed Up', {
      email: values.email,
    });
  } catch (err) {
    dispatch({
      type: SIGNUP_FAIL,
      payload: err.response?.data,
    });
    dispatch({
      type: SET_AUTH_ACTION_LOADING,
      payload: false,
    });
  }
};

export const signupCompleteAction = (values) => async (dispatch) => {
  dispatch({
    type: SIGNUP_START,
  });
  dispatch({
    type: SET_AUTH_ACTION_LOADING,
    payload: true,
  });
  await Api.post(getCompleteSignupApiUrl(), values)
    .then(() => {
      dispatch({
        type: SIGNUP_SUCCESS,
        // payload: response.data,
      });
      // dispatch({
      //   type: SET_AUTH_ACTION_LOADING,
      //   payload: false,
      // });

      // dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: res,
      // });
      document.cookie = 'lastLoginEmail=' + values.email;
      trackUserEventWithoutCommunity('User Signed Up In', {
        email: values.email,
      });
    })
    .catch((err) => {
      dispatch({
        type: SIGNUP_FAIL,
        payload: err.response?.data,
      });
    })
    .finally(() => {
      dispatch({
        type: SET_AUTH_ACTION_LOADING,
        payload: false,
      });
    });
};
export const resetErroMessage = () => async (dispatch) => {
  dispatch({
    type: SIGNUP_START,
  });
};

export const resetPwdAction = (values) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_LOADING,
    payload: true,
  });
  Api.post(getResetPasswordApiUrl(), values)
    .then(() => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: RESET_PASSWORD_FAIL,
      });
    })
    .finally(() =>
      dispatch({
        type: RESET_PASSWORD_LOADING,
        payload: false,
      }),
    );
};

export const resetPasswordWithToken = (password, token) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_LOADING,
    payload: true,
  });
  Api.post(getChangePasswordApiUrl(), { password, token })
    .then(() => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: RESET_PASSWORD_FAIL,
      });
    })
    .finally(() =>
      dispatch({
        type: RESET_PASSWORD_LOADING,
        payload: false,
      }),
    );
};

export const resetPasswordRefreshAction = () => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_REFRESH,
  });
};

export const resetSignUpSuccess = () => (dispatch) => {
  dispatch({
    type: RESET_SIGN_SUCCESS,
    payload: true,
  });
};

export const checkEmailAvailablity =
  (
    email,
    setEmailSubmitSuccess,
    setIsNewSignUp,
    setIsEmailValidationLoading,
    setEmailCheckForDomainError,
    setShowEmailVerificationScreen,
  ) =>
  async (dispatch) => {
    let response;
    return await Api.get(getValidEmailApiUrl(encodeURIComponent(email)))
      .then((res) => {
        response = res;
        if (response?.available) {
          dispatch(
            sendEmailVerificationAction(
              email,
              response,
              setEmailSubmitSuccess,
              setIsNewSignUp,
              setShowEmailVerificationScreen,
              setEmailCheckForDomainError,
              setIsEmailValidationLoading,
            ),
          );
        } else {
          setEmailSubmitSuccess(true);
          setIsNewSignUp(response?.available);
          setEmailCheckForDomainError('');
          dispatch(setLoginWithEmailAction(true));
        }
      })
      .catch((err) => {
        setEmailSubmitSuccess(false);
        setEmailCheckForDomainError(err.response?.data?.message);
      })
      .finally(() => {
        if (!response?.available) {
          setIsEmailValidationLoading(false);
        }
      });
  };

export const sendEmailVerificationAction =
  (
    email,
    response,
    setEmailSubmitSuccess,
    setIsNewSignUp,
    setShowEmailVerificationScreen,
    setEmailCheckForDomainError,
    setIsEmailValidationLoading,
  ) =>
  (dispatch) => {
    Api.post(sendEmailVerificationApiUrl(), { email })
      .then(() => {
        setEmailSubmitSuccess(true);
        setIsNewSignUp(response?.available);
        setShowEmailVerificationScreen(true);
        dispatch(setLoginWithEmailAction(true));
      })
      .catch((err) => {
        setEmailSubmitSuccess(false);
        setEmailCheckForDomainError(err.response?.data?.message);
      })
      .finally(() => {
        setIsEmailValidationLoading(false);
      });
  };

export const getSignupUserInfoFromParamToken = (token, addToast) => (dispatch) => {
  dispatch({
    type: SET_SIGNUP_USER_INFO_FROM_TOKEN_LOADING,
    payload: true,
  });
  Api.get(getSignupUserInfoFromParamTokenApiUrl(token))
    .then((res) => {
      dispatch({
        type: GET_SIGNUP_USER_INFO_FROM_PARAM_TOKEN,
        payload: res,
      });
    })
    .catch((err) => {
      addToast('Something went wrong. Please try again after sometime', {
        appearance: 'error',
      });
    })
    .finally(() => {
      dispatch({
        type: SET_SIGNUP_USER_INFO_FROM_TOKEN_LOADING,
        payload: false,
      });
    });
};
export const setLoginWithEmailAction = (value) => (dispatch) => {
  dispatch({
    type: SET_LOGIN_WITH_EMAIL,
    payload: value,
  });
};

export const sendSignInMagicURL = (email, setMagicLinkSent, addToast) => (dispatch) => {
  Api.post(getMagicLinkURL(), { email })
    .then(() => {
      setMagicLinkSent(true);
    })
    .catch(() => {
      addToast('An error occurred while sending magic Link', {
        appearance: 'error',
      });
      setMagicLinkSent(false);
    });
};
