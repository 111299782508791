import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetMessagesChannelFilter,
  useGetMessagesProviderChannelFilter,
  useGetMessagesStatusFilter,
} from 'context/hooks';

export default function useGetMessagesFilterBubbleNumber() {
  const channelsFilter = useSelector(useGetMessagesChannelFilter);
  const statusFilter = useSelector(useGetMessagesStatusFilter);
  const providerChannelsFilter = useSelector(useGetMessagesProviderChannelFilter);

  const [currentFilters, setCurrentFilters] = useState([]);

  useEffect(() => {
    if (
      statusFilter.includes('all') &&
      channelsFilter.includes('all') &&
      providerChannelsFilter.includes(-1)
    ) {
      setCurrentFilters([]);
      return;
    }
    if (providerChannelsFilter.includes(-1)) {
      setCurrentFilters((prev) => prev.filter((prev) => prev !== 'providerChannelsFilter'));
    }

    if (
      !providerChannelsFilter.includes(-1) &&
      !currentFilters.includes('providerChannelsFilter')
    ) {
      setCurrentFilters((prev) => [...prev, 'providerChannelsFilter']);
    }
    if (channelsFilter.includes('all')) {
      setCurrentFilters((prev) => prev.filter((prev) => prev !== 'channelsFilter'));
    }

    if (statusFilter.includes('all')) {
      setCurrentFilters((prev) => prev.filter((prev) => prev !== 'statusFilter'));
    }

    if (!statusFilter.includes('all') && !currentFilters.includes('statusFilter')) {
      setCurrentFilters((prev) => [...prev, 'statusFilter']);
    }

    if (!channelsFilter.includes('all') && !currentFilters.includes('channelsFilter')) {
      setCurrentFilters((prev) => [...prev, 'channelsFilter']);
    }
  }, [statusFilter, channelsFilter, providerChannelsFilter]); // eslint-disable-line react-hooks/exhaustive-deps
  return currentFilters.length;
}
