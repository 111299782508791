import defAllIcon from 'assets/images/icons/support/sidebar/defAllIcon.svg';
import defAllIconActive from 'assets/images/icons/support/sidebar/defAllIconActive.svg';

import defQueriesIcon from 'assets/images/icons/support/sidebar/defQueriesIcon.svg';
import defQueriesIconActive from 'assets/images/icons/support/sidebar/defQueriesIcon.svg';

import defFeedbackIcon from 'assets/images/icons/support/sidebar/defFeedbackIcon.svg';
import defFeedbackIconActive from 'assets/images/icons/support/sidebar/defFeedbackIconActive.svg';

import defIssuesIcon from 'assets/images/icons/support/sidebar/defIssuesIcon.svg';
import defIssuesIconActive from 'assets/images/icons/support/sidebar/defIssuesIconActive.svg';

import defNegIcon from 'assets/images/icons/support/sidebar/defNegIcon.svg';
import defNegIconActive from 'assets/images/icons/support/sidebar/defNegIconActive.svg';

import defSpamIcon from 'assets/images/icons/support/sidebar/defSpamIcon.svg';
import defSpamIconActive from 'assets/images/icons/support/sidebar/defSpamIconActive.svg';

export const supportDefaultFiltersIconMapper = {
  All: {
    iconSrc: defAllIcon,
    iconSrcActive: defAllIconActive,
  },
  'Queries with no replies': {
    iconSrc: defQueriesIcon,
    iconSrcActive: defQueriesIconActive,
  },
  Spam: {
    iconSrc: defSpamIcon,
    iconSrcActive: defSpamIconActive,
  },
  Feedback: {
    iconSrc: defFeedbackIcon,
    iconSrcActive: defFeedbackIconActive,
  },
  Issue: {
    iconSrc: defIssuesIcon,
    iconSrcActive: defIssuesIconActive,
  },
  'Negative Sentiment': {
    iconSrc: defNegIcon,
    iconSrcActive: defNegIconActive,
  },
  'Promotion': {
    iconSrc: defSpamIcon,
    iconSrcActive: defSpamIconActive
  },
  3: {
    iconSrc: defNegIcon,
    iconSrcActive: defNegIconActive,
  },
};
