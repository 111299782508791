export const GET_DEFAULT_TONES_LIST = 'GET_DEFAULT_TONES_LIST';
export const GET_DEFAULT_RESPONSE_CHECKS_LIST = 'GET_DEFAULT_RESPONSE_CHECKS_LIST';
export const GET_CUSTOMIZE_RESPONSE_CHROME = 'GET_CUSTOMIZE_RESPONSE_CHROME';
export const GET_CUSTOMIZE_RESPONSE_SLACK = 'GET_CUSTOMIZE_RESPONSE_SLACK';
export const GET_AGENTS_LIST_FOR_INVITE = 'GET_AGENTS_LIST_FOR_INVITE';
export const GET_INVITED_AGENTS_LIST = 'GET_INVITED_AGENTS_LIST';
export const SET_CHROME_INSTALL_STATUS_NUDGE = 'SET_CHROME_INSTALL_STATUS_NUDGE';
export const GET_AGENT_ASSIST_ANALYTICS_OVERVIEW = 'GET_AGENT_ASSIST_ANALYTICS_OVERVIEW';
export const GET_AGENT_ASSIST_ANALYTICS_ENGAGEMENT_TREND =
  'GET_AGENT_ASSIST_ANALYTICS_ENGAGEMENT_TREND';
export const GET_AGENT_ASSIST_ANALYTICS_AGENT_LIST_DATA =
  'GET_AGENT_ASSIST_ANALYTICS_AGENT_LIST_DATA';
export const GET_AGENT_ASSIST_ANALYTICS_BOT_RESPONSE_RATE =
  'GET_AGENT_ASSIST_ANALYTICS_BOT_RESPONSE_RATE';
export const GET_AGENT_ASSIST_ANALYTICS_QUERIES_TREND = 'GET_AGENT_ASSIST_ANALYTICS_QUERIES_TREND';

export const GET_LANGUAGES_LIST = 'GET_LANGUAGES_LIST';
export const GET_LANGUAGE_SETTINGS = 'GET_LANGUAGE_SETTINGS';
export const GET_BOT_RESPONSE_LENGTH_SETTINGS = 'GET_BOT_RESPONSE_LENGTH_SETTINGS';