import {
  SET_TICKET_ID,
  GET_TICKET_ID_LOADING,
  GET_TICKET_ID_FAILED,
  IS_NEW_QUESTION_AVAILABLE,
  IS_NEW_QUESTION_AVAILABLE_LOADING,
  IS_NEW_QUESTION_AVAILABLE_FAILED,
  IS_CURRENT_URL_VALID_PLATFORM,
  IS_CURRENT_URL_VALID_PLATFORM_LOADING,
  IS_CURRENT_URL_VALID_PLATFORM_FAILED,
  IS_OPTION_LOADING,
  IS_OPTION,
  IS_OPTION_FAILED,
  SET_REFINE_ACTION_ID,
  SET_REFINE_ACTION_ID_LOADING,
  SET_REFINE_ACTION_ID_FAILED,
  SET_TONE_PREFERENCES,
  SET_TONE_PREFERENCES_LOADING,
  SET_TONE_PREFERENCES_FAILED,
  GET_PREVIOUS_ASK_CHAT_HISTORY_LOADING,
  GET_PREVIOUS_ASK_CHAT_HISTORY,
  GET_PREVIOUS_ASK_CHAT_HISTORY_FAILED,
  RESET_REFINE_ACTION_ID,
  GET_MESSAGE_FEEDBACK,
  SET_PREVIOUS_ASK_CHAT_HISTORY_LOCALLY,
} from '../types';

const initialState = {
  platformName: '',
  ticketId: '',
  externalTicketId: '',
  isTicketFound: null,
  isPlaftormValidationLoading: false,
  isNewQuestionAvailableFailed: false,
  isNewQuestionAvailableLoading: false,
  isNewQuestionAvailable: false,
  isCurrentUrlValidPlatformLoading: false,
  isCurrentUrlValidPlatfrom: false,
  isCurrentUrlValidPlatformFailed: false,
  currentQuestionResponseObject: {},
  refineAndToneOptions: {},
  refineAndToneOptionsLoading: false,
  isActionIdForRefineLoading: false,
  isActionIdForRefineFailed: false,
  isActionObjectForRefineObject: {},
  userTonePreferences: {},
  userTonePreferencesLoading: false,
  userTonePreferencesFailed: false,
  previousChatMessages: [],
  previousMessagesLoading: false,
  previousMessageError: false,
  feedBackMessage: null,
};

export const ticketSupportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_CURRENT_URL_VALID_PLATFORM:
      return {
        ...state,
        isCurrentUrlValidPlatformLoading: false,
        isCurrentUrlValidPlatfrom: true, // payload?.isValidPlatform
        isCurrentUrlValidPlatformFailed: false,
      };
    case IS_CURRENT_URL_VALID_PLATFORM_LOADING:
      return {
        ...state,
        isCurrentUrlValidPlatformLoading: true,
        isCurrentUrlValidPlatfrom: false,
        isCurrentUrlValidPlatformFailed: false,
      };
    case IS_CURRENT_URL_VALID_PLATFORM_FAILED:
      return {
        ...state,
        isCurrentUrlValidPlatformLoading: false,
        isCurrentUrlValidPlatfrom: true,
        isCurrentUrlValidPlatformFailed: true,
      };
    case SET_TICKET_ID:
      return {
        ...state,
        platformName: payload?.platform,
        ticketId: payload?.ticketId,
        externalTicketId: payload?.externalTicketId,
        isTicketFound: true,
        isPlaftormValidationLoading: false,
      };
    case GET_TICKET_ID_LOADING:
      return {
        ...state,
        isPlaftormValidationLoading: true,
        platformName: '',
        ticketId: '',
        externalTicketId: '',
        isTicketFound: null,
      };
    case GET_TICKET_ID_FAILED:
      return {
        ...state,
        isPlaftormValidationLoading: false,
        platformName: '',
        ticketId: '',
        externalTicketId: '',
        isTicketFound: null,
      };
    case IS_NEW_QUESTION_AVAILABLE:
      return {
        ...state,
        isNewQuestionAvailableFailed: false,
        isNewQuestionAvailableLoading: false,
        isNewQuestionAvailable: payload?.available,
        currentQuestionResponseObject: payload,
      };
    case IS_NEW_QUESTION_AVAILABLE_LOADING:
      return {
        ...state,
        isNewQuestionAvailableFailed: false,
        isNewQuestionAvailableLoading: true,
        isNewQuestionAvailable: false,
        currentQuestionResponseObject: {},
      };
    case IS_NEW_QUESTION_AVAILABLE_FAILED:
      return {
        ...state,
        currentQuestionResponseObject: {},
        isNewQuestionAvailableFailed: true,
        isNewQuestionAvailableLoading: false,
        isNewQuestionAvailable: false,
      };
    case IS_OPTION_LOADING:
      return {
        ...state,
        refineAndToneOptions: {},
        refineAndToneOptionsLoading: true,
      };
    case IS_OPTION:
      return {
        ...state,
        refineAndToneOptions: payload,
        refineAndToneOptionsLoading: false,
      };
    case IS_OPTION_FAILED:
      return {
        ...state,
        refineAndToneOptions: {},
        refineAndToneOptionsLoading: false,
      };
    case SET_REFINE_ACTION_ID:
      const currentDateTime = new Date();
      const changedPayload = {
        ...payload,
        timestamp: currentDateTime,
      };
      return {
        ...state,
        isActionIdForRefineLoading: false,
        isActionIdForRefineFailed: false,
        isActionObjectForRefineObject: changedPayload,
      };
    case SET_REFINE_ACTION_ID_LOADING:
      return {
        ...state,
        isActionIdForRefineLoading: true,
        isActionIdForRefineFailed: false,
        isActionObjectForRefineObject: {},
      };
    case SET_REFINE_ACTION_ID_FAILED:
      return {
        ...state,
        isActionIdForRefineLoading: false,
        isActionIdForRefineFailed: true,
        isActionObjectForRefineObject: {},
      };
    case RESET_REFINE_ACTION_ID:
      return {
        ...state,
        isActionObjectForRefineObject: {},
      };
    case SET_TONE_PREFERENCES:
      return {
        ...state,
        userTonePreferences: payload,
        userTonePreferencesLoading: false,
        userTonePreferencesFailed: false,
      };
    case SET_TONE_PREFERENCES_LOADING:
      return {
        ...state,
        userTonePreferences: {},
        userTonePreferencesLoading: true,
        userTonePreferencesFailed: false,
      };
    case SET_TONE_PREFERENCES_FAILED:
      return {
        ...state,
        userTonePreferences: {},
        userTonePreferencesLoading: false,
        userTonePreferencesFailed: true,
      };
    case GET_PREVIOUS_ASK_CHAT_HISTORY:
      return {
        ...state,
        previousChatMessages: payload?.content,
        previousMessagesLoading: false,
        previousMessageError: false,
      };
    case SET_PREVIOUS_ASK_CHAT_HISTORY_LOCALLY:
      return {
        ...state,
        previousChatMessages: payload,
      };
    case GET_PREVIOUS_ASK_CHAT_HISTORY_LOADING:
      return {
        ...state,
        previousChatMessages: [],
        previousMessagesLoading: true,
        previousMessageError: false,
      };
    case GET_PREVIOUS_ASK_CHAT_HISTORY_FAILED:
      return {
        ...state,
        previousChatMessages: [],
        previousMessagesLoading: false,
        previousMessageError: true,
      };
    case GET_MESSAGE_FEEDBACK:
      return {
        ...state,
        feedBackMessage: payload,
      };
    default:
      return state;
  }
};
