import { isEmpty } from 'lodash';
import { object, string } from 'yup';
import { tableTagBackground, tableTagColor } from '../../theme/colorConstants';

export const messageInitialValue = {
  messageReply: '',
};

export const messageSchema = object().shape({
  messageReply: string().required('Please enter message.'),
});

export const messageInitialValueWithTitleDiscourse = {
  messageTitle: '',
  messageReply: '',
};

export const messageSchemaWithTitleDiscourse = object().shape({
  messageTitle: string().trim().required('Please enter message title.'),
  messageReply: string()
    .required('Please enter message.')
    .min(20, 'Message must be atleast 20 characters long'),
});

export const getTagColor = () => {
  return { color: tableTagColor, backgroundColor: tableTagBackground };
};

export const bulkDMInitialValue = {
  integration: 'SLACK',
  messageType: 'DM',
  message: '',
  subject: '',
  name: '',
  messageTitle: '',
  // channels: [],
};

export const bulkDMSchema = object().shape({
  message: string().required(''),
  // channels: array().min(1).required('Please choose atleast one channel'),
});

// BulkDM with Discorse
export const bulkDMInitialValueWithDiscourse = {
  integration: 'SLACK',
  messageType: 'DM',
  message: '',
  subject: '',
  name: '',
  messageTitle: '',
};

export const bulkDMSchemaWithDiscourse = object().shape({
  message: string(),
  messageTitle: string(),
});

export const messageSelectedSchemaWithTitleDiscourse = object().shape({
  messageReply: string(),
  messageTitle: string(),
});

const transformFieldMapper = {
  replies: 'tags',
  reactions: 'tags',
  messageType: 'tags',
  name: 'memberName',
  email: 'memberEmail',
  tags: 'memberTags',
  joinedAt: 'memberJoiningDate',
  postedAt: 'timestamp',
  level: 'memberLevel'
};

const operatorMapper = {
  IN: ' AND ',
  NOT_IN: ' AND NOT ',
  CONTAINS: ' AND ',
  DOESNOT_CONTAINS: ' AND NOT ',
  HAS_ANY_VALUE: ' AND ',
  HAS_NO_VALUE: ' AND NOT ',
  BETWEEN: ' AND ',
  GREATER_THAN: ' AND ',
  LESS_THAN: ' AND ',
  EQUAL_TO: ' AND '
}

const operatorMapperFirst = {
  IN: '',
  NOT_IN: ' NOT ',
  CONTAINS: '',
  DOESNOT_CONTAINS: ' NOT ',
  HAS_ANY_VALUE: '',
  HAS_NO_VALUE: ' NOT ',
  BETWEEN: '',
  GREATER_THAN: '',
  LESS_THAN: '',
  EQUAL_TO: ''
};


const relativeDatesValuesMapper = {
  LAST_0_DAYS: 'now TO now-0d',
  LAST_7_DAYS: 'now TO now-7d',
  LAST_15_DAYS: 'now TO now-15d',
  LAST_30_DAYS: 'now TO now-30d',
  LAST_60_DAYS: 'now TO now-60d',
  LAST_90_DAYS: 'now TO now-90d',
};


const handleFieldValues = (filter) => {
  if (filter.field === 'name' || filter.field === 'email') {
    return filter.value + '*';
  } else if (filter.field === 'messageTopics') {
    if (filter.operator === 'CONTAINS' || filter.operator === 'DOESNOT_CONTAINS') {
      return filter.value
        ?.split(',')
        ?.map((word) => `${word}*`)
        .join(' ,');
    } else {
      return filter.value;
    }
  } else if (filter.field === 'content') {
    return filter.value
      ?.split(',')
      ?.map((word) => `${word}*`)
      .join(' ,');
  } else if (filter.field === 'joinedAt' || filter.field === 'postedAt') {
    return filter.type === 'RELATIVE'
      ? relativeDatesValuesMapper[filter.value]
      : `${filter.from?.split('T')?.[0]} TO ${filter.to?.split('T')?.[0]}`;
  } else if (filter.field === 'messageType') {
    return 'QUESTION';
  } else if (filter.field === 'repliesCount' || filter.field === 'reactionsCount') {
    if (filter.operator === 'GREATER_THAN') {
      return `>=${filter.value}`;
    } else if (filter.operator === 'LESS_THAN') {
      return `<=${filter.value}`;
    } else {
      return `=${filter.value}`;
    }
  } else {
    return filter.value;
  }
};

const handleOperators = (filter, index) => {
  if (!filter.value) {
    return '';
  }
  if (filter.field === 'messageType') {
    if (index !== 0) {
      if (filter.value === 'QUESTION') {
        return operatorMapper.IN;
      } else {
        return operatorMapper.NOT_IN;
      }
    } else if (index === 0) {
      if (filter.value === 'QUESTION') {
        return operatorMapperFirst.IN;
      } else {
        return operatorMapperFirst.NOT_IN;
      }
    }
  } else {
    if (index !== 0) {
      return operatorMapper[filter.operator];
    } else if (index === 0) {
      return operatorMapperFirst[filter.operator];
    } else {
      return '';
    }
  }
};
export const transformFiltersToDesiredString = (supportFeedFilters) => {
  let filteredString = '';
  const filteredObject = {};
  const supportFeedFiltersJson = JSON.parse(JSON.stringify(supportFeedFilters));

  if (supportFeedFiltersJson?.predicate?.length > 0) {
    supportFeedFiltersJson?.predicate?.forEach((filter, index) => {
      filteredObject[filter.field] = filter.value;
      filteredString = `${filteredString}${handleOperators(filter, index)}${
        isEmpty(filter.value)
          ? ''
          : transformFieldMapper.hasOwnProperty(filter.field)
          ? transformFieldMapper[filter.field]
          : filter.field
      }${isEmpty(filter.value) ? '' : ':'}${handleFieldValues(filter)}`;
    });
    const encodedString = encodeURIComponent(filteredString);
    return encodedString;
  } else {
    return '';
  }
};

export const getFilterFieldNameAndValueList = (filter) => {
  if (filter?.predicate?.length) {
    return filter.predicate.map((iter) => {
      return {
        field: iter.field,
        value: iter.value,
      };
    });
  } else {
    return [];
  }
};