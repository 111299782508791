import { colorToken } from './designToken';

export const blackColor = 'rgba(75, 85, 99, 1)';
export const buttonBgColor = 'rgba(209, 213, 219, 1)';
export const whiteColor = '#ffff';
export const GraphTitleTextColor = `#97A6BA`;
export const GraphTotalCountTextColor = `#27303F`;
export const TagProfitColor = `#2BC59633`;
export const TagLossColor = `#F0464633`;
export const TagProfitTextColor = `#2BC596`;
export const TagLossTextColor = `#F04646`;
export const tableCellBlackColor = 'rgba(39, 48, 63, 1)';
export const tableCellYellowColor = 'rgba(255, 217, 84, 0.3)';
export const tableCellGreenColor = 'rgba(43, 197, 150, 1)';
export const tableCellHoverGreenColor = 'rgba(43, 197, 150, 0.8)';
export const tableCellLightGreenColor = 'rgba(43, 197, 150, 0.2)';
export const tableCellLightPurpleColor = 'rgba(114, 80, 210, 0.2)';
export const tableCellPurpleColor = 'rgba(114, 80, 210, 1)';
export const tableCellLightRedColor = 'rgba(240, 70, 70, 0.2)';
export const tableCellRedColor = 'rgba(240, 70, 70, 1)';
export const tableCellLightGreyColor = 'rgba(224, 231, 241, 1)';
export const tableCellGreyColor = 'rgba(100, 116, 139, 1)';
export const tableTagColor = '#8C8AC4';
export const tableTagBackground = 'rgba(175, 173, 246, 0.2)';
export const tableHeaderColor = 'rgba(151, 166, 186, 1)';
export const tableBodyColor = 'rgba(224, 231, 241, 1)';
export const tableHeaderBackgroundColor = 'rgba(243, 247, 250, 1)';
export const yellowColor = colorToken.banner.color;
export const greenColor = 'rgba(43, 197, 150, 1)';
export const sidebarMenuBoxShadowColor = 'rgba(255, 217, 84, 0.56)';
export const borderGreyColor = 'rgba(224, 231, 241, 1)';
export const boxShadowColor = 'rgba(229, 237, 242, 1)';
export const primaryBackgroundColor = colorToken.background.primary.color;
export const blueColor = 'rgba(41, 140, 255, 1)';
export const skyeBlueColor = 'rgba(29, 161, 242, 0.2)';
export const lightYellowColor = 'rgba(236, 178, 46, 0.2)';
export const slackGraphLineColor = '#ECB22E';
export const discordGraphLineColor = '#5865F2';
export const eventBlueColor = 'rgba(33, 150, 243, 1)';
export const emailColor = 'rgba(243, 247, 250, 1)';
export const facebookIconBgColor = 'rgba(60, 90, 154, 0.1)';
export const githubIconBgColor = 'rgba(22, 22, 20, 0.1)';
export const linkedinIconBgColor = 'rgba(0, 102, 153, 0.1)';
export const twitterIconBgColor = 'rgba(22, 22, 20, 0.1)';
export const lightBlackColor = 'rgba(0, 0, 0, 1)';
export const postContentTitleColor = `#64748B`;
export const titleColor = `#27303F`;
export const activeColor = `#2BC596`;
export const deleteButtonText = `#F04646`;
export const deleteButtonColor = 'rgba(240, 70, 70, 0.2)';
export const eventEditText = `#2BC596`;
export const eventEditButton = 'rgba(43, 197, 150, 0.2)';
export const primaryBlackColor = 'rgba(39, 48, 63, 1)';
export const yellowHighlightColor = '#ffffde';
export const tableHeaderBorder = 'rgba(224, 224, 224, 1)';
export const SecondaryButtonBgColor = 'rgba(248, 248, 248, 1)';
export const defaultArrowDropdownColor = '#B3B3B3';
export const greyColorText = '#B3B3B3';
export const greenTextActivity = '#2BC596';
export const redTextActivity = '#F04646';
export const lightGreenBackgroundAcitivity = 'rgba(43, 197, 150, 0.2)';
export const lightRedBackgroundAcitivity = 'rgba(240, 70, 70, 0.2);';
export const bgColor = 'hsl(256,33,10)';
export const dotColor = 'hsl(256,33,70)';

// Dimensions
export const dotSize = '1px';
export const dotSpace = '22px';
