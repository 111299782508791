export const CREATE_DOMAIN = 'CREATE_DOMAIN';
export const CREATE_DOMAIN_LOADING = 'CREATE_DOMAIN_LOADING';
export const CREATE_DOMAIN_ERROR = 'CREATE_DOMAIN_ERROR';
export const DOMAIN_LIST_LOADING = 'DOMAIN_LIST_LOADING';
export const DOMAIN_LIST_LOADING_FAILED = 'DOMAIN_LIST_LOADING_FAILED';
export const DOMAIN_LIST_LOADED = 'DOMAIN_LIST_LOADED';
export const DOMAIN_DETAILS = 'DOMAIN_DETAILS';
export const DOMAIN_DETAILS_LOADING = 'DOMAIN_DETAILS_LOADING';
export const DOMAIN_DETAILS_ERROR = 'DOMAIN_DETAILS_ERROR';
export const DOMAIN_EMAIL_DETAILS = 'DOMAIN_EMAIL_DETAILS';
export const DOMAIN_EMAIL_DETAILS_LOADING = 'DOMAIN_EMAIL_DETAILS_LOADING';
export const DOMAIN_EMAIL_DETAILS_ERROR = 'DOMAIN_EMAIL_DETAILS_ERROR';
export const CREATE_EMAIL = 'CREATE_EMAIL';
export const CREATE_EMAIL_LOADING = 'CREATE_EMAIL_LOADING';
export const CREATE_EMAIL_ERROR = 'CREATE_DOMAIN_ERROR';
export const ORG_EMAIL_LIST = 'ORG_EMAIL_LIST';
export const ORG_EMAIL_LIST_LOADING = 'ORG_EMAIL_LIST_LOADING';
export const ORG_EMAIL_LIST_ERROR = 'ORG_EMAIL_LIST_ERROR';
// Verify Domain
export const VERIFY_DOMAIN = 'VERIFY_DOMAIN';
export const VERIFY_DOMAIN_FAIL = 'VERIFY_DOMAIN_FAIL';
export const VERIFY_DOMAIN_SUCCESS = 'VERIFY_DOMAIN_SUCCESS';
export const VERIFY_DOMAIN_LOADING = 'VERIFY_DOMAIN_LOADING';