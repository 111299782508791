export const GET_MEMBERS_DATA = 'GET_MEMBERS_DATA';
export const GET_MEMBERS_DATA_WITH_SEGMENT = 'GET_MEMBERS_DATA_WITH_SEGMENT';
export const MEMBERS_ERROR_HANDLE = 'MEMBERS_ERROR_HANDLE';
export const MEMBERS_TOAST_HANDLE = 'MEMBERS_TOAST_HANDLE';
export const SET_MEMBERS_LOADING = 'SET_MEMBERS_LOADING';
export const SET_MEMBERS_CHART_LOADING = 'SET_MEMBERS_CHART_LOADING';
export const SET_MEMBERS_CHART_DATA = 'SET_MEMBERS_CHART_DATA';
export const SET_MEMBERS_ROLES = 'SET_MEMBERS_ROLES';
export const SET_SEGMENTS_FILTER = 'SET_SEGMENTS_FILTER';
export const SET_CHANNELS_FILTER = 'SET_CHANNELS_FILTER';
export const SET_GROUPS_FILTER = 'SET_GROUPS_FILTER';
export const SET_MEMBER_SEARCH_FILTER = 'SET_MEMBER_SEARCH_FILTER';
export const SEND_MEMBERS_TOKENS = 'SEND_MEMBERS_TOKENS';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const CLEAR_MEMBER_ERROR = 'CLEAR_MEMBER_ERROR';
export const CLEAR_DELETE_MEMBER_ERROR = 'CLEAR_DELETE_MEMBER_ERROR';
export const MEMBERS_DELETE_ERROR_HANDLE = 'MEMBERS_DELETE_ERROR_HANDLE';
export const GET_SEGMENT_SETTINGS_SCORE = 'GET_SEGMENT_SETTINGS_SCORE';
export const GET_SEGMENT_MEMBER_CHART = 'GET_SEGMENT_MEMBER_CHART';
export const SET_SEGMENT_SETTINGS_STATUS = 'SET_SEGMENT_SETTINGS_STATUS';
export const SET_MEMBER_SEGMENT_TAB_COUNT = 'SET_MEMBER_SEGMENT_TAB_COUNT';
export const SET_TOTAL_MEMBER_COUNT = 'SET_TOTAL_MEMBER_COUNT';
export const SET_CHANNEL_DETAILS = 'SET_CHANNEL_DETAILS';
export const SET_DEFAULT_FILTER = 'SET_DEFAULT_FILTER';
export const SET_SURVEY_LIST = 'SET_SURVEY_LIST';
export const SET_BULK_MEMBER_USERID = 'SET_BULK_MEMBER_USERID';
export const RESET_BULK_MEMBER_USERID = 'RESET_BULK_MEMBER_USERID';
export const LOADING_BULK_MEMBER_USERID = 'LOADING_BULK_MEMBER_USERID';
export const ERROR_BULK_MEMBER_USERID = 'ERROR_BULK_MEMBER_USERID';
export const MEMBER_GRID_ERROR_HANDLER = 'MEMBER_GRID_ERROR_HANDLER';
export const SET_DATA_GRID_FILTER = 'SET_DATA_GRID_FILTER';
export const SET_DRAFT_FILTER = 'SET_DRAFT_FILTER';
export const MEMBER_IMPORT_PROCESS = 'MEMBER_IMPORT_PROCESS';
export const SET_MEMBER_IMPORT_PROCESSING = 'SET_MEMBER_IMPORT_PROCESSING';
export const SET_MEMBER_CUSTOM_FIELDS = 'SET_MEMBER_CUSTOM_FIELDS';
export const SET_MEMBER_CONFIGURE_COLUMN = 'SET_MEMBER_CONFIGURE_COLUMN';
export const SET_MEMBER_LIST_FROM_ID = 'SET_MEMBER_LIST_FROM_ID';
export const SET_V5_LOADING = 'SET_V5_LOADING';
export const SET_UNSUBSCRIBE_MEMBER_LIST = 'SET_UNSUBSCRIBE_MEMBER_LIST';

export const SegmentType = {
  ALL: 'ALL',
  ONBOARDING: 'ONBOARDING',
  CHAMPION: 'CHAMPION',
  CONTRIBUTOR: 'PASSIVE',
  ENGAGED: 'ENGAGED',
  EXPLORER: 'EXPLORER',
  DORMANT: 'DORMANT',
};

export const levelType = {
  LEVEL0: 'LEVEL0',
  LEVEL1: 'LEVEL1',
  LEVEL2: 'LEVEL2',
  LEVEL3: 'LEVEL3',
};
