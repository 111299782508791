import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';
import {
  blueColor,
  borderGreyColor,
  boxShadowColor,
  deleteButtonText,
  skyeBlueColor,
  tableCellBlackColor,
  tableCellRedColor,
  tableHeaderBackgroundColor,
  whiteColor,
  tableCellGreenColor,
  tableCellGreyColor,
} from 'theme/colorConstants';
import { Link } from '@mui/material';
import { colorToken } from 'theme/designToken';

export const CommunityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 750px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
`;

export const ContainerBox = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
`;

export const ItemTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${colorToken.settings.community.integration.box.title.color};
`;

export const CommunityText = styled(Typography)`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: ${tableCellBlackColor};
`;

export const DescriptionText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${tableCellBlackColor};
`;

export const RoleText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: ${tableCellBlackColor};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const EditButton = styled(Button)`
  background: ${tableHeaderBackgroundColor};
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none;
  padding: 6px 20px;
  text-transform: capitalize;
  gap: 3px;
  color: ${tableCellBlackColor};
  &:hover {
    background-color: ${tableHeaderBackgroundColor};
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const IntegrationUberBox = styled.div`
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
  padding: 14px;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  background: ${(props) =>
    props.integrated === 'true' ? tableHeaderBackgroundColor : 'transparent'};
  border: ${(props) =>
    props.integrated === 'true' ? 'none' : `1px solid ${colorToken.genericBorder.color}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
`;

export const IntegrationChannelContainer = styled.div`
  margin-top: 5px;
  background-color: #f8f8f8;
  padding-left: 8px;
`;

export const ChannelBox = styled.div`
  box-shadow: None;
  border-radius: 10px;
  margin-right: 0px;
  min-width: 100px;
  padding: 5px 8px;
  padding-right: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  border: 1px solid ${borderGreyColor};
`;

export const IntegrationBox = styled.div`
  border-radius: 8px;
  display: flex;
  width: 100%;
`;

export const IntegrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const IntegrationImage = styled.img`
  box-shadow: 0px 6px 28px ${boxShadowColor};
  border-radius: 4px;
`;

export const IntegrationText = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: ${colorToken.settings.community.integration.box.title.color};
`;

export const IntegrationAccountButton = styled(Button)`
  background: ${deleteButtonText};
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none;
  padding: 6px;
  text-transform: capitalize;
  color: ${whiteColor};
  &:hover {
    background-color: ${deleteButtonText};
    color: ${deleteButtonText};
    box-shadow: none;
  }
  width: 30px !important;
  height: 30px !important;
  float: right;
`;

export const IntegrationButton = styled(Button)`
  background: ${(props) => (props.integrated === 'true' ? '#ffffff' : '#696DEC')};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: ${(props) =>
    props.integrated === 'true'
      ? `0px 6px 28px
    ${boxShadowColor}`
      : 'none'};
  padding: 10px 20px;
  text-transform: capitalize;
  color: #ffff;
  min-width: ${(props) => props.mWidth || 'auto'};
  &:hover {
    background: ${(props) => (props.integrated === 'true' ? '#ffffff' : '#696DEC')};

    box-shadow: none;
  }
`;

export const IntegrationDetailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
`;

export const IntegrationActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const IntegrationAccountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

export const YesButton = styled(Button)`
  border-radius: 8px;
  background: ${blueColor};
  font-weight: 600;
  font-size: 12px;
  padding: 10px 40px;
  box-shadow: none;
  margin: 15px;
  text-transform: capitalize;
  color: #ffffff;
  &:hover {
    background: ${skyeBlueColor};
    color: #ffffff;
    box-shadow: none;
  }
`;

export const DeleteButton = styled(Button)`
  border-radius: 4px;
  background: ${tableCellRedColor};
  font-weight: 600;
  font-size: 12px;
  padding: 10px 40px;
  box-shadow: none;
  margin: 15px;
  text-transform: capitalize;
  color: #ffffff;
  &:hover {
    background: ${tableCellRedColor};
    color: #ffffff;
    box-shadow: none;
  }
  &.Mui-disabled {
    color: ${colorToken.button.primary.text.color};
  }
`;

export const CancelButton = styled(Button)`
  border-radius: 4px;
  background: ${tableHeaderBackgroundColor};
  font-weight: 600;
  font-size: 12px;
  padding: 10px 40px;
  margin: 15px;
  text-transform: capitalize;
  box-shadow: none;
  color: ${tableCellBlackColor};
  &:hover {
    background: ${tableHeaderBackgroundColor};
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const BlueCommunityText = styled.span`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: ${blueColor};
`;

export const CustomLink = styled(Link)`
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
`;

export const SelectLink = styled(CustomLink)`
  margin-left: 0;
  margin-right: 15px;
  font-size: 13px;
  line-height: 14px;
  & a {
    margin: 0;
  }
`;

export const LinkTitle = styled(Typography)`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${tableCellGreyColor};
  margin-bottom: 10px;
`;

export const LinkContainer = styled.div`
  padding: 8px 18px;
  background: ${tableHeaderBackgroundColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CopyButton = styled(Button)`
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none;
  padding: 0;
  gap: 6px;
  color: ${blueColor};
  &:hover {
    background: transparent;
    color: ${blueColor};
    box-shadow: none;
  }
`;

export const LinkText = styled.a`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${blueColor};
  max-width: 80%;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
`;

export const LinkTextLog = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${blueColor};
  max-width: 80%;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
`;

export const TextListWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SecretText = styled(Typography)`
  font-weight: bold;
  font-size: 12px;
  color: ${tableCellGreenColor};
`;

export const FilterOption = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

export const FilterOptionLabel = styled.div`
  font-weight: ${(props) => (props.is_selected === 'true' ? 'bold' : 'normal')};
  font-size: 12px;
  line-height: 20px;
  color: #3e5273;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
