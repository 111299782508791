import {
  CLEAR_THREAD_NOTIFICATION,
  DELETE_THREAD,
  GET_THREAD_DATA,
  SET_MESSAGES_CHANNEL_FILTER,
  SET_MESSAGES_PROVIDER_CHANNEL_FILTER,
  SET_MESSAGES_STATUS_FILTER,
  SET_SHOW_THREAD_COMPLETELY,
  SET_THREAD_LOADING,
  SET_THREAD_NOTIFICATION,
  SET_SUPPORT_FEED_FILTERS,
  SET_SUPPORT_DRAFT_FILTER,
  SET_SUPPORT_SAVED_FILTERS,
  SET_SUPPORT_DEFAULT_FILTERS,
  SET_IS_VIEW_SAVED_SUCCESS,
  SET_THREADS_DATA_TO_DEFAULT,
  SET_THREAD_DATA_AFTER_REPLY,
  GET_SUPPORT_THREAD_DATA,
  SET_THREAD_LOADING_INFINITE_SCROLL,
  GET_SUPPORT_THREAD_DATA_USING_SCROLL,
  SET_MESSAGE_REPLY_LOADER,
  SET_MESSAGE_TOPICS_LIST,
  SET_SELECTED_FILTER_NAME,
} from '../types';

const initialState = {
  threadsData: {
    content: [],
    page: -1,
    size: -1,
    last: false,
    totalElements: 0,
    totalPages: -1,
  },
  threadsToShowCompletely: {},
  threadsNotification: '',
  channelsFilter: ['all'],
  statusFilter: ['all'],
  threadsLoading: true,
  threadsLoadingFromInfiniteScroll: false,
  providerChannelsFilter: [-1],
  supportSavedFilters: [],
  supportDefaultFilters: [],
  supportFeedFilters: {
    condition: 'and',
    predicate: [],
  },
  isSupportDraftFilter: false,
  isViewSavedSuccess: false,
  threadReplyLoadingInfo: {
    isLoading: false,
    threadId: -1
  },
  messageTopicsList: [],
  selectedFilterName: '',
};

const messagesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SHOW_THREAD_COMPLETELY:
      return {
        ...state,
        threadsToShowCompletely: { ...state.threadsToShowCompletely, [payload]: true },
      };
    case GET_THREAD_DATA:
      return {
        ...state,
        threadsData: payload,
        threadsToShowCompletely: {},
        threadsLoading: false,
      };
    case GET_SUPPORT_THREAD_DATA:
      return {
        ...state,
        threadsData: payload,
        threadsToShowCompletely: {},
        threadsLoading: false,
      };
    case GET_SUPPORT_THREAD_DATA_USING_SCROLL:
      return {
        ...state,
        threadsData: {
          ...payload,
          content: state.threadsData.content.concat(payload.content),
        },
        threadsLoadingFromInfiniteScroll: false,
      };
    case SET_THREADS_DATA_TO_DEFAULT:
      return {
        ...state,
        threadsData: {
          ...state.threadsData,
          content: [],
        },
      };
    case SET_THREAD_DATA_AFTER_REPLY:
      const filteredThread = state.threadsData.content.map((thread) => {
        if (thread.threadId === payload.threadId) {
          thread = { ...payload };
        }
        return thread;
      });
      return {
        ...state,
        threadsData: {
          ...state.threadsData,
          content: filteredThread,
        },
      };
    case SET_MESSAGE_REPLY_LOADER:
      return {
        ...state,
        threadReplyLoadingInfo: {
          isLoading: payload.isLoading,
          threadId: payload.threadId,
        },
      };
    case SET_THREAD_NOTIFICATION:
      return {
        ...state,
        threadsNotification: payload,
        threadsLoading: false,
      };
    case CLEAR_THREAD_NOTIFICATION:
      return {
        ...state,
        threadsNotification: '',
      };
    case SET_MESSAGES_CHANNEL_FILTER:
      return {
        ...state,
        channelsFilter: payload,
      };
    case SET_MESSAGES_STATUS_FILTER:
      return {
        ...state,
        statusFilter: payload,
      };
    case SET_MESSAGES_PROVIDER_CHANNEL_FILTER:
      return {
        ...state,
        providerChannelsFilter: payload,
      };
    case SET_THREAD_LOADING:
      return {
        ...state,
        threadsLoading: true,
      };
    case SET_THREAD_LOADING_INFINITE_SCROLL:
      return {
        ...state,
        threadsLoadingFromInfiniteScroll: true,
      };
    case DELETE_THREAD:
      return {
        ...state,
        threadsData: {
          ...state.threadsData,
          content: state.threadsData.content.filter((item) => item.threadId != payload),
        },
      };
    case SET_SUPPORT_FEED_FILTERS:
      return {
        ...state,
        supportFeedFilters: payload,
      };
    case SET_SUPPORT_DRAFT_FILTER:
      return {
        ...state,
        isSupportDraftFilter: payload,
      };
    case SET_SUPPORT_SAVED_FILTERS:
      return {
        ...state,
        supportSavedFilters: payload,
      };
    case SET_SUPPORT_DEFAULT_FILTERS:
      return {
        ...state,
        supportDefaultFilters: payload,
      };
    case SET_IS_VIEW_SAVED_SUCCESS:
      return {
        ...state,
        isViewSavedSuccess: payload,
      };
    case SET_MESSAGE_TOPICS_LIST:
      return {
        ...state,
        messageTopicsList: payload,
      };
    case SET_SELECTED_FILTER_NAME:
      return {
        ...state,
        selectedFilterName: payload,
      }
    default:
      return state;
  }
};

export default messagesReducer;
