export const UPDATE_POST_CONTENT = 'UPDATE_POST_CONTENT';
export const UPDATE_POST_COMMUNITY_ID = 'UPDATE_POST_COMMUNITY_ID';
export const UPDATE_POST_INTEGRATION_TYPE = 'UPDATE_POST_INTEGRATION_TYPE';
export const UPDATE_POST_DATETIME = 'UPDATE_POST_DATETIME';
export const UPDATE_POST_TIME_ZONE = 'UPDATE_POST_TIME_ZONE';
export const UPDATE_POST_SEGMENTS = 'UPDATE_POST_SEGMENTS';
export const UPDATE_POST_SUBJECT = 'UPDATE_POST_SUBJECT';
export const UPDATE_POST_TAGS = 'UPDATE_POST_TAGS';
export const UPDATE_POST_CHANNELS = 'UPDATE_POST_CHANNELS';
export const SET_TAB = 'SET_TAB';
export const GET_DRAFTED_CONTENT = 'GET_DRAFTED_CONTENT';
export const GET_SCHEDULED_CONTENT = 'GET_SCHEDULED_CONTENT';
export const GET_POSTED_CONTENT = 'GET_POSTED_CONTENT';
export const GET_CONTENT_TEMPLATES = 'GET_CONTENT_TEMPLATES';
export const GET_ALL_CONTENT = 'GET_ALL_CONTENT';
export const GET_CONTENT_DETAILS = 'GET_CONTENT_DETAILS';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const SET_CONTENT_FROM_ID_LOADING = 'SET_CONTENT_FROM_ID_LOADING';
export const GET_CONTENT_FROM_ID = 'GET_CONTENT_FROM_ID';
export const REST_CONTENT = 'RESET_CONTENT';
export const SET_SIDEBAR_ACTION_MESSAGES = 'SET_SIDEBAR_ACTION_MESSAGES';
export const SET_TEMPLATE_LOADING = 'SET_TEMPLATE_LOADING';
export const SET_SELECTED_MESSAGE_ID = 'SET_SELECTED_MESSAGE_ID';
export const RESET_SELECTED_MESSAGE_ID = 'RESET_SELECTED_MESSAGE_ID';
export const GET_TEMPLATE_CATEGORIES = 'GET_TEMPLATE_CATEGORIES';
export const GET_TEMPLATE_FROM_CATEGORY = 'GET_TEMPLATE_FROM_CATEGORY';
export const GET_TEMPLATE_FROM_CATEGORY_LOADING = 'GET_TEMPLATE_FROM_CATEGORY_LOADING';
export const SET_TEMPLATE_CONTENT = 'SET_TEMPLATE_CONTENT';
export const RESET_TEMPLATE_CONTENT = 'RESET_TEMPLATE_CONTENT';

export const ContentType = {
  DIRECT_MESSAGE: 'DIRECT_MESSAGE',
  CHANNEL_MESSAGE: 'CHANNEL_MESSAGE',
  EMAIL_MESSAGE: 'EMAIL_MESSAGE',
};

export const ContentStatus = {
  SCHEDULED: 'SCHEDULED',
  POSTED: 'POSTED',
  DRAFT: 'DRAFT',
  TEMPLATE: 'TEMPLATE',
};

export const IntegrationType = {
  SLACK: 'SLACK',
  DISCORD: 'DISCORD',
  TWITTER: 'TWITTER',
  DISCOURSE: 'DISCOURSE',
  EMAIL: 'EMAIL',
  CONFLUENCE: 'CONFLUENCE',
  ZENDESK: 'ZENDESK',
  FRESHDESK: 'FRESHDESK',
  NOTION: 'NOTION',
  HUBSPOT: 'HUBSPOT',
  CRM_HUBSPOT: 'CRM_HUBSPOT',
  GITHUB: 'GITHUB',
  CHROME: 'CHROME',
  INTERCOM: 'INTERCOM',
  GOOGLE_DRIVE: 'GOOGLE_DRIVE',
  SALESFORCE: 'SALESFORCE',
};
