export const COMMUNITY_MESSAGE_TYPE_SIMPLE_MESSAGE = 'communitySimpleMessage';
export const COMMUNITY_MESSAGE_TYPE_NOTIFICATIONS_COUNT_CHANGED =
  'communityNotificationsCountChanged';
export const multipleSelectDebounce = 1800;
export const loadItemsValue = 20;
export const accountNotVerified = 'ACCOUNT_NOT_VERIFIED';
export const maxRetryAttemptReached = 'MAX_RETRY_ATTEMPT_REACHED';
export const unauthorized = 'UNAUTHORIZED';
export const activityPageMembersLimit = 5;
export const reportSearchDebounce = 800;
export const childUrlsListSearchDebounce = 800;
export const standardSearchDebounceDelay = 800;
