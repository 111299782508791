import { Dispatch } from 'redux';
import {
  cancelSubscriptionUrl,
  getPaymentsStatusUrl,
  getSupportBotPaymentsStatusUrl,
  getSupportCurrentUsageStatusUrl,
  paymentsListUrl,
  supportBotcancelSubscriptionUrl,
  supportBotpaymentsListUrl,
} from '../../../constant/apiConstants';
import Api from 'layers/interceptor/interceptor';
// import { MEMBERS_ERROR_HANDLE, MEMBERS_TOAST_HANDLE } from '../membersProvider/types';
import {
  SET_PAYMENTS_MODAL_OPEN,
  GET_PAYMENTS_STATUS,
  GET_PLAN_LIST,
  PAYMENTS_STATUS_LOADING,
  PLAN_LIST_LOADING,
  ERROR_PAYMENTS_STATUS,
  ERROR_PLAN_LIST,
  CANCEL_SUBSCRIPTION,
  ERROR_CANCEL_SUBSCRIPTION,
  SET_UPGRADE_NOW_EVENT_SOURCE,
  SUPPORT_BOT_PAYMENTS_STATUS_LOADING,
  GET_SUPPORT_BOT_PAYMENTS_STATUS,
  ERROR_SUPPORT_BOT_PAYMENTS_STATUS,
  SUPPORT_BOT_PLAN_LIST_LOADING,
  GET_SUPPORT_BOT_PLAN_LIST,
  ERROR_SUPPORT_BOT_PLAN_LIST,
  CANCEL_SUPPORT_BOT_SUBSCRIPTION,
  ERROR_CANCEL_SUPPORT_BOT_SUBSCRIPTION,
  LOADING_SUPPORT_BOT_CURRENT_USAGE,
  GET_SUPPORT_BOT_CURRENT_USAGE,
} from '../types';
import { transformPaymentsListData, transformSupportBotPaymentsListData } from '../utils';
import { MEMBERS_ERROR_HANDLE, MEMBERS_TOAST_HANDLE } from 'redux/memberProvider/types';

export const setPaymentsModalOpenAction = (open) => (dispatch) => {
  dispatch({
    type: SET_PAYMENTS_MODAL_OPEN,
    payload: open,
  });
};

export const getPaymentStatusAction = (orgId) => (dispatch) => {
  dispatch({
    type: PAYMENTS_STATUS_LOADING,
  });
  Api.get(getPaymentsStatusUrl(orgId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_PAYMENTS_STATUS,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_PAYMENTS_STATUS,
        payload: 'Error occurred while fetching plan details',
      });
    });
};

export const getPaymentsPlanListAction = (orgId, frequency) => (dispatch) => {
  dispatch({
    type: PLAN_LIST_LOADING,
  });
  Api.get(paymentsListUrl(orgId, frequency))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_PLAN_LIST,
        payload: transformPaymentsListData(res),
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_PLAN_LIST,
        payload: 'Error occurred while fetching plan details',
      });
    });
};

export const cancelSubscriptionAction = (orgId, reason) => (dispatch) => {
  Api.post(cancelSubscriptionUrl(orgId), {
    reason: reason,
  })
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: MEMBERS_TOAST_HANDLE,
        payload: {
          success: true,
          message: 'Successfully cancelled the subscription. Your billing has been stopped',
        },
      });
    })
    .catch(() => {
      dispatch({
        type: MEMBERS_ERROR_HANDLE,
        payload: 'Failed to cancel subscription, please try again!',
      });
    });
};

export const setUpgradeNowEventSourceAction = (source) => (dispatch) => {
  dispatch({
    type: SET_UPGRADE_NOW_EVENT_SOURCE,
    payload: source,
  });
};

export const getSupportBotPaymentStatusAction = (orgId) => (dispatch) => {
  dispatch({
    type: SUPPORT_BOT_PAYMENTS_STATUS_LOADING,
  });
  Api.get(getSupportBotPaymentsStatusUrl(orgId))
    .then((res) => {
      dispatch({
        type: GET_SUPPORT_BOT_PAYMENTS_STATUS,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_SUPPORT_BOT_PAYMENTS_STATUS,
        payload: 'Error occurred while fetching plan details',
      });
    });
};

export const getSupportBotPaymentsPlanListAction = (orgId, frequency) => (dispatch) => {
  dispatch({
    type: SUPPORT_BOT_PLAN_LIST_LOADING,
  });
  Api.get(supportBotpaymentsListUrl(orgId, frequency))
    .then((res) => {
      dispatch({
        type: GET_SUPPORT_BOT_PLAN_LIST,
        payload: transformSupportBotPaymentsListData(res),
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_SUPPORT_BOT_PLAN_LIST,
        payload: 'Error occurred while fetching plan details',
      });
    });
};

export const supportBotCancelSubscriptionAction = (orgId, reason) => (dispatch) => {
  Api.post(supportBotcancelSubscriptionUrl(orgId), {
    reason: reason,
  })
    .then((res) => {
      dispatch({
        type: MEMBERS_TOAST_HANDLE,
        payload: {
          success: true,
          message: 'Successfully cancelled the subscription. Your billing has been stopped',
        },
      });
    })
    .catch(() => {
      dispatch({
        type: MEMBERS_ERROR_HANDLE,
        payload: 'Failed to cancel subscription, please try again!',
      });
    });
};


export const getSupportBotCurrentUsageAction = (orgId, addToast) => (dispatch) => {
  dispatch({
    type: LOADING_SUPPORT_BOT_CURRENT_USAGE,
    payload: true,
  });
  Api.get(getSupportCurrentUsageStatusUrl(orgId))
    .then((res) => {
      dispatch({
        type: GET_SUPPORT_BOT_CURRENT_USAGE,
        payload: res,
      });
    })
    .catch(() => {
      addToast(`Error occured while fetching usage!`, {
        appearance: 'error',
      });
      dispatch({
        type: LOADING_SUPPORT_BOT_CURRENT_USAGE,
        payload: false,
      });
    });
};