import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import AuthRoute from '../route/authLazyRoute';
import { ThemeProvider } from '@material-ui/styles';
import { LeftSidebar, PresentationLayout } from '../layout';
import MuiTheme from '../../theme/index';
import { lazyRetry } from './lazyResolver';

const ActivitySummaryPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "activitySummaryPage" */ '../../pages/activity/summary'),
    'activitySummaryPage',
  ),
);

// const AuthenticationUser = lazy(() => import('../../pages/SignIn'));
const AuthenticationUser = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "authenticationUser" */ '../../pages/SignIn'),
    'authenticationUser',
  ),
);

const Signup = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Signup" */ '../../pages/Signup'), 'Signup'),
);

// const EngagementAnalytics = lazy(() => import('../../pages/activity/engagement-analytics'));
const EngagementAnalytics = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "engagementAnalytics" */ '../../pages/activity/engagement-analytics'
      ),
    'engagementAnalytics',
  ),
);

// const RetentionAnalytics = lazy(() => import('../../pages/activity/retention-analytics'));
const RetentionAnalytics = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "retentionAnalytics" */ '../../pages/activity/retention-analytics'
      ),
    'retentionAnalytics',
  ),
);

// const MemberPage = lazy(() => import('../../pages/Member'));
const MemberPage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "memberPage" */ '../../pages/Member'), 'memberPage'),
);

// const NotificationsPage = lazy(() => import('../../pages/logging/notifications'));
const NotificationsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "notificationsPage" */ '../../pages/logging/notifications'),
    'notificationsPage',
  ),
);
const AddTeamMate = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "notificationsPage" */ '../../pages/settings/team/index'),
    'addTeamMate',
  ),
);

const SettingsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "notificationsPage" */ '../../pages/settings'),
    'SettingsPage',
  ),
);

const PreferencePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "notificationsPage" */ '../../pages/settings/preference'),
    'PreferencePage',
  ),
);

const SettingAIPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "notificationsPage" */ '../../pages/settings/settingAI'),
    'SettingAIPage',
  ),
);

// const BillingPage = lazy(() => import('../../pages/settings/billing'));
const BillingPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "billingPage" */ '../../pages/settings/billing'),
    'billingPage',
  ),
);

// const CompanyPage = lazy(() => import('../../pages/settings/company'));
const CompanyPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "companyPage" */ '../../pages/settings/company'),
    'companyPage',
  ),
);

// const TeamPage = lazy(() => import('../../pages/settings/team'));
const TeamPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "teamPage" */ '../../pages/settings/team'),
    'teamPage',
  ),
);

// const ProfilePage = lazy(() => import('../../pages/accounts/profile'));
const ProfilePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "profilePage" */ '../../pages/accounts/profile'),
    'profilePage',
  ),
);

// const RewardsPage = lazy(() => import('../../pages/settings/rewardscenter'));
const RewardsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "rewardsPage" */ '../../pages/settings/rewardscenter'),
    'rewardsPage',
  ),
);

// const CommunityPage = lazy(() => import('../../pages/settings/community'));
const CommunityPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "communityPage" */ '../../pages/settings/community'),
    'communityPage',
  ),
);

// const IntegrationPage = lazy(() => import('../../pages/settings/integrations'));
const IntegrationPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "integrationPage" */ '../../pages/settings/integrations'),
    'integrationPage',
  ),
);

// const NotificationReactPage = lazy(() => import('../../pages/settings/notifications'));
const NotificationReactPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "notificationReactPage" */ '../../pages/settings/notifications'),
    'notificationReactPage',
  ),
);

// const createIntegrationPage = lazy(() => import('../../pages/settings/integrations/create'));
const CreateIntegrationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "createIntegrationPage" */ '../../pages/settings/integrations/create'
      ),
    'createIntegrationPage',
  ),
);

const SelectIntegrationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "selectIntegrationPage" */ '../../pages/settings/integrations/select'
      ),
    'selectIntegrationPage',
  ),
);

// const DiscordMenuReactPage = lazy(() => import('../../pages/settings/integrations/discord'));
const DiscordMenuReactPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "discordMenuReactPage" */ '../../pages/settings/integrations/discord'
      ),
    'discordMenuReactPage',
  ),
);

const DiscordCustomBot = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "discordCustomBot" */ '../../pages/settings/integrations/discord/custom-bot'
      ),
    'discordCustomBot',
  ),
);

const SlackCustomBot = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "discordCustomBot" */ '../../pages/settings/integrations/slack/custom-bot'
      ),
    'slackCustomBot',
  ),
);

const SlackIntegrationOptions = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "slackIntegrationOptions" */ '../../pages/settings/integrations/slack/options'
      ),
    'slackIntegrationOptions',
  ),
);
// const IntegrationDetailPage = lazy(() => import('../../pages/settings/integrations/details'));
const IntegrationDetailPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "integrationDetailPage" */ '../../pages/settings/integrations/details'
      ),
    'integrationDetailPage',
  ),
);

// const ResetPasswordPage = lazy(() => import('components/ResetPassword/ResetPassword'));
const ResetPasswordPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "resetPasswordPage" */ 'components/ResetPassword/ResetPassword'),
    'resetPasswordPage',
  ),
);

// const HubspotIntegrationReactPage = lazy(() => import('../../pages/settings/integrations/hubspot'));
const HubspotIntegrationReactPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "hubspotIntegrationReactPage" */ '../../pages/settings/integrations/hubspot'
      ),
    'hubspotIntegrationReactPage',
  ),
);

//Domain Verification
// const DomainVerificationPage = lazy(() => import('../../pages/settings/domainVerification'));
const DomainVerificationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "domainVerificationPage" */ '../../pages/settings/domainVerification'
      ),
    'domainVerificationPage',
  ),
);

// const DomainDetailPage = lazy(() => import('../../pages/settings/domainVerification/detail'));
const DomainDetailPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "domainDetailPage" */ '../../pages/settings/domainVerification/detail'
      ),
    'domainDetailPage',
  ),
);

const SecuritySettingsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SecuritySettingsPage" */ '../../pages/settings/security/index.jsx'
      ),
    'SecuritySettingsPage',
  ),
);

// const MessageSupportPage = lazy(() => import('../../pages/support'));
const MessageSupportPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "messageSupportPage" */ '../../pages/support'),
    'messageSupportPage',
  ),
);
// const MessageSupportFeedPage = lazy(() => import('../../pages/support-feed'));
const MessageSupportFeedPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "messageSupportFeedPage" */ '../../pages/support-feed'),
    'messageSupportFeedPage',
  ),
);

//Automations Pages
// const AutomationTemplatesPage = lazy(() => import('../../pages/automations'));
const AutomationTemplatesPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "automationTemplatesPage" */ '../../pages/automations'),
    'automationTemplatesPage',
  ),
);

// const AutomationsListPage = lazy(() => import('../../pages/automations/list'));
const AutomationsListPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "automationsListPage" */ '../../pages/automations/list'),
    'automationsListPage',
  ),
);

// const AutomationPage = lazy(() => import('../../pages/automations/workflow'));
const AutomationPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "automationPage" */ '../../pages/automations/workflow'),
    'automationPage',
  ),
);

// Outreach
// const OutreachTemplatesPage = lazy(() => import('../../pages/outreach'));
const OutreachTemplatesPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "outreachTemplatesPage" */ '../../pages/outreach'),
    'outreachTemplatesPage',
  ),
);

// const OutreachCreatePage = lazy(() => import('../../pages/outreach/create'));
const OutreachCreatePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "outreachCreatePage" */ '../../pages/outreach/create'),
    'outreachCreatePage',
  ),
);

// const OutreachEditPage = lazy(() => import('../../pages/outreach/edit'));
const OutreachEditPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "outreachEditPage" */ '../../pages/outreach/edit'),
    'outreachEditPage',
  ),
);

// const OutreachListPage = lazy(() => import('../../pages/outreach/list'));
const OutreachListPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "outreachListPage" */ '../../pages/outreach/list'),
    'outreachListPage',
  ),
);

// const OutreachReportPage = lazy(() => import('../../pages/outreach/report'));
const OutreachReportPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "outreachReportPage" */ '../../pages/outreach/report'),
    'outreachReportPage',
  ),
);

// const UnsubscribeEmailDomainPage = lazy(() => import('../../pages/unsubscribe'));
const UnsubscribeEmailDomainPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "unsubscribeEmailDomainPage" */ '../../pages/unsubscribe'),
    'unsubscribeEmailDomainPage',
  ),
);

// const YearEndReviewPage = lazy(() => import('../../pages/year-end-review/YearEndReviewPage'));
const YearEndReviewPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "yearEndReviewPage" */ '../../pages/year-end-review/YearEndReviewPage'
      ),
    'yearEndReviewPage',
  ),
);

// const TopicPage = lazy(() => import('../../pages/topics/index'));
const TopicPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "topicPage" */ '../../pages/topics/index'),
    'topicPage',
  ),
);

const AnalyticsActivitiesPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/activities/index'
      ),
    'AnalyticsActivitiesPage',
  ),
);

const AnalyticsSentimentPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/activities/sentiments'
      ),
    'AnalyticsSentimentPage',
  ),
);

const AnalyticsChannelsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/activities/channels'
      ),
    'AnalyticsChannelsPage',
  ),
);

const AnalyticsTopicsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/activities/topics'
      ),
    'AnalyticsTopicsPage',
  ),
);

// Member analytics pages
const AnalyticsTotalMembersPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/members/total-members'
      ),
    'AnalyticsTotalMembersPage',
  ),
);
const AnalyticsNewMembersPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/members/new-members'
      ),
    'AnalyticsNewMembersPage',
  ),
);

const AnalyticsTopMembersPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/members/top-members'
      ),
    'AnalyticsTopMembersPage',
  ),
);
const AnalyticsPotentialChampionsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/members/potential-champs'
      ),
    'AnalyticsPotentialChampionsPage',
  ),
);

const RetentionAtRiskMembersActivityPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/retention/at-risk-members'
      ),
    'RetentionAtRiskMembersActivityPage',
  ),
);

const RetentionInsightActivityPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/retention/retention-insight'
      ),
    'RetentionInsightActivityPage',
  ),
);

const ResponsivenessActivitiesPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/engagement/responsiveness-insight'
      ),
    'ResponsivenessActivitiesPage',
  ),
);
const TeamPerformanceActivitiesPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AnalyticsSummaryPage" */ '../../pages/activity/engagement/team-performance-insight'
      ),
    'TeamPerformanceActivitiesPage',
  ),
);

const SetupConfigPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "SetupConfigPage" */ '../../pages/setup/config'),
    'SetupConfigPage',
  ),
);

const ChatbotDataSourcePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ChatbotDataSourcePage" */ '../../pages/chatbot/settings/data-source'
      ),
    'ChatbotDataSourcePage',
  ),
);

const TrainingPageModulePage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "ChatbotDataSourcePage" */ '../../pages/train/index.jsx'),
    'TrainingPageModulePage',
  ),
);

const TestChatBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "TestChatBotPage" */ '../../pages/chatbot/settings/test-chatbot'),
    'TestChatBotPage',
  ),
);

const CustomizationBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CustomizationBotPage" */ '../../pages/chatbot/settings/customization'
      ),
    'CustomizationBotPage',
  ),
);

const InstallBotPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "InstallBotPage" */ '../../pages/chatbot/settings/install'),
    'InstallBotPage',
  ),
);

const BotAnalyticsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BotAnalyticsPage" */ '../../pages/chatbot/bot-analytics/bot-analytics'
      ),
    'BotAnalyticsPage',
  ),
);
const WidgetTestPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WidgetTestPage" */ '../../pages/chatbot/settings/widget'),
    'WidgetTestPage',
  ),
);

const BotConversationsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WidgetTestPage" */ '../../pages/chatbot/conversations'),
    'BotConversationsPage',
  ),
);

const BotAllConversationsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WidgetTestPage" */ '../../pages/chatbot/conversations/all'),
    'BotAllConversationsPage',
  ),
);
const BotOpenConversationsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WidgetTestPage" */ '../../pages/chatbot/conversations/open'),
    'BotOpenConversationsPage',
  ),
);
const BotResolvedConversationsPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "WidgetTestPage" */ '../../pages/chatbot/conversations/resolved'),
    'BotResolvedConversationsPage',
  ),
);

const ChatBotPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WidgetTestPage" */ '../../pages/chatbot'),
    'ChatBotPage',
  ),
);

const ChartBotSettingsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "WidgetTestPage" */ '../../pages/chatbot/settings'),
    'ChartBotSettingsPage',
  ),
);

const AgentAssistCustomizePgae = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AgentAssistCustomizePgae" */ '../../pages/agent-assist/settings/customization'
      ),
    'AgentAssistCustomizePgae',
  ),
);

const AgentAssistInstallPgae = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AgentAssistInstallPgae" */ '../../pages/agent-assist/settings/install'
      ),
    'AgentAssistInstallPgae',
  ),
);

const AgentAssistTestPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AgentAssistTestPage" */ '../../pages/agent-assist/settings/test'
      ),
    'AgentAssistTestPage',
  ),
);

const SlackBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SlackBotPage" */ '../../pages/agent-assist/setup/slack-bot/slackBotPage'
      ),
    'SlackBotPage',
  ),
);

const WebBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "WebBotPage" */ '../../pages/agent-assist/setup/webBot/webBot'),
    'WebBotPage',
  ),
);
const ChromeBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ChromeBotPage" */ '../../pages/agent-assist/setup/chromeBotNew/chromeBot'
      ),
    'ChromeBotPage',
  ),
);
const DiscordBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DiscordBotPage" */ '../../pages/agent-assist/setup/discordBot/discordBot'
      ),
    'DiscordBotPage',
  ),
);

const MSTeamsBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MSTeamsBotPage" */ '../../pages/agent-assist/setup/msTeams/msTeamsPage'
      ),
    'MSTeamsBotPage',
  ),
);
const AskAIPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "AskAIPage" */ '../../pages/agent-assist/ask-ai/ask-ai'),
    'AskAIPage',
  ),
);

const ConversationBotPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "ConversationBotPage" */ '../../pages/conversation/index.jsx'),
    'ConversationBotPage',
  ),
);

const ActionsPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "ActionsPage" */ '../../pages/actions/index.jsx'),
    'ActionsPage',
  ),
);

const CreateActionsPage = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "CreateActionsPage" */ '../../pages/actions/create/index.jsx'),
    'CreateActionsPage',
  ),
);
const AgentListPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AgentListPage" */ '../../pages/agents/AgentListPage/AgentListPage.jsx'
      ),
    'AgentListPage',
  ),
);

const AgentCreatePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AgentCreatePage" */ '../../pages/agents/AgentCreatePage/AgentCreatePage.jsx'
      ),
    'AgentCreatePage',
  ),
);

const Routes = () => {
  const location = useLocation();

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4"></div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <LeftSidebar>
          <Suspense fallback={<SuspenseLoading />}>
            <Switch>
              <Route
                exact
                path={[
                  '/activity/summary',
                  '/activity/engagement-analytics',
                  '/activity/retention-analytics',
                  '/activity/activities',
                  '/activity/sentiments',
                  '/activity/channels',
                  '/activity/topics',
                  '/activity/retention',
                  '/activity/at-risk-members',
                  '/activity/responsiveness',
                  '/activity/team-performance',
                  '/activity/total-members',
                  '/activity/new-members',
                  '/activity/top-members',
                  '/activity/potential-champions',
                  '/automations',
                  '/automations/list',
                  '/automations/:id',
                  '/members',
                  '/outreach',
                  '/outreach/list',
                  '/outreach/edit/:platform/:id',
                  '/outreach/create/:platform',
                  '/outreach/report/:platform/:id',
                  '/outreach/create',
                  '/logging/notifications',
                  '/logging',
                  '/settings',
                  '/settings/billing',
                  '/settings/company',
                  '/settings/staff',
                  '/settings/rewardscenter',
                  '/settings/community',
                  '/settings/preference',
                  '/settings/ai',
                  '/settings/integrations',
                  '/settings/notifications',
                  '/settings/integrations/discord',
                  '/settings/integrations/discord/custom-bot',
                  '/settings/integrations/slack',
                  '/settings/integrations/slack/custom-bot',
                  '/settings/integrations/hubspot/details',
                  '/settings/integrations/details',
                  '/settings/domain-verification',
                  '/settings/domain-verification/domain/:domainId',
                  '/settings/security',
                  '/support',
                  '/support-bot/train',
                  '/support-bot/training',
                  '/support-bot/data-source',
                  '/support-bot/test',
                  '/support-bot/customization',
                  '/support-bot/install',
                  '/support-bot/settings/data-source',
                  '/support-bot/settings/test',
                  '/support-bot/settings/customization',
                  '/support-bot/settings/install',
                  '/support-bot/insights',
                  '/support-bot/widget',
                  '/support-bot/conversations',
                  '/support-bot/conversations/all',
                  '/support-bot/conversations/open',
                  '/support-bot/conversations/resolved',
                  '/support-bot/agent-assist/customization',
                  '/support-bot/agent-assist/install',
                  '/support-bot/agent-assist/test',
                  '/support-bot/setup/slack-bot',
                  '/support-bot/setup/chrome-app',
                  '/support-bot/setup/web-chat',
                  '/support-bot/setup/discord-bot',
                  '/support-bot/setup/ms-teams',
                  '/support-bot/conversation-feed',
                  '/support-bot/ask',
                  '/support-bot/actions',
                  '/support-bot/actions/create',
                  '/support-bot/actions/edit/:actionId',
                  '/support-bot/agents',
                  '/support-bot/agents/create',
                  '/support-bot/agents/edit/:actionId',
                  '/activities',
                  '/accounts/profile',
                  '/community-roundup22/:communityName/:communityId',
                  '/threado-topic-url',
                  '/add-teammate',
                  '/support-bot',
                  '/support-bot/settings',
                  
                ]}>
                <Switch location={location} key={location.pathname}>
                  <AuthRoute path="/activity/summary" component={ActivitySummaryPage} />
                  <AuthRoute
                    path="/activity/engagement-analytics"
                    component={EngagementAnalytics}
                  />
                  <AuthRoute path="/activity/retention-analytics" component={RetentionAnalytics} />
                  <AuthRoute path="/activity/activities" component={AnalyticsActivitiesPage} />
                  <AuthRoute path="/activity/sentiments" component={AnalyticsSentimentPage} />
                  <AuthRoute path="/activity/channels" component={AnalyticsChannelsPage} />
                  <AuthRoute path="/activity/topics" component={AnalyticsTopicsPage} />
                  <AuthRoute path="/activity/retention" component={RetentionInsightActivityPage} />
                  <AuthRoute
                    path="/activity/at-risk-members"
                    component={RetentionAtRiskMembersActivityPage}
                  />
                  <AuthRoute
                    path="/activity/responsiveness"
                    component={ResponsivenessActivitiesPage}
                  />
                  <AuthRoute
                    path="/activity/team-performance"
                    component={TeamPerformanceActivitiesPage}
                  />
                  <AuthRoute path="/activity/total-members" component={AnalyticsTotalMembersPage} />
                  <AuthRoute path="/activity/new-members" component={AnalyticsNewMembersPage} />
                  <AuthRoute path="/activity/top-members" component={AnalyticsTopMembersPage} />
                  <AuthRoute
                    path="/activity/potential-champions"
                    component={AnalyticsPotentialChampionsPage}
                  />
                  <AuthRoute path="/outreach" exact strict component={OutreachTemplatesPage} />
                  <AuthRoute
                    exact
                    strict
                    path="/automations/list"
                    component={AutomationsListPage}
                  />
                  <AuthRoute exact strict path="/automations/:id" component={AutomationPage} />
                  <AuthRoute exact path="/automations" component={AutomationTemplatesPage} />
                  <AuthRoute exact strict path="/outreach/list" component={OutreachListPage} />
                  <AuthRoute path="/outreach/edit/:platform/:id" component={OutreachEditPage} />
                  <AuthRoute path="/outreach/report/:platform/:id" component={OutreachReportPage} />
                  <AuthRoute path="/outreach/create/:platform" component={OutreachCreatePage} />
                  <AuthRoute path="/outreach/create" component={OutreachCreatePage} />
                  <AuthRoute path="/members" component={MemberPage} />
                  <AuthRoute path="/logging/notifications" component={NotificationsPage} />
                  <AuthRoute path="/add-teammate" component={AddTeamMate} />
                  <AuthRoute path="/settings/billing" component={BillingPage} />
                  <AuthRoute
                    exact
                    path="/settings/domain-verification"
                    component={DomainVerificationPage}
                  />
                  <AuthRoute
                    exact
                    path="/settings/domain-verification/domain/:domainId"
                    component={DomainDetailPage}
                  />
                  <AuthRoute path="/settings/company" component={CompanyPage} />
                  <AuthRoute path="/settings/staff" component={TeamPage} />
                  <AuthRoute path="/settings/rewardscenter" component={RewardsPage} />
                  <AuthRoute path="/settings/community" component={CommunityPage} />
                  <AuthRoute exact path="/settings/integrations" component={IntegrationPage} />
                  <AuthRoute path="/settings/preference" component={PreferencePage} />
                  <AuthRoute path="/settings/ai" component={SettingAIPage} />
                  <AuthRoute
                    exact
                    path="/settings/notifications"
                    component={NotificationReactPage}
                  />
                  <AuthRoute
                    exact
                    path="/settings/integrations/discord"
                    component={DiscordMenuReactPage}
                  />
                  <AuthRoute
                    exact
                    path="/settings/integrations/discord/custom-bot"
                    component={DiscordCustomBot}
                  />
                  <AuthRoute
                    exact
                    path="/settings/integrations/slack"
                    component={SlackIntegrationOptions}
                  />
                  <AuthRoute
                    exact
                    path="/settings/integrations/slack/custom-bot"
                    component={SlackCustomBot}
                  />
                  <AuthRoute
                    exact
                    path="/settings/integrations/details"
                    component={IntegrationDetailPage}
                  />
                  <AuthRoute
                    exact
                    path="/settings/integrations/hubspot/details"
                    component={HubspotIntegrationReactPage}
                  />
                  <AuthRoute exact path="/settings/security" component={SecuritySettingsPage} />
                  <AuthRoute path="/settings" component={SettingsPage} />
                  <AuthRoute path="/support" component={MessageSupportPage} />
                  <AuthRoute path="/activities" component={MessageSupportFeedPage} />
                  <AuthRoute path="/accounts/profile" component={ProfilePage} />
                  <AuthRoute
                    path="/community-roundup22/:communityName/:communityId"
                    component={YearEndReviewPage}
                  />
                  <AuthRoute path="/threado-topic-url" component={TopicPage} />
                  <AuthRoute exact path="/support-bot/actions" component={ActionsPage} />
                  <AuthRoute
                    exact
                    path="/support-bot/actions/create"
                    component={(props) => <CreateActionsPage {...props} mode="create" />}
                  />
                  <AuthRoute
                    exact
                    path="/support-bot/actions/edit/:actionId"
                    component={(props) => (
                      <CreateActionsPage
                        {...props}
                        mode="edit"
                        actionId={props.match.params.actionId}
                      />
                    )}
                  />
                  <AuthRoute path="/support-bot/train" component={ChatbotDataSourcePage} />
                  <AuthRoute path="/support-bot/training" component={TrainingPageModulePage} />
                  <AuthRoute path="/support-bot/data-source" component={TrainingPageModulePage} />
                  <AuthRoute path="/support-bot/test" component={TestChatBotPage} />
                  <AuthRoute path="/support-bot/customization" component={CustomizationBotPage} />
                  <AuthRoute path="/support-bot/install" component={InstallBotPage} />
                  <AuthRoute path="/support-bot/ask" component={AskAIPage} />
                  {/* Agent Pages Routes Starts */}
                  <AuthRoute exact path="/support-bot/agents" component={AgentListPage} />
                  <AuthRoute
                    exact
                    path="/support-bot/agents/create"
                    component={(props) => <AgentCreatePage {...props} mode="create" />}
                  />
                  <AuthRoute
                    exact
                    path="/support-bot/agents/edit/:actionId"
                    component={(props) => (
                      <AgentCreatePage
                        {...props}
                        mode="edit"
                        actionId={props.match.params.actionId}
                      />
                    )}
                  />
                  <AuthRoute
                    path="/support-bot/conversation-feed"
                    component={ConversationBotPage}
                  />
                  <AuthRoute
                    path="/support-bot/settings/data-source"
                    component={TrainingPageModulePage}
                  />
                  <AuthRoute path="/support-bot/settings/test" component={TestChatBotPage} />
                  <AuthRoute
                    path="/support-bot/settings/customization"
                    component={CustomizationBotPage}
                  />
                  <AuthRoute path="/support-bot/settings/install" component={InstallBotPage} />
                  <AuthRoute path="/support-bot/setup/slack-bot" component={SlackBotPage} />
                  <AuthRoute path="/support-bot/setup/chrome-app" component={ChromeBotPage} />
                  <AuthRoute path="/support-bot/setup/web-chat" component={WebBotPage} />
                  <AuthRoute path="/support-bot/setup/discord-bot" component={DiscordBotPage} />
                  <AuthRoute path="/support-bot/setup/ms-teams" component={MSTeamsBotPage} />
                  <AuthRoute path="/support-bot/insights" component={BotAnalyticsPage} />
                  <AuthRoute path="/support-bot/widget" component={WidgetTestPage} />
                  <AuthRoute
                    path="/support-bot/conversations/all"
                    component={BotAllConversationsPage}
                  />
                  <AuthRoute
                    path="/support-bot/conversations/open"
                    component={BotOpenConversationsPage}
                  />
                  <AuthRoute
                    path="/support-bot/conversations/resolved"
                    component={BotResolvedConversationsPage}
                  />
                  <AuthRoute path="/support-bot/conversations" component={BotConversationsPage} />
                  <AuthRoute
                    path="/support-bot/agent-assist/customization"
                    component={AgentAssistCustomizePgae}
                  />
                  <AuthRoute
                    path="/support-bot/agent-assist/install"
                    component={AgentAssistInstallPgae}
                  />
                  <AuthRoute
                    path="/support-bot/agent-assist/test"
                    component={AgentAssistTestPage}
                  />
                  <AuthRoute path="/support-bot" component={ChatBotPage} />
                  <AuthRoute path="/support-bot/settings" component={ChartBotSettingsPage} />
                </Switch>
              </Route>

              <Route
                exact
                path={[
                  '/signup',
                  '/login',
                  '/settings/integrations/select',
                  '/settings/integrations/create',
                  '/setup/configuration',
                  '/accounts/password/reset',
                  '/email/unsubscribe',
                ]}>
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <AuthRoute path="/signup" component={Signup} />
                    <AuthRoute path="/login" component={AuthenticationUser} />
                    <AuthRoute
                      path="/settings/integrations/create"
                      component={CreateIntegrationPage}
                    />
                    <AuthRoute
                      path="/settings/integrations/select"
                      component={SelectIntegrationPage}
                    />
                    <AuthRoute path="/accounts/password/reset" component={ResetPasswordPage} />
                    <AuthRoute path="/email/unsubscribe" component={UnsubscribeEmailDomainPage} />
                    <AuthRoute path="/setup/configuration" component={SetupConfigPage} />
                  </Switch>
                </PresentationLayout>
              </Route>

              {/* <Route path="*">
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <AuthRoute path="*" component={NoPath404} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route> */}
            </Switch>
          </Suspense>
        </LeftSidebar>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
