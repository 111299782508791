import emailLogo from 'assets/images/icons/emailLogo.svg';
import slack from 'assets/images/icons/slack.svg';
import discord from 'assets/images/icons/discord.svg';
import discourse from 'assets/images/icons/discourse.svg';
import twitter from 'assets/images/icons/twitter.svg';
import github from 'assets/images/icons/github.svg';
import slackGrey from 'assets/images/icons/slackGrey.svg';
import discordGrey from 'assets/images/icons/discordGrey.svg';
import twitterGrey from 'assets/images/icons/twitterGrey.svg';
import githubGrey from 'assets/images/icons/githubGrey.svg';
import discourseGrey from 'assets/images/icons/discordGrey.svg';
import webLogo from 'assets/images/icons/web.svg';
import zendeskLogo from 'assets/images/icons/zendesk.svg';
import confluenceLogo from 'assets/images/icons/confluence.svg';
import freshdesk from 'assets/images/icons/freshdesk.svg';
import notion from 'assets/images/icons/notion.svg';
import chromeApp from 'assets/images/icons/chromeApp.svg';
import intercom from 'assets/images/icons/intercom.svg';
import hubspot from 'assets/images/icons/hubspotIcon-new.svg';
import googleDrive from 'assets/images/icons/googleDrive.svg';
import msTeams from 'assets/images/trainingPage/teamsModuleIcon.svg';
import salesForceModuleIcon from 'assets/images/trainingPage/salesForceModuleIcon.svg';

export const platformsMapper = {
  slack: slack,
  slackInternal: slack,
  discord: discord,
  discourse: discourse,
  twitter: twitter,
  github: github,
  slackGrey: slackGrey,
  discordGrey: discordGrey,
  discourseGrey: discourseGrey,
  twitterGrey: twitterGrey,
  githubGrey: githubGrey,
  email: emailLogo,
  web: webLogo,
  zendesk: zendeskLogo,
  confluence: confluenceLogo,
  freshdesk: freshdesk,
  notion: notion,
  chrome: chromeApp,
  intercom: intercom,
  hubspot: hubspot,
  google_drive: googleDrive,
  ms_teams: msTeams,
  salesforce: salesForceModuleIcon,
};
