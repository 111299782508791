import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { colorToken } from '../theme/designToken';

export const PaymentsModalTopContainer = styled.div`
  background-color: #ffefbe;
  position: relative;
  display: flex;
  padding: 20px 50px 75px 50px;
  justifycontent: center;
  flex-direction: column;
  align-items: center;
  min-width: 560px;
`;

export const PaymentsModalBottomContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 24px 24px;
  margin-bottom: 0;
  position: relative;
`;

export const PaymentsFeaturesBox = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  top: -80px;
  min-width: 510px;
  margin-bottom: 20px;
`;

export const PaymentsFeaturesBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;
export const PaymentsFeaturesListContainer = styled.div`
  padding: 20px;
`;

export const PaymentsFeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
export const PaymentsGreyText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #575757;
  margin-top: 20px;
  width: 70%;
  text-align: center;
`;
export const PriceFeatText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: ${colorToken.colors.blackTwo};
`;

export const PaymentTitleText = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: ${colorToken.colors.black};
`;

export const PaymentPriceText = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: ${colorToken.colors.black};
`;

export const PurpleTextBold = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: ${colorToken.colors.purpleText};
`;

export const NoOwnerDisplay = styled.div`
  background-color: ${colorToken.colors.white};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #575757;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

export const PlanCardTooltipText = styled.span`
  white-space: pre-line;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #3e485a;
`;

export const UsageTitleText = styled(Typography)`
  color: #464646;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
`;

export const UsageTitleValueText = styled(Typography)`
  color: #191919;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

export const UsageTextHeader = styled(Typography)`
  color: #191919;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
  margin-left: 20px;
`;