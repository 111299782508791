import {
  GET_API_KEYS_LIST,
  GET_API_KEY_BY_ID,
  SET_API_KEYS_LOADING,
  SET_API_KEY_BY_ID_LOADING,
  IS_DELETE_LOADING_API_KEY_BY_ID,
  SET_CREATE_UPDATE_API_KEY_LOADING,
} from 'redux/securitySettingsProvider/type.js';

const initialState = {
  apiKeysList: null,
  apiKeyById: null,
  isApiKeyByIdLoading: false,
  isApiKeysListLoading: false,
  isGeneratingApiKeysLoading: false,
  isApiKeyDeleteLoading: false,
};

const securitySettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_API_KEYS_LIST:
      return {
        ...state,
        apiKeysList: payload,
        isApiKeysListLoading: false,
      };
    case SET_API_KEYS_LOADING:
      return {
        ...state,
        isApiKeysListLoading: payload,
      };
    case GET_API_KEY_BY_ID:
      return {
        ...state,
        apiKeyById: payload,
        isApiKeyByIdLoading: false,
      };
    case SET_API_KEY_BY_ID_LOADING:
      return {
        ...state,
        isApiKeyByIdLoading: payload,
      };
    case SET_CREATE_UPDATE_API_KEY_LOADING:
      return {
        ...state,
        isGeneratingApiKeysLoading: payload,
      };
    case IS_DELETE_LOADING_API_KEY_BY_ID:
      return {
        ...state,
        isApiKeyDeleteLoading: payload,
      };
    default:
      return state;
  }
};

export default securitySettingsReducer;
