const OAUTH2_REDIRECT_URI = '/community';

export const DISCORD_SIGN_IN_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/sign-in/discord';

export const SLACK_SIGN_IN_URL = `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/sign-in/slack';

export const GOOGLE_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` +
  '/oauth2/authorize/google?redirect_uri=' +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const TWITTER_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth1/integration/twitter?';

export const DISCORD_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` +
  '/oauth2/integration/discord?redirect_uri=' +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const ZOOM_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` +
  '/oauth2/integration/zoom?redirect_uri=' +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const GITHUB_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` +
  '/api/github/{communityId}/integration?redirect_uri=' +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const SLACK_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` +
  '/oauth2/integration/slack?redirect_uri=' +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const SLACK_INTERNAL_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` +
  '/oauth2/integration/slackInternal?redirect_uri=' +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const HUBSPOT_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` +
  '/oauth2/integration/hubspot?redirect_uri=' +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const HUBSPOT_AUTH_URL_FUNCTION = (communityId) =>
  `${process.env.REACT_APP_API_BASE_URL}` +
  `/oauth2/integration/hubspot?community_id=${communityId}&is_from_create=false&redirect_uri=` +
  `${process.env.REACT_APP_APP_BASE_URL}` +
  OAUTH2_REDIRECT_URI;

export const STAFF_CONNECT_SLACK_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/staff-connect/slack';

export const STAFF_CONNECT_DISCORD_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/staff-connect/discord';

export const DISCOURSE_CONNECT_INTERNAL_URL = `${process.env.REACT_APP_APP_BASE_URL}/settings/integrations`;

export const CONFLUENCE_CONNECT_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/integration/confluence';

export const ZENDESK_CONNECT_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/integration/zendesk';

export const NOTION_CONNECT_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/integration/notion';

export const INTERCOM_CONNECT_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/integration/intercom';

export const GOOGLE_DRIVE_CONNECT_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/integration/google_drive';

  export const MS_TEAMS_CONNECT_AUTH_URL =
  `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/integration/ms_teams';

// Internal Routing URLS
export const MESSAGE_CONTENT_SCHEDULER_URL = '/support';
export const MESSAGE_SUPPORT_URL = '/support';
export const ACTIVITY_SUMMARY_URL = '/activity/summary';
export const MESSAGE_MESSAGE = '/messages';

//Threado tokens calculation URL
export const THREADO_TOKENS_CALCULATION_DOC_URL =
  'https://threado.notion.site/threado/How-Threado-Tokens-get-calculated-5855ee9f4efd4abca610858e8478b57a';

// Todo : Add support to variable
export const GOOGLE_SIGNIN_URL = `${process.env.REACT_APP_API_BASE_URL}` + '/oauth2/sign-in/google';

export const DISCORD_INTERACTION_END_POINT = (guildId) =>
  `${process.env.REACT_APP_API_BASE_URL}` + `/integration/discord/${guildId}/webhook`;

export const DISCORD_CONNECT_TO_SERVER = (appId) =>
  `https://discord.com/oauth2/authorize?client_id=${appId}&permissions=1507298897111&scope=identify%20email%20guilds%20bot`;

export const PAYMENTS_REDIRECTION_URL = (orgId, productIdName, priceId, clientReferenceId) =>
  `${process.env.REACT_APP_API_BASE_URL}/api/subscription/${orgId}/upgrade?product=${productIdName}&priceId=${priceId}&clientReferenceId=${clientReferenceId}`;

//Threado automation help article URL
export const THREADO_AUTOMATION_TRIGGER_HELP_ARTICLE =
  'https://help.threado.com/en/articles/6416629-how-does-a-community-member-enter-and-exit-an-automation-workflow';

export const THREADO_PLAN_DETAILS_URL = 'https://www.threado.com/pricing';

export const THREADO_BOT_PLAN_DETAILS_URL = 'https://www.threado.com/pricing';

export const THREADO_DATA_TRAIN_URL =
  `${process.env.REACT_APP_APP_BASE_URL}` + '/support-bot/data-source';

export const chromeAgentAssistExtensionUrl = `https://chromewebstore.google.com/detail/threado-ai%E2%80%99s-agent-assist/ngndndcjmcicodoeblfoadeinbfphoil`;

export const INSTALL_THREADO_FRESHDESK_APP_URL = `https://www.freshworks.com/apps/threadoai/`;
