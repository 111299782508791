import * as React from 'react';
import { useEffect, useState } from 'react';
import { CommunityText, ItemTitle } from './CommunityStyle';
import MatTextFieldWrapper from 'commonComponents/MatTextFieldWrapper';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import {
  CustomDialog,
  DialogActionContainer,
  DialogContainer,
  DialogContent,
  DialogDeleteButton,
  DialogFieldContainer,
  DialogHeader,
  ErrorMsgAuth,
  PrimaryButton,
  Image,
  BackNavButton,
} from 'styledComponents/commonStyled';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../context/auth/AuthContext';
import { CommunityData } from 'redux/communityProvider/reducers/communityReducer';
import { useHistory, useLocation } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { communitySchema, submitFormAction, submitFormUpdateAction } from './util';
import LoadingComponent from 'commonComponents/LoadingComponent';
import { useBooleanState } from 'utils/dataStateHook';
import { MenuItem } from '@material-ui/core';
import MatSelectFieldWrapper from 'commonComponents/MatSelectFieldWrapper';
import { timeZoneOptions } from 'constant/optionsConstant';
import { trackUserEvent } from 'utils/segment';
import Mbgm from 'assets/images/media/maintenanceBgm.svg';

import { useGetUserInfo } from '../../context/hooks';
import Tooltip from '@mui/material/Tooltip';
import { getSignupCommunityDetails } from 'redux/communityProvider/actions/communityAction';

const CreateCommunityModal = ({
  open,
  handleClose,
  openDeleteModal,
  communityData,
  isFromCreate,
}) => {
  const communityInitialValues = {
    communityName: '',
    communityDescription: '',
    communityTimezone: '',
    organisationId: '',
    organisationName: '',
  };
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { reloadUser } = useAuth();
  const router = useHistory();
  const isLoading = useBooleanState(false);

  const userInfo = useSelector(useGetUserInfo);
  const track = (eventName, content) => {
    trackUserEvent(eventName, {
      ...content,
    });
  };

  const [errorMsg, setErrorMsg] = useState('');

  const submitForm = () => {
    setErrorMsg('');
    track('Clicked on Create Community', {});
    if (!isEmpty(formik.errors)) {
      return;
    }
    isLoading.setTrue();
    if (openDeleteModal && communityData && communityData.id && communityData?.org?.id) {
      submitFormUpdateAction(formik.values, dispatch, communityData && communityData.id)
        .then(() => {
          reloadUser();
          handleClose();
          communityData &&
            dispatch(getSignupCommunityDetails(communityData.id, communityData?.org.id, addToast));
        })
        .catch((err) => {
          setErrorMsg(err.response?.data?.reason);
        })
        .finally(() => {
          isLoading.setFalse();
        });
    } else {
      submitFormAction(formik.values, dispatch, openDeleteModal)
        .then(() => {
          reloadUser();
          handleClose();
          !openDeleteModal && router.push({ pathname: '/settings/integrations/select' });
        })
        .catch((err) => {
          setErrorMsg(err.response?.data?.reason);
          addToast('An error occurred while saving community', {
            appearance: 'error',
          });
        })
        .finally(() => {
          isLoading.setFalse();
        });
    }
  };
  const formik = useFormik({
    initialValues: communityInitialValues,
    validationSchema: communitySchema,
    validateOnMount: true,
    onSubmit: submitForm,
  });
  const { setFieldValue } = formik;
  useEffect(() => {
    setFieldValue('organisationName', communityData && communityData.org && communityData.org.name);

    setFieldValue('organisationId', communityData && communityData.org && communityData.org.id);
    if ((!communityData && open) || isFromCreate) {
      setFieldValue('communityDescription', '');
      setFieldValue('communityName', '');
      setFieldValue('communityTimezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      return;
    }
    if (communityData && open && !isFromCreate) {
      setFieldValue('communityName', communityData.name);
      setFieldValue('communityDescription', communityData.description);
      setFieldValue(
        'communityTimezone',
        communityData.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
    }
  }, [open, communityData, setFieldValue]);

  return (
    <CustomDialog
      open={open}
      BackdropProps={{
        style: {
          backgroundImage: `url(${Mbgm.src})`,
          backgroundColor: '#FFFFFF',
          backgroundRepeat: 'repeat',
        },
      }}>
      <DialogContainer>
        <BackNavButton
          style={{ border: 'none', marginLeft: '12px', marginTop: '12px' }}
          onClick={handleClose}>
          <Image src="/icons/backArrow.svg" width={16} height={16} alt="back" />
          Back
        </BackNavButton>
        <DialogHeader>
          <div style={{ paddingTop: 10 }}>
            {openDeleteModal ? (
              <CommunityText>Edit Workspace</CommunityText>
            ) : (
              <Image src="/images/group.svg" alt="group" width={223} height={120} />
            )}
          </div>
        </DialogHeader>

        <DialogContent>
          {!openDeleteModal && (
            <CommunityText style={{ textAlign: 'center', marginBottom: 20 }}>
              Get started with setting up your <br /> workspace dashboard
            </CommunityText>
          )}
          {errorMsg && (
            <ErrorMsgAuth>
              <span style={{ marginRight: '4px' }}>
                <Image src="/icons/errorMessageIcon.svg" alt="error" width={18} height={18} />
              </span>
              {errorMsg}
            </ErrorMsgAuth>
          )}
          <DialogFieldContainer style={{ display: 'none' }}>
            <ItemTitle className="required">Organisation Id</ItemTitle>
            <MatTextFieldWrapper
              name={'organisationId'}
              formik={formik}
              variant="outlined"
              placeholder=""
              size={'small'}
              autoComplete="organisationId"
              multiline
              fullWidth
            />
          </DialogFieldContainer>
          <DialogFieldContainer>
            <ItemTitle className="required">Organisation Name</ItemTitle>
            <MatTextFieldWrapper
              name={'organisationName'}
              formik={formik}
              variant="outlined"
              placeholder=""
              size={'small'}
              autoComplete="organisationName"
              multiline
              fullWidth
              disabled={communityData && communityData.org && communityData.org.name ? true : false}
            />
          </DialogFieldContainer>
          <DialogFieldContainer>
            <ItemTitle className="required">Workspace Name</ItemTitle>
            <MatTextFieldWrapper
              name={'communityName'}
              formik={formik}
              variant="outlined"
              placeholder=""
              size={'small'}
              autoComplete="communityName"
              fullWidth
            />
          </DialogFieldContainer>
          <DialogFieldContainer style={{ display: 'none' }}>
            <ItemTitle className="required">Timezone</ItemTitle>
            <MatSelectFieldWrapper
              fullWidth
              formik={formik}
              name={'communityTimezone'}
              autoComplete="timezone"
              size="small"
              placeholder="Select Time Zone">
              {timeZoneOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </MatSelectFieldWrapper>
          </DialogFieldContainer>
          <DialogActionContainer
            style={{ justifyContent: openDeleteModal ? 'space-between' : 'center' }}>
            {openDeleteModal &&
              (userInfo && userInfo.communities.length > 1 ? (
                <DialogDeleteButton
                  onClick={() => {
                    track('Clicked on Delete Workspace', {});
                    handleClose();
                    openDeleteModal();
                  }}>
                  <Image src="/icons/trash.svg" alt="delete" width={14} height={14} />
                  <div>Delete Workspace</div>
                </DialogDeleteButton>
              ) : (
                <>
                  <Tooltip title="Primary workspace can't be deleted. Add another workspace and try deleting this.">
                    <DialogDeleteButton style={{ color: 'grey' }}>
                      <Image src="/icons/trash.svg" alt="delete" width={14} height={14} />
                      <div>Delete Workspace</div>
                    </DialogDeleteButton>
                  </Tooltip>
                </>
              ))}
            <LoadingComponent isLoading={isLoading.value}>
              <PrimaryButton
                onClick={() => formik.submitForm()}
                boxshadow="true"
                padding="12px 30px">
                {openDeleteModal ? 'Save Changes' : 'Create Workspace'}
              </PrimaryButton>
            </LoadingComponent>
          </DialogActionContainer>
        </DialogContent>
      </DialogContainer>
    </CustomDialog>
  );
};

export default CreateCommunityModal;
