import { pageSize } from 'constant/paginationConstants';
import {
  GET_NOTIFICATIONS_DATA,
  LOADING_NOTIFICATIONS,
  NOTIFICATIONS_ERROR_HANDLE,
  GET_DETAILED_NOTIFICATION_DATA,
  GET_DETAILED_NOTIFICATION_CHANNEL_LOAD_DATA,
  LOADING_DETAILED_NOTIFICATION,
  DETAILED_NOTIFICATION_ERROR_HANDLE,
  SET_NOTIFICATION_SETTINGS,
} from '../types';

export const initialState = {
  loggingNotificationsData: {
    content: [],
    size: pageSize,
  },
  loggingNotificationsLoading: false,
  loggingNotificationDetailedLoading: false,
  loggingNotificationDetailedData: {},
  loggingNotificationDetailedChannelLoadData: {},
  loggingNotificationsDataFetchFail: false,
};

export const notificationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATIONS_DATA: {
      return {
        ...state,
        loggingNotificationsData: payload,
        loggingNotificationsLoading: false,
        loggingNotificationsDataFetchFail: false,
      };
    }
    case GET_DETAILED_NOTIFICATION_DATA: {
      return {
        ...state,
        loggingNotificationDetailedData: payload,
        loggingNotificationDetailedLoading: false,
      };
    }
    case GET_DETAILED_NOTIFICATION_CHANNEL_LOAD_DATA: {
      return {
        ...state,
        loggingNotificationDetailedChannelLoadData: payload,
        loggingNotificationDetailedLoading: false,
      };
    }
    case LOADING_NOTIFICATIONS: {
      return {
        ...state,
        loggingNotificationsLoading: true,
        loggingNotificationsDataFetchFail: false,
      };
    }
    case LOADING_DETAILED_NOTIFICATION: {
      return {
        ...state,
        loggingNotificationDetailedLoading: true,
      };
    }
    case NOTIFICATIONS_ERROR_HANDLE: {
      return {
        ...state,
        toastMessage: payload,
        loggingNotificationsLoading: false,
        loggingNotificationsDataFetchFail: true,
      };
    }
    case DETAILED_NOTIFICATION_ERROR_HANDLE: {
      return {
        ...state,
        toastMessage: payload,
        loggingNotificationDetailedLoading: false,
      };
    }
    case SET_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        notificationSettings: payload,
      };
    }
    default:
      return state;
  }
};
