import { object, string } from 'yup';
import { formatDistanceStrict, parseISO } from 'date-fns';

export const cancellationReasonsMapper = {
  LACK_OF_BUDGET: 'Lack of budget',
  BUSINESS_SHUTDOWN: 'My business is shutting down',
  FEATURES_MISSING: 'Some critical features were missing',
  COMPETITOR_PRODUCT: 'Moving to a competitor product',
  NOT_FIND_USEFUL: 'Didn’t find product useful',
  OTHERS: 'Others',
};

export const cancelReasonInitialValue = {
  cancelReason: '',
};

export const cancelReasonSchema = object().shape({
  cancelReason: string().required('Enter your comments'),
});

export const getDaysInFuture = (timestamp) => {
  if (timestamp) {
    return formatDistanceStrict(parseISO(timestamp), new Date());
  }
  return 'NA';
};

export const getDaysInFutureFloor = (timestamp) => {
  if (timestamp) {
    return formatDistanceStrict(parseISO(timestamp), new Date(), {
      roundingMethod: 'floor',
    });
  }
  return 'NA';
};

export const SubscriptionStatusType = {
  PAID: 'PAID',
  FREE: 'FREE',
  TRIAL: 'TRIAL',
  CANCELLED: 'CANCELLED',
};

export const RecurringSubscriptionType = {
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL',
  QUARTERLY: 'QUARTERLY',
};


export const openThreadoAIWidget = () => {
  window._hw && window._hw('event', 'open');
}