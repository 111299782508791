import moment from 'moment';
import React from 'react';
import { CurrentPlanBoldText } from 'styledComponents/settingsStyled';
import {
  BannerContainer,
  BannerContainerFreePlan,
  BannerMessagePayments,
  TextFreePlanBanner,
} from 'styledComponents/launchBannerStyled';
import { workspaceValueRoleMapper } from 'constant/staffRoleData';

const LaunchBannerCancelledPlan = ({ paymentsPlanStatus, communityRole }) => {
  return (
    <>
      {communityRole === workspaceValueRoleMapper.ORGANIZATION_OWNER ? (
        <div>
          <BannerContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '4px',
                backgroundColor: '#FFFFFF',
              }}>
              <BannerMessagePayments style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                {`Subscription is cancelled. You will be moved to free plan automatically on `}
                &nbsp;
                <CurrentPlanBoldText style={{ fontSize: '12px' }}>
                  {moment(new Date(paymentsPlanStatus?.subscriptionEnd)).format('DD MMM YY')}
                </CurrentPlanBoldText>
              </BannerMessagePayments>
            </div>
          </BannerContainer>
        </div>
      ) : (
        <BannerContainerFreePlan>
          <TextFreePlanBanner style={{ margin: '0 10px' }}>
            {`Subscription is cancelled. You will be moved to free plan automatically on `}
            &nbsp;
            <span style={{ fontWeight: 700 }}>
              {moment(new Date(paymentsPlanStatus?.subscriptionEnd)).format('DD MMM YY')}
            </span>
          </TextFreePlanBanner>
        </BannerContainerFreePlan>
      )}
    </>
  );
};

export default LaunchBannerCancelledPlan;
