export const GET_DATA_SOURCE_DETAILS = 'GET_DATA_SOURCE_DETAILS';
export const GET_DATA_SOURCE_STATUS_DETAILS = 'GET_DATA_SOURCE_STATUS_DETAILS';
export const GET_DATA_SOURCE_DETAILS_LOADING = 'GET_DATA_SOURCE_DETAILS_LOADING';
export const GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_DETAILS =
  'GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_NAME_DETAILS';
export const GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_LOADING =
  'GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_LOADING';
export const ERROR_DATA_SOURCE_DETAILS_BY_INTEGRATION = 'ERROR_DATA_SOURCE_DETAILS_BY_INTEGRATION';

export const GET_DATA_SOURCE_DETAILS_CONFLUENCE = 'GET_DATA_SOURCE_DETAILS_CONFLUENCE';
export const GET_DATA_SOURCE_DETAILS_CONFLUENCE_LOADING =
  'GET_DATA_SOURCE_DETAILS_CONFLUENCE_LOADING';
export const ERROR_DATA_SOURCE_DETAILS_CONFLUENCE = 'ERROR_DATA_SOURCE_DETAILS_CONFLUENCE';

export const GET_DATA_SOURCE_DETAILS_FRESHDESK = 'GET_DATA_SOURCE_DETAILS_FRESHDESK';
export const GET_DATA_SOURCE_DETAILS_FRESHDESK_LOADING =
  'GET_DATA_SOURCE_DETAILS_FRESHDESK_LOADING';
export const ERROR_DATA_SOURCE_DETAILS_FRESHDESK = 'ERROR_DATA_SOURCE_DETAILS_FRESHDESK';

export const GET_DATA_SOURCE_DETAILS_NOTION = 'GET_DATA_SOURCE_DETAILS_NOTION';
export const GET_DATA_SOURCE_DETAILS_NOTION_LOADING = 'GET_DATA_SOURCE_DETAILS_NOTION_LOADING';
export const ERROR_DATA_SOURCE_DETAILS_NOTION = 'ERROR_DATA_SOURCE_DETAILS_NOTION';

export const ERROR_DATA_SOURCE_DETAILS = 'ERROR_DATA_SOURCE_DETAILS';
export const SET_DATA_SOURCE_DETAILS = 'SET_DATA_SOURCE_DETAILS';
export const SET_DATA_SOURCE_DETAILS_LOADING = 'SET_DATA_SOURCE_DETAILS_LOADING';
export const ERROR_SET_DATA_SOURCE_DETAILS = 'ERROR_SET_DATA_SOURCE_DETAILS';
export const SEND_TEST_CHATBOT_MESSAGE = 'SEND_TEST_CHATBOT_MESSAGE';
export const SEND_TEST_CHATBOT_MESSAGE_LOADING = 'SEND_TEST_CHATBOT_MESSAGE_LOADING';
export const ERROR_SEND_TEST_BOT_MESSAGE = 'ERROR_SEND_TEST_BOT_MESSAGE';
// Bot customization
export const GET_BOT_CUSTOMIZATION_DETAILS = 'GET_BOT_CUSTOMIZATION_DETAILS';
export const SET_BOT_CUSTOMIZATION_DETAILS = 'SET_BOT_CUSTOMIZATION_DETAILS';
export const GET_BOT_CUSTOMIZATION_DETAILS_LOADING = 'GET_BOT_CUSTOMIZATION_DETAILS_LOADING';
export const GET_BOT_CUSTOMIZATION_DETAILS_ERROR = 'GET_BOT_CUSTOMIZATION_DETAILS_ERROR';
export const SET_BOT_CUSTOMIZATION_DETAILS_LOADING = 'SET_BOT_CUSTOMIZATION_DETAILS_LOADING';
export const SET_BOT_CUSTOMIZATION_DETAILS_ERROR = 'SET_BOT_CUSTOMIZATION_DETAILS_ERROR';
export const SET_CHATBOT_STATUS = 'SET_CHATBOT_STATUS';
export const GET_CHATBOT_SETTINGS = 'GET_CHATBOT_SETTINGS';

export const GET_QUESTION_PRIVATE_SETTINGS = 'GET_QUESTION_PRIVATE_SETTINGS';

export const GET_CHATBOT_LISTEN = 'GET_CHATBOT_LISTEN';
export const SET_CHATBOT_LISTEN = 'SET_CHATBOT_LISTEN';
export const SET_PDF_LIST_AFTER_DELETE = 'SET_PDF_LIST_AFTER_DELETE';
export const SET_CRAWL_URL_LIST_AFTER_DELETE = 'SET_CRAWL_URL_LIST_AFTER_DELETE';
export const SET_TABULAR_DATA_LIST_AFTER_DELETE = 'SET_TABULAR_DATA_LIST_AFTER_DELETE';
export const GET_CHILD_URLS = 'GET_CHILD_URLS';
export const GET_CHILD_URLS_LIST = 'GET_CHILD_URLS_LIST';
export const SET_CHILD_URLS_AFTER_DELETE = 'SET_CHILD_URLS_AFTER_DELETE';

export const GET_BOT_STATIC_DATA = 'GET_BOT_STATIC_DATA';
export const SET_BOT_PROVIDER_FILTER = 'SET_BOT_PROVIDER_FILTER';

export const GET_BOT_ACTIVITY_LOADING = 'GET_BOT_ACTIVITY_LOADING';
export const GET_BOT_ACTIVITY = 'GET_BOT_ACTIVITY';
export const GET_BOT_ACTIVITY_ERROR = 'GET_BOT_ACTIVITY_ERROR';
export const GET_WIDGET_EMBED_CODE_SNIPPET_DATA = 'GET_WIDGET_EMBED_CODE_SNIPPET_DATA';
export const SET_CRAWL_URL_AFTER_TRAIN = 'SET_CRAWL_URL_AFTER_TRAIN';

export const GET_ZENDESK_TICKETS_ASSIGNEE_LIST = 'GET_ZENDESK_TICKETS_ASSIGNEE_LIST';
export const GET_ZENDESK_TICKETS_TAGS_LIST = 'GET_ZENDESK_TICKETS_TAGS_LIST';
export const SET_TRAIN_TICKETS_FILTER = 'SET_TRAIN_TICKETS_FILTER';
export const GET_ZENDESK_TICKETS_GROUPS_LIST = 'GET_ZENDESK_TICKETS_GROUPS_LIST';

export const GET_FRESHDESK_TICKETS_ASSIGNEE_LIST = 'GET_FRESHDESK_TICKETS_ASSIGNEE_LIST';
export const GET_FRESHDESK_TICKETS_TAGS_LIST = 'GET_FRESHDESK_TICKETS_TAGS_LIST';
export const SET_FRESHDESK_TRAIN_TICKETS_FILTER = 'SET_FRESHDESK_TRAIN_TICKETS_FILTER';
export const GET_FRESHDESK_TICKETS_GROUPS_LIST = 'GET_FRESHDESK_TICKETS_GROUPS_LIST';

export const GET_INTERCOM_TICKETS_ASSIGNEE_LIST = 'GET_INTERCOM_TICKETS_ASSIGNEE_LIST';
export const GET_INTERCOM_TICKETS_TAGS_LIST = 'GET_INTERCOM_TICKETS_TAGS_LIST';
export const SET_INTERCOM_TRAIN_TICKETS_FILTER = 'SET_INTERCOM_TRAIN_TICKETS_FILTER';
export const GET_INTERCOM_TICKETS_GROUPS_LIST = 'GET_INTERCOM_TICKETS_GROUPS_LIST';

export const SET_INSTALL_CHANNELS_LIST = 'SET_INSTALL_CHANNELS_LIST';

export const GET_HUBSPOT_TICKETS_ASSIGNEE_LIST = 'GET_HUBSPOT_TICKETS_ASSIGNEE_LIST';
export const GET_HUBSPOT_TICKETS_TAGS_LIST = 'GET_HUBSPOT_TICKETS_TAGS_LIST';
export const SET_HUBSPOT_TRAIN_TICKETS_FILTER = 'SET_HUBSPOT_TRAIN_TICKETS_FILTER';
export const GET_HUBSPOT_TICKETS_GROUPS_LIST = 'GET_HUBSPOT_TICKETS_GROUPS_LIST';

export const GET_SALESFORCE_TICKETS_ASSIGNEE_LIST = 'GET_SALESFORCE_TICKETS_ASSIGNEE_LIST';
export const GET_SALESFORCE_TICKETS_TAGS_LIST = 'GET_SALESFORCE_TICKETS_TAGS_LIST';
export const SET_SALESFORCE_TRAIN_TICKETS_FILTER = 'SET_SALESFORCE_TRAIN_TICKETS_FILTER';
export const GET_SALESFORCE_TICKETS_GROUPS_LIST = 'GET_SALESFORCE_TICKETS_GROUPS_LIST';

// Add Edit Sidebar - Slack
export const IS_ADD_EDIT_SIDEBAR_OPEN = 'IS_ADD_EDIT_SIDEBAR_OPEN';
