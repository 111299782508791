export const GET_OUTREACH_REPORT_BY_ID = 'GET_OUTREACH_REPORT_BY_ID';
export const SET_OUTREACH_REPORT_BY_ID_LOADING =
  'SET_OUTREACH_REPORT_BY_ID_LOADING';
export const HANDLE_OUTREACH_REPORT_BY_ID_ERROR =
  'HANDLE_OUTREACH_REPORT_BY_ID_ERROR';

export const GET_OUTREACH_REPORT_MEMBER_DATA =
  'GET_OUTREACH_REPORT_MEMBER_LIST';
export const SET_OUTREACH_REPORT_MEMBER_DATA_LOADING =
  'SET_OUTREACH_REPORT_MEMBER_LIST_LOADING';
export const HANDLE_OUTREACH_REPORT_MEMBER_DATA_ERROR =
  'HANDLE_OUTREACH_REPORT_MEMBER_LIST_ERROR';