import {
  GET_YEAR_END_REVIEW_DATA,
  SET_YEAR_END_REVIEW_DATA_LOADING,
  HANDLE_YEAR_END_REVIEW_DATA_ERROR,
} from '../types';

const initialState = {
  yearEndReviewData: null,
  isYearEndReviewLoading: false,
  errorFetchingYearEndReviewData: false,
};

const yearEndReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_YEAR_END_REVIEW_DATA:
      return {
        ...state,
        yearEndReviewData: payload,
        isYearEndReviewLoading: false,
        errorFetchingYearEndReviewData: false,
      };
    case SET_YEAR_END_REVIEW_DATA_LOADING:
      return {
        ...state,
        isYearEndReviewLoading: true,
        errorFetchingYearEndReviewData: false,
      };
    case HANDLE_YEAR_END_REVIEW_DATA_ERROR:
      return {
        ...state,
        errorFetchingYearEndReviewData: true,
        isYearEndReviewLoading: false,
      };
    default:
      return state;
  }
};

export default yearEndReducer;
