import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useGetCurrentCommunity, useGetUserInfo } from './hooks';
import { CheckIfFeatureActive } from './useFeature';
import { SYSTEM_FEATURE_NOTIFICATIONS } from 'constant/features';
import { getSocketApiUrl } from 'constant/apiConstants';
import SocketWrapper from './SocketWrapper';
import EventBus from '../events/EventBus';

const processMessageReceived = (message) => {
  switch (message.type) {
    case 'communityNotificationsCountChanged':
      EventBus.communityNotificationsCountChanged(message);
      break;
    case 'communitySimpleMessage':
      EventBus.communitySimpleMessage(message);
      break;
    case 'channelDataLoadedEvent':
      EventBus.channelDataloadEvent(message);
      break;
    case 'integrationDataLoadedEvent':
      EventBus.integrationDataLoadedEvent(message);
      break;
    case 'memberImportProcessedEvent':
      EventBus.memberImportProcessedEvent(message);
      break;
    case 'hubspotSyncProcessEvent':
      EventBus.hubspotSyncProcessEvent(message);
      break;
    case 'trainingStatusForWorkspace':
      EventBus.trainingStatusForWorkspace(message);
      break;
    default:
      console.warn(`WebSocket message with type=${message.type} is not supported`);
  }
};

const WebSocketStompProvider = ({ children }) => {
  const userInfo = useSelector(useGetUserInfo);
  const communityDetail = useSelector(useGetCurrentCommunity);

  if (!CheckIfFeatureActive(SYSTEM_FEATURE_NOTIFICATIONS)) {
    return children;
  }

  if (!userInfo || !communityDetail) {
    return children;
  }

  //TODO Enable channel dataload events
  const TOPICS = [
    `/user/${userInfo.id}/notification`,
    `/community/notification/${communityDetail.id}`,
  ];

  return (
    <>
      <SocketWrapper topics={TOPICS} onMessage={processMessageReceived} url={getSocketApiUrl} />
      {children}
    </>
  );
};

export default WebSocketStompProvider;
