import { formatDistanceStrict, parseISO } from 'date-fns';
import moment from 'moment';

import 'moment-timezone';
import { APP_SERVICES, discordUrl } from 'constant/features';
import { memberContentType } from './types';

export const getAvatarUrl = (externalUserId, avatarId, provider) => {
  if (APP_SERVICES.discord === provider) {
    return discordUrl + externalUserId + '/' + avatarId + '.png';
  }
  return avatarId;
};

export const segementFilter = (segmentList) => {
  if (segmentList) {
    const segementFilterString = segmentList
      .split(',')
      .filter((iter) => {
        if (!['NEW'].includes(iter)) {
          return iter;
        }
      })
      .join();
    if (segementFilterString.length > 0) {
      return segementFilterString;
    } else {
      return 'ALL';
    }
  }
  return 'ALL';
};

export const transformMemberData = (memberData, timezone) =>
  memberData.map((member) => {
    return {
      ...member,
      id: member.id,
      name: member.name || member.userName,
      joinedAt: member.joinedAt ? moment.tz(member.joinedAt, timezone).format('MMM Do yyyy') : '',
      lastActiveTime: member.lastActive
        ? formatDistanceStrict(parseISO(member.lastActive), new Date()) + ' ago'
        : 'Not Active',
      segment: member.segment,
      email: member.email,
      mentions: member.mentions,

      tags: member.tags || [],

      sessions: member.sessions,
      isAtRisk: member.isAtRisk,
    };
  });

export const getUtcDate = (selectedDate, timezone) => {
  return new Date(
    new Date(selectedDate).toLocaleString('en-US', { timeZone: timezone }),
  ).toISOString();
};
