export const SET_PROVIDER_FILTER = 'SET_PROVIDER_FILTER';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';

export const SET_MEMBERS_WIDGET_DATA = 'SET_MEMBERS_WIDGET_DATA';
export const SET_ACTIVITIES_WIDGET_DATA = 'SET_ACTIVITIES_WIDGET_DATA';
export const SET_RECENT_AUTOMATION_WIDGET_DATA = 'SET_RECENT_AUTOMATION_WIDGET_DATA';
export const SET_RECENT_OUTREACH_WIDGET_DATA = 'SET_RECENT_OUTREACH_WIDGET_DATA';

export const SET_TOP_MEMBERS_WIDGET_DATA = 'SET_TOP_MEMBERS_WIDGET_DATA';
export const SET_TOP_MESSAGES_WIDGET_DATA = 'SET_TOP_MESSAGES_WIDGET_DATA';

export const SET_CHANNEL_INSIGHT_LIST = 'SET_CHANNEL_INSIGHT_LIST';
export const SET_CHANNEL_INSIGHT_STATIC_DATA = 'SET_CHANNEL_INSIGHT_STATIC_DATA';

export const SET_TOPIC_INSIGHT_LIST = 'SET_TOPIC_INSIGHT_LIST';
export const SET_TOPIC_INSIGHT_STATIC_DATA = 'SET_TOPIC_INSIGHT_STATIC_DATA';

export const SET_NEW_MEMBERS_INSIGHT_STATIC_DATA = 'SET_NEW_MEMBERS_INSIGHT_STATIC_DATA';
export const SET_NEW_MEMBERS_INSIGHT_STATIC_DATA_LOADING =
  'SET_NEW_MEMBERS_INSIGHT_STATIC_DATA_LOADING';
export const SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA = 'SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA';
export const SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA_LOADING =
  'SET_TOTAL_MEMBERS_INSIGHT_STATIC_DATA_LOADING';
export const SET_TOP_MEMBERS_LIST_DATA = 'SET_TOP_MEMBERS_LIST_DATA';
export const SET_TOP_MEMBERS_LIST_DATA_LOADING = 'SET_TOP_MEMBERS_LIST_DATA_LOADING';
export const SET_TOP_MEMBERS_INSIGHT_STATIC_DATA = 'SET_TOP_MEMBERS_INSIGHT_STATIC_DATA';
export const SET_TOP_MEMBERS_INSIGHT_STATIC_DATA_LOADING =
  'SET_TOP_MEMBERS_INSIGHT_STATIC_DATA_LOADING';
export const SET_POTENTIAL_CHAMPIONS_LIST_DATA = 'SET_POTENTIAL_CHAMPIONS_LIST_DATA';
export const SET_POTENTIAL_CHAMPIONS_LIST_DATA_LOADING =
  'SET_POTENTIAL_CHAMPIONS_LIST_DATA_LOADING';

// At risk members
export const SET_AT_RISK_MEMBERS_DATA = 'SET_AT_RISK_MEMBERS_DATA';
export const SET_AT_RISK_MEMBERS_DATA_LOADING = 'SET_AT_RISK_MEMBERS_DATA_LOADING';

export const SET_ENGAGEMENT_INSIGHT_LIST = 'SET_ENGAGEMENT_INSIGHT_LIST';

export const GET_REPORT_RESPONSIVENESS_ERROR = 'GET_REPORT_RESPONSIVENESS_ERROR';
export const GET_REPORT_RESPONSIVENESS_LOADING = 'GET_REPORT_RESPONSIVENESS_LOADING';
export const GET_REPORT_RESPONSIVENESS = 'GET_REPORT_RESPONSIVENESS';

export const SET_RESPONSIVENESS_INSIGHT_STATIC_DATA_LOADING =
  'SET_RESPONSIVENESS_INSIGHT_STATIC_DATA_LOADING';
export const SET_RESPONSIVENESS_INSIGHT_STATIC_DATA = 'SET_RESPONSIVENESS_INSIGHT_STATIC_DATA';

export const SET_RETENTION_INSIGHT_STATIC_DATA = 'SET_RETENTION_INSIGHT_STATIC_DATA';

export const GET_REPORT_RETENTION_LOADING = 'GET_REPORT_RETENTION_LOADING';
export const GET_REPORT_RETENTION = 'GET_REPORT_RETENTION';
export const GET_REPORT_RETENTION_ERROR = 'GET_REPORT_RETENTION_ERROR';

// Slack Alert Sidebar
export const GET_SIDEBAR_STATE = 'GET_SIDEBAR_STATE';
export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';
export const SET_TYPE_OF_CHANGE = 'SET_TYPE_OF_CHANGE';
export const SET_SLACK_ALERT = 'SET_SLACK_ALERT';
export const GET_SLACK_ALERTS = 'GET_SLACK_ALERTS';
export const GET_SLACK_ALERTS_LOADING = 'GET_SLACK_ALERTS_LOADING';
export const GET_SLACK_ALERT_ERROR = 'GET_SLACK_ALERT_ERROR';
export const GET_SLACK_ALERT_BY_ID = `GET_SLACK_ALERT_BY_ID`;
export const GET_SLACK_ALERT_BY_ID_LOADING = `GET_SLACK_ALERT_BY_ID_LOADING`;
export const GET_SLACK_ALERT_BY_ID_ERROR = `GET_SLACK_ALERT_BY_ID_ERROR`;
export const UPDATE_SLACK_ALERT_BY_ID = 'UPDATE_SLACK_ALERT_BY_ID';
export const UPDATE_SLACK_ALERT_BY_ID_ERROR = 'UPDATE_SLACK_ALERT_BY_ID_ERROR';
export const UPDATE_SLACK_ALERT_BY_ID_LOADING = 'UPDATE_SLACK_ALERT_BY_ID_LOADING';

