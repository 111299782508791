import { AnyAction } from 'redux';
import {
  SET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
  RESET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN,
  SET_MEMBER_DATA_TAB_COUNT_CONCURRENT_TOKEN,
  RESET_MEMBER_DATA_TAB_COUNT_CONCURRENT_TOKEN,
} from './../types';

const initialState = {
  memberDataTable: [],
  memberDataTabCount: [],
};

const globalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN:
      return {
        ...state,
        memberDataTable: [...state.memberDataTable, payload],
      };
    case RESET_MEMBER_DATA_TABLE_CONCURRENT_TOKEN:
      return {
        ...state,
        memberDataTable: [],
      };
    case SET_MEMBER_DATA_TAB_COUNT_CONCURRENT_TOKEN:
      return {
        ...state,
        memberDataTabCount: [...state.memberDataTabCount, payload],
      };
    case RESET_MEMBER_DATA_TAB_COUNT_CONCURRENT_TOKEN:
      return {
        ...state,
        memberDataTabCount: [],
      };

    default:
      return state;
  }
};

export default globalReducer;
