import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './config/configureStore';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import Routes from './layers/route/unboundedLazyRoute';
import { ToastProvider } from 'react-toast-notifications';
import { Toast } from 'atoms/Toast/index';
import AuthProvider from 'context/auth/AuthContext';
import ErrorBoundary from 'layers/error-boundary';
import LaunchBanner from 'components/LaunchBanner/LaunchBanner';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import CommunityManager from 'context/CommunityManager';
import ProtectedRoute from 'layers/route/protectedRoute';
import WebSocketStompProvider from 'context/WebSocketStompProvider';
import EventBusGenericHandler from 'context/EventBusGenericHandler';
import 'commonComponents/Loader/Global/GlobalLoader.css';
// import { IntercomProvider } from 'react-use-intercom';

// const INTERCOM_APP_ID = `${process.env.REACT_APP_ANALYTICS_INTERCOM_KEY}`;

LicenseInfo.setLicenseKey(
  `8c7a29bb2f1fed2af8eec33d8035202eTz02MzEzNixFPTE3MTE3OTU3NDMyMDgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=`,
);

const App = () => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then(
          (registration) => {
            console.log('Service Worker registration successful with scope: ', registration.scope);
          },
          (err) => {
            console.log('Service Worker registration failed: ', err);
          },
        );
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider>
        <ToastProvider
          placement="bottom-right"
          autoDismiss={true}
          components={{ Toast }}
          autoDismissTimeout={5000}>
          <ProtectedRoute>
            {/* <IntercomProvider appId={INTERCOM_APP_ID}> */}
            <ErrorBoundary>
              <BrowserRouter basename="/">
                <LaunchBanner>
                  <CommunityManager>
                    <WebSocketStompProvider>
                      <EventBusGenericHandler>
                        <ScrollToTop>
                          <Routes />
                        </ScrollToTop>
                      </EventBusGenericHandler>
                    </WebSocketStompProvider>
                  </CommunityManager>
                </LaunchBanner>
              </BrowserRouter>
            </ErrorBoundary>
            {/* </IntercomProvider> */}
          </ProtectedRoute>
        </ToastProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
