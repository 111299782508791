import styled from '@emotion/styled';
import { Button, Paper, Typography } from '@material-ui/core';
import {
  blueColor,
  borderGreyColor,
  boxShadowColor,
  discordGraphLineColor,
  lightYellowColor,
  sidebarMenuBoxShadowColor,
  skyeBlueColor,
  slackGraphLineColor,
  tableCellBlackColor,
  tableCellGreenColor,
  tableCellLightGreenColor,
  tableCellYellowColor,
  tableHeaderColor,
  whiteColor,
  redTextActivity,
  lightRedBackgroundAcitivity,
  tableTagBackground,
  tableTagColor,
} from '../theme/colorConstants';
import { colorToken } from '../theme/designToken';

export const TotalDetail = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${borderGreyColor};
`;

export const TotalText = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 6px;
  margin-bottom: 8px;
  color: ${tableHeaderColor};
`;

export const TotalNumber = styled(Typography)`
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: ${tableCellBlackColor};
`;

export const PercentTag = styled.div`
  background: ${(props) =>
    props.direction === 'positive'
      ? tableCellLightGreenColor
      : props.direction === 'negative'
      ? lightRedBackgroundAcitivity
      : colorToken.tableHeader.primary.color};
  border-radius: 4px;
  height: 100%;
  padding: 5px 6px;
  padding: ${(props) => props.direction === 'zero' && '5px 10px'};
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) =>
    props.direction === 'positive'
      ? tableCellGreenColor
      : props.direction === 'negative'
      ? redTextActivity
      : colorToken.tableHeader.text.primary.color};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export const DiscordImage = styled.img`
  background: ${skyeBlueColor};
  border-radius: 8px;
  padding: 5px !important;
`;

export const DiscordImageWithPointer = styled(DiscordImage)`
  cursor: pointer;
`;

export const SlackImage = styled.img`
  background: ${lightYellowColor};
  border-radius: 8px;
  padding: 5px !important;
`;

export const SlackImageWithPointer = styled.img`
  cursor: pointer;
`;

export const MessageImage = styled.img``;

export const MessageImageWithPointer = styled.img`
  cursor: pointer;
`;

export const ReactionImage = styled.img``;

export const ReactionImageWithPointer = styled.img`
  cursor: pointer;
`;

export const ImageWithPointer = styled.img`
  cursor: pointer;
`;

export const SubDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const SubMembersBox = styled.div`
  display: flex;
  align-items: center;
`;

export const SubMembersText = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
  color: ${tableCellBlackColor};
`;

export const TableTitle = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #191919;
`;

export const CustomMessageBoxContainer = styled(Paper)`
  box-shadow: none;
  &:first-of-type {
    border-top: none;
  }
`;

export const CustomMessageBox = styled(Paper)`
  padding: 20px;
  margin-right: 0px;
  box-shadow: none;
  border-top: 0.5px solid #e8e8e8;
  &:hover {
    background: #f5f6fd;
  }
  &:first-child {
    border-top: none;
  }
`;

export const MessageAvatarContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 14px;
`;

export const MessageNameText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${tableCellBlackColor};
`;

export const MessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const MessageText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${tableCellBlackColor};

  p:not(:first-child) {
    display: ${(props) => (props.fromPage == 'dashboard' ? 'none' : '')};
  }

  pre {
    margin: 0;
    word-break: break-all;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'nowrap' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  p {
    margin: 0;
    word-break: break-all;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'pre' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }

  ol {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }

  code {
    display: block;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgba(224, 231, 241, 1);
    background: #efefef;
    margin: 0;
    margin-top: 5px;
    word-break: break-all;
    font-size: 13px;
    width: ${(props) => (props.fromPage == 'dashboard' ? '90%' : '')};
    white-space: ${(props) => (props.fromPage == 'dashboard' ? 'nowrap' : 'break-spaces')};
    overflow: ${(props) => (props.fromPage == 'dashboard' ? 'hidden' : '')};
    text-overflow: ${(props) => (props.fromPage == 'dashboard' ? 'ellipsis' : '')};
  }
`;

export const MessageDetailText = styled(Typography)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${tableCellGreenColor};
`;

export const MessageDetailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 14px;
`;

export const ChartBox = styled(Paper)`
  min-width: 511px;
  width: 49.2%;
  box-shadow: 0px 12px 36px ${boxShadowColor};
  border-radius: 10px;
  margin-right: 0px;
`;

export const ActivityChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 40px;
  @media (max-width: 1380px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ChartTitle = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${tableCellBlackColor};
`;

export const ChartLegendText = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  color: ${tableCellBlackColor};
`;

export const SlackLegendBox = styled.div`
  width: 14px;
  height: 4px;
  background: ${slackGraphLineColor};
  border-radius: 100px;
`;

export const DiscordLegendBox = styled.div`
  width: 14px;
  height: 4px;
  border-radius: 100px;
  background: ${discordGraphLineColor};
`;

export const ChartLegendContainer = styled.div`
  display: flex;
  align-tems: center;
  gap: 24px;
  justify-content: center;
  margin-bottom: 24px;
`;

export const ChartToolTip = styled.div`
  background: #ffffff;
  box-shadow: 0px 12px 36px ${boxShadowColor};
  border-radius: 8px;
  padding: 5px 5px;
`;

export const ToolTipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 12px;
  color: ${tableCellBlackColor};
  margin: 10px;
`;

export const FilterBorderButton = styled(Button)`
  background: #ffffff;
  box-shadow: none;
  font-weight: 600;
  border: 1px solid ${borderGreyColor};
  font-size: 12px;
  line-height: 15px;
  color: ${tableCellBlackColor};
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 15px;
  gap: 40px;
  &:hover {
    background: #ffffff;
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const CustomDateRangeStyled = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${borderGreyColor};
  border-radius: 8px;
  background-color: ${(props) =>
    props.selected ? colorToken.customDateRangeStyled.color : 'transparent'};
  margin-left: 10px;
  box-shadow: ${(props) => (props.selected ? `0px 4px 18px ${sidebarMenuBoxShadowColor}` : 'none')};
  & .DateInput_input {
    padding: 5px;
    padding-bottom: 4px;
    font-size: 12px;
    background-color: transparent;
    font-weight: ${(props) => (props.selected ? 'bold' : '400')};
    color: ${(props) => (props.selected ? tableCellBlackColor : tableHeaderColor)};
  }
  & .DateRangePickerInput {
    background-color: transparent;
    border: none;
    padding-right: ${(props) => (props.selected ? '30px' : '0px')};
  }
  & .DateInput {
    background-color: transparent;
    width: ${(props) => (props.selected ? '45px' : '55px')};
  }
  & .DateRangePickerInput_arrow {
    margin-bottom: 4px;
    margin-right: 4px;
  }
  & .DateRangePickerInput_clearDates {
    padding-top: 7px;
    padding-right: 3px;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
  & .DateRangePicker_picker {
    left: -200px !important;
    z-index: 10000;
  }
  & .CalendarDay__default {
    border: none;
    color: ${tableCellBlackColor};
    font-size: 12px;
    font-weight: 600;
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
    }
  }
  & .CalendarDay__selected {
    background: ${colorToken.customDateRangeStyled.color};
    box-shadow: 0px 4px 18px ${sidebarMenuBoxShadowColor};
    border-radius: 8px;
    &:hover {
      background: ${colorToken.customDateRangeStyled.action.hover.color};
    }
    &:focus {
      background: ${colorToken.customDateRangeStyled.action.focus.color};
    }
  }
  & .CalendarDay__selected_span {
    background: ${tableCellYellowColor};

    &:hover {
      background: ${tableCellYellowColor};
    }
    &:focus {
      background: ${tableCellYellowColor};
    }
  }
  & .DayPicker_weekHeader {
    color: ${tableHeaderColor};
    font-size: 10px !important;
  }
`;

export const MoreDetailsButton = styled(Button)`
  background: ${whiteColor};
  box-shadow: 0px 12px 36px ${boxShadowColor};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${blueColor};
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 15px;
  gap: 85px;
  &:hover {
    background: ${whiteColor};
    color: ${blueColor};
    box-shadow: none;
  }
`;

export const SummaryInsightsContainer = styled.div`
  background: ${whiteColor};
  padding: 10px 0;
  border-radius: 8px;
`;

export const InsightsContentContainer = styled.div`
  padding: 10px 0;
`;

export const InsightsItem = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${colorToken.activitySummary.insights.border.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 54px;
`;

export const InsightsItemTextFirst = styled.div`
  display: flex;
  align-items: center;
`;

export const InsightsCountText = styled.div`
  color: #191919;
  margin-right: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;

export const InsightsDescText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #8b8b8b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InsightsItemTextSecond = styled.div``;

export const SummaryMembersContainer = styled.div`
  background: ${whiteColor};
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

export const SummaryMembersBox = styled.div`
  & .summaryMemberStyled {
    border-top: 0.5px solid ${colorToken.activitySummary.members.border.color};
  }
  & .summaryMemberStyled:first-of-type {
    border-top: none;
  }
`;

export const SummaryMemberStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 10px 0px;
`;

export const MemberAvatarContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const MemberInfoTextBox = styled.div`
  margin-left: 10px;
  width: 200px;
`;

export const MemberName = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-weight: 600;
  line-height: 17px;
  color: #191919;
`;

export const MemberEmail = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #b3b3b3;
`;

export const MemberTagsContainer = styled.div`
  width: 300px;
  max-height: 50px;
  position: relative;
  left: -100px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
`;

export const MemberTag = styled.div`
  background: ${tableTagBackground};
  color: ${tableTagColor};
  font-size: 12px;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 5px;
`;

export const SendMemberMessageButton = styled(Button)`
  background: ${whiteColor};
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${tableCellBlackColor};
  border-radius: 8px;
  text-transform: unset;
  padding: 10px 15px;
  gap: 10px;
  margin-left: auto;
  min-width: 35px;
  height: 35px;
  padding: 0px;
  &:hover {
    background: #efeffd;
    box-shadow: none;
  }
`;

export const SummaryMembersButton = styled(Button)`
  background: ${tableCellBlackColor};
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${whiteColor};
  border-radius: 4px;
  text-transform: capitalize;
  padding: 10px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 10px;
  &:hover {
    background: ${tableCellBlackColor};
    color: ${whiteColor};
    box-shadow: none;
  }
`;

export const ChannelNameText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #696dec;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ModalIntegrationsContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const ModalIntegrationText = styled.div`
  color: #808080;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const AutomationIntegrationText = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${colorToken.automation.createWorkflow.integrations.color};
  display: block;
  padding-bottom: ${colorToken.automation.createWorkflow.integrations.paddingBottom};
`;

export const ModalIntegrationBox = styled.div`
  display: flex;
`;

export const ModalIntegrationItem = styled.div`
  background-color: ${(props) =>
    props.active
      ? colorToken.modal.integrations.activeColor
      : colorToken.modal.integrations.inactiveColor};
  border: ${colorToken.modal.integrations.border.width} solid
    ${(props) =>
      props.active
        ? colorToken.modal.integrations.border.color
        : colorToken.modal.integrations.border.colorInactive};
  border-radius: ${colorToken.modal.integrations.border.radius};
  display: flex;
  align-items: center;
  height: 34px;
  padding: 5px 10px;
  margin-right: 10px;
`;

export const ContentBox = styled.div`
  background: ${colorToken.modal.background.secondary};
  border-radius: 4px;
  width: 100%;
  padding: 10px 14px;
  margin-bottom: 20px;
`;

export const YearEndReviewBannerContainer = styled.div`
  background: linear-gradient(
      360deg,
      rgba(245, 223, 144, 0.2) -45.23%,
      rgba(253, 253, 255, 0.2) 73.03%
    ),
    #ffffff;
  outline: 1px solid #d6d6d6;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0 15px 0 0;
  overflow: hidden;
`;

export const YearEndReviewBannerTextLarge = styled(Typography)`
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #242424;
  margin-bottom: 10px;
`;

export const YearEndReviewBannerTextNormal = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #242424;
`;

export const YearEndBannerStatusBlock = styled.div`
  background: #fdf5e9;
  border: 1px solid #f5e3c8;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 14px;
  color: #e79c47;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`;
