const { platformsMapper } = require('constant/platformsMapper');
import widgetBL from 'assets/images/icons/widgetBL.svg';
import widgetBR from 'assets/images/icons/widgetBR.svg';
import widgetCL from 'assets/images/icons/widgetCL.svg';
import widgetCR from 'assets/images/icons/widgetCR.svg';
import { Emoji } from 'emoji-mart';
import { renderToString } from 'react-dom/server';
import confluenceIcon from 'assets/images/icons/confluence.svg';
import publicUrl from 'assets/images/icons/publicUrlIcon.svg';

import * as Yup from 'yup';
import Api from 'layers/interceptor/interceptor';

// Constants
import { IntegrationType } from 'redux/contentSchedulerProvider/types';

export const setChannelsListWithTransform = (channelList) => {
  const madeupList = channelList?.map((channel) => {
    return {
      name: `#${channel.name}`,
      label: channel.name,
      value: channel.id,
      dataSourceId: channel.dataSourceId,
      image: platformsMapper[channel.providerName],
      provider: channel.providerName,
      autoRespond: channel.autoRespond ? 'Auto-respond' : 'On-tagging',
    };
  });
  return madeupList || [];
};

export const urlSourceValidationSchema = Yup.object().shape({
  urlList: Yup.array()
    .of(
      Yup.object().shape({
        indexURL: Yup.string().url('Invalid URL').required('URL is required'),
      }),
    )
    .required('At least one URL is required'),
});

const regMatch =
  /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_\-\.]+(\.[a-zA-Z]+)+(\/)?([^\s]*)$/;

// Explanation:
// ^((http|https):\/\/)? -> Match optional "http://" or "https://", the "?" makes the preceding group optional
// (www\.)? -> Match optional "www." part
// ([a-zA-Z0-9_-]+\.)* -> Match subdomains: one or more alphanumeric characters, underscores, or hyphens followed by a dot, '*' makes the group optional for subdomains
// [a-zA-Z0-9_\-\.]+ -> Match domain name: one or more alphanumeric characters, underscores, hyphens, or dots
// (\.[a-zA-Z]+)+ -> Match top-level domain: a dot followed by one or more alphabetical characters, "+" allows for multiple domain levels
// (\/)? -> Match optional forward slash ("/") for path part
// ([^\s]*)$ -> Match any character except whitespace zero or more times until the end of the string

// Examples:
// Valid URLs:
// "https://www.example.com/"
// "http://subdomain.example.co.uk/path"
// "ftp://sub_domain.example.com"
// "www.example.net"
// "example.org"
// "https://example.net/path/to/resource.html"
// Invalid URLs:
// "ftp://example.com with space" (contains whitespace)
// "http://www..example.com" (double dots in domain)
// "https://_example.com" (underscore at the beginning of domain)
// "https://example.c" (invalid top-level domain)
// "example.com/ path" (space in path)

export const urlValidationSchema = Yup.object().shape({
  indexURL: Yup.string().matches(regMatch, 'Invalid URL').required('URL is required'),
});

export const zendeskSubdomainUrlValidationSchema = Yup.object().shape({
  indexURL: Yup.string().required('URL is required'),
});

export const freshdeskConnectValidationSchema = Yup.object().shape({
  indexURL: Yup.string().required('URL is required'),
  apiToken: Yup.string().required('Enter API token'),
});

export const getSanitizedUrlFromURL = (urlText) => {
  let sanitizedURL = '';
  if (urlText.startsWith('https://') || urlText.startsWith('http://')) {
    sanitizedURL = urlText;
  } else {
    sanitizedURL = `https://${urlText}`;
  }
  return sanitizedURL;
};

export const slackBotCustomValidationSchemma = Yup.object().shape({
  unanswerable: Yup.string().required('Please enter your unanswerable text.'),
  downvoteMessage: Yup.string().required('Please enter downvote message.'),
  upvoteMessage: Yup.string().required('Please enter your upvote message.'),
});

export const webWidgetBotCustomValidationSchema = Yup.object().shape({
  unanswerable: Yup.string().required('Please enter your unanswerable text.'),
  downvoteMessage: Yup.string().required('Please enter downvote message.'),
  upvoteMessage: Yup.string().required('Please enter your upvote message.'),
  botName: Yup.string().required('Please enter your bot name.'),
  widgetColor: Yup.string().required('Please enter brand color'),
  logoURL: Yup.string().required('Please select or upload your bot avatar.'),
  welcomeMessage: Yup.string().required('Please enter bot welcome message.'),
  widgetPosition: Yup.string().required('Please select bot position.'),
  buttonConfig: Yup.object().shape({
    buttonName: Yup.string().required('Please enter the button text.'),
    buttonURL: Yup.string().url('Invalid URL').required('Please enter button URL.'),
  }),
});

export const defaultWidgetAvatars = [
  {
    url: 'https://storage.googleapis.com/threado-logo-files/widget_logo/Avatars_1689969871282.png',
  },
  {
    url: 'https://storage.googleapis.com/threado-logo-files/widget_logo/Avatars_(1)_1689969906266.png',
  },
  {
    url: 'https://storage.googleapis.com/threado-logo-files/widget_logo/Avatars_(2)_1689969921866.png',
  },
  {
    url: 'https://storage.googleapis.com/threado-logo-files/widget_logo/Avatars_(3)_1689969945562.png',
  },
  {
    url: 'https://storage.googleapis.com/threado-logo-files/widget_logo/Avatars_(4)_1689969958776.png',
  },
];

export const widgetPositions = [
  {
    name: 'Bottom left',
    value: 'BOTTOM_LEFT',
    image: widgetBL,
  },
  {
    name: 'Bottom right',
    value: 'BOTTOM_RIGHT',
    image: widgetBR,
  },
  {
    name: 'Center left',
    value: 'CENTRE_LEFT',
    image: widgetCL,
  },
  {
    name: 'Center right',
    value: 'CENTRE_RIGHT',
    image: widgetCR,
  },
];

export const getSyncingSourceStatusFromId = (url, statusList) => {
  if (!url || !statusList) {
    return null;
  } else {
    return statusList.filter((urlIn) => urlIn.id === url.id)?.[0];
  }
};
export const replaceEmojisWithSymbols = (text) => {
  const regex = /:([a-zA-Z0-9_+-]+):/g;
  return text.replace(regex, (match, emojiCode) => {
    const emojiElement = (
      <span style={{ position: 'relative', top: '3px' }}>
        <Emoji emoji={emojiCode} size={20} />
      </span>
    );
    return renderToString(emojiElement);
  });
};

export const codeSnippetWidget = `<pre><code>&lt;script type="text/javascript">
(function (k) {
  (function (w, d, s, o, f, js, fjs) {
    w[o] = w[o] || function () {
      (w[o].q = w[o].q || []).push(arguments)
    };
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
  }(window, document, 'script', '_hw', '{cdnLink}'));
  _hw('init', { appId: {appId}, minimized: true, token: k, instance: 'chat' });
  _hw('embed', { appId: {appId}, instance: 'embed' });
})('{secretKey}');
&lt;/script></code></pre>`;

export const codeSnippetEmbed = `<pre><code>&lt;script type="text/javascript">
(function (k) {
  (function (w, d, s, o, f, js, fjs) {
    w[o] = w[o] || function () {
      (w[o].q = w[o].q || []).push(arguments)
    };
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
  }(window, document, 'script', '_hw', '{cdnLink}'));
  _hw('init', { appId: {appId}, minimized: true, token: k });
  _hw('embed', { appId: {appId}, instance: 'embed' });
})('{secretKey}');
&lt;/script></code></pre>`;

export const getDesireCodeStringForInstallCode = (codeSnippet) => {
  const withoutPreCodeTags = codeSnippet
    .replace(/<pre><code>/g, '')
    .replace(/<\/code><\/pre>/g, '');
  const escapedCode = withoutPreCodeTags
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');
  return escapedCode;
};

export const loaderMessagesList = [
  "Searching for the best answer 🔎 Shouldn't take too long",
  'Going deep into the archives, 📚 hold on tight',
  'Your curiosity has sparked my excitement! ✨ I am on the hunt for that perfect answer 🔎',
  "Beam me up, Scotty! 🖖🏼 I'm exploring distant realms of information to bring back your answer!",
  "I'm going full Sherlock Holmes on this one! 🕵🏼‍♂️ The game is afoot, and your answer won't remain a mystery for long!",
  "Time flies when you're seeking wisdom ⏰ I am almost there, stay with me!",
  'The gears of knowledge are turning ⚙️ Your answer is coming together as we speak.',
  "Expecto Patronum! ✨ I'm conjuring the perfect answer to ward off any doubts you have! 🦌",
  "Bears, beets, Battlestar Galactica! 🐻 While I'm not fighting Cyclones, I'm hunting for your answer! 🔎",
  "The knowledge compass is pointing north 🧭 We'll be back with your answer in no time!",
];

export const checkBotStatus = (signupCommunityData) => {
  if (!signupCommunityData?.dataSourceStatus) {
    return 'NOT_ADDED';
  }

  if (Object.keys(signupCommunityData?.dataSourceStatus).length === 0) {
    return 'NOT_ADDED';
  } else if (
    signupCommunityData?.dataSourceStatus &&
    Object.keys(signupCommunityData?.dataSourceStatus).length > 0
  ) {
    let atleastOneLoading = false;

    let atleastOneLoaded = false;
    signupCommunityData?.dataSourceStatus &&
      Object.keys(signupCommunityData?.dataSourceStatus).map((inst) => {
        if (signupCommunityData?.dataSourceStatus[inst] === 'LOADING' && !atleastOneLoading) {
          atleastOneLoading = true;
        }

        if (signupCommunityData?.dataSourceStatus[inst] === 'LOADED' && !atleastOneLoaded) {
          atleastOneLoaded = true;
        }
      });

    // if (atleastOneLoading && !atleastOneLoaded) {
    //   return 'TRAINING'
    // }

    let atleastOneActive = false;
    signupCommunityData?.chatBotStatus &&
      Object.keys(signupCommunityData?.chatBotStatus).map((inst) => {
        if (signupCommunityData?.chatBotStatus[inst] === 'ACTIVE' && !atleastOneActive) {
          atleastOneActive = true;
        }
      });

    // if (atleastOneActive === false && atleastOneLoaded) {
    //   return 'READY'
    // }
  }

  return 'SUCCESS';
};

export const triggerCustomiseBotEvent = () => {
  const customiseBtnEvent = new CustomEvent('customiseBotEvent', {
    detail: {},
  });
  document.querySelector('#usetiful')?.dispatchEvent(customiseBtnEvent);
};

export const triggerInstallBotEvent = () => {
  const customiseBtnEvent = new CustomEvent('installBotEvent', {
    detail: {},
  });
  document.querySelector('#usetiful')?.dispatchEvent(customiseBtnEvent);
};

export const triggerTestBotEvent = () => {
  const customiseBtnEvent = new CustomEvent('testBotEvent', {
    detail: {},
  });
  document.querySelector('#usetiful')?.dispatchEvent(customiseBtnEvent);
};

export const triggerTrainBotEvent = () => {
  const customiseBtnEvent = new CustomEvent('trainBotEvent', {
    detail: {},
  });
  document.querySelector('#usetiful')?.dispatchEvent(customiseBtnEvent);
};

/**
 * This function calculates the text color (black or white) that should be used
 * based on the brightness of the background color represented by a hexadecimal code.
 *
 * @param {*} colorCodeHex The input parameter 'colorCodeHex' is the hexadecimal color code of the background color.
 * @returns boolean
 */
export const isTextColorBlack = (colorCodeHex) => {
  if (!colorCodeHex) {
    return 'black';
  }
  // Extract the red, green, and blue components of the color code.
  const red = parseInt(colorCodeHex.slice(1, 3), 16);
  const green = parseInt(colorCodeHex.slice(3, 5), 16);
  const blue = parseInt(colorCodeHex.slice(5, 7), 16);

  // Calculate the brightness of the background color using a weighted sum of the RGB values.
  const brightness = Math.round((red * 299 + green * 587 + blue * 114) / 1000);

  // Determine the text color based on the brightness.
  const textColourX = brightness > 125 ? 'black' : 'white';
  return textColourX === 'black';
};

export const URL_SOURCE_DATA_MAPPER = {
  confluence: {
    type: 'confluence',
    name: 'Confluence',
    iconSrc: confluenceIcon,
    iconSrcActive: confluenceIcon,
  },
  publicUrl: {
    type: 'publicUrl',
    name: 'Public URL',
    label: 'Public URL',
    value: 'publicUrl',
    iconSrc: publicUrl,
    iconSrcActive: publicUrl,
    image: publicUrl,
    imageDefault: publicUrl,
  },
};

/**
 * Zendesk CANNED_RESPONSE are the responses which are default values a user sees
 * after a ticket creation
 */

/**
 * Zendesk TICKETS are the tickets in zendesk
 */

export const DATA_SOURCE_TYPES = {
  ZENDESK: {
    CANNED_RESPONSE: 'CANNED_RESPONSE',
    TICKETS: 'TICKETS',
    SUPPORT_ARTICLES: 'SUPPORT_ARTICLES',
  },
  FRESHDESK: {
    CANNED_RESPONSE: 'CANNED_RESPONSE',
    TICKETS: 'TICKETS',
    SUPPORT_ARTICLES: 'SUPPORT_ARTICLES',
  },
  INTERCOM: {
    TICKETS: 'TICKETS',
    SUPPORT_ARTICLES: 'SUPPORT_ARTICLES',
  },
};

export const ALLOWED_PROTOCOLS_LIST = ['https://', 'http://'];

/**
 * Extracts the subdomain from a given URL by creating a URL object,
 * retrieving the hostname, splitting it by ".", and obtaining the first part,
 * resulting in the subdomain. The final subdomain is then returned as string.
 * @param {*} subdomainString
 * @param {*} allowedProtocols
 * @returns string
 */
export const extractValueFromSubdomain = (subdomainString, allowedProtocols) => {
  if (!subdomainString) {
    return '';
  }
  let validURL = '';
  if (allowedProtocols.filter((iter) => subdomainString.startsWith(iter)).length) {
    validURL = subdomainString;
  } else {
    validURL = `https://${subdomainString}`;
  }

  try {
    // Create a URL object
    const url = new URL(validURL);

    // Get the hostname (which includes the subdomain)
    const hostname = url.hostname;

    // Split the hostname by "." and get the subdomain (first part)
    const subdomain = hostname?.split('.')?.[0];
    return subdomain;
  } catch (error) {
    return '';
  }
};

// Helper function to upload a single file
export const uploadFile = (file, fileType, apiUrl) => {
  const formData = new FormData();
  const dataBlob = new Blob([file], {
    type: fileType || file.type,
  });

  const blobAsFile = new File([dataBlob], file.name, {
    type: fileType || file.type,
  });

  formData.append('file', blobAsFile);

  return Api.post(apiUrl, formData, {
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const addFilesToFormDataForUpload = (files) => {
  const formData = new FormData();

  if (!files || files.length === 0) {
    return formData;
  }

  const filesArray = Array.from(files);

  filesArray.forEach((file, index) => {
    const dataBlob = new Blob([file], {
      type: file.type,
    });

    const blobAsFile = new File([dataBlob], file.name, {
      type: file.type,
    });
    formData.append('files', blobAsFile);
  });
  return formData;
};

/**
 * This function checks the object keys and return true if any of the field
 * value is not null, otherwise return false
 * @param {*} obj
 * @returns boolean
 */
export const hasNoNullValue = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null) {
      return true; // Return true if any key value is not null
    }
  }
  return false; // Return false if all keys values are null
};

export const showPiiMaskedForSourcesList = [
  IntegrationType.INTERCOM,
  IntegrationType.ZENDESK,
  IntegrationType.FRESHDESK,
];

export const combineDataForTraining = (additionalDetails, statusDetails, type, searchString) => {
  // Check if the type exists and has documents in both additionalDetails and statusDetails
  if (
    !additionalDetails ||
    !statusDetails ||
    !additionalDetails[type] ||
    !statusDetails[type] ||
    !additionalDetails[type].length ||
    !statusDetails[type].documents
  ) {
    return {
      lastSyncAt: 'Not Available',
      isLoading: false,
      documents: [], // Return an empty array if the required data is not present
    };
  }
  // const combinedDocuments = statusDetails[type].documents
  //   .filter((doc) => doc.status === status) // Filter documents by the specified status
  //   .map((doc) => {
  //     // Find additional details for each document based on id and ensure the fileName contains the searchString
  //     const details =
  //       additionalDetails[type].find(
  //         (detail) =>
  //           detail &&
  //           detail.id === doc.id &&
  //           (searchString === '' || (detail.fileName && detail.fileName.includes(searchString))),
  //       ) || {};

  //     // Combine the document from statusDetails with the additional details found
  //     return {
  //       ...doc,
  //       ...details,
  //     };
  //   });

  // Combine documents using reduce to apply filtering and merging in one step
  const combinedDocuments = statusDetails[type].documents.reduce((acc, doc) => {
    // Filter documents by the specified status
    if (true) {
      // Find additional details for each document based on id and fileName search condition
      const details =
        additionalDetails[type].find(
          (detail) =>
            detail &&
            detail.id === doc.id &&
            (searchString === '' ||
              searchString === undefined ||
              (detail.fileName &&
                detail.fileName.toLowerCase().includes(searchString.toLowerCase()))),
        ) || {};

      // Combine the document from statusDetails with the additional details found
      if (details.id) {
        acc.push({
          ...doc,
          ...details,
        });
      }
    }
    return acc;
  }, []);

  // Return the combined documents along with the metadata
  return {
    lastSyncAt: statusDetails[type].lastSyncAt,
    isLoading: statusDetails[type].isLoading,
    documents: combinedDocuments,
  };
};


export const getFileExtensionName = (fileName) => {
  // Check if fileName is a string
  if (typeof fileName !== 'string') {
    return;
  }

  // Get the last index of '.' to find the start of the extension
  const dotIndex = fileName.lastIndexOf('.');

  // If there's no dot or it's at the beginning of the file name, return an empty string
  if (dotIndex === -1 || dotIndex === 0 || dotIndex === fileName.length - 1) {
    return '';
  }

  // Get the substring after the last dot, which represents the extension
  const extension = fileName.slice(dotIndex + 1);

  return extension;
};