import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../../../molecules/Sidebar';
import Header from '../../../molecules/SidebarHeader';
import { useHistory, useLocation } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { menuDataForBot, menuDataNew, metaDataMenu } from 'utils/menuData';
import {
  useGetCurrentCommunity,
  useGetSearchFilters,
  useGetSidebarToggleState,
  useGetUserPermissions,
} from 'context/hooks';
import SecondarySidebar from 'molecules/SecondarySidebar';
import { trackUserEvent } from 'utils/segment';
import { MainContent } from 'styledComponents/sidebarStyledComponents';
import { isEmpty } from 'lodash';
import { getMenuDataWithPermissions } from 'utils/menuDataWithPermissions';
export const drawerWidth = 240;
export const drawerMargin = 5;

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      '& .MuiDrawer-paper': {
        height: '90%',
        margin: drawerMargin,
        borderRadius: 14,
        overflowX: 'hidden',
        gap: 6,
      },
    },
    accordionExpand: {
      margin: '0 !important',
    },
  }),
);
const LeftSidebar = (props) => {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [isUserMenuVisible, setUserMenuVisible] = useState();
  const activeFeatures = useSelector((state) => {
    return state.auth?.userInfo?.activeFeatures || [];
  });
  const [menuData, setMenuData] = useState([...menuDataForBot, ...metaDataMenu]);
  const searchFilters = useSelector(useGetSearchFilters);
  const sidebarToggleState = useSelector(useGetSidebarToggleState);
  const [pageCategory, setPageCategory] = useState('');

  const communityData = useSelector(useGetCurrentCommunity);

  const [userInfoPermissions, setUserInfoPermissions] = useState(null);

  // Permissions
  const userPermissions = useSelector(useGetUserPermissions);

  // Effect for fetching permissions
  useEffect(() => {
    if (!communityData) {
      return;
    }
    if (!userPermissions) {
      return;
    }
    if (userPermissions) {
      if (!isEmpty(userPermissions)) {
        setUserInfoPermissions(userPermissions);
      }
    }
  }, [userPermissions, communityData]);

  useEffect(() => {
    if (!communityData || !userInfoPermissions) {
      return;
    }
    setMenuData([]);
    if (communityData?.org?.signupType !== 'THREADO_AI') {
      const menuDataWithPermission = getMenuDataWithPermissions(
        [...menuDataNew, ...metaDataMenu],
        userInfoPermissions,
      );
      setMenuData(menuDataWithPermission);
    } else {
      const menuDataWithPermission = getMenuDataWithPermissions(
        [...menuDataForBot, ...metaDataMenu],
        userInfoPermissions,
      );
      setMenuData(menuDataWithPermission);
    }
  }, [userInfoPermissions, communityData, location]);

  useEffect(() => {
    if (history.location.pathname.startsWith('/support-bot')) {
      setPageCategory('SUPPORT_BOT');
    } else if (history.location.pathname.startsWith('/settings/integrations/slack')) {
      setPageCategory('SUPPORT_BOT');
    } else {
      setPageCategory('');
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (communityData?.org?.signupType !== 'THREADO_AI') {
      const modifiedMenuData = [...menuData].map((menu) => {
        if (menu.name == 'Members') {
          const subMenuData = [];
          searchFilters.map((inst) => {
            subMenuData.push({
              feature: '',
              name: inst.name,
              restricted: false,
              url: '',
              isDefault: inst.isDefault,
              id: inst.id,
            });
          });
          menu.subMenu = subMenuData;
        }
        return menu;
      });
      setMenuData(modifiedMenuData);
    }
  }, [searchFilters]);

  const userNavigation = (navigationUrl, subMenuName) => {
    history.push(navigationUrl);
  };

  const hideSidebar = [
    '/login',
    '/signup',
    '/settings/integrations/select',
    '/settings/integrations/create',
    // '/setup/configuration',
    '/accounts/password/reset',
    '/email/unsubscribe',
  ];

  return (
    <>
      {!hideSidebar.includes(history.location.pathname) ? (
        history.location.pathname.startsWith('/community-roundup22') ? (
          <>{children}</>
        ) : (
          <div>
            {/* Sidebar Logic */}
            <>
              <div>
                <Sidebar />
              </div>
              {!location.pathname?.startsWith('/support-bot') ? (
                <div>
                  <SecondarySidebar
                    open={true}
                    classesMenu={classes}
                    setUserMenuVisible={setUserMenuVisible}
                    activeFeatures={activeFeatures}
                    menuDataNew={menuData}
                    userNavigation={(navigationUrl, subMenuName) =>
                      userNavigation(navigationUrl, subMenuName)
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </>
            <div className="app-main">
              {/* Comman Header and Banner Logic */}
              {/* <Header /> */}
              <div
                style={{
                  marginLeft: isUserMenuVisible
                    ? pageCategory === 'SUPPORT_BOT'
                      ? '130px'
                      : '324px'
                    : sidebarToggleState
                    ? '130px'
                    : '55px',
                  width:
                    pageCategory === 'SUPPORT_BOT'
                      ? 'calc(100% - 130px)'
                      : isUserMenuVisible
                      ? 'calc(100% - 324px)'
                      : sidebarToggleState
                      ? 'calc(100% - 155px)'
                      : 'calc(100% - 155px)',
                  height: '100%',
                  background: '#F8F8F8',
                  paddingLeft:
                    pageCategory === 'SUPPORT_BOT'
                      ? !sidebarToggleState
                        ? '0'
                        : '120px'
                      : !sidebarToggleState
                      ? '20px'
                      : '127px',
                  paddingRight: pageCategory === 'SUPPORT_BOT' ? '0' : '20px',
                }}>
                {children}
              </div>
            </div>
          </div>
        )
      ) : (
        <>{children}</>
      )}
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node,
};

export default LeftSidebar;
