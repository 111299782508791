import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Image, PaperContainer } from '../../styledComponents/commonStyled';
import MessagesFilterManager from 'components/Messages/FilterManager/MessagesFilterManager';
import { colorToken } from '../../theme/designToken';
import { Tooltip } from '@mui/material';
import { GlobalStyles } from '@mui/material';
import { useLocation } from 'react-router';
import MembersSideBar from '../MemberSidebar/MembersSideBar';
import SupportSidebar from 'molecules/SupportSidebar/SupportSidebar';
import MessagesFilterSidebar from 'components/Messages/FilterManager/MessagesFilterSidebar';
import DashboardSideBar from 'molecules/MemberSidebar/DashboardSideBar';
import { useGetCurrentCommunity, useGetSidebarToggleState } from 'context/hooks';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 180,
      maxWidth: 400,
      backgroundColor: `#FFFFF`,
    },
  }),
);

const SecondarySidebar = ({
  menuDataNew,
  open,
  classesMenu,
  activeFeatures,
  userNavigation,
  setUserMenuVisible,
}) => {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState();
  const sidebarToggleState = useSelector(useGetSidebarToggleState);

  const communityData = useSelector(useGetCurrentCommunity);

  const fiters = {
    members: [],
    activity: [],
  };

  const getFiltersByMenu = (menuName) => {
    return fiters[menuName.toLowerCase()];
  };

  const location = useLocation();

  useEffect(() => {
    getMenuByRoute(location.pathname);
  }, [location.pathname, menuDataNew]);

  /**
   * Function to populate filters based on route string
   * @param routeString This is the route as mentioned on MenuData
   */
  const getMenuByRoute = (routeString) => {
    const filteredMenu = menuDataNew.filter((menu) => {
      return routeString.startsWith(menu.url);
    })[0];

    if (!selectedMenu || filteredMenu !== selectedMenu) {
      setSelectedMenu(filteredMenu);
      setUserMenuVisible(filteredMenu?.hasSubMenu);
    }
    // return selectedMenu;
  };

  return (
    <>
      <GlobalStyles
        styles={{
          '.memberSideBar .MuiButtonBase-root.MuiAccordionSummary-root': {
            padding: '0px',
          },
          '.memberSideBar div.MuiAccordionDetails-root': {
            padding: '8px 0px 16px',
          },
          /* Hide scrollbar for Chrome, Safari and Opera */
          '.MuiAccordionDetails-root::-webkit-scrollbar': {
            display: 'none',
          },
          /* Hide scrollbar for IE, Edge and Firefox */
          '.MuiAccordionDetails-root': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },

          '.MuiAccordionSummary-root': {
            flexDirection: 'row-reverse',
          },

          '.sideMenu:hover': {
            background: '#F5F6FD',
          },
        }}
      />
      {((selectedMenu && selectedMenu.subMenu?.length) ||
        (selectedMenu &&
          getFiltersByMenu(selectedMenu.name) &&
          getFiltersByMenu(selectedMenu.name)?.length) ||
        location.pathname?.startsWith('/accountss')) &&
      !location.pathname?.startsWith('/members') &&
      !location.pathname?.startsWith('/activity') &&
      !location.pathname?.startsWith('/activities') &&
      !location.pathname?.startsWith('/analytics') &&
      !location.pathname?.startsWith('/support-bot') &&
      !location.pathname?.startsWith('/settings') ? (
        <PaperContainer
          elevation={0}
          style={{
            borderRadius: colorToken.menuSidebar.secondarySidebar.border.radius,
            display: 'flex',
            alignItems: 'stretch',
            height: 'initial',
            maxHeight: '100vh',
            minHeight: '100vh',
            position: 'fixed',
            paddingTop: '20px',
            paddingLeft: !sidebarToggleState ? '75px' : '260px',
            paddingRight: '10px',
            top: 0,
            zIndex: 999,
            overflow: 'auto',
            backgroundColor: colorToken.menuSidebar.secondarySidebar.backgroundColor,
          }}>
          <Slide direction="right" in={open} easing={'easing.sharp'} mountOnEnter unmountOnExit>
            <div className={classes.root} style={{ paddingTop: '16px' }}>
              {selectedMenu ? (
                <span
                  style={{
                    lineHeight: '19px',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 700,
                    fontSize: '16px',
                    color: '#191919',
                    paddingLeft: '10px',
                    paddingBottom: location.pathname?.startsWith('/support') ? '28px' : '28px',
                  }}>
                  {selectedMenu.name}
                </span>
              ) : (
                <></>
              )}

              {selectedMenu &&
                selectedMenu.subMenu &&
                selectedMenu.subMenu.map((subMenu, index) =>
                  subMenu.restricted ? (
                    <>
                      {activeFeatures && activeFeatures.includes(subMenu.feature) ? (
                        <>
                          <span
                            role="none"
                            key={index}
                            onClick={() => {
                              userNavigation(subMenu.url, subMenu.name);
                            }}
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '14px',
                              lineHeight: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              color: location.pathname?.startsWith(subMenu.url)
                                ? colorToken.menuSidebar.secondarySidebar.text.active.color
                                : colorToken.menuSidebar.secondarySidebar.text.inactive.color,
                              marginBottom: '24px',
                            }}>
                            {subMenu.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {subMenu.name?.length > 0 ? (
                        <div
                          key={index}
                          className={'sideMenu'}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '10px 0px',
                            minHeight: '24px',
                            cursor: 'pointer',
                            alignItems: 'center',
                            padding: '8px 10px',
                            borderRadius: '4px',
                            background: location.pathname?.startsWith(subMenu.url) ? '#ECEDFA' : '',
                          }}
                          onClick={() => {
                            userNavigation(subMenu.url, subMenu.name);
                          }}>
                          <div
                            role="none"
                            key={index}
                            style={{
                              fontStyle: 'normal',
                              fontWeight: location.pathname?.startsWith(subMenu.url) ? 700 : 400,
                              fontSize: '12px',
                              lineHeight: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              color: '#3E5273',
                              cursor: 'pointer',
                              width: selectedMenu.name == 'Members' ? '80%' : '100%',
                              wordBreak: 'break-all',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}>
                            {subMenu.iconSrc && subMenu.activeIconSrc ? (
                              <Image
                                src={
                                  location.pathname?.startsWith(subMenu.url)
                                    ? subMenu.activeIconSrc
                                    : subMenu.iconSrc
                                }
                                height={14}
                                width={14}
                                alt={subMenu.name}
                                style={{
                                  marginRight: '8px',
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            {subMenu.name.length > 18 ? (
                              <Tooltip title={subMenu.name} followCursor>
                                <span style={{ fontSize: '14px' }}>{subMenu.name}</span>
                              </Tooltip>
                            ) : (
                              <span style={{ fontSize: '14px' }}>{subMenu.name}</span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                )}
              {selectedMenu &&
              selectedMenu.subMenu?.length &&
              getFiltersByMenu(selectedMenu.name) &&
              getFiltersByMenu(selectedMenu.name)?.length ? (
                <Divider />
              ) : (
                <></>
              )}
            </div>
          </Slide>
        </PaperContainer>
      ) : location.pathname?.startsWith('/analytics') ||
        location.pathname?.startsWith('/activity') ||
        location.pathname?.startsWith('/support-bot') ||
        location?.pathname?.startsWith('/settings') ? (
        (communityData?.org?.signupType === 'THREADO_AI'
          ? !location?.pathname?.startsWith('/support-bot/train') &&
            !location?.pathname?.startsWith('/support-bot/setup') &&
            !location?.pathname?.startsWith('/support-bot/agent-assist')
          : true) && (
          <>
            <DashboardSideBar
              menuDataNew={menuDataNew}
              open={open}
              classesMenu={classesMenu}
              userNavigation={userNavigation}
            />
          </>
        )
      ) : location.pathname?.startsWith('/members') ? (
        <MembersSideBar
          menuDataNew={menuDataNew}
          open={open}
          classesMenu={classesMenu}
          activeFeatures={activeFeatures}
          userNavigation={userNavigation}
        />
      ) : location.pathname?.startsWith('/activities') ? (
        <SupportSidebar menuDataNew={menuDataNew} open={open} />
      ) : location.pathname?.startsWith('/support') ? (
        <>
          <MessagesFilterSidebar menuDataNew={menuDataNew} open={open} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SecondarySidebar;
