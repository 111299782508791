import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FilterOption, FilterOptionLabel } from 'styledComponents/memberStyled';
import { messagesTagOptions } from '../../../constant/filterMenuData';
import { colorToken } from '../../../theme/designToken';
import checkedBox from 'assets/images/icons/checkedBox.svg';
import dashUnchecked from 'assets/images/icons/dashUnchecked.svg';
import uncheckedBox from 'assets/images/icons/uncheckedBox.svg';
import { Image } from 'styledComponents/commonStyled';

const StatusOptions = ({ setStatusFilter, statusFilter, setStatusFilterCallback }) => {
  const [allStatus, setAllStatus] = useState(true);
  const [filterShowLimit, setFilterShowLimit] = useState(3);

  const setFilterLimit = (filterLimit) => {
    if (filterLimit) {
      setFilterShowLimit(filterLimit);
    }
  };

  const handleSingleGroupChange = (event) => {
    if (allStatus) {
      setAllStatus(false);
    }
    if (statusFilter.includes('all')) {
      statusFilter.splice(statusFilter.indexOf('all'), 1);
    }
    if (!event.target.checked) {
      setStatusFilter(statusFilter.filter((group) => group !== event.target.value));
      setStatusFilterCallback &&
        setStatusFilterCallback(statusFilter.filter((group) => group !== event.target.value));
      return;
    }

    setStatusFilter([...statusFilter, event.target.value]);
    setStatusFilterCallback && setStatusFilterCallback([...statusFilter, event.target.value]);
  };

  const handleallStatusChange = (event) => {
    if (event.target.checked) {
      setStatusFilter(['all']);
      setStatusFilterCallback && setStatusFilterCallback(['all']);
    }
    setAllStatus(event.target.checked);
  };

  useEffect(() => {
    if (statusFilter.includes('all')) {
      setAllStatus(true);
      return;
    }
    setAllStatus(false);
  }, [statusFilter]);

  return (
    <>
      <FilterOption style={{ marginBottom: '6px' }}>
        <Checkbox
          style={{
            paddingLeft: '0px',
            paddingRight: '2px',
            paddingTop: '0px',
            paddingBottom: '2px',
          }}
          name="allStatus"
          checked={allStatus}
          value="allStatus"
          onChange={handleallStatusChange}
          icon={<Image src={dashUnchecked} alt="uncheckedBox" width={16} height={16} />}
          checkedIcon={<Image src={checkedBox} alt="uncheckedBox" width={16} height={16} />}
        />
        <FilterOptionLabel is_selected={allStatus.toString()}>All</FilterOptionLabel>
      </FilterOption>
      {messagesTagOptions.map((option, index) => (
        <FilterOption key={option.name}>
          <Checkbox
            style={{
              paddingLeft: '0px',
              paddingRight: '2px',
              paddingTop: '0px',
              paddingBottom: '2px',
              display: index < filterShowLimit ? 'block' : 'none',
            }}
            name="status"
            checked={statusFilter.includes(option.value)}
            value={option.value}
            onChange={handleSingleGroupChange}
            icon={<Image src={uncheckedBox} alt="uncheckedBox" width={16} height={16} />}
            checkedIcon={<Image src={checkedBox} alt="uncheckedBox" width={16} height={16} />}
          />
          <FilterOptionLabel
            style={{ display: index < filterShowLimit ? 'block' : 'none', marginTop: '-8px' }}
            is_selected={statusFilter.includes(option.value).toString()}>
            {option.name}
          </FilterOptionLabel>
        </FilterOption>
      ))}

      {filterShowLimit === 3 ? (
        <span
          role="none"
          style={{
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'flex-end',
            textAlign: 'right',
            textTransform: 'uppercase',
            color: colorToken.colors.purpleText,
            cursor: 'pointer',
          }}
          onClick={() => setFilterLimit(messagesTagOptions?.length)}>
          Show More
        </span>
      ) : (
        <span
          role="none"
          style={{
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'flex-end',
            textAlign: 'right',
            textTransform: 'uppercase',
            color: colorToken.colors.purpleText,
            cursor: 'pointer',
          }}
          onClick={() => setFilterLimit(3)}>
          Show Less
        </span>
      )}
    </>
  );
};

export default StatusOptions;
