import React from 'react';
import { trackUserEvent } from '../utils/segment';
import {
  SpecialTabText,
  Tab,
  TabCountText,
  TabsContainer,
  TabText,
} from 'styledComponents/commonStyled';
import { Image } from 'styledComponents/commonStyled';

const CreateTabs = ({
  setTab,
  tabs,
  currentTab,
  fullWidth,
  hideTabs,
  fontSize,
  fontWeight,
  selectedFontWeight,
  noPadding,
  disabledTabNames,
  innertextpadding,
  countArray,
  isactivitypage,
  createTabCallback,
  tabwidth,
  tabContainerWidth,
  tabContainerBgColor,
  tabContainerPadding,
  hideborder,
}) => {
  const onTabClick = (tabName) => {
    // @ts-ignore: Object is possibly 'null'.
    if (disabledTabNames && !disabledTabNames.includes(tabName)) {
      setTab(tabName);
      createTabCallback && createTabCallback(tabName);
    } else if (!disabledTabNames) {
      setTab(tabName);
      createTabCallback && createTabCallback(tabName);
    }
    trackUserEvent('Viewed Tab', {
      tabName,
    });
  };
  return (
    <TabsContainer
      isactivitypage={isactivitypage}
      hideborder={hideborder}
      tabContainerWidth={tabContainerWidth}
      tabContainerBgColor={tabContainerBgColor}
      tabContainerPadding={tabContainerPadding}
      fullWidth={fullWidth ? fullWidth.toString() : undefined}>
      {tabs.map(
        (tab) =>
          !hideTabs.includes(tab.tabName) && (
            <Tab
              isImage={tab.tabImage}
              key={tab.tabName}
              fullWidth={fullWidth ? fullWidth.toString() : undefined}
              onClick={() =>
                onTabClick(isactivitypage && tab.tabValue ? tab.tabValue : tab.tabName)
              }
              selected={currentTab === tab.tabName || currentTab === tab.tabValue}
              nopadding={noPadding ? noPadding.toString() : undefined}
              isactivitypage={isactivitypage}
              tabwidth={tabwidth}>
              {tab.tabImage ? (
                <div
                  style={{
                    display: 'block',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginLeft: '19px',
                    marginRight: '-42px',
                    marginTop: '6px',
                  }}>
                  {`${tab.tabImage}`.includes('sendHubspot') ? (
                    <Image src={tab.tabImage} width={18} height={18} alt={tab.tabImage} />
                  ) : (
                    <Image src={tab.tabImage} width={16} height={16} alt={tab.tabImage} />
                  )}
                </div>
              ) : (
                ''
              )}
              <TabText
                innertextpadding={innertextpadding}
                fontSize={fontSize}
                fontWeight={fontWeight}
                selectedFontWeight={selectedFontWeight}
                selected={currentTab === tab.tabName || currentTab === tab.tabValue}
                isactivitypage={isactivitypage}>
                {tab.tabImageBot && (
                  <Image
                    src={tab.tabImageBot}
                    width={18}
                    height={18}
                    alt={tab.tabImageBot}
                    style={{ marginTop: '-1px', marginRight: '8px' }}
                  />
                )}
                {tab.tabName}
                {tab.tabCount !== undefined && (
                  <TabCountText
                    selected={currentTab === tab.tabName || currentTab === tab.tabValue}>
                    ({tab.tabCount})
                  </TabCountText>
                )}
                {tab.isSpecialTab !== undefined && (
                  <SpecialTabText>{tab.isSpecialTab}</SpecialTabText>
                )}
              </TabText>
            </Tab>
          ),
      )}
    </TabsContainer>
  );
};

export default CreateTabs;
