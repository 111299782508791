import styled from '@emotion/styled';
import { Accordion, AccordionDetails } from '@material-ui/core';
// import { tableCellBlackColor, tableHeaderColor } from '../theme/colorConstants';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const tableHeaderColor = 'rgba(151, 166, 186, 1)';
const tableCellBlackColor = 'rgba(39, 48, 63, 1)';

export const CompanyText = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 5px;
  color: ${tableCellBlackColor};
`;

export const AccordionMenu = styled(Accordion)`
  box-shadow: none;
  margin: 0;
  background: none;
  &.Mui-expanded {
    &:last-of-type {
      padding-bottom: 25px !important;
    }
  }
  &:before {
    content: none;
  }
  & .MuiAccordionSummary-root.Mui-expanded {
    min-height: fit-content;
  }
  & .MuiAccordionSummary-root {
    padding: 0 30px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const AccordionMenuContainer = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  gap: 16px;
  align-items: end;
  min-height: 48px;
  justify-content: center;
  margin-top: 30px
  color: ${(props) => (props.selected ? tableCellBlackColor : tableHeaderColor)};
`;
export const AccordionSubMenu = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: end;
  color: ${(props) => (props.selected ? tableCellBlackColor : tableHeaderColor)};
  cursor: pointer;
  width: fit-content;
`;

export const AccordionSubMenuContainer = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 55px;
`;

export const MenuContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  margin-right: 8px;
  // margin-left: 25px;
  height: 32px !important;
  display: flex;
  gap: ${(props) => (props.sidebarToggleState ? '10px' : '0')};
  padding-left: ${(props) => (props.sidebarToggleState ? '16px' : '0')};
  ${(props) => !props.sidebarToggleState && `place-content: center space-evenly`};
  flex-flow: row nowrap;
  height: 36px;
  padding-top: ${(props) => (props.sidebarToggleState ? '10px !important' : '10px')};
  &: ${(props) =>
    props.responseOnHover && !props.sidebarToggleState
      ? `hover {
    background: #403651;
    border-radius: 5px;
    height: 36px;
    width: 36px;`
      : props.responseOnHover && props.sidebarToggleState
      ? `hover {
        box-sizing: border-box;
    background: rgb(244, 245, 246);
    border-radius: 8px;
    width: 100%;
    display: flex;
    margin-right: 8px;
    gap: ${(props) => (props.sidebarToggleState ? '10px' : '0')};
    padding-left: ${(props) => (props.sidebarToggleState ? '10px' : '0')};
      ${(props) => !props.sidebarToggleState && `place-content: center space-evenly`};
    flex-flow: row nowrap;
  padding-top: 10px;
    height: 36px;`
      : ``};
`;

export const FilterArrowDropDownIcon = styled(ArrowDropDownIcon)`
  height: 24px;
  transition: transform 0.2s ease;
  transform: ${(props) =>
    props.selected || props.is_selected === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
  color: ${(props) => (props.selected || props.is_selected === 'true' ? '#3E4D64;' : '#3E4D64;')};
`;

export const FilterArrowDropDownIconImage = styled.img`
  transition: transform 0.2s ease;
  transform: ${(props) =>
    props.selected || props.is_selected === 'true' ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

export const MenuListSidebarItem = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  background: ${(props) => (props.selected ? '#F1F5F9' : '')};
  border-radius: ${(props) => (props.selected ? '4px' : '')};
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3e5273;
  &: hover {
    background: #f1f5f9;
    border-radius: 4px;
  }
`;

export const MenuListSidebarItemText = styled.div`
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 400 : 400)};
  color: ${(props) => (props.selected ? '#000000' : '#000000')};
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
