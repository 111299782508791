import { pageSize } from 'constant/paginationConstants';
import { SET_LOADER, SET_NEXT_ROUTER } from '../utils';

export const outreachReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER: {
      return {
        ...state,
        loaderStatus: payload,
      };
    }
    case SET_NEXT_ROUTER: {
      return {
        ...state,
        nextRouter: payload,
      };
    }

    default:
      return state;
  }
};
