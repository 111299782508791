import { AnyAction } from 'redux';
import {
  CREATE_DOMAIN,
  CREATE_DOMAIN_LOADING,
  CREATE_DOMAIN_ERROR,
  DOMAIN_LIST_LOADING,
  DOMAIN_LIST_LOADED,
  DOMAIN_LIST_LOADING_FAILED,
  DOMAIN_DETAILS,
  DOMAIN_DETAILS_LOADING,
  DOMAIN_DETAILS_ERROR,
  DOMAIN_EMAIL_DETAILS,
  DOMAIN_EMAIL_DETAILS_LOADING,
  DOMAIN_EMAIL_DETAILS_ERROR,
  CREATE_EMAIL,
  CREATE_EMAIL_LOADING,
  CREATE_EMAIL_ERROR,
  ORG_EMAIL_LIST,
  ORG_EMAIL_LIST_LOADING,
  ORG_EMAIL_LIST_ERROR,
  VERIFY_DOMAIN_FAIL,
  VERIFY_DOMAIN_SUCCESS,
  VERIFY_DOMAIN_LOADING,
} from '../types';

export const initialState = {
  domainCreated: false,
  domainCreateError: false,
  domainCreateLoading: false,
  domainList: [],
  domainListLoading: false,
  domainListError: false,
  domainDetail: [],
  domainDetailLoading: false,
  domainDetailError: false,
  domainEmailDetail: [],
  domainEmailDetailLoading: false,
  domainEmailDetailError: false,
  emailCreated: false,
  emailCreateError: false,
  emailCreateLoading: false,
  orgEmail: [],
  orgEmailLoading: false,
  orgEmailError: false,
  isDomainVerified: false,
  domainVerificationSuccess: false,
  domainVerficationLoading: false,
};

const domainVerificationActionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_DOMAIN:
      return {
        ...state,
        domainCreated: true,
        domainCreateError: false,
        domainCreateLoading: false,
      };
    case CREATE_DOMAIN_LOADING:
      return {
        ...state,
        domainCreated: false,
        domainCreateError: false,
        domainCreateLoading: true,
      };
    case CREATE_DOMAIN_ERROR:
      return {
        ...state,
        domainCreated: false,
        domainCreateError: true,
        domainCreateLoading: false,
      };
    case DOMAIN_LIST_LOADING:
      return {
        ...state,
        domainList: [],
        domainListLoading: true,
        domainListError: false,
      };
    case DOMAIN_LIST_LOADED: {
      return {
        ...state,
        domainList: payload,
        domainListLoading: false,
        domainListError: false,
      };
    }
    case DOMAIN_LIST_LOADING_FAILED:
      return {
        ...state,
        domainList: [],
        domainListLoading: false,
        domainListError: true,
      };
    case DOMAIN_DETAILS:
      return {
        ...state,
        domainDetail: payload,
        domainDetailLoading: false,
        domainDetailError: false,
      };

    case DOMAIN_DETAILS_LOADING:
      return {
        ...state,
        domainDetail: [],
        domainDetailLoading: true,
        domainDetailError: false,
      };

    case DOMAIN_DETAILS_ERROR:
      return {
        ...state,
        domainDetail: [],
        domainDetailLoading: false,
        domainDetailError: true,
      };

    case DOMAIN_EMAIL_DETAILS:
      return {
        ...state,
        domainEmailDetail: payload,
        domainEmailDetailLoading: false,
        domainEmailDetailError: false,
      };

    case DOMAIN_EMAIL_DETAILS_LOADING:
      return {
        ...state,
        domainEmailDetail: [],
        domainEmailDetailLoading: true,
        domainEmailDetailError: false,
      };

    case DOMAIN_EMAIL_DETAILS_ERROR:
      return {
        ...state,
        domainEmailDetail: [],
        domainEmailDetailLoading: false,
        domainEmailDetailError: true,
      };

    case CREATE_EMAIL:
      return {
        ...state,
        emailCreated: true,
        emailCreateError: false,
        emailCreateLoading: false,
      };
    case CREATE_EMAIL_LOADING:
      return {
        ...state,
        emailCreated: false,
        emailCreateError: false,
        emailCreateLoading: true,
      };
    case CREATE_EMAIL_ERROR:
      return {
        ...state,
        emailCreated: false,
        emailCreateError: true,
        emailCreateLoading: false,
      };

    case ORG_EMAIL_LIST:
      return {
        ...state,
        orgEmail: payload,
        orgEmailLoading: false,
        orgEmailError: false,
      };

    case ORG_EMAIL_LIST_LOADING:
      return {
        ...state,
        orgEmail: [],
        orgEmailLoading: true,
        orgEmailError: false,
      };

    case ORG_EMAIL_LIST_ERROR:
      return {
        ...state,
        orgEmail: [],
        orgEmailLoading: false,
        orgEmailError: true,
      };

    // VERIFY_DOMAIN_FAIL,
    // VERIFY_DOMAIN_SUCCESS,
    // VERIFY_DOMAIN_LOADING,

    case VERIFY_DOMAIN_LOADING:
      return {
        ...state,
        isDomainVerified: false,
        domainVerificationSuccess: false,
        domainVerficationLoading: true,
      };

    case VERIFY_DOMAIN_SUCCESS:
      return {
        ...state,
        isDomainVerified: true,
        domainVerificationSuccess: true,
        domainVerficationLoading: false,
      };

    case VERIFY_DOMAIN_FAIL:
      return {
        ...state,
        isDomainVerified: false,
        domainVerificationSuccess: false,
        domainVerficationLoading: false,
      };

    default:
      return state;
  }
};

export default domainVerificationActionReducer;
