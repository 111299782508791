import moment from "moment";

/**
 * Function to convert time in string format in AM/PM to 24 hour format
 * @param timeInAMPM time in string in AM/PM format
 */
export const getHourMin = (timeInAMPM) => {
  const time = timeInAMPM || `00:00 AM`;
  // @ts-ignore: Object is possibly 'null'.
  let hours = Number(time.match(/^(\d+)/)[1]);
  // @ts-ignore: Object is possibly 'null'.
  var minutes = Number(time.match(/:(\d+)/)[1]);
  // @ts-ignore: Object is possibly 'null'.
  var AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM == 'PM' && hours < 12) hours = hours + 12;
  if (AMPM == 'AM' && hours == 12) hours = hours - 12;
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if (hours < 10) sHours = '0' + sHours;
  if (minutes < 10) sMinutes = '0' + sMinutes;
  return {
    hour: hours,
    minute: minutes,
  };
};
/**
 * Function to pass date string and get "21 Nov, 05:25 pm" format
 */
export const getDateInFormat = (dateString) => {
  return new Date(dateString).toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

export const getFilters = (filter) => {
  let filters = Object.keys(filter).reduce((acc, iter) => {
    if (iter === 'providers' && filter[iter] === 'all') {
      return acc;
    } else if (iter === 'tags' && filter[iter] === 'all') {
      return acc;
    } else if (iter === 'channelIds' && filter[iter] === '-1') {
      return acc;
      // @ts-ignore
    } else if (typeof iter === 'string' && filter[iter]) {
      // @ts-ignore
      acc = acc + `${iter}=${filter[iter]}&`;
    }
    return acc;
  }, '');
  return filters.substring(0, filters.length - 1);
};

export function removeItem(arr, value) {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

/**
 * Get the latest 10s place value of number
 * @param value
 * @returns
 */
export const getNearest10sPlace = (value) => {
  // @ts-ignore
  const log_10 = parseInt(Math.log(value) / Math.log(10));
  //eslint-disable-next-line
  const basePower = Math.pow(10, log_10);
  // @ts-ignore
  const result = Math.ceil(value / basePower).toFixed(0) * basePower;
  return result;
};

export const strip = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const handleKeyUp = (event, callBack) => {
  //key code for enter
  if (event.keyCode === 13) {
    event.preventDefault();
    event.target.blur();
    callBack();
  }
};

export const isDummyIntegration = (community) => {
  let isDummyIntegration = false;
  for (let key in community.integrations) {
    isDummyIntegration = community.integrations[key].some((integration) => {
      return integration.isDummy;
    });
    if (isDummyIntegration) {
      return true;
    }
  }
  return isDummyIntegration;
};

export const capitalizeFirstLetter = (str) => {
  // converting first letter to uppercase
  if (str) {
    const capitalized = str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
    return capitalized;
  } else {
    return '';
  }
};

export const replaceSpaceByProvidedCharacterInString = (str, providedCharacter) => {
  if (str) {
    const replaceStr = str.replace(/ /g, providedCharacter).toLowerCase();
    return replaceStr;
  } else {
    return '';
  }
};

export const maxRetryCounter = 3;

export const openUrl = (url) => {
  window.open(url, '_blank');
};

export const getQueryParams = () => {
  return decodeURI(window.location.search)
    .replace('?', '')
    .split('&')
    .map((param) => param.split('='))
    .reduce((values, [key, value]) => {
      if (key != '') values[key] = value;
      return values;
    }, {});
};

export const isOnlyThreadoAI = (community) => {
  let listOfIntegrations = community.integrations ? Object.keys(community.integrations) : [];
  if (listOfIntegrations.length === 1 && listOfIntegrations[0] === 'web') {
    return true;
  }
  return false;
};

export const isOnlyCommunity = (community) => {
  let listOfIntegrations = community.integrations ? Object.keys(community.integrations) : [];
  if (listOfIntegrations.length > 0 && !listOfIntegrations.includes('web')) {
    return true;
  }
  return false;
};

export const isCommunityAndAI = (community) => {
  let listOfIntegrations = community.integrations ? Object.keys(community.integrations) : [];
  if (listOfIntegrations.length > 1 && listOfIntegrations.includes('web')) {
    return true;
  }
  return false;
};

/**
 * Converts a timestamp into a human-readable format like "x hours ago".
 * @param {string} timestamp - The timestamp in ISO 8601 format.
 * @returns {string} A human-readable time difference string.
 */
export const timeAgoUtilFunction = (timestamp) => {
  const seconds = Math.floor((Date.now() - new Date(timestamp)) / 1000);
  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (let interval in intervals) {
    const value = Math.floor(seconds / intervals[interval]);
    if (value >= 1) {
      return value + ' ' + interval + (value === 1 ? '' : 's') + ' ago';
    }
  }
  return 'just now';
};


/**
 * Formats a given timestamp into a preferred date and time format like "09 May, 24 - 06:21 PM".
 * @param {number} timestamp - The timestamp to be formatted.
 * @returns {string} - The formatted date and time string, or '--' if the timestamp is falsy.
 */
export const getAbsoluteDateTimeFormatFromTimestamp = (timestamp) =>
  timestamp ? moment(new Date(timestamp)).format('DD MMM, YY - hh:mm A') : '--';

export const getCommunitySignupType = (community) => {
  return community?.org?.signupType;
};

export const redirectUrlMapperBasedOnSignupType = {
  COMMUNITY_AND_THREADO_AI: {
    defaultThreadoAIAndCommunityUrl: '/activity/summary',
  },
  THREADO_AI: {
    defaultThreadoAIUrl: '/support-bot/insights',
  },
};

export const urlsTypeListExcludeForCommunitySwitch = [
  '/support-bot',
  '/settings',
  '/accounts',
  '/add-teammate',
];

export const isExcludedPath = (pathname, pathNameList) =>
  pathNameList.some((excludedPath) => pathname.startsWith(excludedPath));

export const urlRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
