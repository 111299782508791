import {
    SET_DATE_FILTER,
    SET_MEMBERS_WIDGET_DATA,
    SET_PROVIDER_FILTER,
    SET_RECENT_AUTOMATION_WIDGET_DATA,
    SET_RECENT_OUTREACH_WIDGET_DATA,
    SET_TOP_MEMBERS_WIDGET_DATA,
    SET_ACTIVITIES_WIDGET_DATA,
    SET_TOP_MESSAGES_WIDGET_DATA,
    SET_CHANNEL_INSIGHT_LIST,
    SET_CHANNEL_INSIGHT_STATIC_DATA,
    SET_RETENTION_INSIGHT_STATIC_DATA,
    GET_REPORT_RETENTION,
    GET_REPORT_RETENTION_LOADING,
    GET_REPORT_RETENTION_ERROR,
} from '../types';

const initialState = {
    retentionInsightData: null,
    chartLoading: false,
    chartLoadingError: false,
    chartDataSentiment: null,

};

const retentionInsightReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_RETENTION_INSIGHT_STATIC_DATA:
            return {
                ...state,
                retentionInsightData: payload,
            };

        case GET_REPORT_RETENTION:
            return {
                ...state,
                chartLoading: false,
                chartDataSentiment: payload,
                chartLoadingError: false,
            };

        case GET_REPORT_RETENTION_LOADING:
            return {
                ...state,
                chartLoading: true,
                chartLoadingError: false,
                chartDataSentiment: null,
            };

        case GET_REPORT_RETENTION_ERROR:
            return {
                ...state,
                chartLoading: false,
                chartLoadingError: true,
                chartDataSentiment: null,
            };
        default:
            return state;
    }
};

export default retentionInsightReducer;
