export const CREATE_WORKFLOW = 'CREATE_WORKFLOW';
export const ERROR_CREATE_WORKFLOW = 'ERROR_CREATE_WORKFLOW';
export const SUCCESS_CREATE_WORKFLOW = 'SUCCESS_CREATE_WORKFLOW';
export const PENDING_CREATE_WORKFLOW = 'PENDING_CREATE_WORKFLOW';
export const SUCCESS_UPDATED_WORKFLOW = 'SUCCESS_UPDATED_WORKFLOW';
export const SUCCESS_CREATE_WORKFLOW_NEW = 'SUCCESS_CREATE_WORKFLOW_NEW';

// Workflow List
export const SET_WORKFLOW_LIST_LOADING = 'SET_WORKFLOW_LIST_LOADING';
export const GET_WORKFLOW_LIST_DATA = 'GET_WORKFLOW_LIST_DATA';
export const HANDLE_WORKFLOW_LIST_ERROR = 'HANDLE_WORKFLOW_LIST_ERROR';

// Event Node
export const ADD_EVENT_NODE = 'ADD_EVENT_NODE';
export const DELETE_EVENT_NODE = 'DELETE_EVENT_NODE';
// Delay Node
export const ADD_DELAY_NODE = 'ADD_DELAY_NODE';
export const DELETE_DELAY_NODE = 'DELETE_DELAY_NODE';
// Condition Node
export const ADD_CONDITION_NODE = 'ADD_CONDITION_NODE';
export const DELETE_CONDITION_NODE = 'DELETE_CONDITION_NODE';
// Sidebar
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
// Current node
export const GET_CURRENT_NODE = 'GET_CURRENT_NODE';
export const SET_CURRENT_NODE = 'SET_CURRENT_NODE';
export const DELETE_CURRENT_NODE = 'DELETE_CURRENT_NODE';
// Update flowchart
export const UPDATE_FLOW_CHART = 'UPDATE_FLOW_CHART';
export const GET_FLOW_CHART = 'GET_FLOW_CHART';
export const SET_FLOW_CHART = 'SET_FLOW_CHART';
// Sidebar Action
export const SET_SIDEBAR_ACTION = 'SET_SIDEBAR_ACTION';
// New node
export const SET_NEW_NODE_TYPE = 'SET_NEW_NODE_TYPE';
export const REMOVE_NEW_NODE_TYPE = 'REMOVE_NEW_NODE_TYPE';
// First Node
export const SET_FIRST_NODE = 'SET_FIRST_NODE';
export const DELETE_FIRST_NODE = 'DELETE_FIRST_NODE';
// Update WorkflowId
export const UPDATE_WORKFLOW_ID = 'UPDATE_WORKFLOW_ID';
// Toaster
export const RESET_TOASTER = 'RESET_TOASTER';
// Automation Criteria
export const GET_AUTOMATION_CRITERIA = 'GET_AUTOMATION_CRITERIA';
export const SET_AUTOMATION_CRITERIA = 'SET_AUTOMATION_CRITERIA';
export const ERROR_HANDLE_AUTOMATION_CRITERIA = 'ERROR_HANDLE_AUTOMATION_CRITERIA';
export const LOADING_AUTOMATION_CRITERIA = 'LOADING_AUTOMATION_CRITERIA';
export const SET_HOVERED_ROW_ID = 'SET_HOVERED_ROW_ID';
// Add Node Open
export const SET_AUTOMATION_SIDE_BAR = 'SET_AUTOMATION_SIDE_BAR';

//First Node description
export const SET_FIRST_NODE_DESCRIPTION = 'SET_FIRST_NODE_DESCRIPTION';

//Automation Menu bar
export const SET_AUTOMATION_MENU_BAR_OPEN = 'SET_AUTOMATION_MENU_BAR_OPEN';
export const SET_AUTOMATION_MENU_BAR_NODE = 'SET_AUTOMATION_MENU_BAR_NODE';

export const SET_CURRENT_WORKFLOW_STATUS = 'SET_CURRENT_WORKFLOW_STATUS';
export const SET_TOASTER_MESSAGE = 'SET_TOASTER_MESSAGE';

export const SET_UPDATE_TRIGGER_EVENT = 'SET_UPDATE_TRIGGER_EVENT';
export const SET_UPDATE_TRIGGER_EVENT_SUB_TYPE = 'SET_UPDATE_TRIGGER_EVENT_SUB_TYPE';

// Survey list
export const GET_WORKFLOW_SURVEY_LIST_DATA = 'GET_WORKFLOW_SURVEY_LIST_DATA';
export const HANDLE_WORKFLOW_SURVEY_LIST_ERROR = 'HANDLE_WORKFLOW_SURVEY_LIST_ERROR';
export const MEMBERS_TOAST_HANDLE = 'MEMBERS_TOAST_HANDLE';
export const SET_WORKFLOW_LIST_ROW_STATUS = 'SET_WORKFLOW_LIST_ROW_STATUS';
export const SET_WORKFLOW_LIST_DELETED_ROWS = 'SET_WORKFLOW_LIST_DELETED_ROWS';
// Is anynode edited
export const SET_IS_ANY_NODE_EDITED = 'SET_IS_ANY_NODE_EDITED';

export const SET_FLOWCHART_WITH_TRIGGER_NAME = 'SET_FLOWCHART_WITH_TRIGGER_NAME';

export const SET_SURVEY_NAME_LOCALLY = 'SET_SURVEY_NAME_LOCALLY';
// Refactor according to new API
// Create new node
export const CREATE_NEW_NODE_FROM_API = 'CREATE_NEW_NODE_FROM_API';
export const CREATE_NEW_NODE_FROM_API_LOADING = 'CREATE_NEW_NODE_FROM_API_LOADING';
export const CREATE_NEW_NODE_FROM_API_ERROR = 'CREATE_NEW_NODE_FROM_API_LOADING';
// Edit new node
export const EDIT_NEW_NODE_FROM_API = 'EDIT_NEW_NODE_FROM_API';
export const EDIT_NEW_NODE_FROM_API_LOADING = 'EDIT_NEW_NODE_FROM_API_LOADING';
export const EDIT_NEW_NODE_FROM_API_ERROR = 'EDIT_NEW_NODE_FROM_API_LOADING';
// Delete new node
export const DELETE_NEW_NODE_FROM_API = 'DELETE_NEW_NODE_FROM_API';
export const DELETE_NEW_NODE_FROM_API_LOADING = 'DELETE_NEW_NODE_FROM_API_LOADING';
export const DELETE_NEW_NODE_FROM_API_ERROR = 'DELETE_NEW_NODE_FROM_API_LOADING';

// Automation Reporting
export const SET_AUTOMATION_REPORT_MODE = 'SET_AUTOMATION_REPORT_MODE';
export const SET_REPORT_NEW_NODE_TYPE = 'SET_REPORT_NEW_NODE_TYPE';
export const GET_AUTOMATION_REPORT_BY_BLOCK = 'GET_AUTOMATION_REPORT_BY_BLOCK';
export const GET_AUTOMATION_REPORT_LOADING = 'GET_AUTOMATION_REPORT_LOADING';
export const ERROR_GET_AUTOMATION_REPORT = 'ERROR_GET_AUTOMATION_REPORT';
export const SET_AUTOMATION_REPORT_CURRENT_NODE = 'SET_AUTOMATION_REPORT_CURRENT_NODE';
export const OPEN_AUTOMATION_REPORT_SIDEBAR = 'OPEN_AUTOMATION_REPORT_SIDEBAR';
export const CLOSE_AUTOMATION_REPORT_SIDEBAR = 'CLOSE_AUTOMATION_REPORT_SIDEBAR';
export const GET_AUTOMATION_REPORT_BLOCK_STATUS = 'GET_AUTOMATION_REPORT_BLOCK_STATUS';
export const GET_AUTOMATION_REPORT_BLOCK_STATUS_LOADING =
  'GET_AUTOMATION_REPORT_BLOCK_STATUS_LOADING';
export const ERROR_GET_AUTOMATION_REPORT_BLOCK_STATUS = 'ERROR_GET_AUTOMATION_REPORT_BLOCK_STATUS';

// Automation scheduling save days and time locally
export const SET_AUTOMATION_SCHEDULE_CONFIG_DAY_TIME = 'SET_AUTOMATION_SCHEDULE_CONFIG_DAY_TIME';
export const SET_SLACK_INTERNAL_CHANNELS_LIST = 'SET_SLACK_INTERNAL_CHANNELS_LIST';

export const SET_AUTOMATION_BLOCK_VIEW_MODE = 'SET_AUTOMATION_BLOCK_VIEW_MODE';

// export const nodes = {
//   communityId: 0,
//   workflowId: 0,
//   blocks: [
//     {
//       prevStep: null,
//       step: '1', // uniqueId()
//       type: 'event', // event
//       blockName: 'member_filter', // event_triggr
//       message: 'Entry Condition', // map
//       value: 0, // 0
//       node: [], // []
//     },
//   ],
// };

// export enum eventType {
//   first_message = 'first_message',
//   moved_to_at_risk = 'moved_to_at_risk',
//   moved_to_inactive = 'moved_to_inactive',
//   member_filter = 'member_filter',
//   new_member = 'new_member',
// }

// export const triggerTypeToEventTextMapper: {
//   [key in any];
// } = {
//   first_message: 'First message from a member',
//   moved_to_at_risk: 'When a user has moved to At Risk segment',
//   moved_to_inactive: 'When a user has moved to Inactive segment',
//   member_filter: 'Entry Condition',
//   new_member: 'New member joins',
// };

// export enum delayNodeDurationType {
//   S = 'S',
//   M = 'M',
//   H = 'H',
//   D = 'D',
//   W = 'W',
//   MO = 'MO',
// }

export const delayNodeDurationTextMapper = {
  S: 'seconds',
  M: 'minutes',
  H: 'hours',
  W: 'weeks',
  D: 'days',
  MO: 'months',
};

// export enum actionNodeDurationType {
//   send_dm = 'send_dm',
//   send_email = 'send_email',
// }

export const actionNodeDurationTextMapper = {
  send_dm: 'Send a DM message',
  send_email: 'Send a email',
};

export const conditionNodeDurationType = {
  has_done_activity: 'has_done_activity',
  has_messaged: 'has_messaged',
  has_reacted: 'has_reacted',
  '!has_done_activity': '!has_done_activity',
  '!has_messaged': '!has_messaged',
  '!has_reacted': '!has_reacted',
};
export const conditionNodeTextMapper = {
  has_done_activity: 'Member who has done any activity in condition value',
  has_messaged: 'Member has posted/replied in the past provided days',
  has_reacted: 'Member has reacted to a post in the past provided days',
  '!has_done_activity': 'Member who has not done any activity',
  '!has_messaged': 'Member who has not posted/replied in the past provided days',
  '!has_reacted': 'Member who has not reacted in the past provided days',
};

const getMessageString = (numberOfMessage) => {
  if (parseInt(numberOfMessage) > 1) {
    return 'messages';
  } else {
    return 'message';
  }
};

const getReactionString = (numberOfReaction) => {
  if (parseInt(numberOfReaction) > 1) {
    return 'reactions';
  } else {
    return 'reaction';
  }
};

const getMessageAndReactionString = (numberOfMessageReaction) => {
  if (parseInt(numberOfMessageReaction) > 1) {
    return 'messages or reactions';
  } else {
    return 'message or reaction';
  }
};

const getConditionByLabel = (conditionLabel) => {
  switch (conditionLabel) {
    case `gt`:
      return `>`;
    case `eq`:
      return `=`;
    case `lt`:
      return `<`;
  }
  return ``;
};

export const conditionNodeText = (conditionKey, conditionSign, conditionVal) => {
  let text = '';
  switch (conditionKey) {
    case `has_done_activity`: {
      return `Member has posted ${getConditionByLabel(
        conditionSign,
      )} ${conditionVal} ${getMessageAndReactionString(conditionVal)}`;
    }
    case `has_messaged`: {
      return `Member has posted ${getConditionByLabel(
        conditionSign,
      )} ${conditionVal} ${getMessageString(conditionVal)}`;
    }
    case `has_reacted`: {
      return `Member has posted ${getConditionByLabel(
        conditionSign,
      )} ${conditionVal} ${getReactionString(conditionVal)}`;
    }
    case `!has_done_activity`: {
      return `Member has not posted ${getConditionByLabel(
        conditionSign,
      )} ${conditionVal} ${getMessageAndReactionString(conditionVal)}`;
    }
    case `!has_messaged`: {
      return `Member has not posted ${getConditionByLabel(
        conditionSign,
      )} ${conditionVal} ${getMessageString(conditionVal)}`;
    }
    case `!has_reacted`: {
      return `Member has not posted ${getConditionByLabel(
        conditionSign,
      )} ${conditionVal} ${getReactionString(conditionVal)}`;
    }
  }
  return ``;
};

export const transformListRowStatus = (workflowListRowStatus, statusObject) => {
  const clonedWorkflowListRowStatus = JSON.parse(JSON.stringify(workflowListRowStatus));
  const i = clonedWorkflowListRowStatus.findIndex(
    (_element) => _element.workflowId === statusObject.workflowId,
  );
  if (i > -1) clonedWorkflowListRowStatus[i] = { ...statusObject };
  else clonedWorkflowListRowStatus.push({ ...statusObject });
  // console.log(clonedWorkflowListRowStatus);
  return clonedWorkflowListRowStatus;
};
