import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import { PaperContainer, Image } from 'styledComponents/commonStyled';

import { createStyles, makeStyles } from '@material-ui/styles';
import AccordionSummary from '@mui/material/AccordionSummary';

import { useSelector, useDispatch } from 'react-redux';
import {
  useGetMembersSegmentsFilter,
  useGetMembersChannelsFilter,
  useGetMembersRolesFilter,
  useGetCurrentCommunity,
  useGetDefaultFilter,
  useGetSearchFilters,
  useGetUserInfo,
  useGetTotalMemberCountHook,
  useGetSidebarToggleState,
} from 'context/hooks';
import { useDataState } from 'utils/dataStateHook';
import {
  setSegmentsFilterAction,
  setChannelsFilterAction,
  setGroupsFilterAction,
  deleteFilters,
  setMemberDataGridFilter,
} from 'redux/memberProvider/actions/membersAction';

import { colorToken } from 'theme/designToken';
import { useToasts } from 'react-toast-notifications';
import { Tooltip } from '@mui/material';
import { getSavedFilters } from 'redux/communityProvider/actions/communityAction';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import Accordion from '@mui/material/Accordion';

import { GlobalStyles } from '@mui/material';
import DeleteFilterDialog from './DeleteFilterDialog';

import { ToolTipCustom } from 'atoms/Tooltip';

import { useLocation, useHistory } from 'react-router';
import { useQuery } from 'utils/useQuery';
import { trackUserEvent } from 'utils/segment';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 175,
      maxWidth: 400,
      backgroundColor: `#FFFFF`,
    },
    accordionExpand: {
      margin: '0 !important',
    },
  }),
);

export const formatNumberToCount = (n) => {
  if (n === undefined || n === null) return '';
  if (n == 0) return '0';
  if (n < 1e3) return n + '';
  if (n >= 1e3) return +(n / 1e3).toFixed(1) + 'K';
  return '';
};

const MembersSideBar = ({ menuDataNew, open, userNavigation }) => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectedId, setSelectedId] = useState();
  const selectedFilter = useDataState('');
  const segmentFilter = useSelector(useGetMembersSegmentsFilter);
  const channelsFilter = useSelector(useGetMembersChannelsFilter);
  const memberRolesFilter = useSelector(useGetMembersRolesFilter);
  const segmenFilterState = useDataState(segmentFilter);
  const channelsFilterState = useDataState(channelsFilter);
  const memberRolesFilterState = useDataState(memberRolesFilter);
  const totalMemberCountHook = useSelector(useGetTotalMemberCountHook);

  const defaultFilter = useSelector(useGetDefaultFilter);

  const searchFilter = useSelector(useGetSearchFilters);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const communityData = useSelector(useGetCurrentCommunity);

  const userInfo = useSelector(useGetUserInfo);
  const dispatch = useDispatch();

  const location = useLocation();
  //useRouter();
  const pageInitialConfiguration = useQuery();

  const [refreshFilterData, setRefreshFilterData] = useState('');

  const deleteFilter = (communityId, filterId) => {
    communityId && dispatch(deleteFilters(communityId, filterId, addToast, setRefreshFilterData));
  };
  const sidebarToggleState = useSelector(useGetSidebarToggleState);

  const handleClose = () => {
    setDeleteOpen(false);
  };
  useEffect(() => {
    communityData && dispatch(getSavedFilters(communityData?.id));
    setDeleteOpen(false);
  }, [refreshFilterData]);

  useEffect(() => {
    if (pageInitialConfiguration.filter) {
      if (typeof pageInitialConfiguration.segment === 'string') {
        dispatch(setSegmentsFilterAction([pageInitialConfiguration.segment]));
      } else if (typeof pageInitialConfiguration.segment === 'object') {
        dispatch(setSegmentsFilterAction([...pageInitialConfiguration.segment]));
      }
    }
  }, [pageInitialConfiguration]);

  useEffect(() => {
    if (selectedFilter.value !== 'RESET') {
      dispatch(setSegmentsFilterAction(segmenFilterState?.value || []));
      dispatch(setChannelsFilterAction(channelsFilterState?.value || []));
      dispatch(setGroupsFilterAction(memberRolesFilterState?.value || []));
    }
  }, [dispatch, segmenFilterState, channelsFilterState, memberRolesFilterState]);
  /**
   * Function to populate filters based on route string
   * @param routeString This is the route as mentioned on MenuData
   */
  const getMenuByRoute = (routeString) => {
    const filteredMenu = menuDataNew.filter((menu) => {
      return routeString.startsWith(menu.url);
    })[0];

    if (!selectedMenu) {
      setSelectedMenu(filteredMenu);
    }
    return selectedMenu;
  };

  useEffect(() => {
    getMenuByRoute(location.pathname);
  }, [location.pathname, menuDataNew]);

  const handleClickOpen = () => {
    setDeleteOpen(true);
  };

  const openDeleteFilter = () => {
    handleClickOpen();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterName, setFilterName] = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [communityId, setCommunityId] = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subMenuId, setSubMenuId] = useState();

  const setDataGridFilter = (filterId, subMenu) => {
    let filteredFilter = filterId
      ? (searchFilter || []).filter((inst) => {
          return inst.id == filterId;
        })
      : searchFilter || [];

    if (filteredFilter.length == 0)
      filteredFilter = filterId
        ? (userInfo?.defaultFilters?.filter((inst) => inst.service === 'MEMBER') || []).filter(
            (inst) => {
              return inst.id == filterId;
            },
          )
        : userInfo?.defaultFilters?.filter((inst) => inst.service === 'MEMBER') || [];

    setCommunityId(communityData?.id);

    if (filteredFilter.length > 0) {
      setSelectedId(filteredFilter[0].id);
      setFilterName(filteredFilter[0].name);
      setSubMenuId(filteredFilter[0]?.id);
    }

    if (subMenu) {
      trackUserEvent('MemberPage - View loaded', {
        viewName: subMenu.name,
        filtersApplied: filteredFilter[0].criteria,
        type: subMenu.isDefault === false ? 'CUSTOM' : 'DEFAULT',
      });
    }

    filteredFilter?.length > 0 &&
      setMemberDataGridFilter &&
      dispatch(setMemberDataGridFilter(filteredFilter[0].criteria));
  };

  useEffect(() => {
    if (selectedMenu?.name != 'Members') return;

    const filteredFilter = (searchFilter || []).filter((inst) => {
      return inst.isDefault == true;
    });

    if (filteredFilter?.length > 0) {
      setDataGridFilter(filteredFilter[0].id);
      setSelectedId(filteredFilter[0].id);
      return;
    }

    if (filteredFilter?.length == 0 && (searchFilter || []).length > 0) {
      setDataGridFilter(searchFilter[0].id);
      setSelectedId(searchFilter[0].id);
      return;
    }
  }, [selectedMenu]);

  useEffect(() => {
    setDataGridFilter(defaultFilter);
  }, [defaultFilter]);

  const [style, setStyle] = useState({ display: 'none' });

  const [hoveredFilterId, setHoveredFilterId] = useState();

  return (
    <>
      <GlobalStyles
        styles={{
          '.memberSideBar': {
            '& .MuiPaper-root.MuiAccordion-root ': {
              boxShadow: 'unset',
            },
            '& .MuiPaper-root.MuiAccordion-root::before ': {
              display: 'none',
            },
            '& div.MuiAccordionSummary-expandIconWrapper': {
              color: '#637da6',
            },
            '& .memberSideBar .MuiButtonBase-root.MuiAccordionSummary-root': {
              minHeight: 'unset',
              paddingLeft: '4px',
            },

            '& div.MuiAccordion-root.Mui-expanded': {
              margin: '0px',
            },

            '& .MuiAccordionSummary-expandIconWrapper': {
              marginLeft: '-5px',
            },

            '& .MuiAccordionSummary-content': {
              paddingLeft: '4px',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto !important',
            },
            '& .MuiAccordionSummary-root:hover': {
              background: '#F5F6FD',
              borderRadius: '4px',
            },
            '& .MuiAccordionDetails-root': {
              marginTop: '20px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '12px 0px !important',
            },
          },
        }}
      />
      <PaperContainer
        className="memberSideBar"
        elevation={0}
        style={{
          borderRadius: colorToken.menuSidebar.secondarySidebar.border.radius,
          display: 'flex',
          alignItems: 'stretch',
          height: 'initial',
          maxHeight: '100vh',
          minHeight: '100vh',
          position: 'fixed',
          paddingTop: '20px',
          paddingLeft: !sidebarToggleState ? '75px' : '260px',
          top: 0,
          zIndex: 999,
          overflow: 'auto',
          backgroundColor: colorToken.menuSidebar.secondarySidebar.backgroundColor,
          // borderRight: `1px solid ${colorToken .menuSidebar.secondarySidebar.border.color}`,
          paddingRight: '10px',
        }}>
        <Slide direction="right" in={open} easing={'easing.sharp'} mountOnEnter unmountOnExit>
          <div className={classes.root} style={{ paddingTop: '16px' }}>
            <span
              style={{
                lineHeight: '19px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '-0.01em',
                fontWeight: 700,
                fontSize: '16px',
                color: '#191919',
                paddingLeft: '10px',
                paddingBottom: '28px',
                justifyContent: 'space-between',
              }}>
              <div>Members</div>
              <div
                style={{
                  padding: '3px 5px',
                  left: '138px',
                  top: '99px',
                  background: '#F5F6FD',
                  borderRadius: '3px',
                  marginRight: '10px',
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#3E5273',
                  cursor: 'default',
                }}>
                <ToolTipCustom
                  placement="top"
                  title={
                    <div>
                      <span
                        style={{ color: 'black', textTransform: 'capitalize', fontWeight: 'bold' }}>
                        Total community members
                      </span>
                    </div>
                  }>
                  <div> {formatNumberToCount(totalMemberCountHook) || 0}</div>
                </ToolTipCustom>
              </div>
            </span>
            <div
              style={{
                borderBottom: '0.5px solid #E6E6E6',
              }}></div>
            <>
              <Accordion defaultExpanded={true} style={{ margin: '10px 0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1ab-content"
                  id="panel1ab-header">
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#3E5273',
                    }}>
                    Custom Views
                  </Typography>
                  <div
                    style={{
                      padding: '3px 5px',
                      left: '138px',
                      top: '99px',
                      background: '#F5F6FD',
                      borderRadius: '3px',
                      marginRight: '10px',
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '11px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#3E5273',
                    }}>
                    {formatNumberToCount(selectedMenu?.subMenu.length) || 0}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingRight: '0px',
                    overflowX: 'scroll',
                  }}>
                  {selectedMenu &&
                    selectedMenu.subMenu.length > 0 &&
                    selectedMenu.subMenu.map(
                      (subMenu, index) =>
                        subMenu.name.length > 0 && (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              cursor: 'pointer',
                              alignItems: 'center',
                              background:
                                subMenu.id == selectedId || subMenu.id == hoveredFilterId
                                  ? '#F1F5F9'
                                  : '',
                              borderRadius: subMenu.id == selectedId ? '4px' : '',
                              padding: '4.5px 10.5px',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '14px',
                              color: '#4D4D4D',
                              marginBottom: '4px',
                              height: '30px',
                            }}
                            onMouseEnter={() => {
                              setStyle({ display: 'flex' });
                              setHoveredFilterId(subMenu.id);
                            }}
                            onMouseLeave={() => {
                              setStyle({ display: 'none' });
                              setHoveredFilterId(undefined);
                            }}
                            onClick={() => {
                              if (subMenu.id) {
                                setDataGridFilter(subMenu.id, subMenu);
                              } else {
                                userNavigation(subMenu.url, subMenu.name);
                              }
                            }}>
                            <div
                              role="none"
                              key={index}
                              style={{
                                fontStyle: 'normal',
                                fontWeight: subMenu.id == selectedId ? 600 : 400,
                                color: subMenu.id == selectedId ? '#3E5273' : '#4D4D4D',
                                cursor: 'pointer',
                                width: selectedMenu.name == 'Members' ? '80%' : '100%',
                                wordBreak: 'break-all',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                fontSize: '12px',
                                lineHeight: '14px',
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              {subMenu.name.length > 18 ? (
                                <Tooltip title={subMenu.name} followCursor>
                                  <span>{subMenu.name}</span>
                                </Tooltip>
                              ) : (
                                <span>{subMenu.name}</span>
                              )}
                            </div>
                            {subMenu.id == hoveredFilterId && (
                              <>
                                {' '}
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    ...style,
                                    marginLeft: '5px',
                                    alignItems: 'center',

                                    display: 'flex',
                                  }}>
                                  <Image
                                    src="/icons/trashgrey.svg"
                                    width={20}
                                    height={20}
                                    onClick={() => {
                                      setFilterName(subMenu.name);
                                      setSubMenuId(subMenu.id);
                                      openDeleteFilter();
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ),
                    )}
                </AccordionDetails>
              </Accordion>
            </>
            <div
              style={{
                borderBottom: '0.5px solid #E6E6E6',
              }}></div>
            <>
              <Accordion style={{ margin: '10px 0' }} defaultExpanded={true}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#3E5273',
                    }}>
                    Default Views
                  </Typography>
                  <div
                    style={{
                      padding: '3px 5px',
                      background: '#F5F6FD',
                      borderRadius: '3px',
                      marginRight: '10px',
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '11px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#3E5273',
                    }}>
                    {formatNumberToCount(
                      userInfo?.defaultFilters?.filter((inst) => inst.service === 'MEMBER')?.length,
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingRight: '0px',
                    overflowX: 'scroll',
                  }}>
                  {userInfo?.defaultFilters
                    ?.filter((inst) => inst.service === 'MEMBER')
                    ?.map((subMenu, index) => (
                      <div
                        key={'_' + index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          alignItems: 'center',
                          background:
                            subMenu.id === selectedId || subMenu.id === hoveredFilterId
                              ? '#F1F5F9'
                              : '',
                          borderRadius: subMenu.id === selectedId ? '4px' : '',
                          padding: '4.5px 10.5px',
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '14px',
                          color: '#4D4D4D',
                          marginBottom: '4px',
                          height: '30px',
                        }}
                        onClick={() => {
                          if (subMenu.id) {
                            setDataGridFilter(subMenu.id, subMenu);
                          }
                        }}
                        onMouseEnter={() => {
                          setStyle({ display: 'flex' });
                          setHoveredFilterId(subMenu.id);
                        }}
                        onMouseLeave={() => {
                          setStyle({ display: 'none' });
                          setHoveredFilterId(undefined);
                        }}>
                        <div
                          role="none"
                          key={index}
                          style={{
                            fontStyle: 'normal',
                            fontWeight: subMenu.id == selectedId ? 600 : 400,
                            color: subMenu.id == selectedId ? '#3E5273' : '#4D4D4D',
                            cursor: 'pointer',
                            wordBreak: 'break-all',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontSize: '12px',
                            lineHeight: '14px',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          {subMenu.name.length > 18 ? (
                            <Tooltip title={subMenu.name} followCursor>
                              <span>{subMenu.name}</span>
                            </Tooltip>
                          ) : (
                            <span>{subMenu.name}</span>
                          )}
                        </div>
                      </div>
                    ))}
                </AccordionDetails>
              </Accordion>
            </>
          </div>
        </Slide>
      </PaperContainer>
      <DeleteFilterDialog
        handleClose={handleClose}
        deleteFilter={deleteFilter}
        open={deleteOpen}
        filterName={filterName}
        communityId={communityId}
        subMenuId={subMenuId}
      />
    </>
  );
};

export default MembersSideBar;
