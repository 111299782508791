import Api from 'layers/interceptor/interceptor';
import {
  getCriteriaForMessage,
  getCriteriaForMessageDefault,
  getMessageReplyApiUrl,
  getMessageTopicsListApiUrl,
  getSupportThreadsDataApiUrl,
  getThreadDataApiUrl,
  getThreadsDataApiUrl,
  spotSupportCriteria,
  updateConversationStatus,
} from '../../../constant/apiConstants';
import { pageSize } from '../../../constant/paginationConstants';
import { getFilters } from '../../../utils/common';
import {
  CLEAR_THREAD_NOTIFICATION,
  DELETE_THREAD,
  GET_SUPPORT_THREAD_DATA,
  GET_SUPPORT_THREAD_DATA_USING_SCROLL,
  GET_THREAD_DATA,
  SET_IS_VIEW_SAVED_SUCCESS,
  SET_MESSAGES_CHANNEL_FILTER,
  SET_MESSAGES_PROVIDER_CHANNEL_FILTER,
  SET_MESSAGES_STATUS_FILTER,
  SET_MESSAGE_REPLY_LOADER,
  SET_MESSAGE_TOPICS_LIST,
  SET_SELECTED_FILTER_NAME,
  SET_SHOW_THREAD_COMPLETELY,
  SET_SUPPORT_DEFAULT_FILTERS,
  SET_SUPPORT_DRAFT_FILTER,
  SET_SUPPORT_FEED_FILTERS,
  SET_SUPPORT_SAVED_FILTERS,
  SET_THREADS_DATA_TO_DEFAULT,
  SET_THREAD_DATA_AFTER_REPLY,
  SET_THREAD_LOADING,
  SET_THREAD_LOADING_INFINITE_SCROLL,
  SET_THREAD_NOTIFICATION,
} from '../types';
import { trackUserEvent } from '../../../utils/segment';
import { generateChatBotUrl } from '../utils';

export const getMessagesDataAction =
  (
    communityId,
    q = '',
    provider = 'all',
    tags = 'all',
    channelId = '',
    page = 0,
    size = pageSize,
    fromDate,
    toDate,
  ) =>
    (cancelToken) =>
      (dispatch) => {
        dispatch(setThreadsLoading());
        const filters = getFilters({
          communityId,
          from: fromDate,
          to: toDate,
          q,
          providers: provider,
          tags,
          channelIds: channelId,
          page,
          size,
        });
        if (q.length > 0) {
          trackUserEvent('Searched Query', {
            search: q,
          });
        }
        Api.get(getThreadsDataApiUrl(communityId, filters), {
          cancelToken: cancelToken,
        })
          //eslint-disable-next-line
          .then((res) => {
            dispatch({
              type: GET_THREAD_DATA,
              payload: res,
            });
          })
          .catch((e) => {
            if (!e.__CANCEL__) {
              dispatch({
                type: SET_THREAD_NOTIFICATION,
                payload: 'Error occurred while fetching messages',
              });
            }
          });
      };

export const getMessagesSupportDataAction =
  (communityId, supportFeedFilters, page, isChatBotMessage) => (cancelToken) => (dispatch) => {
    dispatch(setThreadsLoading());
    Api.get(getSupportThreadsDataApiUrl(communityId, supportFeedFilters, pageSize, page, isChatBotMessage), {
      cancelToken: cancelToken,
    })
      .then((res) => {
        dispatch({
          type: GET_SUPPORT_THREAD_DATA,
          payload: res,
        });
      })
      .catch((e) => {
        if (!e.__CANCEL__) {
          dispatch({
            type: SET_THREAD_NOTIFICATION,
            payload: 'Error occurred while fetching messages',
          });
        }
      });
  };

export const getMessagesSupportDataActionThroughInfiniteScroll =
  (communityId, supportFeedFilters, page, isChatBotMessage) => (cancelToken) => (dispatch) => {
    dispatch(setThreadsLoadingInfiniteScroll());
    Api.get(getSupportThreadsDataApiUrl(communityId, supportFeedFilters, pageSize, page, isChatBotMessage), {
      cancelToken: cancelToken,
    })
      .then((res) => {
        dispatch({
          type: GET_SUPPORT_THREAD_DATA_USING_SCROLL,
          payload: res,
        });
      })
      .catch((e) => {
        if (!e.__CANCEL__) {
          dispatch({
            type: SET_THREAD_NOTIFICATION,
            payload: 'Error occurred while fetching messages',
          });
        }
      });
  };

export const setSupportThreadsDataToDefault = () => (dispatch) => {
  dispatch({
    type: SET_THREADS_DATA_TO_DEFAULT,
  });
};

export const getMessagesSupportThreadDataAction = (communityId, threadId) => (dispatch) => {
  dispatch(setThreadsLoading());
  Api.get(getThreadDataApiUrl(communityId, threadId))
    .then((res) => {
      dispatch({
        type: GET_THREAD_DATA,
        payload: {
          content: [res],
        },
      });
    })
    .catch((e) => {
      if (!e.__CANCEL__) {
        dispatch({
          type: SET_THREAD_NOTIFICATION,
          payload: 'Error occurred while fetching messages',
        });
      }
    });
};

export const getMessagesSupportThreadDataActionNew = (communityId, threadId) => (dispatch) => {
  dispatch(setThreadsLoading());
  Api.get(getThreadDataApiUrl(communityId, threadId))
    .then((res) => {
      dispatch({
        type: GET_SUPPORT_THREAD_DATA,
        payload: {
          content: [res],
        },
      });
    })
    .catch((e) => {
      if (!e.__CANCEL__) {
        dispatch({
          type: SET_THREAD_NOTIFICATION,
          payload: 'Error occurred while fetching messages',
        });
      }
    });
};

export const deleteThreadAction = (communityId, threadId) => (dispatch) => {
  Api.delete(getThreadDataApiUrl(communityId, threadId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: DELETE_THREAD,
        payload: threadId,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_THREAD_NOTIFICATION,
        payload: 'Error occurred while deleting thread',
      });
    });
};

export const getSendMessageReplyAction =
  (messageId, message, threadId, communityId) => (dispatch) => {
    dispatch(
      setMessageReplyLoaderInfo({
        isLoading: true,
        threadId: threadId,
      }),
    );
    Api.post(getMessageReplyApiUrl(), { messageId, reply: message })
      .then(() => {
        localStorage.setItem('_messageValue', '');
        let element = document.getElementsByClassName('ql-editor');
        for (const e of element) {
          e.innerHTML = '';
        }
        setTimeout(() => {
          Api.get(getThreadDataApiUrl(communityId, threadId))
            .then((res) => {
              dispatch({
                type: SET_THREAD_DATA_AFTER_REPLY,
                payload: res,
              });
              dispatch({
                type: SET_THREAD_NOTIFICATION,
                payload: 'Message sent successfully',
              });
              localStorage.setItem('_messageValue', '');
            })
            .catch((e) => {
              if (!e.__CANCEL__) {
                dispatch({
                  type: SET_THREAD_NOTIFICATION,
                  payload: 'Error occurred while fetching message',
                });
              }
            })
            .finally(() => {
              dispatch(
                setMessageReplyLoaderInfo({
                  isLoading: false,
                  threadId: -1,
                }),
              );
            });
        }, 2500);
      })
      .catch(() => {
        dispatch({
          type: SET_THREAD_NOTIFICATION,
          payload: 'Error occurred while sending message',
        });
      });
  };

export const getSupportSavedFilters = (communityId) => (dispatch) => {
  Api.get(getCriteriaForMessage(communityId))
    .then((res) => {
      dispatch({ type: SET_SUPPORT_SAVED_FILTERS, payload: res });
    })
    .catch(() =>
      dispatch({
        type: SET_THREAD_NOTIFICATION,
        payload: 'Error occurred while fetching message filters',
      }),
    );
};

export const getSupportDefaultFilters = (communityId) => (dispatch) => {
  Api.get(getCriteriaForMessageDefault(communityId))
    .then((res) => {
      dispatch({ type: SET_SUPPORT_DEFAULT_FILTERS, payload: res });
    })
    .catch(() =>
      dispatch({
        type: SET_THREAD_NOTIFICATION,
        payload: 'Error occurred while fetching message default filters',
      }),
    );
};

export const saveSupportFilters =
  (communityId, saveFilter, addToast, setRefreshFilterData) => (dispatch) => {
    Api.post(getCriteriaForMessage(communityId), saveFilter)
      .then((res) => {
        addToast(`${saveFilter.name} is successfully saved!`, { appearance: 'success' });
        setRefreshFilterData(new Date().toString());
        dispatch(setIsViewSavedSuccessAction(true));
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };

export const deleteSupportFilters =
  (communityId, filterId, addToast, setRefreshFilterData) => () => {
    Api.delete(spotSupportCriteria(communityId, filterId))
      .then((res) => {
        addToast(`View is successfully deleted!`, { appearance: 'success' });
        setRefreshFilterData(new Date().toString());
      })
      .catch(() => {
        addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
      });
  };
export const showThreadCompletely = (threadId) => ({
  type: SET_SHOW_THREAD_COMPLETELY,
  payload: threadId,
});

export const setThreadsLoading = () => ({
  type: SET_THREAD_LOADING,
});

export const setThreadsLoadingInfiniteScroll = () => ({
  type: SET_THREAD_LOADING_INFINITE_SCROLL,
});

export const setMessageReplyLoaderInfo = (payload) => ({
  type: SET_MESSAGE_REPLY_LOADER,
  payload: payload,
});
export const clearThreadsNotification = () => ({
  type: CLEAR_THREAD_NOTIFICATION,
});

export const setMessagesChannelsFilterAction = (filter) => ({
  type: SET_MESSAGES_CHANNEL_FILTER,
  payload: filter,
});

export const setMessagesStatusFilterAction = (filter) => ({
  type: SET_MESSAGES_STATUS_FILTER,
  payload: filter,
});

export const setMessagesProviderChannelsFilterAction = (filter) => ({
  type: SET_MESSAGES_PROVIDER_CHANNEL_FILTER,
  payload: filter,
});

export const setSupportFeedFilters = (filter) => ({
  type: SET_SUPPORT_FEED_FILTERS,
  payload: filter,
});

export const setSupportDraftFilter = (payload) => ({
  type: SET_SUPPORT_DRAFT_FILTER,
  payload: payload,
});

export const setIsViewSavedSuccessAction = (payload) => ({
  type: SET_IS_VIEW_SAVED_SUCCESS,
  payload: payload,
});


export const getMessageTopicsListAction = (communityId, addToast) => (dispatch) => {
  Api.get(getMessageTopicsListApiUrl(communityId))
    .then((res) => {
      dispatch({
        type: SET_MESSAGE_TOPICS_LIST,
        payload: res.content,
      });
    })
    .catch(() => {
      addToast('Error occuered while fetching message keywords topics, Please try later!', {
        appearance: 'error',
      });
    });
};

export const setSelectedFilterNameAction = (filterName) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FILTER_NAME,
    payload: filterName,
  })
}


export const updateConversationStatusAction = (payload, addToast) => (dispatch) => {
  Api.post(updateConversationStatus(), payload)
    .then((res) => {
      addToast('Conversation Status updated successfully!', {
        appearance: 'success',
      });
    })
    .catch(() => {
      addToast('Error occuered while fetching message keywords topics, Please try later!', {
        appearance: 'error',
      });
    });
};
