import {
  GET_ACTIONS_TEMPLATES_LIST,
  SET_ACTIONS_TEMPLATES_LIST_LOADING,
  SET_ACTIONS_LIST_LOADING,
  GET_ACTION_DETAILS_BY_ID,
  SET_ACTION_DETAILS_BY_ID_LOADING,
  GET_ACTIONS_LIST,
} from '../types';

const initialState = {
  actionsTemplatesListLoading: false,
  actionsTemplatesList: [],
  actionsListLoading: false,
  actionsList: [],
  actionDetailsById: null,
  actionDetailsByIdLoading: false,
};

const agentActionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIONS_TEMPLATES_LIST_LOADING:
      return {
        ...state,
        actionsTemplatesListLoading: payload,
      };
    case GET_ACTIONS_TEMPLATES_LIST:
      return {
        ...state,
        actionsTemplatesListLoading: false,
        actionsTemplatesList: payload,
      };
    case SET_ACTIONS_LIST_LOADING:
      return {
        ...state,
        actionsListLoading: payload,
      };
    case GET_ACTIONS_LIST:
      return {
        ...state,
        actionsList: payload,
        actionsListLoading: false,
      };
    case SET_ACTION_DETAILS_BY_ID_LOADING:
      return {
        ...state,
        actionDetailsByIdLoading: payload,
      };
    case GET_ACTION_DETAILS_BY_ID:
      return {
        ...state,
        actionDetailsById: payload,
        actionDetailsByIdLoading: false,
      };
    default:
      return state;
  }
};

export default agentActionsReducer;
