import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { colorToken } from '../theme/designToken';

export const BannerContainer = styled.div`
  position: fixed;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 25px;
  margin-top: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: ${colorToken.banner.colorLight};
  z-index: 899;
  padding-left: 265px;
`;

export const BannerOfflineContainer = styled.div`
  position: fixed;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1500;
  height: 25px;
  background-color: ${colorToken.banner.colorLight};
`;

export const AutomationToolBar = styled.div`
  position: sticky;
  width: 100%;
  top: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1190;
  padding-top: 66px !important;
  flex-direction: column;
`;

export const BannerMessage = styled(Typography)`
  padding-right: 5px;
  padding-left: 20px;
  color: #0a071b;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const ConnectText = styled(Typography)`
  font-weight: 700;
  text-decoration: underline;
  line-height: 1.5;
  cursor: pointer;
`;

export const CloseBanner = styled.span`
  font-weight: 700;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
  position: absolute;
  right: 10px;
`;

export const BannerMessagePayments = styled(Typography)`
  padding-right: 10px;
  padding-left: 10px;
  color: #191919;
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
`;

export const BannerContainerFreePlan = styled.div`
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 30px;
  margin-top: 0;
  justify-content: center;
  align-items: center;
  background-color: ${colorToken.banner.colorFreePlanBanner};
  border: 1px solid ${colorToken.banner.borderFreePlanBanner};
  z-index: 1100;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 0px;
  margin-left: 125px;
`;

export const TextFreePlanBanner = styled(Typography)`
  font-size: 11px;
  line-height: 13px;
  color: ${colorToken.colors.blackThree};
  font-weight: 400;
`;

export const VerticalDividerFreePlan = styled.div`
  height: 14px;
  background-color: ${colorToken.colors.greyText};
  width: 1px;
`;
