export const GET_ACTIVITY_SUMMARY_DATA = 'GET_ACTIVITY_SUMMARY_DATA';
export const GET_ACTIVITY_SUMMARY_INSIGHTS_DATA = 'GET_ACTIVITY_SUMMARY_INSIGHTS_DATA';
export const GET_ACTIVITY_MEMBERS_HELPFUL_DATA = 'GET_ACTIVITY_MEMBERS_HELPFUL_DATA';
export const GET_ACTIVITY_MEMBERS_DISCUSSION_DATA = 'GET_ACTIVITY_MEMBERS_DISCUSSION_DATA';
export const GET_ACTIVITY_MEMBERS_NEW_DATA = 'GET_ACTIVITY_MEMBERS_NEW_DATA';
export const GET_DAILY_ACTIVE_MEMBERS_DATA = 'GET_DAILY_ACTIVE_MEMBERS_DATA';
export const GET_DAILY_ACTIVE_CHANNELS_DATA = 'GET_DAILY_ACTIVE_CHANNELS_DATA';
export const GET_ACTIVITY_MESSAGES_POSTED_DATA = 'GET_ACTIVITY_MESSAGES_POSTED_DATA';
export const GET_ACTIVITY_TOP_CHANNELS_DATA = 'GET_ACTIVITY_TOP_CHANNELS_DATA';
export const GET_ACTIVITY_TOP_MESSAGES_DATA = 'GET_ACTIVITY_TOP_MESSAGES_DATA';
export const ACTIVITY_ERROR_HANDLE = 'ACTIVITY_ERROR_HANDLE';
export const ACTIVITY_SUMMARY_INSIGHTS_ERROR_HANDLE = 'ACTIVITY_SUMMARY_INSIGHTS_ERROR_HANDLE';
export const ACTIVITY_MEMBERS_HELPFUL_ERROR_HANDLE = 'ACTIVITY_MEMBERS_HELPFUL_ERROR_HANDLE';
export const ACTIVITY_MEMBERS_DISCUSSION_ERROR_HANDLE = 'ACTIVITY_MEMBERS_DISCUSSION_ERROR_HANDLE';
export const ACTIVITY_MEMBERS_NEW_ERROR_HANDLE = 'ACTIVITY_MEMBERS_NEW_ERROR_HANDLE';
export const SET_ACTIVITY_TOP_MEMBERS_DATA = 'SET_ACTIVITY_TOP_MEMBERS_DATA';
export const SET_ACTIVITY_LOADING = 'SET_ACTIVITY_LOADING';
export const SET_ACTIVITY_HELPFUL_MEMBERS_LOADING = 'SET_ACTIVITY_HELPFUL_MEMBERS_LOADING';
export const SET_ACTIVITY_DISCUSSION_MEMBERS_LOADING = 'SET_ACTIVITY_DISCUSSION_MEMBERS_LOADING';
export const SET_ACTIVITY_MEMBERS_NEW_LOADING = 'SET_ACTIVITY_MEMBERS_NEW_LOADING';
export const SET_SELECTED_ACTIVITY_CHANNEL = 'SET_SELECTED_ACTIVITY_CHANNEL';
export const SET_SELECTED_ACTIVITY_MESSAGE_INTEGRATION =
  'SET_SELECTED_ACTIVITY_MESSAGE_INTEGRATION';
export const UPDATE_ACTIVITY_INTEGRATION = 'UPDATE_ACTIVITY_INTEGRATION';
export const SET_ACTIVITY_SUMMARY_INSIGHTS_LOADING = 'SET_ACTIVITY_SUMMARY_INSIGHTS_LOADING';
export const SET_ACTIVITY_TOP_MESSAGES_DATA = 'SET_ACTIVITY_TOP_MESSAGES_DATA';
export const MEMBERS_ERROR_HANDLE = 'MEMBERS_ERROR_HANDLE';
export const MEMBERS_TOAST_HANDLE = 'MEMBERS_TOAST_HANDLE';
export const GET_YEAR_END_REVIEW_BANNER_DATA = 'GET_YEAR_END_REVIEW_BANNER_DATA';
export const SET_YEAR_END_REVIEW_BANNER_LOADING = 'SET_YEAR_END_REVIEW_BANNER_LOADING';
export const ERROR_HANDLE_YEAR_END_REVIEW_BANNER = 'ERROR_HANDLE_YEAR_END_REVIEW_BANNER';


export const SET_SIDEBAR_TOGGLE_STATE = 'SET_SIDEBAR_TOGGLE_STATE';