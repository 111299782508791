import { useState } from 'react';

export const useBooleanState = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);
  const toggle = () => setValue(!value);
  return { value, toggle, setTrue, setFalse };
};

export const useDataState = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const set = (newValue) => setValue(newValue);
  const reset = () => setValue(undefined);
  return { value, set, reset };
};
