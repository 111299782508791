import React from 'react';
import { InputAdornment, SelectProps } from '@material-ui/core';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import { CustomSelectField, Image } from 'styledComponents/commonStyled';

const MatSelectFieldWrapper = (props) => {
  if (props.formik) {
    return (
      <CustomSelectField
        {...props}
        value={props.name && get(props.formik.values, props.name)}
        error={
          !!props.name &&
          !!get(props.formik.errors, props.name) &&
          !!get(props.formik.touched, props.name)
        }
        onChange={props.onChange || props.formik.handleChange}
        onBlur={() => {
          props.name && props.formik && props.formik.setFieldTouched(props.name);
        }}
        placeholder={
          props.name && get(props.formik.values, props.name) === 'placeholder' ? 'true' : 'false'
        }
        endAdornment={
          props.endicon ? (
            <InputAdornment position="end" style={{ marginLeft: 10 }}>
              <Image src={props.endicon} alt="dateIcon" width={40} height={40} />
            </InputAdornment>
          ) : (
            !props.defArrIcon && (
              <InputAdornment position="end" style={{ marginLeft: 10 }}>
                <Image src="/icons/arrowDownIcon.svg" alt="dateIcon" width={16} height={16} />
              </InputAdornment>
            )
          )
        }>
        {props.children}
      </CustomSelectField>
    );
  }
  return (
    <CustomSelectField
      toshowplaceholder={props.toshowplaceholder}
      endAdornment={
        props.endicon ? (
          <InputAdornment position="end" style={{ marginLeft: 10 }}>
            <Image src={props.endicon} alt="dateIcon" width={40} height={40} />
          </InputAdornment>
        ) : (
          !props.defArrIcon && (
            <InputAdornment position="end" style={{ marginLeft: 10 }}>
              <Image src="/icons/arrowDownIcon.svg" alt="dateIcon" width={16} height={16} />
            </InputAdornment>
          )
        )
      }
      {...props}>
      {props.children}
    </CustomSelectField>
  );
};

export default MatSelectFieldWrapper;
