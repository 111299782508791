import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// import all the reducers here
// import themeOptionsReducer from "../reducers";
import activityReducer from '../redux/activityProvider/reducers/activityReducers';
import authReducer from '../redux/authProvider/reducers/authProviderReducer';

import themeOptionsReducer from '../reducers';
import { automationReducer } from 'redux/automationProvider/reducers/automationReducer';
import communityReducer from 'redux/communityProvider/reducers/communityReducer';
import permissionsReducer from 'redux/permissionsProvider/reducers/permissionsReducer';
import { automationTemplatesReducer } from 'redux/automationTemplatesProvider/reducers/automationTemplatesReducer';
import paymentsReducer from 'redux/paymentsProvider/reducers/paymentsReducer';
import { engagementReducer } from 'redux/engagementProvider/reducers/engagementReducer';
import { retentionReducer } from 'redux/retentionProvider/reducers/retentionReducer';
import { notificationsReducer } from 'redux/notificationsProvider/reducers/notificationsReducer';
import rewardReducer from 'redux/rewardsProvider/reducers/rewardsReducer';
import messagesReducer from 'redux/messagesProvider/reducers/messagesReducer';
import staffReducer from 'redux/staffProvider/reducers/staffReducer';
import membersReducer from 'redux/memberProvider/reducers/membersReducer';
import editorReducer from 'redux/editorProvider/reducers/editorReducer';
import outreachTemplatesReducer from 'redux/outreachTemplatesProvider/reducers/outreachTemplatesReducer';
import domainVerificationActionReducer from 'redux/domainVerificationProvider/reducers/domainVerificationReducer';
import outreachReportReducer from 'redux/outreachReportProvider/reducers/outreachReportReducer';
import { outreachReducer } from 'redux/outreachProvider/reducers/outreachReducer';
import yearEndReducer from 'redux/yearEndReviewProvider/reducers/yearEndReducer';
import globalReducer from 'redux/globalProvider/reducers/globalReducer';
import { hubspotReducer } from 'redux/hubspotProvider/reducers/hubspotReducer';
import { companyReducer } from 'redux/companyProvider/reducers/companyReducer';
import analyticsSummaryReducer from 'redux/analyticsProvider/reducers/analyticsSummaryReducer';
import channelInsightReducer from 'redux/analyticsProvider/reducers/channelInsightReducer';
import topicInsightReducer from 'redux/analyticsProvider/reducers/topicInsightReducer';
import { graphReducer } from 'redux/graphProvider/reducers/graphReducer';
import memberAnalyticsReducer from 'redux/analyticsProvider/reducers/memberAnalyticsReducer';
import engagementInsightReducer from 'redux/analyticsProvider/reducers/engagementInsightReducer';
import retentionInsightReducer from 'redux/analyticsProvider/reducers/retentionInsightReducer';
import { chatbotReducer } from 'redux/chatbotProvider/reducers/chatbotReducer';
import slackAlertAnalyticsReducer from 'redux/analyticsProvider/reducers/slackAlertAnalyticsReducer';
import { agentAssistReducer } from 'redux/agentAssistProvider/reducers/agentAssistReducer';
import { ticketSupportReducer } from 'redux/ticketSupportProvider/reducers/ticketSupportReducer';
import conversationFeedReducer from 'redux/conversationFeedProvider/reducers/conversationFeedReducers.js';
import { botAnalyticsReducer } from 'redux/botAnalyticsProvider/reducers/botAnalyticsReducer.js';
import securitySettingsReducer from 'redux/securitySettingsProvider/reducers/securitySettingsReducer';
import trainingSupportReducer from 'redux/trainingProvider/reducers/trainingReducer.js';
import gocobaltIntegrationReducer from 'redux/gocobaltProvider/reducers/gocobaltReducers.js';
import agentActionsReducer from 'redux/agentActionsProvider/reducers/agentActionsReducers';
import { agentActionReducer } from 'redux/agentProvider/reducers/agentReducer';

const initialState = {};
const middleware = [thunk];

const reducers = combineReducers({
  activityReducer: activityReducer,
  auth: authReducer,
  automationReducer: automationReducer,
  communityReducer: communityReducer,
  permissionsReducer: permissionsReducer,
  automationTemplatesReducer: automationTemplatesReducer,
  paymentsReducer: paymentsReducer,
  engagementReducer: engagementReducer,
  retentionReducer: retentionReducer,
  notificationsReducer: notificationsReducer,
  rewardReducer: rewardReducer,
  messagesReducer: messagesReducer,
  staffReducer: staffReducer,
  membersReducer: membersReducer,
  editorReducer: editorReducer,
  outreachTemplatesReducer: outreachTemplatesReducer,
  domainVerificationActionReducer: domainVerificationActionReducer,
  outreachReportReducer: outreachReportReducer,
  outreachReducer: outreachReducer,
  yearEndReducer: yearEndReducer,
  globalReducer: globalReducer,
  hubspotReducer: hubspotReducer,
  companyReducer: companyReducer,
  analyticsSummaryReducer: analyticsSummaryReducer,
  channelInsightReducer: channelInsightReducer,
  topicInsightReducer: topicInsightReducer,
  graphReducer: graphReducer,
  memberAnalyticsReducer: memberAnalyticsReducer,
  engagementInsightReducer: engagementInsightReducer,
  retentionInsightReducer: retentionInsightReducer,
  chatbotReducer: chatbotReducer,
  slackAlertAnalyticsReducer: slackAlertAnalyticsReducer,
  agentAssistReducer: agentAssistReducer,
  agentActionReducer: agentActionReducer,
  ticketSupportReducer: ticketSupportReducer,
  conversationFeedReducer: conversationFeedReducer,
  botAnalyticsReducer: botAnalyticsReducer,
  securitySettingsReducer: securitySettingsReducer,
  trainingSupportReducer: trainingSupportReducer,
  gocobaltIntegrationReducer: gocobaltIntegrationReducer,
  agentActionsReducer: agentActionsReducer,
  ...themeOptionsReducer,
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(reducers, initialState, enhancer);

export default store;
