export const GET_API_KEYS_LIST = 'GET_API_KEYS_LIST';
export const SET_API_KEYS_LOADING = 'SET_API_KEYS_LOADING';

export const GET_API_KEY_BY_ID = 'GET_API_KEY_BY_ID';
export const SET_API_KEY_BY_ID_LOADING = 'SET_API_KEY_BY_ID_LOADING';

export const GENERATE_API_KEY = 'GENERATE_API_KEY';
export const UPDATE_API_KEY_BY_ID = 'UPDATE_API_KEY_BY_ID';
export const SET_CREATE_UPDATE_API_KEY_LOADING = 'SET_CREATE_UPDATE_API_KEY_LOADING';

export const DELETE_API_KEY_BY_ID = 'DELETE_API_KEY_BY_ID';
export const IS_DELETE_LOADING_API_KEY_BY_ID = 'IS_DELETE_LOADING_API_KEY_BY_ID';
