import {
  GET_REWARD_CONFIG,
  REWARD_TOAST_HANDLE,
  REWARD_INSIGHT_TOAST_HANDLE,
  GET_REWARD_INSIGHT_DETAILS,
  GET_REWARD_INSIGHT_SUMMARY,
  CLEAR_TOAST,
  GET_TOPIC,
  GET_TOPIC_LOADING,
  GET_TOPIC_FAILED,
  GET_TOPIC_UNIGRAM_LOADING,
  GET_UNIGRAM_TOPIC,
  GET_UNIGRAM_TOPIC_FAILED,
  GET_TOPIC_BIGRAM_LOADING,
  GET_BIGRAM_TOPIC,
  GET_BIGRAM_TOPIC_FAILED,
} from '../types';

const initialState = {
  rewardConfig: [],
  rewardToast: { success: false, message: '' },
  isLoading: true,
  isInsightLoading: true,
  insightMembers: [],
  insightSummary: {
    totalRewards: 0,
    lastWeekRewards: 0,
    swingPercentage: 0,
    isNegative: false,
    percentageValue: 0,
    formattedTotalRewards: 0,
    formattedLastWeekRewards: 0,
  },
  insightToast: { success: false, message: '' },
  topic: null,
  topicLoading: true,
  topicLoadingFailed: false,
  unigramTopic: null,
  unigramTopicLoading: false,
  unigramTopicLoadingFailed: false,
  bigramTopic: null,
  bigramTopicLoading: false,
  bigramTopicLoadingFailed: false,
};

const rewardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REWARD_TOAST_HANDLE:
      return {
        ...state,
        rewardToast: { ...payload },
        isLoading: false,
      };
    case GET_REWARD_CONFIG:
      return {
        ...state,
        rewardConfig: payload,
        isLoading: false,
      };
    case REWARD_INSIGHT_TOAST_HANDLE:
      return {
        ...state,
        insightToast: { ...payload },
        isLoading: false,
      };
    case GET_REWARD_INSIGHT_DETAILS:
      return {
        ...state,
        insightMembers: payload,
        isInsightLoading: false,
      };
    case GET_REWARD_INSIGHT_SUMMARY:
      return {
        ...state,
        insightSummary: payload,
      };
    case CLEAR_TOAST:
      return {
        ...state,
        rewardToast: { success: false, message: '' },
        insightToast: { success: false, message: '' },
      };
    case GET_TOPIC_LOADING:
      return {
        ...state,
        topicLoading: true,
        topic: null,
      };
    case GET_TOPIC:
      return {
        ...state,
        topic: payload,
        topicLoading: false,
        topicLoadingFailed: false,
      };
    case GET_TOPIC_FAILED:
      return {
        ...state,
        topicLoading: false,
        topic: null,
        topicLoadingFailed: true,
      };

    case GET_TOPIC_UNIGRAM_LOADING:
      return {
        ...state,
        unigramTopicLoading: true,
        unigramTopic: null,
      };
    case GET_UNIGRAM_TOPIC:
      return {
        ...state,
        unigramTopic: payload,
        unigramTopicLoading: false,
        unigramTopicLoadingFailed: false,
      };
    case GET_UNIGRAM_TOPIC_FAILED:
      return {
        ...state,
        unigramTopicLoading: false,
        unigramTopic: null,
        unigramTopicLoadingFailed: true,
      };

    case GET_TOPIC_BIGRAM_LOADING:
      return {
        ...state,
        bigramTopicLoading: true,
        bigramTopic: null,
      };
    case GET_BIGRAM_TOPIC:
      return {
        ...state,
        bigramTopic: payload,
        bigramTopicLoading: false,
        bigramTopicLoadingFailed: false,
      };
    case GET_BIGRAM_TOPIC_FAILED:
      return {
        ...state,
        bigramTopicLoading: false,
        bigramTopic: null,
        bigramTopicLoadingFailed: true,
      };

    default:
      return state;
  }
};

export default rewardReducer;
