import React from 'react';
import { useDispatch } from 'react-redux';
import { Image, PrimaryButton } from 'styledComponents/commonStyled';
import { colorToken } from 'theme/designToken';
import { setUpgradeNowEventSourceAction } from 'redux/paymentsProvider/actions/paymentsActions';
import { trackUserEvent } from 'utils/segment';
import {
  BannerContainerFreePlan,
  TextFreePlanBanner,
  VerticalDividerFreePlan,
} from 'styledComponents/launchBannerStyled';
import { useHistory } from 'react-router';
import lockIconPurple from 'assets/images/icons/lockIconPurple.svg';
import { workspaceValueRoleMapper } from 'constant/staffRoleData';

const BotLaunchBannerFreePlan = ({ communityRole }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div>
      <BannerContainerFreePlan>
        {communityRole === workspaceValueRoleMapper.ORGANIZATION_OWNER ? (
          <TextFreePlanBanner style={{ margin: '0 10px' }}>
            You are on <span style={{ fontWeight: 700 }}>FREE</span> Threado AI plan. Upgrade to get
            most out of Threado AI
          </TextFreePlanBanner>
        ) : (
          <TextFreePlanBanner style={{ margin: '0 10px' }}>
            You are on <span style={{ fontWeight: 700 }}>FREE</span> Threado AI plan. Upgrade to get
            most out of Threado AI
          </TextFreePlanBanner>
        )}
        <>
          <VerticalDividerFreePlan />

          <PrimaryButton
            style={{
              height: '24px',
              backgroundColor: 'transparent',
              color: colorToken.colors.purpleText,
              fontSize: '11px',
            }}
            onClick={() => {
              history.push('/settings/billing');
              dispatch(setUpgradeNowEventSourceAction('Top bar'));
              trackUserEvent('Clicked Upgrade now', { source: 'Top bar' });
            }}>
            <Image src={lockIconPurple} alt="lock" height={10} width={10} /> Upgrade Now
          </PrimaryButton>
          <VerticalDividerFreePlan />
        </>
      </BannerContainerFreePlan>
    </div>
  );
};

export default BotLaunchBannerFreePlan;
