import React, { Suspense, lazy } from 'react';
import { hydrate, render } from 'react-dom';
import { loadCommunityIdFromLocalStorage } from 'utils/segment';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MaintenancePage from 'maintenance.js';

if (typeof window !== 'undefined') {
  // Perform localStorage action
  const communityConfig = loadCommunityIdFromLocalStorage();
  if (communityConfig?.orgId === null) {
    const communityData = null;
    localStorage.setItem('selectedCommunity', communityData);
  }
}

const APP = (
  <>
    <App />
  </>
);

// render(<App />, root);
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
