import {
  CLEAR_MEMBER_ERROR,
  DELETE_MEMBER,
  SEND_MEMBERS_TOKENS,
  GET_MEMBERS_DATA,
  GET_MEMBERS_DATA_WITH_SEGMENT,
  MEMBERS_ERROR_HANDLE,
  MEMBERS_TOAST_HANDLE,
  SET_CHANNELS_FILTER,
  SET_GROUPS_FILTER,
  SET_MEMBERS_CHART_DATA,
  SET_MEMBERS_LOADING,
  SET_MEMBERS_CHART_LOADING,
  SET_MEMBERS_ROLES,
  SET_MEMBER_SEARCH_FILTER,
  SET_SEGMENTS_FILTER,
  SET_DRAFT_FILTER,
  MEMBERS_DELETE_ERROR_HANDLE,
  CLEAR_DELETE_MEMBER_ERROR,
  GET_SEGMENT_SETTINGS_SCORE,
  GET_SEGMENT_MEMBER_CHART,
  SET_SEGMENT_SETTINGS_STATUS,
  SET_MEMBER_SEGMENT_TAB_COUNT,
  SET_TOTAL_MEMBER_COUNT,
  SET_CHANNEL_DETAILS,
  SET_DEFAULT_FILTER,
  SET_SURVEY_LIST,
  SET_BULK_MEMBER_USERID,
  LOADING_BULK_MEMBER_USERID,
  RESET_BULK_MEMBER_USERID,
  MEMBER_GRID_ERROR_HANDLER,
  SET_DATA_GRID_FILTER,
  MEMBER_IMPORT_PROCESS,
  SET_MEMBER_IMPORT_PROCESSING,
  SET_MEMBER_CUSTOM_FIELDS,
  SET_MEMBER_CONFIGURE_COLUMN,
  SET_MEMBER_LIST_FROM_ID,
  SET_UNSUBSCRIBE_MEMBER_LIST,
  SET_V5_LOADING,
} from 'redux/memberProvider/types';

const initialState = {
  membersData: {
    content: [],
    page: -1,
    size: -1,
    last: false,
    totalElements: 0,
    totalPages: -1,
  },
  membersChartContent: [],
  membersToast: { success: false, message: '' },
  membersLoading: true,
  membersChartLoading: true,
  segmentsFilter: ['ALL'],
  channelsFilter: ['all'],
  memberRoles: [],
  memberRolesFilter: [],
  memberSearchFilter: '',
  memberDeleteError: undefined,
  segmentSettings: [],
  segmentMemberChartData: {},
  segmentTabCount: [],
  surveyList: [],
  bulkMessageUserIdCount: 0,
  bulkMessageUserIdCountLoading: true,
  memberImportProcessing: false,
  memberConfigureColumn: [],
  memberDataGridFilter: {
    condition: 'and',
    predicate: [],
  },
  memberListFromId: [],
};

const membersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MEMBERS_DATA:
      return {
        ...state,
        membersData: payload,
        membersLoading: false,
      };
    case GET_MEMBERS_DATA_WITH_SEGMENT:
      return {
        ...state,
        membersData: payload,
        membersLoading: false,
        segmentTabCount: payload.segments,
      };
    case SET_MEMBERS_CHART_DATA:
      return {
        ...state,
        membersChartContent: payload,
        membersChartLoading: false,
      };
    case SET_MEMBERS_ROLES:
      return {
        ...state,
        memberRoles: payload,
      };
    case SET_MEMBER_SEARCH_FILTER:
      return {
        ...state,
        memberSearchFilter: payload,
      };
    case DELETE_MEMBER:
      return {
        ...state,
        membersData: {
          ...state.membersData,
          content: state.membersData.content.filter((data) => data.id !== payload),
        },
      };
    case SEND_MEMBERS_TOKENS:
      return {
        ...state,
        membersData: {
          ...state.membersData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          content: state.membersData.content.map((data) => {
            if (data.id === payload.memberId) {
              data.reward = (Number(data.reward) || 0) + Number(payload.points);
            }
            return data;
          }),
        },
      };
    case SET_GROUPS_FILTER:
      return {
        ...state,
        memberRolesFilter: payload,
      };
    case SET_SEGMENTS_FILTER:
      return {
        ...state,
        segmentsFilter: payload,
      };
    case SET_DRAFT_FILTER:
      return {
        ...state,
        isDraftFilter: payload,
      };
    case SET_CHANNELS_FILTER:
      return {
        ...state,
        channelsFilter: payload,
      };
    case MEMBERS_ERROR_HANDLE:
      return {
        ...state,
        membersToast: { success: false, message: payload },
      };
    case MEMBER_GRID_ERROR_HANDLER:
      return {
        ...state,
        membersData: payload,
        membersLoading: false,
      };
    case MEMBERS_DELETE_ERROR_HANDLE:
      return {
        ...state,
        memberDeleteError: payload,
      };
    case MEMBERS_TOAST_HANDLE:
      return {
        ...state,
        membersToast: { ...payload },
      };
    case CLEAR_MEMBER_ERROR:
      return {
        ...state,
        membersToast: { success: false, message: '' },
      };
    case CLEAR_DELETE_MEMBER_ERROR:
      return {
        ...state,
        memberDeleteError: undefined,
      };
    case SET_MEMBERS_LOADING:
      return {
        ...state,
        membersLoading: payload,
      };
    case SET_MEMBERS_CHART_LOADING:
      return {
        ...state,
        membersChartLoading: payload,
      };
    case GET_SEGMENT_SETTINGS_SCORE:
      return {
        ...state,
        segmentSettings: payload,
      };
    case GET_SEGMENT_MEMBER_CHART:
      return {
        ...state,
        segmentMemberChartData: payload,
      };
    case SET_SEGMENT_SETTINGS_STATUS:
      return {
        ...state,
        segmentStatus: payload,
      };
    case SET_TOTAL_MEMBER_COUNT:
      return {
        ...state,
        totalMemberCount: payload,
      };
    case SET_MEMBER_SEGMENT_TAB_COUNT:
      return {
        ...state,
        segmentTabCount: payload,
      };
    case SET_CHANNEL_DETAILS:
      return {
        ...state,
        channelList: payload,
      };
    case SET_DEFAULT_FILTER:
      return {
        ...state,
        defaultFilter: payload,
      };
    case SET_SURVEY_LIST:
      return {
        ...state,
        surveyList: payload,
      };
    case SET_BULK_MEMBER_USERID:
      return {
        ...state,
        bulkMessageUserIdCount: payload?.count || 0,
        bulkMessageUserIdCountLoading: false,
      };
    case LOADING_BULK_MEMBER_USERID:
      return {
        ...state,
        bulkMessageUserIdCountLoading: true,
      };
    case SET_DATA_GRID_FILTER:
      return {
        ...state,
        memberDataGridFilter: payload,
      };
    case RESET_BULK_MEMBER_USERID:
      return {
        ...state,
        bulkMessageUserIdCount: 0,
        bulkMessageUserIdCountLoading: true,
      };

    case MEMBER_IMPORT_PROCESS:
      return {
        ...state,
        memberImportProcessing: payload.status.toLowerCase() == 'processing',
      };
    case SET_MEMBER_IMPORT_PROCESSING:
      return {
        ...state,
        memberImportProcessing: payload,
      };
    case SET_MEMBER_CUSTOM_FIELDS:
      return {
        ...state,
        memberCustomFields: payload,
      };
    case SET_MEMBER_CONFIGURE_COLUMN:
      return {
        ...state,
        memberConfigureColumn: payload,
      };
    case SET_MEMBER_LIST_FROM_ID:
      return {
        ...state,
        memberListFromId: payload,
        isV5Loading: false,
      };
    case SET_UNSUBSCRIBE_MEMBER_LIST:
      return {
        ...state,
        unsubscribeMemberList: payload,
        isV5Loading: false,
      };
    case SET_V5_LOADING:
      return {
        ...state,
        isV5Loading: payload,
      };

    default:
      return state;
  }
};

export default membersReducer;
