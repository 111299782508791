import Api from '../../../layers/interceptor/interceptor';
import { Dispatch } from 'redux';
import {
  ACTIVITY_ERROR_HANDLE,
  ACTIVITY_SUMMARY_INSIGHTS_ERROR_HANDLE,
  ACTIVITY_MEMBERS_HELPFUL_ERROR_HANDLE,
  ACTIVITY_MEMBERS_DISCUSSION_ERROR_HANDLE,
  ACTIVITY_MEMBERS_NEW_ERROR_HANDLE,
  GET_ACTIVITY_MESSAGES_POSTED_DATA,
  GET_ACTIVITY_SUMMARY_DATA,
  GET_ACTIVITY_SUMMARY_INSIGHTS_DATA,
  GET_ACTIVITY_TOP_CHANNELS_DATA,
  GET_ACTIVITY_TOP_MESSAGES_DATA,
  GET_DAILY_ACTIVE_CHANNELS_DATA,
  GET_DAILY_ACTIVE_MEMBERS_DATA,
  GET_ACTIVITY_MEMBERS_HELPFUL_DATA,
  GET_ACTIVITY_MEMBERS_DISCUSSION_DATA,
  GET_ACTIVITY_MEMBERS_NEW_DATA,
  SET_ACTIVITY_TOP_MEMBERS_DATA,
  SET_ACTIVITY_LOADING,
  SET_ACTIVITY_HELPFUL_MEMBERS_LOADING,
  SET_ACTIVITY_DISCUSSION_MEMBERS_LOADING,
  SET_ACTIVITY_MEMBERS_NEW_LOADING,
  SET_SELECTED_ACTIVITY_CHANNEL,
  SET_SELECTED_ACTIVITY_MESSAGE_INTEGRATION,
  UPDATE_ACTIVITY_INTEGRATION,
  SET_ACTIVITY_SUMMARY_INSIGHTS_LOADING,
  SET_ACTIVITY_TOP_MESSAGES_DATA,
  MEMBERS_ERROR_HANDLE,
  MEMBERS_TOAST_HANDLE,
  GET_YEAR_END_REVIEW_BANNER_DATA,
  ERROR_HANDLE_YEAR_END_REVIEW_BANNER,
  SET_SIDEBAR_TOGGLE_STATE,
} from '../types';
import {
  getMembersApiUrl,
  getActivityAllChannelsApiUrl,
  getActivityMessagesPostedApiUrl,
  getActivityProviderSummaryApiUrl,
  getActivitySummaryApiUrl,
  getActivitySummaryInsightsApiUrl,
  getActivityTopChannelsApiUrl,
  getActivityTopMessagesApiUrl,
  getActivityMembersHelpfulApiUrl,
  getActivityMembersDiscussionApiUrl,
  getActivityMembersNewApiUrl,
  getMemberBulkMessageApiUrl,
  getActivityYearEndReviewBannerApiUrl,
  postYearEndOgImageUrl,
} from '../../../constant/apiConstants';

import {
  transformActivitySummary,
  transformActivityTopChannels,
  transformTopMessagesData,
  transformMemberData,
} from '../actionUtils';

export const getMembersDataAction = (community, options) => (dispatch) => {
  const { id: communityId, timezone } = community;
  const {
    fromDate = '',
    toDate = '',
    segment = 'ALL',
    provider = 'all',
    page = 0,
    size = 10,
    sortBy = undefined,
  } = options;

  if (!fromDate.length && !toDate.length) return;
  let filters = `fromDate=${fromDate}&toDate=${toDate}&size=${size}&page=${page}&segment=${segment}&provider=${provider}`;

  if (sortBy) {
    filters += `&sortBy=${sortBy}`;
  }
  Api.get(getMembersApiUrl(communityId, filters))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: SET_ACTIVITY_TOP_MEMBERS_DATA,
        payload: transformMemberData(res.content || [], timezone),
      });
    })
    .catch(() => {
      dispatch({
        type: MEMBERS_ERROR_HANDLE,
        payload: 'Error occurred while fetching members data.',
      });
    });
};

export const getActivitySummaryAction = (communityId, fromDate, toDate) => (dispatch) => {
  dispatch(setActivityIsLoading());
  Api.get(getActivitySummaryApiUrl(communityId, fromDate, toDate))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_ACTIVITY_SUMMARY_DATA,
        payload: transformActivitySummary(res),
      });
    })
    .catch(() => {
      dispatch({
        type: ACTIVITY_ERROR_HANDLE,
        payload: 'Error occurred while fetching activity summary data.',
      });
    });
};

export const getActivitySummaryInsightsAction =
  (communityId, provider, fromDate, toDate) => (dispatch) => {
    dispatch(setActivitySummaryInsightsLoading());
    Api.get(getActivitySummaryInsightsApiUrl(communityId, provider, fromDate, toDate))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: GET_ACTIVITY_SUMMARY_INSIGHTS_DATA,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVITY_SUMMARY_INSIGHTS_ERROR_HANDLE,
          payload: 'Error occurred while fetching activity summary insights data.',
        });
      });
  };

export const getActivityMembersHelpfulAction =
  (communityId, provider, fromDate, toDate) => (dispatch) => {
    dispatch(setActivityMembersHelpfulLoading());
    Api.get(getActivityMembersHelpfulApiUrl(communityId, provider, fromDate, toDate))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: GET_ACTIVITY_MEMBERS_HELPFUL_DATA,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVITY_MEMBERS_HELPFUL_ERROR_HANDLE,
          payload: 'Error occurred while fetching activity summary helpful members data.',
        });
      });
  };

export const getActivityMembersDiscussionAction =
  (communityId, provider, fromDate, toDate) => (dispatch) => {
    dispatch(setActivityMembersDiscussionLoading());
    Api.get(getActivityMembersDiscussionApiUrl(communityId, provider, fromDate, toDate))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: GET_ACTIVITY_MEMBERS_DISCUSSION_DATA,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVITY_MEMBERS_DISCUSSION_ERROR_HANDLE,
          payload: 'Error occurred while fetching activity summary members discussion data.',
        });
      });
  };

export const getActivityMembersNewAction =
  (communityId, provider, fromDate, toDate) => (dispatch) => {
    dispatch(setActivityMembersNewLoading());
    Api.get(getActivityMembersNewApiUrl(communityId, provider, fromDate, toDate))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: GET_ACTIVITY_MEMBERS_NEW_DATA,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVITY_MEMBERS_NEW_ERROR_HANDLE,
          payload: 'Error occurred while fetching activity summary new members data.',
        });
      });
  };

export const getActivityProviderSummaryAction =
  (communityId, fromDate, toDate, channelId) => (dispatch) => {
    let filters = `fromDate=${fromDate}&toDate=${toDate}`;

    if (channelId && channelId !== '-1') {
      filters += `&channelId=${channelId}`;
    }
    dispatch(setActivityIsLoading());
    Api.get(getActivityProviderSummaryApiUrl(communityId, filters))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: GET_DAILY_ACTIVE_MEMBERS_DATA,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVITY_ERROR_HANDLE,
          payload: 'Error occurred while fetching provider summary data.',
        });
      });
  };

export const getActivityProviderSummaryChannelsAction = (communityId) => (dispatch) => {
  dispatch(setActivityIsLoading());
  Api.get(getActivityAllChannelsApiUrl(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_DAILY_ACTIVE_CHANNELS_DATA,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({
        type: ACTIVITY_ERROR_HANDLE,
        payload: 'Error occurred while fetching provider summary data.',
      });
    });
};

export const getActivityMessagesPostedAction =
  (communityId, fromDate, toDate, provider) => (dispatch) => {
    let filters = `fromDate=${fromDate}&toDate=${toDate}`;

    if (provider !== 'none') {
      filters += `&provider=${provider}`;
    }
    dispatch(setActivityIsLoading());
    Api.get(getActivityMessagesPostedApiUrl(communityId, filters))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: GET_ACTIVITY_MESSAGES_POSTED_DATA,
          payload: res,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVITY_ERROR_HANDLE,
          payload: 'Error occurred while fetching provider summary data.',
        });
      });
  };

export const getActivityTopChannelsAction = (communityId, fromDate, toDate) => (dispatch) => {
  dispatch(setActivityIsLoading());
  Api.get(getActivityTopChannelsApiUrl(communityId, fromDate, toDate))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_ACTIVITY_TOP_CHANNELS_DATA,
        payload: transformActivityTopChannels(res),
      });
    })
    .catch(() => {
      dispatch({
        type: ACTIVITY_ERROR_HANDLE,
        payload: 'Error occurred while activity top channel data.',
      });
    });
};

export const getActivityTopMessagesAction =
  (communityId, provide, fromDate, toDate) => (dispatch) => {
    dispatch(setActivityMembersTopMessageLoading());
    Api.get(getActivityTopMessagesApiUrl(communityId, provide, fromDate, toDate))
      //eslint-disable-next-line
      .then((res) => {
        dispatch({
          type: GET_ACTIVITY_TOP_MESSAGES_DATA,
          payload: transformTopMessagesData(res),
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVITY_ERROR_HANDLE,
          payload: 'Error occurred while fetching top messages.',
        });
      });
  };

export const sendMembersBulkMessageInChannelsAction = (dataPayload) => (dispatch) => {
  Api.post(getMemberBulkMessageApiUrl(), dataPayload)
    .then(() => {
      dispatch({
        type: MEMBERS_TOAST_HANDLE,
        payload: { success: true, message: 'Message sent successfully' },
      });
    })
    .catch(() => {
      dispatch({
        type: ACTIVITY_ERROR_HANDLE,
        payload: 'Error occurred while sending direct message',
      });
    });
};

export const getYearEndReviewBannerDataAction = (communityId) => (dispatch) => {
  dispatch(setYearEndReviewDataLoading());
  Api.get(getActivityYearEndReviewBannerApiUrl(communityId))
    .then((res) => {
      dispatch({
        type: GET_YEAR_END_REVIEW_BANNER_DATA,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_HANDLE_YEAR_END_REVIEW_BANNER,
        payload: 'Error occurred while fetching year end banner details',
      });
    });
};

export const saveYearEndReviewOgImageUrlAction = (communityId, payload) => (dispatch) => {
  Api.post(postYearEndOgImageUrl(communityId), payload)
    .then((res) => {
      dispatch(getYearEndReviewBannerDataAction(communityId));
    })
    .catch(() => {
      dispatch({
        type: HANDLE_YEAR_END_REVIEW_DATA_ERROR,
        payload: 'Error occurred while saving og image url.',
      });
    });
};

export const setSelectedActivityAction = (ids) => ({
  type: SET_SELECTED_ACTIVITY_CHANNEL,
  payload: ids,
});

export const setSelectedActivityMessageIntegration = (provider) => ({
  type: SET_SELECTED_ACTIVITY_MESSAGE_INTEGRATION,
  payload: provider,
});

export const setActivityIsLoading = () => ({
  type: SET_ACTIVITY_LOADING,
});

export const setActivityProviderByType = (updatedProvider) => (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVITY_INTEGRATION,
    payload: updatedProvider,
  });
};

export const setActivityMembersHelpfulLoading = () => ({
  type: SET_ACTIVITY_HELPFUL_MEMBERS_LOADING,
});

export const setActivityMembersDiscussionLoading = () => ({
  type: SET_ACTIVITY_DISCUSSION_MEMBERS_LOADING,
});

export const setActivityMembersNewLoading = () => ({
  type: SET_ACTIVITY_MEMBERS_NEW_LOADING,
});

export const setActivitySummaryInsightsLoading = () => ({
  type: SET_ACTIVITY_SUMMARY_INSIGHTS_LOADING,
});

export const setActivityMembersTopMessageLoading = () => ({
  type: SET_ACTIVITY_TOP_MESSAGES_DATA,
});

export const setYearEndReviewDataLoading = () => ({
  type: GET_YEAR_END_REVIEW_BANNER_DATA,
});

export const setSidebarToggleState = (sidebarToggleState) => (dispatch) => {
  localStorage.setItem('_sidebarToggleState', sidebarToggleState ? '1' : '0');
  dispatch({
    type: SET_SIDEBAR_TOGGLE_STATE,
    payload: sidebarToggleState,
  });
};
