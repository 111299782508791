import { maxRetryCounter } from 'utils/common';
/**
 * Resolver for chunk failure logs, function to retry loading a chunk to avoid chunk load error for out of date code
 * @param {*} componentImport
 * @param {*} name
 * @returns
 */
export const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshedCount = +JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || '0',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, '0');
        resolve(component);
      })
      .catch((error) => {
        if (hasRefreshedCount < maxRetryCounter) {
          // not been refreshed yet
          let updatedCounter = hasRefreshedCount + 1;
          window.sessionStorage.setItem(`retry-${name}-refreshed`, updatedCounter); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
