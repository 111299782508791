import React from 'react';
import { BannerContainer, BannerMessagePayments } from 'styledComponents/launchBannerStyled';
import { Image, PrimaryButton } from 'styledComponents/commonStyled';
import { useHistory } from 'react-router';
import lockWhite from 'assets/images/icons/lockWhite.svg';

const BotLaunchBannerLimitReach = ({ paymentsPlanStatus }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/settings/billing');
  };
  return (
    <>
      <div>
        <BannerContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: '4px',
              backgroundColor: '#FFFFFF',
            }}>
            <BannerMessagePayments style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              {`Your bot has reached its monthly response limit.`}
              &nbsp;
              <PrimaryButton style={{ height: '24px' }} onClick={() => handleClick()}>
                <Image src={lockWhite} alt="lock" height={10} width={10} /> Increase limit
              </PrimaryButton>
            </BannerMessagePayments>
          </div>
        </BannerContainer>
      </div>
    </>
  );
};

export default BotLaunchBannerLimitReach;
