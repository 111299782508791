import { AnyAction } from 'redux';
import {
  GET_REPORT_ACTIVITY,
  GET_REPORT_ACTIVITY_LOADING,
  GET_REPORT_ACTIVITY_ERROR,
  GET_ACTIVITY_TOP_CARD,
  GET_ACTIVITY_TOP_CARD_LOADING,
  GET_ACTIVITY_TOP_CARD_FAILED,
  GET_REPORT_SENTIMENT,
  GET_REPORT_SENTIMENT_LOADING,
  GET_REPORT_SENTIMENT_ERROR,
  GET_SENTIMENT_TOP_CARD,
  GET_SENTIMENT_TOP_CARD_LOADING,
  GET_SENTIMENT_TOP_CARD_FAILED,
  SET_GRID_TYPE,
  SET_FREQUENCY_TYPE,
  SET_ACTIVITY_TYPE,
  SET_SENTIMENT_FREQUENCY,
  CLEAN_UP_ACTION,
  GET_NEW_MEMBERS_TREND_DATA,
  GET_NEW_MEMBERS_TREND_DATA_LOADING,
  ERROR_GET_NEW_MEMBERS_TREND_DATA,
  GET_TOTAL_MEMBERS_TREND_DATA,
  GET_TOTAL_MEMBERS_TREND_DATA_LOADING,
  ERROR_TOTAL_MEMBERS_TREND_DATA,
  SET_RESPONSIVENESS_TYPE,
} from '../types';
import { ACTIVITY_TYPE, ACTIVITY_FREQUENCY, RESPONSIVENESS_TYPE } from 'constant/AnalyticsConstants';

export const initialState = {
  chartConfiguration: 'bar', //line or bar
  chartType: 'member', // member or activity
  chartDuration: 'monthly',
  chartLoading: false,
  chartLoadingError: false,
  activityTopCardDetails: null,
  activityTopCardLoading: false,
  activityTopCardError: false,
  chartData: null,
  chartDataSentiment: null,
  chartLoadingSentiment: false,
  chartLoadingErrorSentiment: false,
  sentimentTopCardDetails: null,
  sentimentTopCardLoading: false,
  sentimentTopCardError: false,
  gridType: ACTIVITY_TYPE[0].name,
  frequencyType: ACTIVITY_FREQUENCY[1].value,
  activityType: ACTIVITY_TYPE[0].value,
  chartDataNewMembers: null,
  chartLoadingNewMembers: false,
  chartLoadingErrorNewMembers: false,
  chartDataTotalMembers: null,
  chartLoadingTotalMembers: false,
  chartLoadingErrorTotalMembers: false,
  sentimentFrequency: ACTIVITY_FREQUENCY[1].value,
  responsivenessType: RESPONSIVENESS_TYPE[0].value
};
export const graphReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REPORT_ACTIVITY: {
      return {
        ...state,
        chartData: payload,
        chartLoading: false,
        chartLoadingError: false,
      };
    }
    case GET_REPORT_ACTIVITY_LOADING: {
      return {
        ...state,
        chartData: null,
        chartLoading: true,
        chartLoadingError: false,
      };
    }
    case GET_REPORT_ACTIVITY_ERROR: {
      return {
        ...state,
        chartData: null,
        chartLoading: false,
        chartLoadingError: true,
      };
    }
    case GET_ACTIVITY_TOP_CARD: {
      return {
        ...state,
        activityTopCardDetails: payload,
        activityTopCardLoading: false,
        activityTopCardError: false,
      };
    }
    case GET_ACTIVITY_TOP_CARD_LOADING: {
      return {
        ...state,
        activityTopCardDetails: payload,
        activityTopCardLoading: false,
        activityTopCardError: false,
      };
    }
    case GET_ACTIVITY_TOP_CARD_FAILED: {
      return {
        ...state,
        activityTopCardDetails: payload,
        activityTopCardLoading: false,
        activityTopCardError: false,
      };
    }

    case GET_REPORT_SENTIMENT: {
      return {
        ...state,
        chartDataSentiment: payload,
        chartLoading: false,
        chartLoadingError: false,
      };
    }
    case GET_REPORT_SENTIMENT_LOADING: {
      return {
        ...state,
        chartDataSentiment: null,
        chartLoading: true,
        chartLoadingError: false,
      };
    }
    case GET_REPORT_SENTIMENT_ERROR: {
      return {
        ...state,
        chartDataSentiment: null,
        chartLoading: false,
        chartLoadingError: true,
      };
    }
    case GET_SENTIMENT_TOP_CARD: {
      return {
        ...state,
        sentimentTopCardDetails: payload,
        sentimentTopCardLoading: false,
        sentimentTopCardError: false,
      };
    }
    case GET_SENTIMENT_TOP_CARD_LOADING: {
      return {
        ...state,
        sentimentTopCardDetails: payload,
        sentimentTopCardLoading: false,
        sentimentTopCardError: false,
      };
    }
    case GET_SENTIMENT_TOP_CARD_FAILED: {
      return {
        ...state,
        sentimentTopCardDetails: payload,
        sentimentTopCardLoading: false,
        sentimentTopCardError: false,
      };
    }
    case SET_GRID_TYPE: {
      return {
        ...state,
        gridType: payload,
      };
    }
    case SET_FREQUENCY_TYPE: {
      return {
        ...state,
        frequencyType: payload,
      };
    }
    case SET_ACTIVITY_TYPE: {
      return {
        ...state,
        activityType: payload,
      };
    }
    case SET_SENTIMENT_FREQUENCY: {
      return {
        ...state,
        sentimentFrequency: payload,
      };
    }
    case SET_RESPONSIVENESS_TYPE: {
      return {
        ...state,
        responsivenessType: payload,
      };
    }
    case CLEAN_UP_ACTION: {
      return {
        ...state,
        chartData: null,
        chartDataSentiment: null,
        gridType: ACTIVITY_TYPE[0].name,
        frequencyType: ACTIVITY_FREQUENCY[1].value,
        activityType: ACTIVITY_TYPE[0].value,
        sentimentFrequency: ACTIVITY_FREQUENCY[1].value,
        responsivenessType: RESPONSIVENESS_TYPE[0].value,
      };
    }
    case GET_NEW_MEMBERS_TREND_DATA: {
      return {
        ...state,
        chartDataNewMembers: payload,
        chartLoadingNewMembers: false,
        chartLoadingErrorNewMembers: false,
      };
    }
    case GET_NEW_MEMBERS_TREND_DATA_LOADING: {
      return {
        ...state,
        chartLoadingNewMembers: true,
        chartLoadingErrorNewMembers: false,
      };
    }
    case ERROR_GET_NEW_MEMBERS_TREND_DATA: {
      return {
        ...state,
        chartLoadingNewMembers: false,
        chartLoadingErrorNewMembers: true,
      };
    }
    case GET_TOTAL_MEMBERS_TREND_DATA: {
      return {
        ...state,
        chartDataTotalMembers: payload,
        chartLoadingTotalMembers: false,
        chartLoadingErrorTotalMembers: false,
      };
    }
    case GET_TOTAL_MEMBERS_TREND_DATA_LOADING: {
      return {
        ...state,
        chartLoadingTotalMembers: true,
        chartLoadingErrorTotalMembers: false,
      };
    }
    case ERROR_TOTAL_MEMBERS_TREND_DATA: {
      return {
        ...state,
        chartLoadingTotalMembers: false,
        chartLoadingErrorTotalMembers: true,
      };
    }
    default:
      return state;
  }
};
