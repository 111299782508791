import React, { useState, useEffect } from 'react';
import {
  CommunityItem,
  CommunityItemText,
  CommunityItemContainer,
  CommunityIntegratedImage,
  CommunityIntegrateContainer,
} from './HeaderStyle';
import { colorToken } from 'theme/designToken';
import { isDummyIntegration } from 'utils/common';
import { useSelector } from 'react-redux';
import { useGetCurrentCommunity } from '../../context/hooks';
import { staffRoleMapper } from 'constant/staffRoleData';

const CommunitySelecter = ({
  name,
  role,
  id,
  integrations,
  selectCommunity,
  selected,
  community,
}) => {
  const colors = [
    '#146152', // (RGB: 20, 97, 82)
    '#44803F', // (RGB: 68, 128, 63)
    '#B4CF66', // (RGB: 180, 207, 102)
    '#FFECC5', // Corrected from #FFECSC (RGB: 255, 236, 92)
    '#FF5A33', // Corrected from #FFSA33 (RGB: 255, 90, 51)
  ];

  const [backgroundColor, setBackgroundColor] = useState(colors[0]);

  // Effect to set a random color when the component mounts
  useEffect(() => {
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    setBackgroundColor(randomColor);
  }, []); // Empty dependency array means this will only run once, on mount

  const communityData = useSelector(useGetCurrentCommunity);
  return (
    <>
      <CommunityItem selected={selected} onClick={() => selectCommunity(id)}>
        <CommunityItemContainer>
          <div style={{ marginLeft: '0px', marginRight: '0px' }}>
            <div className="navigation-switcher-box">
              <span
                style={{ backgroundColor: backgroundColor }}
                className="navigation-switcher-box-text">
                {name.substring(0, 1)}
              </span>
            </div>
          </div>
          <div>
            <CommunityItemText>{name}</CommunityItemText>
            <div
              style={{
                display: 'flex',
                gap: '6px',
                marginTop: '8px',
              }}>
              <div className="navigation-role-box">
                <span className="navigation-role-text">
                  {staffRoleMapper[role?.toLowerCase()] || role.toLowerCase()}
                </span>
              </div>
              <CommunityIntegrateContainer>
                {integrations.map((integration) => {
                  return (
                    !isDummyIntegration(community) &&
                    integration !== 'email' &&
                    integration !== 'slackInternal' &&
                    integration !== 'twitter' && (
                      <CommunityIntegratedImage
                        key={integration}
                        src={`/icons/${integration}.svg`}
                        alt={integration}
                        width={24}
                        height={24}
                      />
                    )
                  );
                })}
              </CommunityIntegrateContainer>
            </div>
          </div>
        </CommunityItemContainer>
      </CommunityItem>
    </>
  );
};

export default CommunitySelecter;
