import { AnyAction } from 'redux';
import {
  GET_HUBSPOT_CONFIG,
  GET_HUBSPOT_CONFIG_LOADING,
  GET_HUBSPOT_CONFIG_ERROR,
  GET_HUBSPOT_HUBSPOT_DROPDOWN_LIST,
  GET_HUBSPOT_THREADO_DROPDOWN_LIST,
  GET_HUBSPOT_SEND_CONFIG,
  GET_HUBSPOT_SEND_CONFIG_LOADING,
  GET_HUBSPOT_SEND_CONFIG_ERROR,
  GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST,
  GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST_ERROR,
} from '../types';

export const initialState = {
  hubspotRecievedConfig: null,
  hubspotRecieveError: false,
  hubspotRecieveLoading: false,
  hubspotDropdownData: null,
  threadoDropdownData: null,
  threadoCustomPropertyDropdownData: null,
  hubspotSendConfig: null,
  hubspotSendError: false,
  hubspotSendLoading: false,
};

export const hubspotReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_HUBSPOT_CONFIG_LOADING: {
      return {
        ...state,
        hubspotRecieveLoading: true,
        hubspotRecieveError: false,
        hubspotRecievedConfig: null,
      };
    }
    case GET_HUBSPOT_CONFIG: {
      return {
        ...state,
        hubspotRecieveLoading: false,
        hubspotRecieveError: false,
        hubspotRecievedConfig: payload,
      };
    }
    case GET_HUBSPOT_CONFIG_ERROR: {
      return {
        ...state,
        hubspotRecieveLoading: false,
        hubspotRecieveError: true,
        hubspotRecievedConfig: null,
      };
    }
    case GET_HUBSPOT_HUBSPOT_DROPDOWN_LIST: {
      return {
        ...state,
        hubspotDropdownData: payload,
      };
    }
    case GET_HUBSPOT_THREADO_DROPDOWN_LIST: {
      return {
        ...state,
        threadoDropdownData: payload,
      };
    }
    case GET_HUBSPOT_SEND_CONFIG: {
      return {
        ...state,
        hubspotSendConfig: payload,
        hubspotSendError: false,
        hubspotSendLoading: false,
      };
    }
    case GET_HUBSPOT_SEND_CONFIG_LOADING: {
      return {
        ...state,
        hubspotSendConfig: null,
        hubspotSendError: false,
        hubspotSendLoading: true,
      };
    }
    case GET_HUBSPOT_SEND_CONFIG_ERROR: {
      return {
        ...state,
        hubspotSendConfig: null,
        hubspotSendError: true,
        hubspotSendLoading: false,
      };
    }
    case GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST: {
      return {
        ...state,
        threadoCustomPropertyDropdownData: payload,
      };
    }
    case GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST_ERROR: {
      return {
        ...state,
        threadoCustomPropertyDropdownData: [],
      };
    }
    default:
      return state;
  }
};
