export const GET_REWARD_CONFIG = 'GET_REWARD_CONFIG';
export const REWARD_TOAST_HANDLE = 'REWARD_TOAST_HANDLE';
export const GET_REWARD_INSIGHT_DETAILS = 'GET_REWARD_INSIGHT_DETAILS';
export const GET_REWARD_INSIGHT_SUMMARY = 'GET_REWARD_INSIGHT_SUMMARY';
export const REWARD_INSIGHT_TOAST_HANDLE = 'REWARD_INSIGHT_TOAST_HANDLE';
export const CLEAR_TOAST = 'CLEAR_TOAST';

export const GET_TOPIC = `GET_TOPIC`;
export const GET_UNIGRAM_TOPIC = `GET_UNIGRAM_TOPIC`;
export const GET_BIGRAM_TOPIC = `GET_BIGRAM_TOPIC`;

export const GET_TOPIC_LOADING = `GET_TOPIC_LOADING`;
export const GET_TOPIC_UNIGRAM_LOADING = 'GET_TOPIC_UNIGRAM_LOADING';
export const GET_TOPIC_BIGRAM_LOADING = 'GET_TOPIC_BIGRAM_LOADING';

export const GET_TOPIC_FAILED = 'GET_TOPIC_FAILED';
export const GET_UNIGRAM_TOPIC_FAILED = 'GET_UNIGRAM_TOPIC_FAILED';
export const GET_BIGRAM_TOPIC_FAILED = `GET_BIGRAM_TOPIC_FAILED`;
