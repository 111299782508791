import Api from 'layers/interceptor/interceptor';
import {
  getAgentAssistAnalyticsAgentsUsageListApiUrl,
  getAgentAssistAnalyticsEngagementApiUrl,
  getAgentAssistAnalyticsOverviewApiUrl,
  getAgentAssistBotResponseRateApiUrl,
  getAgentAssistCustomizeResponseApiUrl,
  getAgentAssistQueriesTrendApiUrl,
  getAgentInstallListApiUrl,
  getAgentResponseChecksApiUrl,
  getAgentsListApiUrl,
  getBotResponseLengthApiUrl,
  getInstallChromeAppNudgeStatusApiUrl,
  getLanguageSettingsApiUrl,
  getListOfLanguagesApiUrl,
  getToneDefaultListApiUrl,
  postAgentAssistSettingsApiUrl,
  postBulkInviteApiUrl,
  setBotResponseLengthApiUrl,
  setLanguagePreferenceApiUrl,
  setLanguageSelectApiUrl,
} from '../../../constant/apiConstants';
import {
  GET_AGENTS_LIST_FOR_INVITE,
  GET_AGENT_ASSIST_ANALYTICS_AGENT_LIST_DATA,
  GET_AGENT_ASSIST_ANALYTICS_BOT_RESPONSE_RATE,
  GET_AGENT_ASSIST_ANALYTICS_ENGAGEMENT_TREND,
  GET_AGENT_ASSIST_ANALYTICS_OVERVIEW,
  GET_AGENT_ASSIST_ANALYTICS_QUERIES_TREND,
  GET_BOT_RESPONSE_LENGTH_SETTINGS,
  GET_CUSTOMIZE_RESPONSE_CHROME,
  GET_CUSTOMIZE_RESPONSE_SLACK,
  GET_DEFAULT_RESPONSE_CHECKS_LIST,
  GET_DEFAULT_TONES_LIST,
  GET_INVITED_AGENTS_LIST,
  GET_LANGUAGES_LIST,
  GET_LANGUAGE_SETTINGS,
  SET_CHROME_INSTALL_STATUS_NUDGE,
} from '../types';
import { constructDateRangeURL } from 'redux/analyticsProvider/utils';

export const getDefaultTonesListAction = (addToast) => (dispatch) => {
  Api.get(getToneDefaultListApiUrl())
    .then((res) => {
      dispatch({
        type: GET_DEFAULT_TONES_LIST,
        payload: res,
      });
    })
    .catch(() => {
      addToast(`Error occurred while fetching tones list! Please try again later.`, {
        appearance: 'error',
      });
    });
};

export const getDefaultResponseChecksListAction = (addToast) => (dispatch) => {
  Api.get(getAgentResponseChecksApiUrl())
    .then((res) => {
      dispatch({
        type: GET_DEFAULT_RESPONSE_CHECKS_LIST,
        payload: res,
      });
    })
    .catch(() => {
      addToast(`Error occurred while fetching tones list! Please try again later.`, {
        appearance: 'error',
      });
    });
};

export const getAgentAssistCustomizeResponse =
  (communityId, platform, addToast, setIsLoading) => (dispatch) => {
    setIsLoading && setIsLoading(true);
    Api.get(getAgentAssistCustomizeResponseApiUrl(communityId, platform))
      .then((res) => {
        if (platform === 'CHROME') {
          dispatch({
            type: GET_CUSTOMIZE_RESPONSE_CHROME,
            payload: res,
          });
        } else if (platform === 'SLACK') {
          dispatch({
            type: GET_CUSTOMIZE_RESPONSE_SLACK,
            payload: res,
          });
        }
      })
      .catch(() => {
        addToast(`Error occured while fetching customization details! Please try again later.`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading && setIsLoading(false);
      });
  };

export const setAgentAssistCustomizeResponse = (payload, addToast, setIsLoading) => (dispatch) => {
  setIsLoading && setIsLoading(true);
  Api.post(postAgentAssistSettingsApiUrl(), payload)
    .then((res) => {
      dispatch(
        getAgentAssistCustomizeResponse(
          payload.communityId,
          payload.platform,
          addToast,
          setIsLoading,
        ),
      );
      addToast(`Successfully set Agent assist customization details!`, {
        appearance: 'success',
      });
    })
    .catch(() => {
      addToast(`Error occured while saving customization details! Please try again later.`, {
        appearance: 'error',
      });
    })
    .finally(() => {
      setIsLoading && setIsLoading(false);
    });
};

export const setAgentAssistCustomizeToneAndResponse =
  (payload, addToast, setIsLoading) => (dispatch) => {
    setIsLoading && setIsLoading(true);
    Api.post(postAgentAssistSettingsApiUrl(), payload)
      .then((res) => {
        dispatch(
          getAgentAssistCustomizeResponse(
            payload.communityId,
            payload.platform,
            addToast,
            setIsLoading,
          ),
        );
      })
      .catch(() => {
        addToast(`Error occured while saving customization details! Please try again later.`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading && setIsLoading(false);
      });
  };

export const getAgentsListForInviteActions =
  (communityId, addToast, setIsLoading) => (dispatch) => {
    setIsLoading(true);
    Api.get(getAgentsListApiUrl(communityId))
      .then((res) => {
        dispatch({
          type: GET_AGENTS_LIST_FOR_INVITE,
          payload: res,
        });
      })
      .catch((err) => {
        addToast(`Error occured while fetching agents list for invite! Please try again later.`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

/**
 *
 * @param {*} payload
 * @param {*} addToast
 * @param {*} setIsLoading
 * @param {*} platform
 * @param {*} handleClose
 * @param {*} setRefreshToken this is responsible for differentiating the api call action from Install page or Analytics page
 * @returns
 */
export const sendAgentAssistInvitesAction =
  (payload, addToast, setIsLoading, platform, handleClose, setRefreshToken) => (dispatch) => {
    setIsLoading(true);
    Api.post(postBulkInviteApiUrl(), payload)
      .then(() => {
        if (setRefreshToken) {
          setRefreshToken(new Date().toString());
          handleClose();
        } else {
          Api.get(getAgentInstallListApiUrl(payload.communityId, platform)).then((res) => {
            dispatch({
              type: GET_INVITED_AGENTS_LIST,
              payload: res,
            });
          });
          handleClose();
        }

        addToast(`You have successfully sent the invite. You can now see their status here`, {
          appearance: 'success',
        });
      })
      .catch(() => {
        addToast(`Error occured while sending invites to selected agents! Please try again later`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const getInvitedAgentsListAction =
  (communityId, platform, addToast, setIsLoading) => (dispatch) => {
    setIsLoading(true);
    Api.get(getAgentInstallListApiUrl(communityId, platform))
      .then((res) => {
        dispatch({
          type: GET_INVITED_AGENTS_LIST,
          payload: res,
        });
      })
      .catch(() => {
        addToast(`Error occured while fetching agents invite list! Please try again later`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

export const setAgentsListForInviteAction = (payload) => (dispatch) => {
  dispatch({
    type: GET_AGENTS_LIST_FOR_INVITE,
    payload: payload,
  });
};

export const getInstallChromAppNudgeStatusAction = (communityId, addToast) => (dispatch) => {
  Api.get(getInstallChromeAppNudgeStatusApiUrl(communityId))
    .then((res) => {
      dispatch({
        type: SET_CHROME_INSTALL_STATUS_NUDGE,
        payload: res,
      });
    })
    .catch((err) => {
      addToast(`Error occured while fetching chrome app install status! Please try again later`, {
        appearance: 'error',
      });
    });
};

export const getAgentAssistAnalyticsOverviewAction =
  (communityId, provider, dateRange, fromDate, toDate, addToast, isLoading) =>
  (cancelToken) =>
  async (dispatch) => {
    await Api.get(
      constructDateRangeURL(
        getAgentAssistAnalyticsOverviewApiUrl(communityId, provider),
        dateRange,
        fromDate,
        toDate,
      ),
      {
        cancelToken: cancelToken,
      },
    )
      .then((res) => {
        dispatch({
          type: GET_AGENT_ASSIST_ANALYTICS_OVERVIEW,
          payload: res?.results,
        });
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          console.error(err?.stack);
          addToast &&
            addToast('Facing issues in fetching agent assist overview data!', {
              appearance: 'error',
            });
        }
      })
      .finally(() => {
        isLoading && isLoading.setFalse();
      });
  };

export const getAgentAssistAnalyticsEngagementTrendAction =
  (communityId, provider, frequency, dateRange, fromDate, toDate, addToast, isLoading) =>
  (cancelToken) =>
  async (dispatch) => {
    Api.get(
      constructDateRangeURL(
        getAgentAssistAnalyticsEngagementApiUrl(communityId, provider, frequency),
        dateRange,
        fromDate,
        toDate,
      ),
      {
        cancelToken: cancelToken,
      },
    )
      .then((res) => {
        dispatch({
          type: GET_AGENT_ASSIST_ANALYTICS_ENGAGEMENT_TREND,
          payload: res,
        });
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          console.error(err?.stack);
          addToast &&
            addToast('Facing issues in fetching bot trending insight data!', {
              appearance: 'error',
            });
        }
      })
      .finally(() => {
        isLoading && isLoading.setFalse();
      });
  };
export const getAgentAssistAnalyticsBotResponseRateAction =
  (communityId, provider, dateRange, fromDate, toDate, addToast, isLoading) =>
  (cancelToken) =>
  async (dispatch) => {
    await Api.get(
      constructDateRangeURL(
        getAgentAssistBotResponseRateApiUrl(communityId, provider),
        dateRange,
        fromDate,
        toDate,
      ),
      {
        cancelToken: cancelToken,
      },
    )
      .then((res) => {
        dispatch({
          type: GET_AGENT_ASSIST_ANALYTICS_BOT_RESPONSE_RATE,
          payload: res?.results,
        });
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          console.error(err?.stack);
          addToast &&
            addToast('Facing issues in fetching agent assist bot response rate data!', {
              appearance: 'error',
            });
        }
      })
      .finally(() => {
        isLoading && isLoading.setFalse();
      });
  };

export const getAgentAssistAnalyticsQueriesTrendAction =
  (communityId, provider, frequency, dateRange, fromDate, toDate, addToast, isLoading) =>
  (cancelToken) =>
  async (dispatch) => {
    Api.get(
      constructDateRangeURL(
        getAgentAssistQueriesTrendApiUrl(communityId, provider, frequency),
        dateRange,
        fromDate,
        toDate,
      ),
      {
        cancelToken: cancelToken,
      },
    )
      .then((res) => {
        dispatch({
          type: GET_AGENT_ASSIST_ANALYTICS_QUERIES_TREND,
          payload: res,
        });
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          console.error(err?.stack);
          addToast &&
            addToast('Facing issues in fetching bot trending insight data!', {
              appearance: 'error',
            });
        }
      })
      .finally(() => {
        isLoading && isLoading.setFalse();
      });
  };

export const getAgentAssistAnalyticsAgentsUsageListAction =
  (
    communityId,
    provider,
    dateRange,
    fromDate,
    toDate,
    currentPage,
    searchText,
    addToast,
    isLoading,
  ) =>
  (cancelToken) =>
  async (dispatch) => {
    Api.get(
      constructDateRangeURL(
        getAgentAssistAnalyticsAgentsUsageListApiUrl(
          communityId,
          provider,
          currentPage,
          searchText,
        ),
        dateRange,
        fromDate,
        toDate,
      ),
      {
        cancelToken: cancelToken,
      },
    )
      .then((res) => {
        dispatch({
          type: GET_AGENT_ASSIST_ANALYTICS_AGENT_LIST_DATA,
          payload: res,
        });
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          console.error(err?.stack);
          addToast &&
            addToast('Facing issues in fetching bot trending insight data!', {
              appearance: 'error',
            });
        }
      })
      .finally(() => {
        isLoading && isLoading.setFalse();
      });
  };

export const getListOfLanguagesActions = (addToast) => (dispatch) => {
  Api.get(getListOfLanguagesApiUrl())
    .then((res) => {
      dispatch({
        type: GET_LANGUAGES_LIST,
        payload: res?.languageOptions,
      });
    })
    .catch(() => {
      addToast('Facing some issues from our end while fetching languages list. Try again Later!', {
        appearance: 'error',
      });
    });
};

export const getLanguagesSettingsWithTypeAction = (communityId, addToast) => (dispatch) => {
  Api.get(getLanguageSettingsApiUrl(communityId))
    .then((res) => {
      dispatch({
        type: GET_LANGUAGE_SETTINGS,
        payload: res,
      });
    })
    .catch(() => {
      addToast(
        'Facing some issues from our end while fetching language settings. Try again Later!',
        {
          appearance: 'error',
        },
      );
    });
};
export const selectAiLanguageWithTypeAction =
  (payload, addToast, setRadioLanguageTypeVal) => (dispatch) => {
    Api.post(setLanguageSelectApiUrl(), payload)
      .then((res) => {
        setRadioLanguageTypeVal(payload?.autoDetect ? 'AUTO_DETECT' : payload.language);
        addToast(`You have successfully selected your Language preference`, {
          appearance: 'success',
        });
        dispatch({
          type: GET_LANGUAGE_SETTINGS,
          payload: res,
        });
      })
      .catch(() => {
        addToast('Facing some issues while selecting your language preference. Try again Later!', {
          appearance: 'error',
        });
      });
  };


  export const getBotResponseLengthDataAction = (communityId, addToast) => (dispatch) => {
    Api.get(getBotResponseLengthApiUrl(communityId))
      .then((res) => {
        dispatch({
          type: GET_BOT_RESPONSE_LENGTH_SETTINGS,
          payload: res,
        });
      })
      .catch(() => {
        addToast(
          'Facing some issues from our end while fetching language settings. Try again Later!',
          {
            appearance: 'error',
          },
        );
      });
  };

  export const selectBotResponseLengthAction =
    (payload, addToast, setRadioBotResponseLengthVal) => (dispatch) => {
      Api.post(setBotResponseLengthApiUrl(), payload)
        .then((res) => {
          setRadioBotResponseLengthVal(payload.chatBotResponseLength);
          addToast(`You have successfully selected your bot response length preference`, {
            appearance: 'success',
          });
          dispatch({
            type: GET_BOT_RESPONSE_LENGTH_SETTINGS,
            payload: res,
          });
        })
        .catch(() => {
          addToast(
            'Facing some issues while selecting your bot response length preference. Try again Later!',
            {
              appearance: 'error',
            },
          );
        });
    };