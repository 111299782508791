import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUserInfoAction,
  loginAction,
  logoutAction,
  setLoadingAction,
  setServerErrorAction,
  setUserInfoAction,
  setVerifiedStatus,
  signupCompleteAction,
} from 'redux/authProvider/actions/authProviderAction';
import { addGlobalResponseErrorHandler } from 'layers/interceptor/interceptor';
import {
  refreshSelectedCommunityAction,
  selectCommunityAction,
  switchWorkspacePostAction,
} from 'redux/communityProvider/actions/communityAction';
import { useGetUserInfo } from '../hooks';
import { useGetIsLoading, useGetIsServerError } from '../hooks';

export const AuthContext = createContext({
  isAuthenticated: false,
  userInfo: null,
  isLoading: false,
  serverError: '',
  reloadUser: async () => {
    return;
  },
  login: async () => {
    return;
  },
  logout: async () => {
    return;
  },
  verifiedStatus: async () => {
    return;
  },
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(useGetUserInfo);
  const isLoading = useSelector(useGetIsLoading);
  const serverError = useSelector(useGetIsServerError);

  const verifiedStatus = async (verified, reason) => {
    dispatch(setVerifiedStatus(verified, reason));
  };

  useEffect(() => {
    addGlobalResponseErrorHandler(unauthorizedResponseHandler);
    loadUserInfo();
  }, []);

  const loadUserInfo = useCallback(async () => {
    dispatch(setLoadingAction(true));
    loadUserInfoAction()
      .then((res) => {
        dispatch(setUserInfoAction(res));
        dispatch(setServerErrorAction(''));
        var d = new Date();

        var datestring =
          d.getDate() +
          '-' +
          (d.getMonth() + 1) +
          '-' +
          d.getFullYear() +
          ' ' +
          d.getHours() +
          ':' +
          d.getMinutes();
      })
      .catch((error) => {
        if (error.response?.status !== 401) {
          dispatch(
            setServerErrorAction(
              error.response?.status
                ? `Server error. Status: ${error.response?.status}`
                : `Server error`,
            ),
          );
        }
      })
      .finally(() => {
        dispatch(setLoadingAction(false));
      });
  }, []);

  const unauthorizedResponseHandler = (error) => {
    if (error.response?.status === 401) {
      dispatch(setUserInfoAction(null));
    }
  };

  const reloadUser = async () => {
    const userInfo = await loadUserInfoAction();
    dispatch(setUserInfoAction(userInfo));
    dispatch(refreshSelectedCommunityAction(userInfo));
  };

  const login = async (email, password) => {
    await dispatch(loginAction(email, password));
    await loadUserInfo();
  };

  const signup = async (values) => {
    await dispatch(signupCompleteAction(values));
    await loadUserInfo();
  }
  const logout = async () => {
    try {
      await dispatch(logoutAction());
    } catch (error) {
      console.warn(
        "Logout failed. It's not a problem. It can be caused because of server is not available",
      );
    }
    dispatch(setUserInfoAction(null));
    dispatch(selectCommunityAction(null, null));
    switchWorkspacePostAction({
      id: null,
    })
      .then(() => {})
      .catch((err) => {
        console.warn('FAILED TO SET NULL');
      });
  };

  const context = {
    isAuthenticated: !!userInfo,
    userInfo,
    isLoading,
    serverError,
    reloadUser,
    signup,
    login,
    logout,
    verifiedStatus,
  };
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
