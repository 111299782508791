import { useSelector } from 'react-redux';

export const CheckIfFeatureActive = (featureName) => {
  const activeFeatures = useSelector((state) => {
    // return true || state.auth?.userInfo?.activeFeatures || [];
    return state.auth?.userInfo?.activeFeatures || [];
  });
  return activeFeatures && activeFeatures.includes(featureName);
  // return true;
};

export const CheckIfFeatureActiveForMenuLink = (featureName, activeFeatures = []) => {
  return activeFeatures && activeFeatures?.includes(featureName);
};
