import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import { PaperContainer, Image, NoViewsCreatedBox } from 'styledComponents/commonStyled';
import { createStyles, makeStyles } from '@material-ui/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetCurrentCommunity,
  useGetIsViewSavedSuccess,
  useGetSavedSupportFeedFilters,
  useGetSupportDefaultFilters,
  useGetUserPermissions,
  useGetSidebarToggleState,
} from 'context/hooks';
import { colorToken } from 'theme/designToken';
import { useToasts } from 'react-toast-notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import { GlobalStyles } from '@mui/material';
import { ToolTipCustom } from 'atoms/Tooltip';
import DeleteSupportFilterDialog from './DeleteSupportFilterDialog';
import {
  deleteSupportFilters,
  getSupportDefaultFilters,
  getSupportSavedFilters,
  setSupportFeedFilters,
  setIsViewSavedSuccessAction,
  setSelectedFilterNameAction,
} from 'redux/messagesProvider/actions/messagesAction';
import customViewFilterSelectedIcon from 'assets/images/icons/customViewFilterSelectedIcon.svg';
import customViewFilterIcon from 'assets/images/icons/customViewFilterIcon.svg';
import { supportDefaultFiltersIconMapper } from './util';
import { isEmpty } from 'lodash';
import { PlanCardTooltipText } from 'styledComponents/paymentsStyled';
import { useQuery } from 'utils/useQuery';
import { trackUserEvent } from 'utils/segment';
import { getFilterFieldNameAndValueList } from 'components/Messages/util';
import { PermissionsType } from 'utils/permissionTypes';
import {
  setPaymentsModalOpenAction,
  setUpgradeNowEventSourceAction,
} from 'redux/paymentsProvider/actions/paymentsActions';
import premiumIconGreyBG from 'assets/images/icons/premiumIconGreyBG.svg';
import { PurpleText } from 'components/Login/LoginStyled';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 175,
      maxWidth: 400,
      backgroundColor: `#FFFFF`,
    },
    accordionExpand: {
      margin: '0 !important',
    },
  }),
);

const SupportSidebar = ({ open }) => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState();
  const sidebarToggleState = useSelector(useGetSidebarToggleState);

  const supportDefaultFilters = useSelector(useGetSupportDefaultFilters);
  const supportSavedFilters = useSelector(useGetSavedSupportFeedFilters);
  const isViewSavedSuccess = useSelector(useGetIsViewSavedSuccess);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const communityData = useSelector(useGetCurrentCommunity);

  const dispatch = useDispatch();

  const [refreshFilterData, setRefreshFilterData] = useState('');
  const pageInitialConfiguration = useQuery();
  const [defaultFilterCounter, setDefaultFilterCount] = useState(1);

  // Permissions
  const userPermissions = useSelector(useGetUserPermissions);
  const [userInfoPermissionsSupport, setUserInfoPermissionsSupport] = useState(null);

  // Effect for fetching permissions
  useEffect(() => {
    if (!communityData) {
      return;
    }
    if (!userPermissions) {
      return;
    }
    if (userPermissions.SUPPORT) {
      const permissionsSupport = userPermissions.SUPPORT;
      if (!isEmpty(permissionsSupport)) {
        setUserInfoPermissionsSupport(permissionsSupport);
      }
    }
  }, [userPermissions, communityData]);

  const deleteFilter = (communityId, filterId) => {
    communityId &&
      dispatch(deleteSupportFilters(communityId, filterId, addToast, setRefreshFilterData));
  };

  const handleClose = () => {
    setDeleteOpen(false);
  };
  useEffect(() => {
    if (!communityData) {
      return;
    }
    dispatch(getSupportSavedFilters(communityData?.id));
    dispatch(getSupportDefaultFilters(communityData?.id));
    setDeleteOpen(false);
  }, [refreshFilterData]);

  const handleClickOpen = () => {
    setDeleteOpen(true);
  };

  const openDeleteFilter = () => {
    handleClickOpen();
  };

  const [filterName, setFilterName] = useState();
  const [communityId, setCommunityId] = useState();
  const [subMenuId, setSubMenuId] = useState();

  // This effect will update the selected filter name in redux store which'll be used in MessagesNew.jsx
  useEffect(() => {
    dispatch(setSelectedFilterNameAction(filterName));
  }, [filterName]);

  const [style, setStyle] = useState({ display: 'none' });

  const [hoveredFilterId, setHoveredFilterId] = useState();

  const setDataGridFilter = (filterId, criteria) => {
    let filteredFilter = filterId
      ? (supportSavedFilters || []).filter((inst) => {
          return inst.id == filterId;
        })
      : supportSavedFilters || [];

    if (filteredFilter.length == 0)
      filteredFilter = filterId
        ? (supportDefaultFilters || []).filter((inst) => {
            return inst.id == filterId;
          })
        : supportDefaultFilters || [];

    setCommunityId(communityData?.id);

    if (filteredFilter.length > 0) {
      setSelectedId(filteredFilter[0].id);
      setFilterName(filteredFilter[0].name);
      setSubMenuId(filteredFilter[0]?.id);
    }
    if (!isEmpty(criteria)) {
      dispatch(setSupportFeedFilters(criteria));
    } else if (filteredFilter?.length > 0 && setSupportFeedFilters) {
      dispatch(setSupportFeedFilters(filteredFilter[0].criteria));
    }
  };

  useEffect(() => {
    if (pageInitialConfiguration?.get('threadId')) {
      return;
    }
    if (isViewSavedSuccess) {
      if (!isEmpty(supportSavedFilters)) {
        setDataGridFilter(supportSavedFilters[0].id);
        dispatch(setIsViewSavedSuccessAction(false));
        return;
      }
    }
    if (
      typeof pageInitialConfiguration.get('isRedirect') === 'stirng' &&
      pageInitialConfiguration.get('isRedirect') === 'true'
    ) {
      return;
    }
    if (
      typeof pageInitialConfiguration.get('filter') === 'string' &&
      !isEmpty(pageInitialConfiguration.get('filter'))
    ) {
      return;
    }
    if (supportDefaultFilters?.length > 0 && defaultFilterCounter === 1) {
      setDataGridFilter(supportDefaultFilters[0].id);
      setSelectedId(supportDefaultFilters[0].id);
      setDefaultFilterCount(defaultFilterCounter + 1);
      return;
    }
  }, [supportSavedFilters, supportDefaultFilters, isViewSavedSuccess, pageInitialConfiguration]);

  useEffect(() => {
    if (typeof pageInitialConfiguration.get('redirectFilters') == 'string') {
      if (!isEmpty(pageInitialConfiguration.get('redirectFilters'))) {
        const parsedFilters = JSON.parse(pageInitialConfiguration.get('redirectFilters'));
        setDataGridFilter('', parsedFilters);
      }
    }
    return;
  }, [pageInitialConfiguration]);

  const sendUserEvent = (filter) => {
    if (!filter) {
      return;
    }
    trackUserEvent('Activity- View loaded', {
      viewName: filter.name,
      filtersApplied: getFilterFieldNameAndValueList(filter.criteria),
      type: filter.isDefault === false ? 'CUSTOM' : 'DEFAULT',
    });
  };

  const handleClickOnFilter = (filter) => {
    if (!filter?.id) {
      return;
    }
    if (userInfoPermissionsSupport?.READ === PermissionsType.PERMISSION && filter.name !== 'All') {
      handleClickPremium();
    } else {
      setDataGridFilter(filter.id);
      sendUserEvent(filter);
    }
  };

  const handleClickPremium = () => {
    dispatch(setPaymentsModalOpenAction(true));
    dispatch(setUpgradeNowEventSourceAction('Support Filter'));
    trackUserEvent('Premium icon clicked', { source: 'Support Filter' });
  };

  return (
    <>
      <GlobalStyles
        styles={{
          '.memberSideBar': {
            '& .MuiPaper-root.MuiAccordion-root ': {
              boxShadow: 'unset',
            },
            '& .MuiPaper-root.MuiAccordion-root::before ': {
              display: 'none',
            },
            '& div.MuiAccordionSummary-expandIconWrapper': {
              color: '#637da6',
            },
            '& .memberSideBar .MuiButtonBase-root.MuiAccordionSummary-root': {
              minHeight: 'unset',
              paddingLeft: '4px',
            },

            '& div.MuiAccordion-root.Mui-expanded': {
              margin: '0px',
            },

            '& .MuiAccordionSummary-expandIconWrapper': {
              marginLeft: '-5px',
            },

            '& .MuiAccordionSummary-content': {
              paddingLeft: '4px',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto !important',
            },
            '& .MuiAccordionSummary-root:hover': {
              background: '#F5F6FD',
              borderRadius: '4px',
            },
            '& .MuiAccordionDetails-root': {
              marginTop: '20px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '12px 0px !important',
            },
          },
        }}
      />
      <PaperContainer
        className="memberSideBar"
        elevation={0}
        style={{
          borderRadius: colorToken.menuSidebar.secondarySidebar.border.radius,
          display: 'flex',
          alignItems: 'stretch',
          height: 'initial',
          maxHeight: '100vh',
          minHeight: '100vh',
          position: 'fixed',
          paddingTop: '20px',
          paddingLeft: !sidebarToggleState ? '75px' : '260px',
          top: 0,
          zIndex: 999,
          overflow: 'auto',
          backgroundColor: colorToken.menuSidebar.secondarySidebar.backgroundColor,
          paddingRight: '10px',
        }}>
        <Slide direction="right" in={open} easing={'easing.sharp'} mountOnEnter unmountOnExit>
          <div className={classes.root} style={{ paddingTop: '20px' }}>
            <span
              style={{
                lineHeight: '19px',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 700,
                fontSize: '18px',
                color: '#191919',
                paddingLeft: '10px',
                paddingBottom: '19px',
                justifyContent: 'space-between',
              }}>
              <div>Activity</div>
              <div
                style={{
                  padding: '3px 5px',
                  left: '138px',
                  top: '99px',
                  background: '#F5F6FD',
                  borderRadius: '3px',
                  marginRight: '10px',
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#3E5273',
                  cursor: 'default',
                }}></div>
            </span>
            <div
              style={{
                borderBottom: '0.5px solid #E6E6E6',
              }}></div>
            <>
              <Accordion style={{ margin: '10px 0' }} defaultExpanded={true}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#3E5273',
                      textTransform: 'uppercase',
                    }}>
                    Default Views
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingRight: '0px',
                    overflowX: 'scroll',
                  }}>
                  {supportDefaultFilters?.map((subMenu, index) => (
                    <div
                      key={'_' + index}
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        background:
                          subMenu.id === selectedId || subMenu.id === hoveredFilterId
                            ? '#F1F5F9'
                            : '',
                        borderRadius: subMenu.id === selectedId ? '4px' : '',
                        padding: '4.5px 4.5px',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#4D4D4D',
                        marginBottom: '4px',
                        height: '30px',
                      }}
                      onClick={() => handleClickOnFilter(subMenu)}
                      onMouseEnter={() => {
                        setStyle({ display: 'flex' });
                        setHoveredFilterId(subMenu.id);
                      }}
                      onMouseLeave={() => {
                        setStyle({ display: 'none' });
                        setHoveredFilterId(undefined);
                      }}>
                      {supportDefaultFiltersIconMapper.hasOwnProperty(subMenu.name) && (
                        <Image
                          src={
                            supportDefaultFiltersIconMapper.hasOwnProperty(subMenu.name)
                              ? subMenu.id === selectedId
                                ? supportDefaultFiltersIconMapper[subMenu.name].iconSrcActive
                                : supportDefaultFiltersIconMapper[subMenu.name].iconSrc
                              : ''
                          }
                          height={14}
                          width={14}
                          style={{
                            marginRight: '10px',
                          }}
                        />
                      )}
                      <div
                        role="none"
                        key={index}
                        style={{
                          fontStyle: 'normal',
                          fontWeight: subMenu.id === selectedId ? 600 : 400,
                          color: '#3E5273',
                          cursor: 'pointer',
                          wordBreak: 'break-all',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          fontSize: '12px',
                          lineHeight: '14px',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        {subMenu.name.length > 18 ? (
                          <ToolTipCustom
                            arrow
                            title={<PlanCardTooltipText>{subMenu.name}</PlanCardTooltipText>}>
                            <span>{subMenu.name}</span>
                          </ToolTipCustom>
                        ) : (
                          <span>{subMenu.name}</span>
                        )}
                      </div>
                      {subMenu.name !== 'All' &&
                      userInfoPermissionsSupport?.READ === PermissionsType.PERMISSION ? (
                        <ToolTipCustom
                          arrow
                          title={
                            <PlanCardTooltipText
                              style={{ whiteSpace: 'pre-line', display: 'flex', fontSize: '10px' }}>
                              <PurpleText
                                onClick={() => handlePremiumClick()}
                                style={{
                                  display: 'flex',
                                  cursor: 'pointer',
                                  alignItems: 'center',
                                  fontWeight: 600,
                                }}>
                                Upgrade now &nbsp;
                              </PurpleText>{' '}
                              to select view
                            </PlanCardTooltipText>
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              ...style,
                              marginLeft: '5px',
                              alignItems: 'center',
                              display: 'flex',
                            }}>
                            <Image src={premiumIconGreyBG} width={20} height={20} />
                          </div>
                        </ToolTipCustom>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
            <div
              style={{
                borderBottom: '0.5px solid #E6E6E6',
              }}></div>
            <>
              <Accordion defaultExpanded={true} style={{ margin: '10px 0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1ab-content"
                  id="panel1ab-header">
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#3E5273',
                      textTransform: 'uppercase',
                    }}>
                    Custom Views
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingRight: '0px',
                    overflowX: 'scroll',
                  }}>
                  {supportSavedFilters?.length > 0 ? (
                    supportSavedFilters.map(
                      (filter, index) =>
                        filter.name.length > 0 && (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              cursor: 'pointer',
                              alignItems: 'center',
                              background:
                                filter.id === selectedId || filter.id === hoveredFilterId
                                  ? '#F1F5F9'
                                  : '',
                              padding: '4.5px 4.5px',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '14px',
                              color: '#4D4D4D',
                              marginBottom: '4px',
                              height: '30px',
                              borderRadius: '4px',
                            }}
                            onMouseEnter={() => {
                              setStyle({ display: 'flex' });
                              setHoveredFilterId(filter.id);
                            }}
                            onMouseLeave={() => {
                              setStyle({ display: 'none' });
                              setHoveredFilterId(undefined);
                            }}
                            onClick={() => {
                              if (filter.id) {
                                setDataGridFilter(filter.id);
                                sendUserEvent(filter);
                              }
                            }}>
                            <Image
                              src={
                                filter.id === selectedId
                                  ? customViewFilterSelectedIcon
                                  : customViewFilterIcon
                              }
                              height={14}
                              width={14}
                              style={{
                                marginRight: '10px',
                              }}
                            />
                            <div
                              role="none"
                              key={index}
                              style={{
                                fontStyle: 'normal',
                                fontWeight: filter.id == selectedId ? 600 : 400,
                                color: '#3E5273',
                                cursor: 'pointer',
                                width: '100%',
                                wordBreak: 'break-all',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                fontSize: '12px',
                                lineHeight: '14px',
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              {filter.name.length > 18 ? (
                                <ToolTipCustom
                                  arrow
                                  title={<PlanCardTooltipText>{filter.name}</PlanCardTooltipText>}>
                                  <span>{filter.name}</span>
                                </ToolTipCustom>
                              ) : (
                                <span>{filter.name}</span>
                              )}
                            </div>
                            {filter.id == hoveredFilterId && (
                              <>
                                {' '}
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    ...style,
                                    marginLeft: '5px',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}>
                                  <Image
                                    src="/icons/trashgrey.svg"
                                    width={20}
                                    height={20}
                                    onClick={() => {
                                      setFilterName(filter.name);
                                      setSubMenuId(filter.id);
                                      openDeleteFilter();
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ),
                    )
                  ) : (
                    <NoViewsCreatedBox>
                      <span style={{ opacity: '0.5' }}>No views created yet</span>
                    </NoViewsCreatedBox>
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          </div>
        </Slide>
      </PaperContainer>
      <DeleteSupportFilterDialog
        handleClose={handleClose}
        deleteFilter={deleteFilter}
        open={deleteOpen}
        filterName={filterName}
        communityId={communityId}
        subMenuId={subMenuId}
      />
    </>
  );
};

export default SupportSidebar;
