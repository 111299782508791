import { pageSize } from 'constant/paginationConstants';
import {
  ERROR_SEND_TEST_BOT_MESSAGE,
  SEND_TEST_CHATBOT_MESSAGE,
  SEND_TEST_CHATBOT_MESSAGE_LOADING,
  GET_CHATBOT_LISTEN,
  GET_CHATBOT_SETTINGS,
  SET_CHATBOT_LISTEN,
  SET_CHATBOT_STATUS,
  SET_PDF_LIST_AFTER_DELETE,
  GET_DATA_SOURCE_STATUS_DETAILS,
  SET_CRAWL_URL_LIST_AFTER_DELETE,
  GET_CHILD_URLS,
  GET_CHILD_URLS_LIST,
  SET_CHILD_URLS_AFTER_DELETE,
  SET_BOT_PROVIDER_FILTER,
  GET_BOT_STATIC_DATA,
  GET_BOT_ACTIVITY,
  GET_BOT_ACTIVITY_LOADING,
  GET_BOT_ACTIVITY_ERROR,
  GET_WIDGET_EMBED_CODE_SNIPPET_DATA,
  SET_CRAWL_URL_AFTER_TRAIN,
  GET_QUESTION_PRIVATE_SETTINGS,
  GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_DETAILS,
  GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_LOADING,
  ERROR_DATA_SOURCE_DETAILS_BY_INTEGRATION,
  GET_DATA_SOURCE_DETAILS_CONFLUENCE,
  GET_DATA_SOURCE_DETAILS_CONFLUENCE_LOADING,
  ERROR_DATA_SOURCE_DETAILS_CONFLUENCE,
  GET_DATA_SOURCE_DETAILS_FRESHDESK,
  ERROR_DATA_SOURCE_DETAILS_FRESHDESK,
  GET_DATA_SOURCE_DETAILS_FRESHDESK_LOADING,
  GET_DATA_SOURCE_DETAILS_NOTION,
  GET_DATA_SOURCE_DETAILS_NOTION_LOADING,
  ERROR_DATA_SOURCE_DETAILS_NOTION,
  GET_ZENDESK_TICKETS_ASSIGNEE_LIST,
  GET_ZENDESK_TICKETS_TAGS_LIST,
  SET_TRAIN_TICKETS_FILTER,
  GET_FRESHDESK_TICKETS_ASSIGNEE_LIST,
  GET_FRESHDESK_TICKETS_TAGS_LIST,
  SET_FRESHDESK_TRAIN_TICKETS_FILTER,
  GET_ZENDESK_TICKETS_GROUPS_LIST,
  GET_FRESHDESK_TICKETS_GROUPS_LIST,
  SET_TABULAR_DATA_LIST_AFTER_DELETE,
  GET_INTERCOM_TICKETS_ASSIGNEE_LIST,
  GET_INTERCOM_TICKETS_TAGS_LIST,
  GET_INTERCOM_TICKETS_GROUPS_LIST,
  SET_INTERCOM_TRAIN_TICKETS_FILTER,
  SET_INSTALL_CHANNELS_LIST,
  GET_HUBSPOT_TICKETS_ASSIGNEE_LIST,
  GET_HUBSPOT_TICKETS_TAGS_LIST,
  GET_HUBSPOT_TICKETS_GROUPS_LIST,
  SET_HUBSPOT_TRAIN_TICKETS_FILTER,
  GET_SALESFORCE_TICKETS_ASSIGNEE_LIST,
  GET_SALESFORCE_TICKETS_TAGS_LIST,
  SET_SALESFORCE_TRAIN_TICKETS_FILTER,
  GET_SALESFORCE_TICKETS_GROUPS_LIST,
} from '../types';
import {
  ERROR_DATA_SOURCE_DETAILS,
  ERROR_SET_DATA_SOURCE_DETAILS,
  GET_DATA_SOURCE_DETAILS,
  GET_DATA_SOURCE_DETAILS_LOADING,
  SET_DATA_SOURCE_DETAILS,
  SET_DATA_SOURCE_DETAILS_LOADING,
  GET_BOT_CUSTOMIZATION_DETAILS,
  GET_BOT_CUSTOMIZATION_DETAILS_LOADING,
  GET_BOT_CUSTOMIZATION_DETAILS_ERROR,
  SET_BOT_CUSTOMIZATION_DETAILS,
  SET_BOT_CUSTOMIZATION_DETAILS_LOADING,
  SET_BOT_CUSTOMIZATION_DETAILS_ERROR,
  IS_ADD_EDIT_SIDEBAR_OPEN,
} from '../types';

export const initialState = {
  dataSourceDetails: null,
  dataSourceStatusDetails: null,
  dataSourceDetailsLoading: false,
  errorDataSourceDetails: false,
  dataSourceZendeskDetails: null,
  dataSourceZendeskLoading: false,
  errorDataSourceZendesk: false,
  dataSourceFreshdeskDetails: null,
  dataSourceFreshdeskLoading: false,
  errorDataSourceFreshdesk: false,
  dataSourceConfluenceDetails: null,
  dataSourceConfluenceLoading: false,
  errorDataSourceConfluence: false,
  dataSourceNotionDetails: null,
  dataSourceNotionLoading: false,
  errorDataSourceNotion: false,
  testBotMessageLoading: false,
  testBotMessageResponse: '',
  errorTestBotMessage: false,
  fetchDataCustomizationLoading: false,
  fetchDataCustomizationError: false,
  dataCustomizationDetails: null,
  setDataCustomizationLoading: false,
  setDataCustomizationError: false,
  chatbotStatus: null,
  chatbotSettings: null,
  chatbotListen: null,
  botProviderFilter: null,
  botStaticData: null,
  childURLs: {
    syncedUrlsCount: null,
    syncingUrlsCount: null,
    totalUrlsCount: null,
    failedUrlsCount: null,
    outboundUrls: [],
  },
  childUrlsList: null,
  chartData: null,
  chartLoading: false,
  chartLoadingError: false,
  webSnippetData: {},
  questionPrivateSettings: null,
  zendeskTicketsAssigneeList: [],
  zendeskTicketsTagsList: [],
  zendeskTicketsGroupsList: [],
  trainTicketsDataFilter: {
    condition: 'and',
    predicate: [],
  },
  freshdeskTicketsAssigneeList: [],
  freshdeskTicketsTagsList: [],
  freshdeskTicketsGroupsList: [],
  freshdeskTrainTicketsDataFilter: {
    condition: 'and',
    predicate: [],
  },
  intercomTicketsAssigneeList: [],
  intercomTicketsTagsList: [],
  intercomTicketsGroupsList: [],
  intercomTrainTicketsDataFilter: {
    condition: 'and',
    predicate: [],
  },
  hubspotTicketsAssigneeList: [],
  hubspotTicketsTagsList: [],
  hubspotTicketsGroupsList: [],
  hubspotTrainTicketsDataFilter: {
    condition: 'and',
    predicate: [],
  },
  salesforceTicketsAssigneeList: [],
  salesforceTicketsTagsList: [],
  salesforceTicketsGroupsList: [],
  salesforceTrainTicketsDataFilter: {
    condition: 'and',
    predicate: [],
  },
  installChannelsList: [],
  addEditSidebarOpenState: false,
};

export const chatbotReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DATA_SOURCE_DETAILS: {
      return {
        ...state,
        dataSourceDetails: payload,
        dataSourceDetailsLoading: false,
        errorDataSourceDetails: false,
      };
    }
    case GET_DATA_SOURCE_STATUS_DETAILS: {
      return {
        ...state,
        dataSourceStatusDetails: payload,
      };
    }
    case GET_DATA_SOURCE_DETAILS_LOADING: {
      return {
        ...state,
        dataSourceDetailsLoading: true,
      };
    }
    case ERROR_DATA_SOURCE_DETAILS: {
      return {
        ...state,
        dataSourceDetailsLoading: false,
        errorDataSourceDetails: true,
      };
    }
    case GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_DETAILS: {
      return {
        ...state,
        dataSourceZendeskDetails: payload,
        dataSourceZendeskLoading: false,
        errorDataSourceZendesk: false,
      };
    }

    case GET_DATA_SOURCE_DETAILS_BY_INTEGRATION_LOADING: {
      return {
        ...state,
        dataSourceZendeskLoading: true,
      };
    }
    case ERROR_DATA_SOURCE_DETAILS_BY_INTEGRATION: {
      return {
        ...state,
        dataSourceZendeskLoading: false,
        errorDataSourceZendesk: true,
      };
    }

    case GET_DATA_SOURCE_DETAILS_CONFLUENCE: {
      return {
        ...state,
        dataSourceConfluenceDetails: payload,
        dataSourceConfluenceLoading: false,
        errorDataSourceConfluence: false,
      };
    }

    case GET_DATA_SOURCE_DETAILS_CONFLUENCE_LOADING: {
      return {
        ...state,
        dataSourceConfluenceLoading: true,
      };
    }
    case ERROR_DATA_SOURCE_DETAILS_CONFLUENCE: {
      return {
        ...state,
        dataSourceConfluenceLoading: false,
        errorDataSourceConfluence: true,
      };
    }

    case GET_DATA_SOURCE_DETAILS_FRESHDESK: {
      return {
        ...state,
        dataSourceFreshdeskDetails: payload,
        dataSourceFreshdeskLoading: false,
        errorDataSourceFreshdesk: false,
      };
    }

    case GET_DATA_SOURCE_DETAILS_FRESHDESK_LOADING: {
      return {
        ...state,
        dataSourceFreshdeskLoading: true,
      };
    }
    case ERROR_DATA_SOURCE_DETAILS_FRESHDESK: {
      return {
        ...state,
        dataSourceFreshdeskLoading: false,
        errorDataSourceFreshdesk: true,
      };
    }
    case GET_DATA_SOURCE_DETAILS_NOTION: {
      return {
        ...state,
        dataSourceNotionDetails: payload,
        dataSourceNotionLoading: false,
        errorDataSourceNotion: false,
      };
    }

    case GET_DATA_SOURCE_DETAILS_NOTION_LOADING: {
      return {
        ...state,
        dataSourceNotionLoading: true,
      };
    }
    case ERROR_DATA_SOURCE_DETAILS_NOTION: {
      return {
        ...state,
        dataSourceNotionLoading: false,
        errorDataSourceNotion: true,
      };
    }
    case SET_DATA_SOURCE_DETAILS: {
      return {
        ...state,
        dataSourceDetails: true,
        dataSourceDetailsLoading: false,
        errorDataSourceDetails: false,
      };
    }
    case SET_DATA_SOURCE_DETAILS_LOADING: {
      return {
        ...state,
        dataSourceDetailsLoading: payload,
        errorDataSourceDetails: false,
      };
    }
    case ERROR_SET_DATA_SOURCE_DETAILS: {
      return {
        ...state,
        dataSourceDetailsLoading: false,
        errorDataSourceDetails: true,
      };
    }
    case SEND_TEST_CHATBOT_MESSAGE: {
      return {
        ...state,
        testBotMessageResponse: payload,
        testBotMessageLoading: false,
        errorTestBotMessage: false,
      };
    }
    case SEND_TEST_CHATBOT_MESSAGE_LOADING: {
      return {
        ...state,
        testBotMessageLoading: true,
      };
    }
    case ERROR_SEND_TEST_BOT_MESSAGE: {
      return {
        ...state,
        testBotMessageLoading: false,
        errorTestBotMessage: true,
      };
    }
    case GET_BOT_CUSTOMIZATION_DETAILS: {
      return {
        ...state,
        fetchDataCustomizationLoading: false,
        fetchDataCustomizationError: false,
        dataCustomizationDetails: payload,
      };
    }
    case GET_BOT_CUSTOMIZATION_DETAILS_LOADING: {
      return {
        ...state,
        fetchDataCustomizationLoading: true,
        fetchDataCustomizationError: false,
        dataCustomizationDetails: null,
      };
    }
    case GET_BOT_CUSTOMIZATION_DETAILS_ERROR: {
      return {
        ...state,
        fetchDataCustomizationLoading: false,
        fetchDataCustomizationError: true,
        dataCustomizationDetails: null,
      };
    }
    case SET_BOT_CUSTOMIZATION_DETAILS: {
      return {
        ...state,
        setDataCustomizationLoading: false,
        setDataCustomizationError: false,
      };
    }
    case SET_BOT_CUSTOMIZATION_DETAILS_LOADING: {
      return {
        ...state,
        setDataCustomizationLoading: true,
        setDataCustomizationError: false,
      };
    }
    case SET_BOT_CUSTOMIZATION_DETAILS_ERROR: {
      return {
        ...state,
        setDataCustomizationLoading: false,
        setDataCustomizationError: true,
      };
    }
    case SET_CHATBOT_STATUS: {
      return {
        ...state,
        chatbotStatus: payload,
      };
    }
    case GET_CHATBOT_SETTINGS: {
      return {
        ...state,
        chatbotSettings: payload,
      };
    }
    case GET_CHATBOT_LISTEN: {
      return {
        ...state,
        chatbotListen: payload,
      };
    }
    case SET_CHATBOT_LISTEN: {
      return {
        ...state,
        chatbotListen: payload,
      };
    }

    case GET_BOT_STATIC_DATA: {
      return {
        ...state,
        botStaticData: payload,
      };
    }

    case GET_BOT_ACTIVITY: {
      return {
        ...state,
        chartData: payload,
        chartLoading: false,
        chartLoadingError: false,
      };
    }
    case GET_BOT_ACTIVITY_LOADING: {
      return {
        ...state,
        chartData: null,
        chartLoading: true,
        chartLoadingError: false,
      };
    }
    case GET_BOT_ACTIVITY_ERROR: {
      return {
        ...state,
        chartData: null,
        chartLoading: false,
        chartLoadingError: true,
      };
    }

    case SET_PDF_LIST_AFTER_DELETE: {
      const pdfsAfterRemovingById = state?.dataSourceDetails?.pdf?.filter(
        (pdfItem) => pdfItem.id !== payload,
      );
      return {
        ...state,
        dataSourceDetails: { ...state.dataSourceDetails, pdf: pdfsAfterRemovingById },
      };
    }
    case SET_CRAWL_URL_LIST_AFTER_DELETE: {
      const urlsAfterRemovingById = state?.dataSourceDetails?.crawlURL?.filter(
        (urlItem) => urlItem.id !== payload,
      );
      return {
        ...state,
        dataSourceDetails: { ...state.dataSourceDetails, crawlURL: urlsAfterRemovingById },
      };
    }
    case SET_TABULAR_DATA_LIST_AFTER_DELETE: {
      const csvsAfterRemovingById = state?.dataSourceDetails?.tabular?.filter(
        (item) => item.id !== payload,
      );
      return {
        ...state,
        dataSourceDetails: { ...state.dataSourceDetails, tabular: csvsAfterRemovingById },
      };
    }
    case GET_CHILD_URLS: {
      return {
        ...state,
        childURLs: payload,
      };
    }
    case GET_CHILD_URLS_LIST: {
      return {
        ...state,
        childUrlsList: payload,
      };
    }
    case SET_BOT_PROVIDER_FILTER: {
      return {
        ...state,
        botProviderFilter: payload,
      };
    }

    case SET_CHILD_URLS_AFTER_DELETE: {
      const childURLsListAfterRemovingById = state.childUrlsList.content.filter(
        (childUrlItem) => childUrlItem.id !== payload,
      );
      return {
        ...state,
        childUrlsList: { ...state.childUrlsList, content: childURLsListAfterRemovingById },
      };
    }
    case GET_WIDGET_EMBED_CODE_SNIPPET_DATA: {
      return {
        ...state,
        webSnippetData: payload,
      };
    }
    case SET_CRAWL_URL_AFTER_TRAIN: {
      return {
        ...state,
        dataSourceDetails: { ...state.dataSourceDetails, crawlURL: payload },
      };
    }
    case GET_QUESTION_PRIVATE_SETTINGS: {
      return {
        ...state,
        questionPrivateSettings: payload,
      };
    }
    case GET_ZENDESK_TICKETS_ASSIGNEE_LIST: {
      return {
        ...state,
        zendeskTicketsAssigneeList: payload,
      };
    }
    case GET_ZENDESK_TICKETS_TAGS_LIST: {
      return {
        ...state,
        zendeskTicketsTagsList: payload,
      };
    }
    case GET_ZENDESK_TICKETS_GROUPS_LIST: {
      return {
        ...state,
        zendeskTicketsGroupsList: payload,
      };
    }
    case SET_TRAIN_TICKETS_FILTER:
      return {
        ...state,
        trainTicketsDataFilter: payload,
      };
    case GET_FRESHDESK_TICKETS_ASSIGNEE_LIST: {
      return {
        ...state,
        freshdeskTicketsAssigneeList: payload,
      };
    }
    case GET_FRESHDESK_TICKETS_TAGS_LIST: {
      return {
        ...state,
        freshdeskTicketsTagsList: payload,
      };
    }
    case GET_FRESHDESK_TICKETS_GROUPS_LIST: {
      return {
        ...state,
        freshdeskTicketsGroupsList: payload,
      };
    }
    case SET_FRESHDESK_TRAIN_TICKETS_FILTER:
      return {
        ...state,
        freshdeskTrainTicketsDataFilter: payload,
      };
    case GET_INTERCOM_TICKETS_ASSIGNEE_LIST: {
      return {
        ...state,
        intercomTicketsAssigneeList: payload,
      };
    }
    case GET_INTERCOM_TICKETS_TAGS_LIST: {
      return {
        ...state,
        intercomTicketsTagsList: payload,
      };
    }
    case GET_INTERCOM_TICKETS_GROUPS_LIST: {
      return {
        ...state,
        intercomTicketsGroupsList: payload,
      };
    }
    case SET_INTERCOM_TRAIN_TICKETS_FILTER:
      return {
        ...state,
        intercomTrainTicketsDataFilter: payload,
      };
    case SET_INSTALL_CHANNELS_LIST:
      return {
        ...state,
        installChannelsList: payload,
      };
    case GET_HUBSPOT_TICKETS_ASSIGNEE_LIST: {
      return {
        ...state,
        hubspotTicketsAssigneeList: payload,
      };
    }
    case GET_HUBSPOT_TICKETS_TAGS_LIST: {
      return {
        ...state,
        hubspotTicketsTagsList: payload,
      };
    }
    case GET_HUBSPOT_TICKETS_GROUPS_LIST: {
      return {
        ...state,
        hubspotTicketsGroupsList: payload,
      };
    }
    case SET_HUBSPOT_TRAIN_TICKETS_FILTER:
      return {
        ...state,
        hubspotTrainTicketsDataFilter: payload,
      };

    case GET_SALESFORCE_TICKETS_ASSIGNEE_LIST: {
      return {
        ...state,
        salesforceTicketsAssigneeList: payload,
      };
    }
    case GET_SALESFORCE_TICKETS_TAGS_LIST: {
      return {
        ...state,
        salesforceTicketsTagsList: payload,
      };
    }
    case GET_SALESFORCE_TICKETS_GROUPS_LIST: {
      return {
        ...state,
        salesforceTicketsGroupsList: payload,
      };
    }
    case SET_SALESFORCE_TRAIN_TICKETS_FILTER:
      return {
        ...state,
        salesforceTrainTicketsDataFilter: payload,
      };

    case IS_ADD_EDIT_SIDEBAR_OPEN:
      return {
        ...state,
        addEditSidebarOpenState: payload,
      };
    default:
      return state;
  }
};
