import React from 'react';
import { get } from 'lodash';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import { colorToken } from 'theme/designToken';

const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colorToken.inputFields.text.border.color} !important;
    border-radius: 4px;
  }
  .Mui-focused {
    border-color: ${colorToken.inputFields.text.border.color} !important;
  }
`;

const MatTextFieldWrapper = (props) => {
  if (props.formik) {
    return (
      <CustomTextField
        {...props}
        value={props.name && get(props.formik.values, props.name)}
        error={
          !!props.name &&
          !!get(props.formik.errors, props.name) &&
          !!get(props.formik.touched, props.name)
        }
        onChange={props.onChange || props.formik.handleChange}
        onBlur={() => {
          props.name && props?.formik?.setFieldTouched(props.name);
        }}
        helperText={
          !!props.name &&
          !!get(props.formik.touched, props.name) &&
          get(props.formik.errors, props.name)
        }
      />
    );
  }
  return (
    <>
      <CustomTextField {...props} />
    </>
  );
};

export default MatTextFieldWrapper;
