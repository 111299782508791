import {
  INTERNAL_SLACK_CONNECT_DETAILS_LOADING,
  GET_INTERNAL_SLACK_CONNECT_DETAILS,
  ERROR_INTERNAL_SLACK_CONNECT_DETAILS,
} from '../types';

const initialState = {
  slackInternalDetails: null,
  isSlackInternalLoading: false,
  errorSlackInternal: false,
};

export const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INTERNAL_SLACK_CONNECT_DETAILS_LOADING:
      return {
        ...state,
        isSlackInternalLoading: true,
        errorSlackInternal: false,
      };
    case GET_INTERNAL_SLACK_CONNECT_DETAILS:
      return {
        ...state,
        slackInternalDetails: payload,
        isSlackInternalLoading: false,
        errorSlackInternal: false,
      };
    case ERROR_INTERNAL_SLACK_CONNECT_DETAILS:
      return {
        ...state,
        slackInternalDetails: null,
        isSlackInternalLoading: false,
        errorSlackInternal: true,
      };
    default:
      return state;
  }
};
