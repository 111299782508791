export const GET_THREAD_DATA = 'GET_THREAD_DATA';
export const SET_SHOW_THREAD_COMPLETELY = 'SET_SHOW_THREAD_COMPLETELY';
export const SET_THREAD_NOTIFICATION = 'SET_THREAD_NOTIFICATION';
export const DELETE_THREAD = 'DELETE_THREAD';
export const SET_THREAD_LOADING = 'SET_THREAD_LOADING';
export const SET_MESSAGES_CHANNEL_FILTER = 'SET_MESSAGES_CHANNEL_FILTER';
export const SET_MESSAGES_STATUS_FILTER = 'SET_MESSAGES_STATUS_FILTER';
export const SET_MESSAGES_PROVIDER_CHANNEL_FILTER = 'SET_MESSAGES_PROVIDER_CHANNEL_FILTER';
export const CLEAR_THREAD_NOTIFICATION = 'CLEAR_THREAD_NOTIFICATION';
export const SET_SUPPORT_FEED_FILTERS = 'SET_SUPPORT_FEED_FILTERS';
export const SET_SUPPORT_DRAFT_FILTER = 'SET_SUPPORT_DRAFT_FILTER';
export const SET_SUPPORT_SAVED_FILTERS = 'SET_SUPPORT_SAVED_FILTERS';
export const SET_SUPPORT_DEFAULT_FILTERS = 'SET_SUPPORT_DEFAULT_FILTERS';
export const SET_IS_VIEW_SAVED_SUCCESS = 'SET_IS_VIEW_SAVED_SUCCESS';
export const SET_THREADS_DATA_TO_DEFAULT = 'SET_THREADS_DATA_TO_DEFAULT';
export const SET_THREAD_DATA_AFTER_REPLY = 'SET_THREAD_DATA_AFTER_REPLY';
export const GET_SUPPORT_THREAD_DATA = 'GET_SUPPORT_THREAD_DATA';
export const SET_THREAD_LOADING_INFINITE_SCROLL = 'SET_THREAD_LOADING_INFINITE_SCROLL';
export const GET_SUPPORT_THREAD_DATA_USING_SCROLL = 'GET_SUPPORT_THREAD_DATA_USING_SCROLL';
export const SET_MESSAGE_REPLY_LOADER = 'SET_MESSAGE_REPLY_LOADER';
export const SET_MESSAGE_TOPICS_LIST = 'SET_MESSAGE_TOPICS_LIST';
export const SET_SELECTED_FILTER_NAME = 'SET_SELECTED_FILTER_NAME';