import styled from '@emotion/styled';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import {
  borderGreyColor,
  primaryBackgroundColor,
  tableCellBlackColor,
  tableCellGreenColor,
  tableCellGreyColor,
  tableCellLightGreenColor,
  tableHeaderBackgroundColor,
  tableHeaderColor,
  whiteColor,
  yellowColor,
} from '../theme/colorConstants';
import { colorToken } from '../theme/designToken';

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 100%;
`;

export const DetailsBox = styled(Paper)`
  min-width: 390px;
  padding: 15px 24px;
  box-shadow: none;
  border-radius: 14px;
  margin-right: 0px;
`;

export const DetailGreyBox = styled.div`
  background: ${primaryBackgroundColor};
  min-width: 342px;
  border-radius: 8px;
  padding: 20px;
  margin-top: 14px;
`;

export const DetailHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailTitle = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${tableCellBlackColor};
`;

export const SmallTitle = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${tableHeaderColor};
  margin-bottom: 5px;
`;

export const PlanText = styled(Typography)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${tableCellGreenColor};
`;

export const GreyBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
`;

export const BillText = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${tableCellBlackColor};
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 14px 0;
`;
export const FeatureOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const FeatureText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${tableCellBlackColor};
`;

export const CardText = styled(Typography)`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${tableHeaderColor};
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 10px;
`;

export const EditButton = styled(Button)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-transform: capitalize;
  color: ${tableCellGreenColor};
`;

export const StatusText = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${tableCellGreenColor};
`;

export const SubscriptionContainer = styled.div`
  background: ${whiteColor};
  border-radius: 14px;
  margin-top: 30px;
  padding: 0 10px 10px 24px;
`;

export const PlanSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const PlanTypeText = styled.div`
  font-weight: ${(props) => (props.selected ? 'bold' : '400')};
  font-size: 14px;
  line-height: 19px;
  color: ${tableCellBlackColor};
`;

export const PlanSmallText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${tableCellGreyColor};
`;

export const SelectedPlanText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.selected ? tableCellGreenColor : tableCellBlackColor)};
`;

export const SelectedPlanContainer = styled.div`
  background: ${(props) => (props.selected ? 'transparent' : tableHeaderBackgroundColor)};
  ${(props) => props.bordertop && 'border-top-right-radius: 8px; border-top-left-radius: 8px;'}
  ${(props) =>
    props.borderbottom && 'border-bottom-right-radius: 8px; border-bottom-left-radius: 8px;'}
  display: flex;
  padding: 20px;
  ${(props) => props.selected && `border: 1px solid ${tableCellGreenColor};`}
  border-bottom: 0;
  border-top: 0;
  ${(props) =>
    props.selected && props.borderbottom && `border-bottom: 1px solid ${tableCellGreenColor};`}
  ${(props) => props.selected && props.bordertop && `border-top: 1px solid ${tableCellGreenColor};`}
  flex-direction: column;
  gap: 6px;
`;

export const SelectedPlanPriceText = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.selected ? tableCellGreenColor : tableCellBlackColor)};
`;

export const PlanContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const CustomGrid = styled(Grid)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid ${borderGreyColor};
  }
`;

export const ChoosePlanButton = styled(Button)`
  background-color: ${(props) => (props.selected ? borderGreyColor : yellowColor)};
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none;
  padding: ${(props) => (props.selected ? '10px 20px' : '10px 12px')};
  text-transform: capitalize;
  gap: 3px;
  color: ${tableCellBlackColor};
  &:hover {
    background-color: ${yellowColor};
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const PlanPurchasedInfoContainer = styled.div`
  background-color: ${colorToken.colors.white};
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 0;
`;

export const CurrentPlanBoldText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  color: ${tableCellBlackColor};
`;

export const CurrentPlanNormalText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: ${tableCellBlackColor};
`;

export const DisplayFlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const GreyBgBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: ${tableCellBlackColor};
  padding: 5px;
  font-size: 10px;
`;

export const CurrentPlanStatusBox = styled.div`
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 4px;
  background-color: ${(props) => (props.active ? tableCellLightGreenColor : yellowColor)};
  color: ${(props) => (props.active ? tableCellGreenColor : '#5C5C5C')};
  margin-left: 10px;
  margin-right: 10px;
`;

export const CurrentPlanStatusCancelBox = styled.div`
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 4px;
  background-color: #ffe6e6;
  color: #ff6262;
  margin-left: 10px;
  margin-right: 10px;
`;

export const CurrentPlanFeatureBox = styled.div`
  display: flex;
  align-items: center;
`;

export const PlanDetailsCardContainer = styled.div`
  border-radius: 8px;
  width: 253px;
  position: relative;
  overflow: hidden;
`;

export const PlanDetailsCardTopContainer = styled.div`
  padding: 16px;
  position: relative;
`;

export const PlanDetailsCardBottomContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
`;

export const CancelSubModalTopContainer = styled.div`
  background-color: #ffefbe;
  position: relative;
  display: flex;
  padding: 20px 50px 20px 50px;
  justifycontent: center;
  flex-direction: column;
  align-items: center;
`;

export const CancelReasonItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  border: 1px solid ${(props) => (props.is_selected ? colorToken.colors.purpleText : '#eaeaea')};
  border-radius: 4px;
  margin: 10px 0;
  cursor: pointer;
  box-shadow: 0px 3.38275px 11.8396px rgba(0, 0, 0, 0.05);
`;

export const CancelReasonText = styled(Typography)`
  color: ${colorToken.colors.black};
  font-weight: ${(props) => (props.is_selected ? 600 : 400)};
  font-size: 12px;
`;

export const VerticalDivider = styled.div`
  height: 14px;
  background-color: #ebebeb;
  width: 1px;
`;

export const PlanDetailsCardCancelBox = styled.div`
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 4px;
  background-color: #ff6262;
  color: ${colorToken.colors.white};
  height: 22px;
  margin-left: 12px;
`;

export const PlanDetailsCardTitle = styled(Typography)`
  font-weight: 800;
  font-size: 18px;
  // line-height: 22px;
  color: ${colorToken.colors.black};
  text-align: center;
  margin-bottom: 10px;
  height: 27px;
`;

export const PlanDetailsCardSubTitle = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${colorToken.colors.black};
  text-align: center;
`;

export const PaymentCardPrice = styled(Typography)`
  font-weight: 800;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #533c94;
`;

export const PaymentCardInnerBoxOne = styled.div`
  background: #f8f8f8;
  border-radius: 4px;
  padding: 10px 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16x;
  text-align: center;
  color: #533c94;
  width: 100%;
`;

export const PaymentCardCurrentPlanBox = styled.div`
  background: #ffffff;
  border: 1px solid #696dec;
  border-radius: 4px;
  padding: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  letter-spacing: 0.02857em;
  color: #696dec;
`;

export const PaymentCardPlanExpiryText = styled.div`
  background: #ffefbe;
  padding: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #191919;
  margin: 10px 0;
  position: absolute;
  top: 155px;
  width: 100%;
  @media (max-width: 1440px) {
    font-size: 9px;
  }
`;

export const PaymentCardTrialPlanBoxText = styled.div`
  background: #f8f8f8;
  padding: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #191919;
  margin: 10px 0;
  position: absolute;
  top: 155px;
  width: 100%;
`;

export const PaymentCardPopularBox = styled.div`
  background-color: #fed87b;
  color: #191919;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #191919;
  padding: 10px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
`;

export const PlanDetailsTopBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const PlanDetailsMainTitle = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #191919;
  margin: 0 10px;
`;

export const PlanDetailsMainSubtitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b3b3b3;
  margin: 10px 0;
`;

export const SwitchText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => (props.checked ? '#191919' : '#B3B3B3')};
`;

export const InternalSlackConnectBox = styled.div`
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 10px;
  margin-top: 25px;
`;

export const InternalSlackConnectTeamName = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #191919;
`;

export const InternalSlackConnectDescription = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #959595;
  margin-top: 5px;
`;

export const PlanDetailsCardsContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 15px;
  justify-content: center;
  @media (max-width: 1440px) {
    gap: 8px;
  }
`;

export const NoteboxPricing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #444444;
  padding: 8px 10px;
  background: rgba(248, 248, 248, 0.6);
  border: 1px solid #e2e8f0;
  border-radius: 4px;
`;

export const SecuritySettingsBox = styled.div`
  background: #fff;
  filter: drop-shadow(0px 1.789px 5.368px rgba(0, 0, 0, 0.06));
  padding: 20px 0 10px 0;
  border-radius: 20px;
  margin-top: 20px;
`;

export const APIKeysTitle = styled.div`
  color: #191919;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
`;

export const APIKeysSubtitle = styled.div`
  color: #7d7d7d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
`;

export const APIKeysTableInfoText = styled.div`
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 167.336%;
  letter-spacing: -0.39px;
`;

export const ApiKeySidebarTitle = styled.div`
  color: #35354e;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.54px;
`;

export const ApiKeySidebarLabelText = styled.div`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ApiKeySuccessGenerateWarningText = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
`;

export const BotResponseLengthContainer = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.03) 0px 14px 24px;
  border-radius: 12px;
  padding: 23px 23px 19px 25px;
`;