import { THREADO_BOT_PLAN_TYPES, THREADO_PLAN_TYPES } from 'components/Payments/utils';
import { isEmpty } from 'lodash';
const orderOfPlans = [
  THREADO_PLAN_TYPES.BASIC_PLAN,
  THREADO_PLAN_TYPES.PRO_PLAN,
  // THREADO_PLAN_TYPES.MAX_PLAN,
];

const orderOfPlansSupportBot = [THREADO_BOT_PLAN_TYPES.BASIC_PLAN];

export const transformPaymentsListData = (paymentsPlanList) => {
  if (isEmpty(paymentsPlanList) || isEmpty(paymentsPlanList?.prices)) {
    return;
  }
  const modifiedPrices = paymentsPlanList.prices?.sort(
    (a, b) => orderOfPlans.indexOf(a.productIdName) - orderOfPlans.indexOf(b.productIdName),
  );
  return [...modifiedPrices];
};

export const transformSupportBotPaymentsListData = (paymentsPlanList) => {
  if (isEmpty(paymentsPlanList) || isEmpty(paymentsPlanList?.prices)) {
    return;
  }
  const modifiedPrices = paymentsPlanList.prices?.sort(
    (a, b) =>
      orderOfPlansSupportBot.indexOf(a.productIdName) -
      orderOfPlansSupportBot.indexOf(b.productIdName),
  );

  return [...modifiedPrices];
};
