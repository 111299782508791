import {
  GET_BOT_ANALYTICS_ACCURACY_DATA,
  GET_BOT_ANALYTICS_RESPONSE_DATA,
  GET_BOT_ANALYTICS_TREND_DATA,
} from 'redux/botAnalyticsProvider/types.js';

export const initialState = {
  botAnalyticsAccuracyMetric: null,
  botAnalyticsResponseMetric: null,
  botAnalyticsTrend: null,
};

export const botAnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BOT_ANALYTICS_ACCURACY_DATA:
      return {
        ...state,
        botAnalyticsAccuracyMetric: payload,
      };
    case GET_BOT_ANALYTICS_RESPONSE_DATA:
      return {
        ...state,
        botAnalyticsResponseMetric: payload,
      };
    case GET_BOT_ANALYTICS_TREND_DATA:
      return {
        ...state,
        botAnalyticsTrend: payload,
      };
    default:
      return state;
  }
};
