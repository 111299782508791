import { createEventBus, slot } from 'ts-event-bus';

const EventBusEvents = {
  communityNotificationsCountChanged: slot(),
  communitySimpleMessage: slot(),
  channelDataloadEvent: slot(),
  integrationDataLoadedEvent: slot(),
  memberImportProcessedEvent: slot(),
  hubspotSyncProcessEvent: slot(),
  trainingStatusForWorkspace: slot(),
};

const EventBus = createEventBus({
  events: EventBusEvents,
});

export default EventBus;
