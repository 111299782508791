import React from 'react';
import {
  CustomDialog,
  DeleteDialogText,
  DialogCloseButton,
  DialogContainer,
  DialogHeader,
  Image,
} from 'styledComponents/commonStyled';

import closeCircle from 'assets/images/icons/closeCircle.svg';
import redCross from 'assets/images/icons/redCross.svg';
import { CancelButton, DeleteButton } from 'styledComponents/communityStyled';

const DeleteSupportFilterDialog = ({
  filterName,
  open,
  handleClose,
  deleteFilter,
  communityId,
  subMenuId,
}) => {
  return (
    <CustomDialog open={open} style={{ zIndex: 1999 }} onClose={handleClose}>
      <DialogContainer>
        <DialogHeader>
          <div style={{ paddingTop: 10 }}>
            <Image src={redCross} alt="redCross" width={34} height={34} />
          </div>
          <DialogCloseButton onClick={handleClose}>
            <Image src={closeCircle} alt="closeCircle" width={34} height={34} />
          </DialogCloseButton>
        </DialogHeader>
      </DialogContainer>
      <div style={{ padding: '10px 100px' }}>
        <DeleteDialogText>Are you sure you want to remove {filterName} filter?</DeleteDialogText>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <DeleteButton onClick={() => deleteFilter(communityId, subMenuId)}>Delete</DeleteButton>
        </div>
      </div>
    </CustomDialog>
  );
};

export default DeleteSupportFilterDialog;
