export const GET_REPORT_ACTIVITY = 'GET_REPORT_ACTIVITY';
export const GET_REPORT_ACTIVITY_LOADING = 'GET_REPORT_ACTIVITY_LOADING';
export const GET_REPORT_ACTIVITY_ERROR = 'GET_REPORT_ACTIVITY_ERROR';
export const CLEAN_UP_ACTION = 'CLEAN_UP_ACTION';
// Activity Top Card details
export const GET_ACTIVITY_TOP_CARD = 'GET_ACTIVITY_TOP_CARD';
export const GET_ACTIVITY_TOP_CARD_LOADING = 'GET_ACTIVITY_TOP_CARD_LOADING';
export const GET_ACTIVITY_TOP_CARD_FAILED = 'GET_ACTIVITY_TOP_CARD_FAILED';

export const GET_REPORT_SENTIMENT = 'GET_REPORT_SENTIMENT';
export const GET_REPORT_SENTIMENT_LOADING = 'GET_REPORT_SENTIMENT_LOADING';
export const GET_REPORT_SENTIMENT_ERROR = 'GET_REPORT_SENTIMENT_ERROR';
// SENTIMENT Top Card details
export const GET_SENTIMENT_TOP_CARD = 'GET_SENTIMENT_TOP_CARD';
export const GET_SENTIMENT_TOP_CARD_LOADING = 'GET_SENTIMENT_TOP_CARD_LOADING';
export const GET_SENTIMENT_TOP_CARD_FAILED = 'GET_SENTIMENT_TOP_CARD_FAILED';
export const SET_GRID_TYPE = 'SET_GRID_TYPE';
export const SET_FREQUENCY_TYPE = 'SET_FREQUENCY_TYPE';
export const SET_ACTIVITY_TYPE = 'SET_ACTIVITY_TYPE';
export const SET_SENTIMENT_FREQUENCY = 'SET_SENTIMENT_FREQUENCY';

// MEMBERS Graph data
export const GET_NEW_MEMBERS_TREND_DATA = 'GET_NEW_MEMBERS_TREND_DATA';
export const GET_NEW_MEMBERS_TREND_DATA_LOADING = 'GET_NEW_MEMBERS_TREND_DATA_LOADING';
export const ERROR_GET_NEW_MEMBERS_TREND_DATA = 'ERROR_GET_NEW_MEMBERS_TREND_DATA';

export const GET_TOTAL_MEMBERS_TREND_DATA = 'GET_TOTAL_MEMBERS_TREND_DATA';
export const GET_TOTAL_MEMBERS_TREND_DATA_LOADING = 'GET_TOTAL_MEMBERS_TREND_DATA_LOADING';
export const ERROR_TOTAL_MEMBERS_TREND_DATA = 'ERROR_TOTAL_MEMBERS_TREND_DATA';


export const SET_RESPONSIVENESS_TYPE = 'SET_RESPONSIVENESS_TYPE';
