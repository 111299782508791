export const GET_OUTREACH_TEMPLATES_CATEGORIES_LIST = 'GET_OUTREACH_TEMPLATES_CATEGORIES_LIST';
export const SET_OUTREACH_TEMPLATES_CATEGORIES_LIST_LOADING =
  'SET_OUTREACH_TEMPLATES_CATEGORIES_LIST_LOADING';
export const HANDLE_OUTREACH_TEMPLATES_CATEGORIES_LIST_ERROR =
  'HANDLE_OUTREACH_TEMPLATES_CATEGORIES_LIST_ERROR';

export const GET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM =
  'GET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM';
export const SET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_LOADING =
  'SET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_LOADING';
export const HANDLE_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_ERROR =
  'HANDLE_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_ERROR';

export const GET_OUTREACH_TEMPLATE_BY_ID = 'GET_OUTREACH_TEMPLATE_BY_ID';
export const SET_OUTREACH_TEMPLATE_BY_ID_LOADING = 'SET_OUTREACH_TEMPLATE_BY_ID_LOADING';
export const HANDLE_OUTREACH_TEMPLATE_BY_ID_ERROR = 'HANDLE_OUTREACH_TEMPLATE_BY_ID_ERROR';

export const GET_RECENT_OUTREACH_LIST = 'GET_RECENT_OUTREACH_LIST';
export const SET_RECENT_OUTREACH_LIST_LOADING = 'SET_RECENT_OUTREACH_LIST_LOADING';
export const HANDLE_RECENT_OUTREACH_LIST_ERROR = 'HANDLE_RECENT_OUTREACH_LIST_ERROR';

export const GET_OUTREACH_LIST = 'GET_OUTREACH_LIST';
export const SET_OUTREACH_LIST_LOADING = 'SET_OUTREACH_LIST_LOADING';
export const HANDLE_OUTREACH_LIST_ERROR = 'HANDLE_OUTREACH_LIST_ERROR';

// Email-Template
export const GET_EMAIL_SENDFROM_LIST= 'GET_EMAIL_SENDFROM_LIST';

export const SET_IS_OUTREACH_TEMPLATE_ACTIVE = 'SET_IS_OUTREACH_TEMPLATE_ACTIVE';