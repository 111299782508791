import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import { CustomTextField } from 'styledComponents/commonStyled';


const MatTextFieldWrapper = (props) => {
  if (props.formik) {
    return (
      <CustomTextField
        {...props}
        value={props.name && get(props.formik.values, props.name)}
        error={
          !!props.name &&
          !!get(props.formik.errors, props.name) &&
          !!get(props.formik.touched, props.name)
        }
        onChange={props.onChange || props.formik.handleChange}
        onBlur={() => {
          props.name && props?.formik?.setFieldTouched(props.name);
        }}
        helperText={
          !!props.name &&
          !!get(props.formik.touched, props.name) &&
          get(props.formik.errors, props.name)
        }
      />
    );
  }
  return (
    <>
      <CustomTextField {...props} />
    </>
  );
};

export default MatTextFieldWrapper;
