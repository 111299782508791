import React from 'react';
import { BannerContainer, BannerMessagePayments } from 'styledComponents/launchBannerStyled';
import { Image, PrimaryButton } from 'styledComponents/commonStyled';
import { openUrl } from 'utils/common';
import { chromeAgentAssistExtensionUrl } from 'constant/urlConstants';
import { CircularDiv } from 'styledComponents/chatbotStyled';
import downloadBotIcon from 'assets/images/icons/downloadBotIcon.svg';

const SupportAgentInstallBanner = ({}) => {
  return (
    <>
      <div>
        <BannerContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: '4px',
              backgroundColor: 'transparent',
            }}>
            <CircularDiv style={{ width: '24px', height: '24px' }}>
              <Image src={downloadBotIcon} height={15} width={15} alt="download" />
            </CircularDiv>
            <BannerMessagePayments
              style={{
                paddingTop: '2px',
                paddingBottom: '2px',
                color: '#474859',
                fontSize: '13px',
                fontWeight: 400,
                letterSpacing: '-0.26px',
              }}>
              {`Give super fast replies to your customers by installing chrome app now`}
            </BannerMessagePayments>
            <PrimaryButton
              style={{ height: '24px', textTransform: 'unset' }}
              onClick={() => openUrl(chromeAgentAssistExtensionUrl)}>
              Install chrome app
            </PrimaryButton>
          </div>
        </BannerContainer>
      </div>
    </>
  );
};

export default SupportAgentInstallBanner;
