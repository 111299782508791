import {
  SET_DATE_FILTER,
  SET_MEMBERS_WIDGET_DATA,
  SET_PROVIDER_FILTER,
  SET_RECENT_AUTOMATION_WIDGET_DATA,
  SET_RECENT_OUTREACH_WIDGET_DATA,
  SET_TOP_MEMBERS_WIDGET_DATA,
  SET_ACTIVITIES_WIDGET_DATA,
  SET_TOP_MESSAGES_WIDGET_DATA,
} from '../types';

const initialState = {
  providerFilter: null,
  dateFilter: null,
  membersWidgetData: null,
  activitiesWidgetData: null,
  recentAutomationsWidgetData: [],
  recentOutreachWidgetData: [],
  topMembersWidgetData: [],
  topMessagesWidgetData: [],
};

const analyticsSummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PROVIDER_FILTER:
      return {
        ...state,
        providerFilter: payload,
      };
    case SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: payload,
      };
    case SET_MEMBERS_WIDGET_DATA:
      return {
        ...state,
        membersWidgetData: payload,
      };
    case SET_ACTIVITIES_WIDGET_DATA:
      return {
        ...state,
        activitiesWidgetData: payload,
      };

    case SET_RECENT_AUTOMATION_WIDGET_DATA:
      return {
        ...state,
        recentAutomationsWidgetData: payload,
      };
    case SET_RECENT_OUTREACH_WIDGET_DATA:
      return {
        ...state,
        recentOutreachWidgetData: payload,
      };

    case SET_TOP_MEMBERS_WIDGET_DATA:
      return {
        ...state,
        topMembersWidgetData: payload,
      };
    case SET_TOP_MESSAGES_WIDGET_DATA:
      return {
        ...state,
        topMessagesWidgetData: payload,
      };
    default:
      return state;
  }
};

export default analyticsSummaryReducer;
