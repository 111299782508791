import React, { Component } from 'react';
import ErrorPageClient from '../../commonComponents/ErrorPageClient';
import { trackUserEvent } from 'utils/segment';
import { maxRetryCounter } from 'utils/common';

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';
const retryPageLoading = (error, errorInfo) => {
  const pageHasAlreadyBeenForceRefreshedCount = +JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || '0',
  );

  if (pageHasAlreadyBeenForceRefreshedCount < maxRetryCounter) {
    let updatedPageHasAlreadyBeenForceRefreshedCount = pageHasAlreadyBeenForceRefreshedCount + 1;
    window.localStorage.setItem(
      PageHasBeenForceRefreshed,
      updatedPageHasAlreadyBeenForceRefreshedCount,
    );
    return window.location.reload();
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, '0');
    trackUserEvent('ErrorBoundary errors', {
      error: error,
      errorInfo: errorInfo,
    });
    console.info('Uncaught error:', error, errorInfo);
  }
};

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  // static getDerivedStateFromError(_) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  componentDidCatch(error, errorInfo) {
    retryPageLoading(error, errorInfo);
    setTimeout(() => {
      this.setState({ hasError: true });
    }, 1000);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPageClient />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
