export const GET_NOTIFICATIONS_DATA = 'GET_NOTIFICATIONS_DATA';
export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS';
export const NOTIFICATIONS_ERROR_HANDLE = 'NOTIFICATIONS_ERROR_HANDLE';
export const GET_DETAILED_NOTIFICATION_DATA = 'GET_DETAILED_NOTIFICATION_DATA';
export const LOADING_DETAILED_NOTIFICATION = 'LOADING_DETAILED_NOTIFICATION';
export const DETAILED_NOTIFICATION_ERROR_HANDLE = 'DETAILED_NOTIFICATION_ERROR_HANDLE';
export const GET_DETAILED_NOTIFICATION_CHANNEL_LOAD_DATA =
  'GET_DETAILED_NOTIFICATION_CHANNEL_LOAD_DATA';
export const SET_NOTIFICATION_SETTINGS = 'SET_NOTIFICATION_SETTINGS';

export const notificationModalType = {
  SEND_DM: 'SEND_DM',
  SEND_BULK_DM: 'SEND_BULK_DM',
  LOAD_CHANNEL: 'LOAD_CHANNEL',
  AUTOMATED_DM: 'AUTOMATED_DM',
};
