import {
    SET_DATE_FILTER,
    SET_MEMBERS_WIDGET_DATA,
    SET_PROVIDER_FILTER,
    SET_RECENT_AUTOMATION_WIDGET_DATA,
    SET_RECENT_OUTREACH_WIDGET_DATA,
    SET_TOP_MEMBERS_WIDGET_DATA,
    SET_ACTIVITIES_WIDGET_DATA,
    SET_TOP_MESSAGES_WIDGET_DATA,
    SET_CHANNEL_INSIGHT_LIST,
    SET_CHANNEL_INSIGHT_STATIC_DATA,
    SET_TOPIC_INSIGHT_LIST,
    SET_TOPIC_INSIGHT_STATIC_DATA,
} from '../types';

const initialState = {
    topicInsightList: [],
    topicInsightListDetails: {},
    topicTrendingInsight: null

};

const topicInsightReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_TOPIC_INSIGHT_LIST:
            return {
                ...state,
                topicInsightList: payload.data,
                topicInsightListDetails: payload.details,
            };
        case SET_TOPIC_INSIGHT_STATIC_DATA:
            return {
                ...state,
                topicTrendingInsight: payload,
            };


        default:
            return state;
    }
};

export default topicInsightReducer;
