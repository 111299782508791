import { Button } from '@mui/material';
import styled from '@emotion/styled';
import {
  borderGreyColor,
  boxShadowColor,
  tableCellGreenColor,
  tableCellHoverGreenColor,
  whiteColor,
} from 'theme/colorConstants';

export const ReAuthorizeButton = styled(Button)`
  background-color: ${tableCellGreenColor};
  border: none;
  width: 100%;
  color: ${whiteColor};
  &:hover {
    background-color: ${tableCellHoverGreenColor};
  }
`;

export const IntegrationReAuthorizeDetailsOuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IntegrationReAuthorizeDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IntegrationReAuthorizeContainer = styled.div`
  margin-top: 16px;
  border: 1px solid ${borderGreyColor};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 8px ${boxShadowColor};
  background-color: ${whiteColor};
`;

export const IntegrationReFreshText = styled.label`
  width: 100%;
  margin: 10px 0;
`;
