import {
  GET_SIDEBAR_STATE,
  SET_SIDEBAR_STATE,
  SET_TYPE_OF_CHANGE,
  SET_SLACK_ALERT,
  GET_SLACK_ALERTS,
  GET_SLACK_ALERTS_LOADING,
  GET_SLACK_ALERT_ERROR,
  GET_SLACK_ALERT_BY_ID,
  GET_SLACK_ALERT_BY_ID_LOADING,
  GET_SLACK_ALERT_BY_ID_ERROR,
} from '../types';

const initialState = {
  isSidebarOpen: false,
  typeOfChange: 'new', // 'new'|'update',
  slackAlerts: [],
  slackAlertLoading: false,
  slackAlertError: false,
  slackAlertObj: null,
};

const slackAlertAnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SIDEBAR_STATE:
      return {
        ...state,
        isSidebarOpen: payload,
      };
    case SET_TYPE_OF_CHANGE:
      return {
        ...state,
        typeOfChange: payload,
      };
    case GET_SLACK_ALERTS:
      return {
        ...state,
        slackAlerts: payload,
        slackAlertLoading: false,
        slackAlertError: false,
      };
    case GET_SLACK_ALERTS_LOADING:
      return {
        ...state,
        slackAlerts: [],
        slackAlertLoading: true,
        slackAlertError: false,
      };
    case GET_SLACK_ALERT_ERROR:
      return {
        ...state,
        slackAlerts: [],
        slackAlertLoading: false,
        slackAlertError: true,
      };
    case GET_SLACK_ALERT_BY_ID:
      return {
        ...state,
        slackAlertObj: payload,
      };
    case GET_SLACK_ALERT_BY_ID_ERROR:
      return {
        ...state,
        slackAlertObj: null,
      };
    default:
      return state;
  }
};

export default slackAlertAnalyticsReducer;
