import {
  SET_PAYMENTS_MODAL_OPEN,
  GET_PAYMENTS_STATUS,
  GET_PLAN_LIST,
  PAYMENTS_STATUS_LOADING,
  PLAN_LIST_LOADING,
  ERROR_PAYMENTS_STATUS,
  ERROR_PLAN_LIST,
  SET_UPGRADE_NOW_EVENT_SOURCE,
  GET_SUPPORT_BOT_PAYMENTS_STATUS,
  SUPPORT_BOT_PAYMENTS_STATUS_LOADING,
  ERROR_SUPPORT_BOT_PAYMENTS_STATUS,
  GET_SUPPORT_BOT_PLAN_LIST,
  ERROR_SUPPORT_BOT_PLAN_LIST,
  SUPPORT_BOT_PLAN_LIST_LOADING,
  GET_SUPPORT_BOT_CURRENT_USAGE,
  LOADING_SUPPORT_BOT_CURRENT_USAGE,
} from '../types';

const paymentStatusInitialValue = {
  status: '',
  currentPlan: '',
  plan: '',
  trialStart: 0,
  trialEnd: 0,
  product: '',
  productIdName: '',
  subscriptionStart: 0,
  subscriptionEnd: 0,
  recurring: '',
  amount: 0,
};

const supportBotPaymentStatusInitialValues = {
  status: '',
  currentPlan: '',
  plan: '',
  trialStart: 0,
  trialEnd: 0,
  product: '',
  productIdName: '',
  subscriptionStart: 0,
  subscriptionEnd: 0,
  recurring: '',
  amount: 0,
};

const initialState = {
  paymentsModalOpen: false,
  paymentsPlanStatus: paymentStatusInitialValue,
  paymentsPlanStatusLoading: false,
  paymentsPlanList: null,
  paymentsPlanListLoading: false,
  supportBotPaymentsPlanStatus: paymentStatusInitialValue,
  supportBotPaymentsPlanStatusLoading: false,
  supportBotPaymentsPlanList: null,
  supportBotPaymentsPlanListLoading: false,
  upgradeNowEventSource: '',
  botCurrentUsageIsLoading: false,
  botCurrentUsage: null,
};

const paymentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PAYMENTS_MODAL_OPEN:
      return {
        ...state,
        paymentsModalOpen: payload,
      };
    case GET_PAYMENTS_STATUS:
      return {
        ...state,
        paymentsPlanStatus: payload,
        paymentsPlanStatusLoading: false,
      };
    case PAYMENTS_STATUS_LOADING:
      return {
        ...state,
        paymentsPlanStatusLoading: true,
      };

    case ERROR_PAYMENTS_STATUS:
      return {
        ...state,
        paymentsPlanStatusLoading: false,
      };

    case GET_PLAN_LIST:
      return {
        ...state,
        paymentsPlanList: payload,
        paymentsPlanListLoading: false,
      };
    case PLAN_LIST_LOADING:
      return {
        ...state,
        paymentsPlanListLoading: true,
      };

    case ERROR_PLAN_LIST:
      return {
        ...state,
        paymentsPlanListLoading: false,
      };

    case SET_UPGRADE_NOW_EVENT_SOURCE:
      return {
        ...state,
        upgradeNowEventSource: payload,
      };
    case GET_SUPPORT_BOT_PAYMENTS_STATUS:
      return {
        ...state,
        supportBotPaymentsPlanStatus: payload,
        supportBotPaymentsPlanStatusLoading: false,
      };
    case SUPPORT_BOT_PAYMENTS_STATUS_LOADING:
      return {
        ...state,
        supportBotPaymentsPlanStatusLoading: true,
      };
    case ERROR_SUPPORT_BOT_PAYMENTS_STATUS:
      return {
        ...state,
        supportBotPaymentsPlanStatusLoading: false,
      };
    case GET_SUPPORT_BOT_PLAN_LIST:
      return {
        ...state,
        supportBotPaymentsPlanList: payload,
        supportBotPaymentsPlanListLoading: false,
      };
    case SUPPORT_BOT_PLAN_LIST_LOADING:
      return {
        ...state,
        supportBotPaymentsPlanListLoading: true,
      };
    case ERROR_SUPPORT_BOT_PLAN_LIST:
      return {
        ...state,
        supportBotPaymentsPlanListLoading: false,
      };
    case GET_SUPPORT_BOT_CURRENT_USAGE:
      return {
        ...state,
        botCurrentUsage: payload,
        botCurrentUsageIsLoading: false,
      };
    case LOADING_SUPPORT_BOT_CURRENT_USAGE:
      return {
        ...state,
        botCurrentUsageIsLoading: payload,
      };
    default:
      return state;
  }
};

export default paymentsReducer;
