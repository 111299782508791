// Import engagement data
export const GET_ENGAGEMENT_MEMBERS_DATA = 'GET_ENGAGEMENT_MEMBERS_DATA';
export const GET_ENGAGEMENT_ACTIONS_DATA = 'GET_ENGAGEMENT_ACTIONS_DATA';

export const GET_ENGAGEMENT_MEMBERS_DATA_MESSAGES = 'GET_ENGAGEMENT_MEMBERS_DATA_MESSAGES';
export const GET_ENGAGEMENT_MEMBERS_DATA_REACTIONS = 'GET_ENGAGEMENT_MEMBERS_DATA_REACTIONS';
export const GET_ENGAGEMENT_ACTIONS_DATA_MESSAGES = 'GET_ENGAGEMENT_ACTIONS_DATA_MESSAGES';
export const GET_ENGAGEMENT_ACTIONS_DATA_REACTIONS = 'GET_ENGAGEMENT_ACTIONS_DATA_REACTIONS';

export const LOADING_ENGAGEMENT = 'LOADING_ENGAGEMENT';
export const HANDLE_ENGAGEMENT_ERROR_MEMBERS_DATA = 'HANDLE_ENGAGEMENT_ERROR_MEMBERS_DATA';
export const HANDLE_ENGAGEMENT_ERROR_ACTIONS_DATA = 'HANDLE_ENGAGEMENT_ERROR_ACTIONS_DATA';

export const RESET_ENGAGEMENT_TOASTER_MESSAGE = 'RESET_ENGAGEMENT_TOASTER_MESSAGE';
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const UPDATE_ENGAGEMENT_METRICS = 'UPDATE_ENGAGEMENT_METRICS';
export const GET_PROVIDER_CHANNELS_DATA = 'GET_PROVIDER_CHANNELS_DATA';
export const PROVIDER_CHANNELS_ERROR_HANDLE = 'PROVIDER_CHANNELS_ERROR_HANDLE';
export const SET_ENGAGEMENT_PROVIDER_CHANNELS_FILTER = 'SET_ENGAGEMENT_PROVIDER_CHANNELS_FILTER';
export const LOADING_ENGAGEMENT_PROVIDER_CHANNELS = 'LOADING_ENGAGEMENT_PROVIDER_CHANNELS';
export const UPDATE_ENGAGEMENT_METRICS_MEMBERS = 'UPDATE_ENGAGEMENT_METRICS_MEMBERS';
export const UPDATE_ENGAGEMENT_METRICS_ACTIONS_TAKEN = 'UPDATE_ENGAGEMENT_METRICS_ACTIONS_TAKEN';
