import { AnyAction } from 'redux';
import {
  CREATE_AGENT,
  GET_AGENT_LIST,
  GET_AGENT_LIST_LOADING,
  GET_AGENT_LIST_FAILED,
} from '../types';

export const initialState = {
  agents: [],
};

export const agentActionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_AGENT:
      return {
        ...state,
      };
    case GET_AGENT_LIST:
      return {
        ...state,
        agents: payload,
      };
    default:
      return state;
  }
};
