import {
  GET_AGENTS_LIST_FOR_INVITE,
  GET_AGENT_ASSIST_ANALYTICS_AGENT_LIST_DATA,
  GET_AGENT_ASSIST_ANALYTICS_BOT_RESPONSE_RATE,
  GET_AGENT_ASSIST_ANALYTICS_ENGAGEMENT_TREND,
  GET_AGENT_ASSIST_ANALYTICS_OVERVIEW,
  GET_AGENT_ASSIST_ANALYTICS_QUERIES_TREND,
  GET_CUSTOMIZE_RESPONSE_CHROME,
  GET_CUSTOMIZE_RESPONSE_SLACK,
  GET_DEFAULT_RESPONSE_CHECKS_LIST,
  GET_DEFAULT_TONES_LIST,
  GET_INVITED_AGENTS_LIST,
  GET_LANGUAGES_LIST,
  GET_LANGUAGE_SETTINGS,
  SET_CHROME_INSTALL_STATUS_NUDGE,
  GET_BOT_RESPONSE_LENGTH_SETTINGS,
} from '../types';

export const initialState = {
  defaultTonesList: [],
  defaultResponseChecksList: [],
  customizeResponseChrome: null,
  customizeResponseSlack: null,
  agentsListForInvite: [],
  invitedAgentsList: [],
  chromeAppInstallStatusNudge: false,
  analyticsOverview: null,
  analyticsEngagementTrend: null,
  analyticsAgentList: [],
  analyticsBotResponseRate: null,
  analyticsQueriesTrend: null,
  languageSettings: null,
  languagesList: [],
  botResponseLengthSettings: null,
};

export const agentAssistReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DEFAULT_TONES_LIST:
      return {
        ...state,
        defaultTonesList: payload,
      };
    case GET_DEFAULT_RESPONSE_CHECKS_LIST:
      return {
        ...state,
        defaultResponseChecksList: payload,
      };
    case GET_CUSTOMIZE_RESPONSE_CHROME:
      return {
        ...state,
        customizeResponseChrome: payload,
      };
    case GET_CUSTOMIZE_RESPONSE_SLACK:
      return {
        ...state,
        customizeResponseSlack: payload,
      };
    case GET_AGENTS_LIST_FOR_INVITE:
      return {
        ...state,
        agentsListForInvite: payload,
      };
    case GET_INVITED_AGENTS_LIST:
      return {
        ...state,
        invitedAgentsList: payload,
      };
    case SET_CHROME_INSTALL_STATUS_NUDGE:
      return {
        ...state,
        chromeAppInstallStatusNudge: payload,
      };
    case GET_AGENT_ASSIST_ANALYTICS_OVERVIEW:
      return {
        ...state,
        analyticsOverview: payload,
      };
    case GET_AGENT_ASSIST_ANALYTICS_ENGAGEMENT_TREND:
      return {
        ...state,
        analyticsEngagementTrend: payload,
      };
    case GET_AGENT_ASSIST_ANALYTICS_AGENT_LIST_DATA:
      return {
        ...state,
        analyticsAgentList: payload,
      };
    case GET_AGENT_ASSIST_ANALYTICS_BOT_RESPONSE_RATE:
      return {
        ...state,
        analyticsBotResponseRate: payload,
      };
    case GET_AGENT_ASSIST_ANALYTICS_QUERIES_TREND:
      return {
        ...state,
        analyticsQueriesTrend: payload,
      };
    case GET_LANGUAGES_LIST:
      return {
        ...state,
        languagesList: payload,
      };
    case GET_LANGUAGE_SETTINGS:
      return {
        ...state,
        languageSettings: payload,
      };
    case GET_BOT_RESPONSE_LENGTH_SETTINGS:
      return {
        ...state,
        botResponseLengthSettings: payload,
      };
    default:
      return state;
  }
};
