import React from 'react';
import Slide from '@material-ui/core/Slide';
import { PaperContainer } from 'styledComponents/commonStyled';
import { createStyles, makeStyles } from '@material-ui/styles';
import { colorToken } from 'theme/designToken';
import MessagesFilterManager from './MessagesFilterManager';
import { useGetSidebarToggleState } from 'context/hooks';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 175,
      maxWidth: 400,
      backgroundColor: `#FFFFF`,
    },
    accordionExpand: {
      margin: '0 !important',
    },
  }),
);

const MessagesFilterSidebar = ({ open }) => {
  const classes = useStyles();
  const sidebarToggleState = useSelector(useGetSidebarToggleState);
  return (
    <PaperContainer
      className="memberSideBar"
      elevation={0}
      style={{
        borderRadius: colorToken.menuSidebar.secondarySidebar.border.radius,
        display: 'flex',
        alignItems: 'stretch',
        height: 'initial',
        maxHeight: '100vh',
        minHeight: '100vh',
        position: 'fixed',
        paddingTop: '20px',
        paddingLeft: !sidebarToggleState ? '85px' : '260px',
        top: 0,
        zIndex: 999,
        overflow: 'auto',
        backgroundColor: colorToken.menuSidebar.secondarySidebar.backgroundColor,
        paddingRight: '10px',
      }}>
      <Slide direction="right" in={open} easing={'easing.sharp'} mountOnEnter unmountOnExit>
        <div className={classes.root} style={{ paddingTop: '16px' }}>
          <span
            style={{
              lineHeight: '19px',
              display: 'flex',
              alignItems: 'center',
              fontWeight: 700,
              fontSize: '16px',
              color: '#191919',
              paddingLeft: '10px',
              paddingBottom: '28px',
              justifyContent: 'space-between',
            }}>
            <div>Activity</div>
            <div
              style={{
                padding: '3px 5px',
                left: '138px',
                top: '99px',
                background: '#F5F6FD',
                borderRadius: '3px',
                marginRight: '10px',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '16px',
                display: 'flex',
                alignItems: 'center',
                color: '#3E5273',
                cursor: 'default',
              }}></div>
          </span>
          <MessagesFilterManager />
        </div>
      </Slide>
    </PaperContainer>
  );
};

export default MessagesFilterSidebar;
