import { APP_SERVICES } from '../../constant/features';
import moment from 'moment';
import { formatDistanceStrict, parseISO } from 'date-fns';

export const transformActivitySummary = (
  data,
) =>
  data.map((activity, index) => ({
    id: index,
    name: activity.title === 'messages' ? activity.title + ' Posted' : activity.title + ' Members',
    total: activity.count,
    totalPercentage: activity.swingPercentage,
    discordMembers:
      activity.data.filter((item) => item.title === APP_SERVICES.discord)[0]?.count || 0,
    discordPercentage:
      activity.data.filter((item) => item.title === APP_SERVICES.discord)[0]?.swingPercentage || 0,
    slackMembers: activity.data.filter((item) => item.title === APP_SERVICES.slack)[0]?.count || 0,
    slackPercentage:
      activity.data.filter((item) => item.title === APP_SERVICES.slack)[0]?.swingPercentage || 0,
    zoomMembers: activity.data.filter((item) => item.title === APP_SERVICES.zoom)[0]?.count || 0,
    zoomPercentage:
      activity.data.filter((item) => item.title === APP_SERVICES.zoom)[0]?.swingPercentage || 0,
  }));

export const transformActivityTopChannels = (data) =>
  data.map((channel, index) => ({ ...channel, id: index }));

export const transformTopMessagesData = (data) => ({
  ...data,
  content: data.content.map((message, index) => ({
    id: index,
    name: message.username,
    channelType: message.providerName,
    profileSrc: '',
    message: message.messageContent,
    tags: (message && message.segment && message.segment.split(',')) || [],
    reactions: message.reactionCount,
    replies: message.repliedCount,
    avatarId: message.avatarId,
    url: message.url,
  })),
});


export const transformMemberData = (
    memberData,
    timezone,
  ) =>
    memberData.map((member) => {
      return {
        ...member,
        id: member.id,
        name: member.name || member.userName,
        // @ts-ignore
        joinedAt: member.joinedAt ? moment.tz(member.joinedAt, timezone).format('MMM Do yyyy') : '',
        lastActiveTime: member.lastActive
          ? formatDistanceStrict(parseISO(member.lastActive), new Date()) + ' ago'
          : 'Not Active',
        segment: member.segment,
        email: member.email,
        mentions: member.mentions,
  
        tags: member.tags || [],
  
        sessions: member.sessions,
        isAtRisk: member.isAtRisk,
      };
    });
