export const SET_PAYMENTS_MODAL_OPEN = 'SET_PAYMENTS_MODAL_OPEN';
export const GET_PAYMENTS_STATUS = 'GET_PAYMENTS_STATUS';
export const PAYMENTS_STATUS_LOADING = 'PAYMENTS_STATUS_LOADING';
export const GET_PLAN_LIST = 'GET_PLAN_LIST';
export const PLAN_LIST_LOADING = 'PLAN_LIST_LOADING';
export const ERROR_PAYMENTS_STATUS = 'ERROR_PAYMENTS_STATUS';
export const ERROR_PLAN_LIST = 'ERROR_PLAN_LIST';
export const CANCEL_SUBSCRIPTION = 'CANCE_SUBSCRIPTION';
export const ERROR_CANCEL_SUBSCRIPTION = 'ERROR_CANCEL_SUBSCRIPTION';
export const SET_UPGRADE_NOW_EVENT_SOURCE = 'SET_UPGRADE_NOW_EVENT_SOURCE';

// Support Bot
export const GET_SUPPORT_BOT_PAYMENTS_STATUS = 'GET_SUPPORT_BOT_PAYMENTS_STATUS';
export const SUPPORT_BOT_PAYMENTS_STATUS_LOADING = 'SUPPORT_BOT_PAYMENTS_STATUS_LOADING';
export const GET_SUPPORT_BOT_PLAN_LIST = 'GET_SUPPORT_BOT_PLAN_LIST';
export const SUPPORT_BOT_PLAN_LIST_LOADING = 'SUPPORT_BOT_PLAN_LIST_LOADING';
export const ERROR_SUPPORT_BOT_PAYMENTS_STATUS = 'ERROR_SUPPORT_BOT_PAYMENTS_STATUS';
export const ERROR_SUPPORT_BOT_PLAN_LIST = 'ERROR_SUPPORT_BOT_PLAN_LIST';
export const CANCEL_SUPPORT_BOT_SUBSCRIPTION = 'CANCEL_SUPPORT_BOT_SUBSCRIPTION';
export const ERROR_CANCEL_SUPPORT_BOT_SUBSCRIPTION = 'ERROR_CANCEL_SUPPORT_BOT_SUBSCRIPTION';
export const GET_SUPPORT_BOT_CURRENT_USAGE = 'GET_SUPPORT_BOT_CURRENT_USAGE';
export const LOADING_SUPPORT_BOT_CURRENT_USAGE = 'LOADING_SUPPORT_BOT_CURRENT_USAGE';