export const GET_HUBSPOT_CONFIG = 'GET_HUBSPOT_CONFIG';
export const GET_HUBSPOT_CONFIG_LOADING = 'GET_HUBSPOT_CONFIG_LOADING';
export const GET_HUBSPOT_CONFIG_ERROR = 'GET_HUBSPOT_CONFIG_ERROR';
export const GET_HUBSPOT_HUBSPOT_DROPDOWN_LIST = 'GET_HUBSPOT_HUBSPOT_DROPDOWN_LIST';
export const GET_HUBSPOT_THREADO_DROPDOWN_LIST = 'GET_HUBSPOT_THREADO_DROPDOWN_LIST';
export const GET_HUBSPOT_SEND_CONFIG = 'GET_HUBSPOT_SEND_CONFIG';
export const GET_HUBSPOT_SEND_CONFIG_LOADING = 'GET_HUBSPOT_SEND_CONFIG_LOADING';
export const GET_HUBSPOT_SEND_CONFIG_ERROR = 'GET_HUBSPOT_SEND_CONFIG_ERROR';
export const POST_SAVE = 'POST_SAVE';
export const POST_SAVE_LOADING = 'POST_SAVE_LOADING';
export const POST_SAVE_ERROR = 'POST_SAVE_ERROR';
export const POST_SYNC = 'POST_SYNC';
export const POST_SYNC_LOADING = 'POST_SYNC_LOADING';
export const POST_SYNC_ERROR = 'POST_SYNC_ERROR';
export const GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST = 'GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST';
export const GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST_ERROR =
  'GET_HUBSPOT_CUSTOM_FIELD_DROPDOWN_LIST_ERROR';
