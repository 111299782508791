import discord from 'assets/images/icons/discord.svg';
import discordGrey from 'assets/images/icons/discordGrey.svg';
import discourse from 'assets/images/icons/discourse.svg';
import github from 'assets/images/icons/github.svg';
import githubGrey from 'assets/images/icons/githubGrey.svg';
import slack from 'assets/images/icons/slack.svg';
import slackGrey from 'assets/images/icons/slackGrey.svg';

import discourseGrey from 'assets/images/icons/discordGrey.svg';

import {
  APP_FEATURE_DISCORD_INTEGRATION,
  APP_FEATURE_GITHUB_INTEGRATION,
  APP_FEATURE_SLACK_INTEGRATION,
  APP_FEATURE_DISCOURSE_INTEGRATION,
  // APP_FEATURE_ZOOM_INTEGRATION,
} from './features';

export const CUSTOM_DATE_MAP = {
  LAST_0_DAYS: 'Today',
  LAST_7_DAYS: 'Last 7 Days',
  LAST_15_DAYS: 'Last 15 Days',
  LAST_30_DAYS: 'Last 30 Days',
  LAST_60_DAYS: 'Last 60 Days',
  LAST_90_DAYS: 'Last 90 Days',
  LAST_180_DAYS: 'Last 180 Days',
  TILL_DATE: 'Till Date',
  CUSTOM: 'Custom',
};

export const intervalData = [
  {
    name: CUSTOM_DATE_MAP.LAST_30_DAYS,
  },
  {
    name: CUSTOM_DATE_MAP.LAST_7_DAYS,
  },
];

export const channelOptions = [
  {
    name: 'Discord',
    iconSrc: discordGrey,
    activeIconSrc: discord,
    value: 'discord',
    feature: APP_FEATURE_DISCORD_INTEGRATION,
    attributes: { reactionsCount: 0, messagesCount: 0 },
    isWorkflowAllowed: true,
    label: 'Discord',
  },
  {
    name: 'Slack',
    iconSrc: slackGrey,
    activeIconSrc: slack,
    value: 'slack',
    feature: APP_FEATURE_SLACK_INTEGRATION,
    attributes: {
      reactionsCount: 0,
      messagesCount: 0,
    },
    isWorkflowAllowed: true,
    label: 'Slack',
  },
  {
    name: 'GitHub',
    iconSrc: githubGrey,
    activeIconSrc: github,
    value: 'github',
    feature: APP_FEATURE_GITHUB_INTEGRATION,
    attributes: {
      reactionsCount: 0,
      messagesCount: 0,
      subscribedRepoCount: 0,
      forkedRepoCount: 0,
      pullRequestCount: 0,
      pullRequestOpenCount: 0,
      pullRequestMergedCount: 0,
      starredRepoCount: 0,
      issueCount: 0,
    },
    isWorkflowAllowed: false,
    label: 'GitHub',
  },
  {
    name: 'Discourse',
    iconSrc: discourseGrey,
    activeIconSrc: discourse,
    value: 'discourse',
    feature: APP_FEATURE_DISCOURSE_INTEGRATION,
    attributes: { reactionsCount: 0, messagesCount: 0 },
    isWorkflowAllowed: false,
    label: 'Discourse',
  },
  // {
  //   name: 'Zoom',
  //   iconSrc: 'zoomGrey.svg',
  //   activeIconSrc: 'zoom.svg',
  //   value: 'zoom',
  //   feature: APP_FEATURE_ZOOM_INTEGRATION,
  // },
];

export const segmentOptions = [
  {
    name: 'Onboarding',
    value: 'ONBOARDING',
  },
  {
    name: 'Champion',
    value: 'CHAMPION',
  },
  {
    name: 'Contributor',
    value: 'CONTRIBUTOR',
  },
  {
    name: 'Engaged',
    value: 'ENGAGED',
  },
  {
    name: 'Explorer',
    value: 'EXPLORER',
  },
  {
    name: 'Dormant',
    value: 'DORMANT',
  },
  {
    name: 'At Risk',
    value: 'AT_RISK',
  },
];

export const messagesTagOptions = [
  { name: 'Question', value: 'QUESTION' },
  { name: 'No Replies', value: 'NO_REPLIES' },
  { name: 'Has Replies', value: 'HAS_REPLIES' },
  { name: 'No Reaction', value: 'NO_REACTIONS' },
  { name: 'Has Reaction', value: 'HAS_REACTIONS' },
];
