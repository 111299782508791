import {
  GET_STAFF_DATA,
  SET_STAFF_LOADING,
  SET_INVITED_LOADING,
  GET_INVITED_DATA,
  HANDLE_STAFF_DELETE_SUCCESS,
} from '../types';

const initialState = {
  staffError: '',
  staffLoading: false,
  staffData: [],
  invitedError: '',
  invitedLoading: false,
  invitedData: [],
};

const staffReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case HANDLE_STAFF_DELETE_SUCCESS:
      let filteredStaff = state.staffData.filter((item) => item.id != payload);
      return {
        ...state,
        staffData: filteredStaff,
      };

    case GET_STAFF_DATA:
      let allPayloadWithId = payload.map((item) => {
        return {
          ...item,
          id: item.id || item.email,
        };
      });
      return {
        ...state,
        staffData: allPayloadWithId,
        staffLoading: false,
      };
    case GET_INVITED_DATA:
      let payloadWithId = payload.map((item) => {
        return {
          ...item,
          id: item.email,
        };
      });
      return {
        ...state,
        invitedData: payloadWithId,
        invitedLoading: false,
      };
    case SET_STAFF_LOADING:
      return {
        ...state,
        staffLoading: true,
      };
    case SET_INVITED_LOADING:
      return {
        ...state,
        invitedLoading: true,
      };
    default:
      return state;
  }
};

export default staffReducer;
