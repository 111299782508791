// Usage : Samples
// 1. addToast('Some General Message', { appearance: 'success'}); - Shows Only Message
// 2. addToast('Some General Message', { appearance: 'success', title: 'Title To Display', description: 'Description To Display', actionButton: 'ButttonName', actionButtonCallback: functionName  }); - Shows Title , Description & Button with Functionality ignores message part
// 3. addToast('', { appearance: 'success', title: 'Title To Display', description: 'Description To Display'  }); - Shows Title , Description alone
// 4. addToast('', { appearance: 'success', title: 'Title To Display' }); - Shows Title alone
// 5. addToast('', { appearance: 'success', title: 'Title To Display', actionButton: 'ButttonName'  }); - Shows Title alone and button wont be visible as functionality is not provided

import { DefaultToast, ToastProps } from 'react-toast-notifications';
import styled from '@emotion/styled';
import { Image, PrimaryButton } from 'styledComponents/commonStyled';
import { GlobalStyles } from '@mui/material';
import errorToast from 'assets/images/icons/errorToast.svg';
import infoToast from 'assets/images/icons/infoToast.svg';
import successToast from 'assets/images/icons/successToast.svg';
import warningToast from 'assets/images/icons/warningToast.svg';


export const ToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 317px;
  min-height: 72px;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 24px;
  align-items: center;
`;

const toastMapper = {
  info: infoToast,
  success: successToast,
  warning: warningToast,
  error: errorToast
}

export const Toast = ({ children, ...props }) => {
  return (
    <>
      <DefaultToast {...props}>
        <GlobalStyles
          styles={{
            '.css-h0gpo1-ToastElement': {
              marginBottom: '12px',
            },
            '.react-toast-notifications__toast__icon-wrapper': {
              display: 'none',
            },
            'div.react-toast-notifications__toast__content': {
              padding: '0',
            },
            'div.react-toast-notifications__toast__content > div': {
              margin: 0,
              minHeight: 'unset',
            },

            'div.react-toast-notifications__toast--success': {
              border: '1px solid #2BC596',
              background: '#E6FAF4',
            },
            'div.react-toast-notifications__toast--warning': {
              border: '1px solid #DEAA12',
              background: '#FCF3D9',
            },
            'div.react-toast-notifications__toast--error': {
              border: '1px solid #BA400D',
              background: '#FDEAE2',
            },
            'div.react-toast-notifications__toast--info': {
              border: '1px solid #8E95AE',
              background: '#EDEFF2',
            },
            'div.react-toast-notifications__toast__dismiss-button': {
              paddingTop: '16px',
              paddingRight: '16px',
              color: 'black',
            },
          }}
        />

        <ToastWrapper>
          {props.appearance && (
            <div style={{ display: 'flex' }}>
              <Image
                src={toastMapper[props.appearance]}
                alt={props.appearance}
                width={24}
                height={24}
              />
            </div>
          )}

          <div style={{ width: '230px', marginLeft: '12px' }}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {props && (props).title ? (
              <>
                <div
                  style={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '20px',
                    height: '20px',
                    color: 'black',
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {(props).title}
                </div>

                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {(props).description && (
                  <div style={{ fontWeight: 400, fontSize: '14px', color: '#474C62' }}>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {(props).description}
                  </div>
                )}
              </>
            ) : (
              <div style={{ fontWeight: 400, fontSize: '14px', color: 'black' }}>{children}</div>
            )}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {props && (props).actionButton && (props).actionButtonCallback && (
              <div>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <PrimaryButton
                  onClick={(props).actionButtonCallback}
                  style={{
                    color: 'black',
                    background: 'white',
                    border: '1px solid #EBEBEB',
                    marginTop: '12px',
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {(props).actionButton}
                </PrimaryButton>
              </div>
            )}
          </div>
        </ToastWrapper>
      </DefaultToast>
    </>
  );
};
