import { SET_PERMISSIONS } from '../types';

const initialState = {
  permissions: null,
};

const permissionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      };
    default:
      return state;
  }
};

export default permissionsReducer;
