import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';
import {
  blueColor,
  borderGreyColor,
  boxShadowColor,
  primaryBackgroundColor,
  tableCellBlackColor,
  tableCellGreyColor,
  tableHeaderBackgroundColor,
  tableHeaderColor,
  whiteColor,
} from 'theme/colorConstants';

export const HeaderText = styled(Typography)`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #27303f;
`;

export const BellButton = styled(Button)`
  border: 1px solid ${borderGreyColor};
  text-transform: capitalize;
  font-size: 12px;
  padding: 0px;
  min-width: 42px;
  height: 42px;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

export const AvatarButton = styled(Button)`
  text-transform: capitalize;
  font-size: 12px;
  margin-right: 8px;
  padding: 6px 1px;
  gap: 6px;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
  &:hover {
    background-color: ${(props) =>
      props.show_background ? 'rgba(232, 232, 232, 0.3);' : 'transparent'};
    box-shadow: none;
  }
`;

export const AvatarButtonSidebar = styled.div`
  max-width: 24px;
  box-sizing: border-box;
  width: 34px !important;
  min-width: 42px !important;
  height: 85px;
  border-radius: 4px;
  background: rgb(248, 248, 248);
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(244, 245, 246);
  /* color: rgb(255, 255, 255); */
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  margin-right: 0px;
  margin-left: 32px;
`;

export const AvatarImage = styled.img`
  border-radius: 8px;
`;

export const CommunityMenuText = styled(Typography)`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${tableCellGreyColor};
`;

export const CommunityItem = styled(Button)`
  background: ${(props) => (props.selected ? '#F7F7F7' : 'transparent')};
  border-radius: 4px;
  padding: 4px;
  display: block;
  width: 100%;
  margin-top: 0px !important;
  text-transform: capitalize;
  margin-top: 14px;
  box-shadow: none;
  &:hover {
    background: #F7F7F7;
    box-shadow: none;
  }
`;

export const CommunityItemText = styled(Typography)`
  font-style: normal;
  display: flex;
  align-items: center;
  color: #4d4d4d;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-left: 7px;
`;

export const CommunityItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
`;

export const CommunityIntegratedImage = styled.img`
  // background: ${tableHeaderBackgroundColor};
  // background: #ffff;
  border-radius: 6px;
  padding: 4px !important;
`;

export const CommunityIntegrateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
`;

export const MarkAsReadButton = styled(Button)`
  background: ${blueColor};
  box-shadow: 0px 12px 36px ${boxShadowColor};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${whiteColor};
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 25px;
  gap: 85px;
  &:hover {
    background: ${blueColor};
    color: ${whiteColor};
    box-shadow: none;
  }
`;

export const NotificationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background: ${primaryBackgroundColor};
  padding: 18px 6px;
  border-radius: 8px;
`;

export const NotificationDetailContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const NotificationDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const NotificationTitle = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: ${tableCellBlackColor};
`;

export const NotificationMessage = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  max-width: 280px;
  color: ${tableHeaderColor};
`;

export const NotificationTimeText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: ${tableHeaderColor};
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: -2px;
  right: -1px;
`;
