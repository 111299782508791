import React from 'react';
import { MenuContainer } from 'styledComponents/sidebarStyled';
import { ToolTipCustom, customTooltipClass } from '../../atoms/Tooltip';
import { trackUserEvent } from 'utils/segment';
import activityIcon from '../../assets/images/icons/activityIcon.svg';
import activityActiveIcon from '../../assets/images/icons/activityActiveIcon.svg';
import memberInactive from '../../assets/images/icons/memberInactive.svg';
import memberActive from '../../assets/images/icons/memberActive.svg';
import messageIcon from '../../assets/images/icons/messageIcon.svg';
import messagesActive from '../../assets/images/icons/messagesActive.svg';
import automationIcon from '../../assets/images/icons/automationIcon.svg';
import automationActiveIcon from '../../assets/images/icons/automationActiveIcon.svg';
import bellIcon from '../../assets/images/icons/bellIcon.svg';
import notificationIconActive from '../../assets/images/icons/notificationIconActive.svg';
import settingIcon from '../../assets/images/icons/settingIcon.svg';
import settingsActive from '../../assets/images/icons/settingsActive.svg';
import adminDefault from '../../assets/images/icons/adminDefault.svg';
import adminDefaultActive from '../../assets/images/icons/adminDefaultActive.svg';
import outreachActiveIcon from '../../assets/images/icons/outreachActive.svg';
import outreachDefaultIcon from 'assets/images/icons/outreachIcon.svg';
import yearEndIcon from 'assets/images/icons/yearEndIcon.svg';
import supportBotIcon from 'assets/images/icons/supportBot.svg';
import supportBotIconActive from 'assets/images/icons/supportBotActive.svg';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useGetSidebarToggleState } from 'context/hooks';
import teamMateInactive from 'assets/images/icons/teamMateInactive.svg';
import teamMateActive from 'assets/images/icons/teamMateActive.svg';
import joinCommunityInactive from 'assets/images/icons/joinCommunityInactive.svg';

import botconversation from 'assets/images/icons/botcustomization.svg';

import botcustomizationactive from 'assets/images/icons/botcustomizationactive.svg';

import dataSourceBotIcon from 'assets/images/icons/dataSourceBotIcon.svg';
import dataSourceBotIconActive from 'assets/images/icons/dataSourceBotIconActive.svg';

import botSettingsIcon from 'assets/images/icons/botSettingsIcon.svg';
import botSettingsIconActive from 'assets/images/icons/botSettingsIconActive.svg';

import botnewicon from 'assets/images/icons/botnewicon.svg';
import botnewiconActive from 'assets/images/icons/botnewiconactive.svg';

import trainIcon from 'assets/images/icons/trainIcon.svg';
import trainIconActive from 'assets/images/icons/trainIconActive.svg';

import agentAssistIcon from 'assets/images/icons/agentAssistIcon.svg';
import agentAssistIconActive from 'assets/images/icons/agentAssistIconActive.svg';

import customerBotAnalytics from 'assets/images/icons/customerBotAnalytics.svg';

import devicemessageActive from 'assets/images/icons/devicemessageActive.svg';
import devicemessage from 'assets/images/icons/devicemessage.svg';

import slackBotMenu from 'assets/images/icons/slackBotMenu.svg';
import slackBotMenuActive from 'assets/images/icons/slackBotMenuActive.svg';

import chomeMenuItem from 'assets/images/icons/chomeMenuItem.svg';
import chomeMenuItemActive from 'assets/images/icons/chomeMenuItemActive.svg';

import webMenuItem from 'assets/images/icons/webMenuItem.svg';
import webMenuItemActive from 'assets/images/icons/webMenuItemActive.svg';

import discordMenuItem from 'assets/images/icons/discordMenuItem.svg';
import discordMenuItemActive from 'assets/images/icons/discordMenuItemActive.svg';

import testBotIcon from 'assets/images/icons/testBotIcon.svg';
import testBotIconActive from 'assets/images/icons/testBotIconActive.svg';

import openConversation from 'assets/images/icons/openConversation.svg';

import resolvedConversation from 'assets/images/icons/resolvedConversation.svg';

import allConversation from 'assets/images/icons/allconversation.svg';

import askAiIcon from 'assets/images/icons/askAI.svg';

import msTeamsIcon from 'assets/images/icons/msTeamsSidebarIcon.svg';
import msTeamsIconActive from 'assets/images/icons/msTeamsSidebarIconActive.svg';
import actionsAi from 'assets/images/icons/actionsAi.svg';

export const GetMenuLink = ({ menu }) => {
  const classes = customTooltipClass();
  const router = useHistory();
  const sidebarToggleState = useSelector(useGetSidebarToggleState);
  const getImageIcon = () => {
    if (!menu) {
      return;
    }
    const isActive = router?.location?.pathname?.startsWith(menu.url);
    switch (menu.url) {
      case '/activity': {
        if (isActive) {
          return activityActiveIcon;
        } else {
          return activityIcon;
        }
      }
      case '/members': {
        if (isActive) {
          return memberActive;
        } else {
          return memberInactive;
        }
      }
      case '/support': {
        if (isActive) {
          return messagesActive;
        } else {
          return messageIcon;
        }
      }
      case '/activities': {
        if (isActive) {
          return messagesActive;
        } else {
          return messageIcon;
        }
      }
      case '/automations': {
        if (isActive) {
          return automationActiveIcon;
        } else {
          return automationIcon;
        }
      }
      case '/logging/notifications': {
        if (isActive) {
          return notificationIconActive;
        } else {
          return bellIcon;
        }
      }
      case '/settings': {
        if (isActive) {
          return settingsActive;
        } else {
          return settingIcon;
        }
      }
      case '/accounts': {
        if (isActive) {
          return adminDefaultActive;
        } else {
          return adminDefault;
        }
      }
      // comment to disable outreach
      case '/outreach': {
        if (isActive) {
          return outreachActiveIcon;
        } else {
          return outreachDefaultIcon;
        }
      }
      case '/community-roundup22': {
        return yearEndIcon;
      }
      case '/add-teammate': {
        if (isActive) {
          return teamMateActive;
        } else {
          return teamMateInactive;
        }
      }
      case 'https://www.threado.com/community#Join': {
        if (isActive) {
          return joinCommunityInactive;
        } else {
          return joinCommunityInactive;
        }
      }
      case '/support-bot/insights': {
        if (isActive) {
          return dataSourceBotIconActive;
        } else {
          return dataSourceBotIcon;
        }
      }
      case '/support-bot/insights/overall': {
        if (isActive) {
          return customerBotAnalytics;
        } else {
          return customerBotAnalytics;
        }
      }
      case '/support-bot/insights/agent-assist': {
        if (isActive) {
          return devicemessageActive;
        } else {
          return devicemessage;
        }
      }
      case '/support-bot/conversation-feed': {
        if (isActive) {
          return botcustomizationactive;
        } else {
          return botconversation;
        }
      }
      case '/support-bot/conversations/open': {
        if (isActive) {
          return openConversation;
        } else {
          return openConversation;
        }
      }
      case '/support-bot/conversations/resolved': {
        if (isActive) {
          return resolvedConversation;
        } else {
          return resolvedConversation;
        }
      }

      case '/support-bot/conversations/all': {
        if (isActive) {
          return allConversation;
        } else {
          return allConversation;
        }
      }
      case '/support-bot/settings': {
        if (isActive) {
          return botSettingsIconActive;
        } else {
          return botSettingsIcon;
        }
      }

      case '/support-bot/data-source': {
        if (isActive) {
          return supportBotIconActive;
        } else {
          return supportBotIcon;
        }
      }

      case '/support-bot': {
        if (isActive) {
          return botnewiconActive;
        } else {
          return botnewicon;
        }
      }
      case '/support-bot/train': {
        if (isActive) {
          return trainIconActive;
        } else {
          return trainIcon;
        }
      }
      case '/support-bot/training': {
        if (isActive) {
          return trainIconActive;
        } else {
          return trainIcon;
        }
      }
      case '/support-bot/ask': {
        if (isActive) {
          return askAiIcon;
        } else {
          return askAiIcon;
        }
      }
      case '/support-bot/actions': {
        if (isActive) {
          return actionsAi;
        } else {
          return actionsAi;
        }
      }
      case '/support-bot/setup': {
        if (isActive) {
          return agentAssistIconActive;
        } else {
          return agentAssistIcon;
        }
      }
      case '/support-bot/setup/slack-bot': {
        if (isActive) {
          return slackBotMenuActive;
        } else {
          return slackBotMenu;
        }
      }
      case '/support-bot/setup/chrome-app': {
        if (isActive) {
          return chomeMenuItemActive;
        } else {
          return chomeMenuItem;
        }
      }
      case '/support-bot/setup/web-chat': {
        if (isActive) {
          return webMenuItemActive;
        } else {
          return webMenuItem;
        }
      }
      case '/support-bot/setup/discord-bot': {
        if (isActive) {
          return discordMenuItemActive;
        } else {
          return discordMenuItem;
        }
      }
      case '/support-bot/setup/ms-teams': {
        if (isActive) {
          return msTeamsIconActive;
        } else {
          return msTeamsIcon;
        }
      }
      case '/support-bot/agent-assist': {
        if (isActive) {
          return agentAssistIconActive;
        } else {
          return agentAssistIcon;
        }
      }
      case '/support-bot/agent-assist/test': {
        if (isActive) {
          return testBotIconActive;
        } else {
          return testBotIcon;
        }
      }
      case '/support-bot/agent-assist/customization': {
        if (isActive) {
          return testBotIconActive;
        } else {
          return testBotIcon;
        }
      }
      default:
        break;
    }
  };

  /**
   * Get icons configuration on open state
   * @returns
   */
  const getIconsInOpenState = () => {
    return (
      <>
        {menu.name && (
          <a
            role="listitem"
            style={
              ['Notifications', 'Menu Options', 'Settings', 'Add teammates'].indexOf(menu.name) !==
              -1
                ? {
                    flexDirection: 'column !important',
                    display: 'inline-flex !important',
                    justifyContent: 'flex-end !important',
                    cursor: 'pointer !important',
                    margin: '0px !important',
                    top: '18% !important',
                    // height: menu.name === 'Add teammate' ? '100% !important' : 'auto !important',
                    alignItems: 'center !important',
                    // marginTop: '10px !important',
                    pointerEvents: 'none !important',
                  }
                : {
                    flexDirection: 'column !important',
                    display: 'inline-flex !important',
                    justifyContent: 'flex-end !important',
                    cursor: 'pointer !important',
                    margin: '0px !important',
                    top: '18% !important',
                    // height: menu.name === 'Add teammate' ? '100% !important' : 'auto !important',
                    alignItems: 'center !important',
                    // marginTop: '10px !important',
                    pointerEvents: 'none !important',
                  }
            }>
            {!['Notifications', 'Menu Options', 'Settings', 'Add teammates'].includes(menu.name) ? (
              <span
                style={
                  ['Notifications', 'Menu Options', 'Settings', 'Add teammates'].includes(menu.name)
                    ? {
                        flexDirection: 'column',
                        display: 'inline-flex',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                        height: menu.name === 'Add teammates' ? '100%' : 'auto',
                        marginBottom: '2px',
                        alignItems: 'center',
                        // marginTop: '10px',
                      }
                    : {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        cursor: 'pointer',
                        height: 'auto',
                        marginBottom: '2px',
                        alignItems: 'center',
                        marginLeft: `${menu.name === 'Join community' ? '0px' : '0px'}`,
                        marginRight: '25px',
                        width: '100%',
                      }
                }>
                <MenuContainer
                  responseOnHover={true}
                  sidebarToggleState={sidebarToggleState}
                  onClick={() => {
                    trackUserEvent('Clicked on Tab - Side Menu', {
                      tabName: menu.name,
                    });
                  }}
                  style={
                    router?.location?.pathname?.startsWith(menu.url)
                      ? {
                          background: 'rgb(244, 245, 246)',
                          borderRadius: '8px',
                          height: '32px',
                          width: '100%',
                          display: 'flex',
                          boxSizing: 'border-box',
                          marginRight: '8px',
                        }
                      : {}
                  }>
                  <div
                    style={{
                      display: router?.location?.pathname?.startsWith(menu.url) ? 'block' : 'none',
                    }}>
                    <img
                      loading="eager"
                      src={getImageIcon()}
                      alt={`${menu.name}Icon`}
                      width={15}
                      height={15}
                    />
                  </div>
                  <div
                    style={{
                      display: !router?.location?.pathname?.startsWith(menu.url) ? 'block' : 'none',
                    }}>
                    <img
                      loading="eager"
                      src={getImageIcon()}
                      alt={`${menu.name}Icon`}
                      width={15}
                      height={15}
                    />
                  </div>
                  <div
                    className="sidebar-open-menu-style"
                    style={{
                      color: router?.location?.pathname?.startsWith(menu.url)
                        ? '#000000'
                        : '#000000',
                    }}>
                    {menu.name}
                  </div>
                </MenuContainer>
              </span>
            ) : (
              <div style={{ pointerEvents: 'all' }}>
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    cursor: 'pointer',
                    height: 'auto',
                    marginBottom: '2px',
                    alignItems: 'center',
                    marginLeft: menu.name === 'Add teammates' ? '9px' : '0px',
                    marginRight: '75px',
                    width: '100%',
                  }}>
                  <MenuContainer
                    responseOnHover={true}
                    sidebarToggleState={sidebarToggleState}
                    onClick={() => {
                      trackUserEvent('Clicked on Tab - Side Menu', {
                        tabName: menu.name,
                      });
                    }}
                    style={
                      router?.location?.pathname?.startsWith(menu.url)
                        ? {
                            background: 'rgb(244, 245, 246)',
                            borderRadius: '8px',
                            height: '36px',
                            // width: '153px',
                            // border: '1px solid rgba(255, 255, 255, 0.18)',
                            display: 'flex',
                            boxSizing: 'border-box',
                            paddingTop: '8px !important',
                            marginRight: menu.name === 'Add teammates' ? '25px' : 'revert-layer',
                            paddingRight: menu.name === 'Add teammates' ? '25px' : 'revert-layer',
                          }
                        : {
                            marginRight: menu.name === 'Add teammates' ? '25px' : 'revert-layer',
                            paddingRight: menu.name === 'Add teammates' ? '25px' : 'revert-layer',
                          }
                    }>
                    <div
                      style={{
                        display: router?.location?.pathname?.startsWith(menu.url)
                          ? 'block'
                          : 'none',
                      }}>
                      <img
                        loading="eager"
                        src={getImageIcon()}
                        alt={`${menu.name}Icon`}
                        width={15}
                        height={15}
                      />
                    </div>
                    <div
                      style={{
                        display: !router?.location?.pathname?.startsWith(menu.url)
                          ? 'block'
                          : 'none',
                      }}>
                      <img
                        loading="eager"
                        src={getImageIcon()}
                        alt={`${menu.name}Icon`}
                        width={15}
                        height={15}
                      />
                    </div>
                    <div
                      className="sidebar-open-menu-style"
                      style={{
                        color: router?.location?.pathname?.startsWith(menu.url)
                          ? '#000000'
                          : '#000000',
                      }}>
                      {menu.name}
                    </div>
                  </MenuContainer>
                </span>
              </div>
            )}
          </a>
        )}
      </>
    );
  };
  /**
   * Get icons configuration in closed state
   * @returns
   */
  const getIconsInClosedState = () => {
    return (
      <>
        {menu.name && (
          <a
            role="listitem"
            style={
              ['Notifications', 'Menu Options', 'Settings', 'Add teammates'].indexOf(menu.name) !==
              -1
                ? {
                    flexDirection: 'column !important',
                    display: 'inline-flex !important',
                    justifyContent: 'flex-end !important',
                    cursor: 'pointer !important',
                    margin: '0px !important',
                    top: '18% !important',
                    height: menu.name === 'Add teammates' ? '100% !important' : 'auto !important',
                    alignItems: 'center !important',
                    // marginTop: '10px !important',
                    pointerEvents: 'none !important',
                  }
                : {
                    display: 'flex !important',
                    justifyContent: 'center !important',
                    cursor: 'pointer !important',
                    height: '52px !important',
                    margin: '0px !important',
                    top: '18% !important',
                    alignItems: 'center !important',
                    // marginTop: '10px !important',
                  }
            }>
            {!['Notifications', 'Menu Options', 'Settings', 'Add teammates'].includes(menu.name) ? (
              <ToolTipCustom
                classes={{
                  tooltip: classes.customTooltip,
                }}
                placement={'right'}
                arrow={true}
                title={
                  menu.name !== 'Add teammates' ? (
                    <div>
                      <span
                        style={{
                          color: 'black',
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          fontSize: '13px',
                          marginTop: '20px',
                        }}>
                        {menu.name}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span
                        style={{
                          color: 'black',
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          fontSize: '13px',
                          marginTop: '20px',
                        }}>
                        {menu.name}
                      </span>
                    </div>
                  )
                }>
                <span
                  style={
                    ['Notifications', 'Menu Options', 'Settings', 'Add teammates'].includes(
                      menu.name,
                    )
                      ? {
                          flexDirection: 'column',
                          display: 'inline-flex',
                          justifyContent: 'flex-end',
                          cursor: 'pointer',
                          marginTop: '5px',
                          height: menu.name === 'Add teammates' ? '100%' : 'auto',
                          alignItems: 'center',
                          // marginTop: '10px',
                        }
                      : {
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          height: 'auto',
                          marginTop: '5px',
                          alignItems: 'center',
                          // marginTop: '10px',
                        }
                  }>
                  <MenuContainer
                    responseOnHover={true}
                    sidebarToggleState={sidebarToggleState}
                    onClick={() => {
                      trackUserEvent('Clicked on Tab - Side Menu', {
                        tabName: menu.name,
                      });
                    }}
                    style={
                      router?.location?.pathname?.startsWith(menu.url)
                        ? {
                            background: '#403651',
                            borderRadius: '5px',
                            height: '38px',
                            width: '38px',
                            // border: '1px solid rgba(255, 255, 255, 0.18)',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                            alignContent: 'center',
                            alignItems: 'center',
                            paddingTop: '0px',
                          }
                        : {}
                    }>
                    <div
                      style={{
                        display: router?.location?.pathname?.startsWith(menu.url) ? 'flex' : 'none',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        marginTop: '-1px',
                      }}>
                      <img
                        loading="eager"
                        src={getImageIcon()}
                        alt={`${menu.name}Icon`}
                        width={15}
                        height={15}
                      />
                    </div>
                    <div
                      style={{
                        display: router?.location?.pathname?.startsWith(menu.url) ? 'flex' : 'none',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        marginTop: '-1px',
                      }}>
                      <img
                        loading="eager"
                        src={getImageIcon()}
                        alt={`${menu.name}Icon`}
                        width={15}
                        height={15}
                      />
                    </div>
                  </MenuContainer>
                </span>
              </ToolTipCustom>
            ) : (
              <div style={{ pointerEvents: 'all' }}>
                <ToolTipCustom
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  placement={'right'}
                  arrow={true}
                  title={
                    <div>
                      <span
                        style={{
                          color: 'black',
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          marginTop: '20px',
                        }}>
                        {menu.name}
                      </span>
                    </div>
                  }>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      height: 'auto',
                      marginTop: '5px',
                      alignItems: 'center',
                    }}>
                    <MenuContainer
                      responseOnHover={true}
                      sidebarToggleState={sidebarToggleState}
                      onClick={() => {
                        trackUserEvent('Clicked on Tab - Side Menu', {
                          tabName: menu.name,
                        });
                      }}
                      style={
                        router?.location?.pathname?.startsWith(menu.url)
                          ? {
                              background: '#403651',
                              borderRadius: '5px',
                              height: '38px',
                              width: '38px',
                              // border: '1px solid rgba(255, 255, 255, 0.18)',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              flexWrap: 'nowrap',
                              alignContent: 'center',
                              alignItems: 'center',
                              paddingTop: '0px',
                            }
                          : {}
                      }>
                      <div
                        style={{
                          display: router?.location?.pathname?.startsWith(menu.url)
                            ? 'block'
                            : 'none',
                        }}>
                        <img
                          loading="eager"
                          src={getImageIcon()}
                          alt={`${menu.name}Icon`}
                          width={15}
                          height={15}
                        />
                      </div>
                      <div
                        style={{
                          display: !router?.location?.pathname?.startsWith(menu.url)
                            ? 'block'
                            : 'none',
                        }}>
                        <img
                          loading="eager"
                          src={getImageIcon()}
                          alt={`${menu.name}Icon`}
                          width={15}
                          height={15}
                        />
                      </div>
                    </MenuContainer>
                  </span>
                </ToolTipCustom>
              </div>
            )}
          </a>
        )}
      </>
    );
  };

  return <>{sidebarToggleState ? <>{getIconsInOpenState()}</> : <>{getIconsInClosedState()}</>}</>;
};
