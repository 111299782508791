export const updateObjectWithChanges = (obj1, obj2) => {
  let newObj = JSON.parse(JSON.stringify(obj1)); // Deep copy of obj1

  for (const key in obj2) {
    if (obj2[key] !== null && obj2[key] !== undefined) {
      if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
        if (!newObj[key]) {
          newObj[key] = {};
        }
        newObj[key] = updateObjectWithChanges(newObj[key], obj2[key]);
      } else {
        newObj[key] = obj2[key];
      }
    }
  }

  return newObj;
};
