import {
  APP_FEATURE_DISCORD_INTEGRATION,
  APP_FEATURE_DISCOURSE_INTEGRATION,
  APP_FEATURE_SLACK_INTEGRATION,
  APP_FEATURE_TWITTER_INTEGRATION,
  APP_FEATURE_GITHUB_INTEGRATION,
  APP_FEATURE_HUBSPOT_INTEGRATION,
  APP_FEATURE_CRM_HUBSPOT_INTEGRATION,
  APP_FEATURE_SALESFORCE_INTEGRATION,
} from './features';

import slack from 'assets/images/icons/slack.svg';
import discord from 'assets/images/icons/discord.svg';
import discourse from 'assets/images/icons/discourse.svg';
import twitter from 'assets/images/icons/twitter.svg';
import github from 'assets/images/icons/github.svg';
import email from 'assets/images/icons/email.svg';
import recieveHubspot from 'assets/images/icons/recieveHubspot.svg';
import sendHubspot from 'assets/images/icons/sendHubspot.svg';
import hubspotIcon from 'assets/images/icons/hubspotIcon-new.svg';
import webTabIcon from 'assets/images/icons/webTabIcon.svg';
import chromApp from 'assets/images/icons/chromeApp.svg';

import { THREADO_PLAN_TYPES } from 'components/Payments/utils';

export const rewardsTabsData = [
  {
    tabName: 'Activity Score',
  },
  // {
  //   tabName: 'Member Levels',
  // },
  // {
  //   tabName: 'Member Badges',
  // },
];

export const eventTabsData = [
  {
    tabName: 'Upcoming',
    tabCount: 12,
  },
  {
    tabName: 'Past',
    tabCount: 6,
  },
];

export const createEventTabsData = [
  {
    tabName: 'Main Information',
  },
  {
    tabName: 'Event Details',
  },
];

export const schedulerTabData = [
  {
    tabName: 'Scheduled',
    tabCount: 6,
  },
  {
    tabName: 'Posted',
    tabCount: 12,
  },
  {
    tabName: 'Draft',
    tabCount: 2,
  },
];

export const weeklySummaryTabData = [
  {
    tabName: 'Create Summary',
  },
  {
    tabName: 'Summary Scheduler',
  },
];

export const weeklySummaryContentTabData = [
  {
    tabName: 'TOP DISCUSSIONS',
  },
  {
    tabName: 'TOP MEMBERS',
  },
  {
    tabName: 'UPCOMING EVENTS',
  },
];

export const contentBuilderTabData = [
  {
    tabName: 'Create Posts',
  },
  {
    tabName: 'Templates',
  },
  {
    tabName: 'Scheduled',
  },
  {
    tabName: 'Posted',
  },
];

export const contentBuilderSettingsTabData = [
  {
    tabName: 'Posts Settings',
  },
  {
    tabName: 'Templates',
  },
  {
    tabName: 'Drafts',
  },
];

export const editContentSchedulerTabData = [
  {
    tabName: 'Content',
  },
  {
    tabName: 'Platform',
  },
  {
    tabName: 'Segment',
  },
  {
    tabName: 'Date',
  },
];

export const eventTabData = [
  {
    tabName: 'Details',
  },
  {
    tabName: 'Invites',
  },
  {
    tabName: 'Reminders',
  },
  {
    tabName: 'Feedback',
  },
];

export const configureSegment = [
  {
    tabName: 'Actions and Tokens',
  },
  {
    tabName: 'Segment Settings',
  },
];

export const outreachTemplateData = [
  {
    tabName: 'Templates gallery',
  },
  {
    tabName: 'Saved templates',
  },
];

export const memberDetailsData = [
  {
    tabName: 'All Members',
  },
  {
    tabName: 'Onboarding',
  },
  {
    tabName: 'At Risk',
  },
  {
    tabName: 'Champions',
  },
  {
    tabName: 'Contributors',
  },
  {
    tabName: 'Engaged',
  },
  {
    tabName: 'Explorer',
  },
  {
    tabName: 'Dormant',
  },
];

export const acitvitySummaryInsightsTabData = [
  {
    tabName: 'Slack',
    tabValue: 'slack',
    feature: APP_FEATURE_SLACK_INTEGRATION,
    image: slack,
  },
  {
    tabName: 'Discord',
    tabValue: 'discord',
    feature: APP_FEATURE_DISCORD_INTEGRATION,
    image: discord,
  },
  {
    tabName: 'GitHub',
    tabValue: 'github',
    feature: APP_FEATURE_GITHUB_INTEGRATION,
    image: github,
  },
  {
    tabName: 'Twitter',
    tabValue: 'twitter',
    feature: APP_FEATURE_TWITTER_INTEGRATION,
    image: twitter,
  },
  {
    tabName: 'Discourse',
    tabValue: 'discourse',
    feature: APP_FEATURE_DISCOURSE_INTEGRATION,
    image: discourse,
  },
  // {
  //   tabName: 'HubSpot',
  //   tabValue: 'hubspot',
  //   feature: APP_FEATURE_HUBSPOT_INTEGRATION,
  //   image: hubspotIcon,
  // },
  {
    tabName: 'SlackInternal',
    tabValue: 'slackInternal',
    feature: false,
    image: hubspotIcon,
  },
  {
    tabName: 'Email',
    tabValue: 'email',
    feature: 'APP_FEATURE_EMAIL',
    image: email,
  },
  // {
  //   tabName: 'Zoom',
  //   tabValue: 'zoom',
  // },
];

export const paymentsPlanModalTabData = [
  // {
  //   tabName: 'BASIC',
  //   tabValue: 'growth',
  //   productIdName: THREADO_PLAN_TYPES.BASIC_PLAN,
  // },
  {
    tabName: 'PRO',
    tabValue: 'pro',
    productIdName: THREADO_PLAN_TYPES.PRO_PLAN,
    isSpecialTab: 'Most popular',
  },
  // {
  //   tabName: 'MAX',
  //   tabValue: 'max',
  //   productIdName: THREADO_PLAN_TYPES.MAX_PLAN,
  //   isSpecialTab: 'Most popular',
  // },
];

export const domainDetailTabs = [
  {
    tabName: 'Verify',
  },
  {
    tabName: 'Senders',
  },
];

export const HubspotIntegrationTabs = [
  {
    tabName: `Receive data from HubSpot`,
    tabImage: recieveHubspot,
  },
  {
    tabName: `Send data to HubSpot`,
    tabImage: sendHubspot,
  },
];

export const botCustomizationTabData = [
  {
    tabName: 'Slack',
    tabValue: 'slack',
    tabImageBot: slack,
  },
  {
    tabName: 'Discord',
    tabValue: 'discord',
    tabImageBot: discord,
  },
  {
    tabName: 'Web',
    tabValue: 'widget',
    tabImageBot: webTabIcon,
  },
];

export const botInstallTabData = [
  {
    tabName: 'Add to Slack',
    tabValue: 'SLACK',
    tabImageBot: slack,
  },
  {
    tabName: 'Add to Discord',
    tabValue: 'DISCORD',
    tabImageBot: discord,
  },
  {
    tabName: 'Web',
    tabValue: 'WEB',
    tabImageBot: webTabIcon,
  },
];

export const agentAssistCustomizationTabData = [
  {
    tabName: 'Chrome app',
    tabValue: 'CHROME',
    tabImageBot: chromApp,
  },
  {
    tabName: 'Slack',
    tabValue: 'SLACK',
    tabImageBot: slack,
  },
];
