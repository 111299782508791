import { useDispatch, useSelector } from 'react-redux';
import {
  selectCommunityAction,
  switchWorkspacePostAction,
} from 'redux/communityProvider/actions/communityAction';

import { useGetCurrentCommunity, useGetUserInfo } from './hooks';
import IntegrationTokenRefresh from 'components/Community/IntegrationManager/IntegrationTokenRefresh';
import { identifyUser } from 'utils/segment';
import { useHistory } from 'react-router';
import { useQuery } from 'utils/useQuery';
import { useEffect } from 'react';

const pagesWithoutCommunity = [
  '/accounts/login',
  '/accounts/signup',
  '/accounts/password',
  '/settings/integrations/select',
  '/settings/integrations/create',
  // '/setup/configuration',
  '/events/join',
  '/email/unsubscribe',
  '/',
];

const loadCommunityIdFromLocalStorage = () => {
  const selectedCommunityWithUserIdString = localStorage.getItem('selectedCommunity');
  try {
    return selectedCommunityWithUserIdString !== null
      ? JSON.parse(selectedCommunityWithUserIdString)
      : null;
  } catch (err) {
    return null;
  }
};

const checkTokenRefresh = (integrations) => {
  const providers = integrations ? Object.keys(integrations) : [];
  let hasToRefresh = false;
  providers.forEach((provider) => {
    const providerIntegrations = integrations[provider];
    providerIntegrations.forEach((integration) => {
      hasToRefresh = integration.refresh || hasToRefresh;
    });
  });
  return hasToRefresh;
};

const CommunityProvider = ({ children }) => {
  const router = useHistory();
  const location = router.location;
  const routerQuery = useQuery();
  const userInfo = useSelector(useGetUserInfo);
  const dispatch = useDispatch();
  const communityDetail = useSelector(useGetCurrentCommunity);
  const hasToRefresh = communityDetail && checkTokenRefresh(communityDetail.integrations);

  const searchParams = new URLSearchParams(location);

  // TODO remove this when feature is over
  if (location.pathname.includes('/community-roundup22')) {
    return children;
  }

  if (userInfo && communityDetail)
    if (hasToRefresh && communityDetail) {
      const { id } = communityDetail;
      return (
        <IntegrationTokenRefresh integrations={communityDetail.integrations} communityId={id} />
      );
    }

  if (
    !userInfo &&
    (location.pathname === '/login' ||
      location.pathname === '/signup' ||
      location.pathname === '/accounts/password/reset' ||
      location.pathname === '/email/unsubscribe')
  ) {
    return children;
  }

  if (!userInfo && location.pathname !== '/login') {
    router.push({
      pathname: '/login',
    });
    return null;
  }

  if (communityDetail || (userInfo && pagesWithoutCommunity.some((p) => location.pathname === p))) {
    return children;
  }

  const userCommunities = userInfo?.communities || [];

  const communityInfoFromLocalStorage = loadCommunityIdFromLocalStorage();

  if (
    communityInfoFromLocalStorage &&
    userInfo &&
    communityInfoFromLocalStorage.userId === userInfo.id &&
    userCommunities.some((uc) => uc.id === communityInfoFromLocalStorage.communityId)
  ) {
    switchWorkspacePostAction(
      userCommunities.find((uc) => uc.id === communityInfoFromLocalStorage.communityId),
    )
      .then(() => {})
      .catch((err) => {
        console.warn('Failed to switch community!');
      });
    dispatch(
      selectCommunityAction(
        userCommunities.find((uc) => uc.id === communityInfoFromLocalStorage.communityId),
        userInfo,
      ),
    );
    identifyUser(userInfo);

    if (!pagesWithoutCommunity.includes(router.location.pathname)) {
      return children;
    }

    router.push({
      pathname: '/activity/summary',
      query: {
        ...routerQuery,
      },
    });
    return null;
  }

  if (userCommunities.length === 1 && userInfo) {
    switchWorkspacePostAction(userCommunities[0])
      .then(() => {})
      .catch((err) => {
        console.warn('Failed to switch community!');
      });
    dispatch(selectCommunityAction(userCommunities[0], userInfo, true));
    identifyUser(userInfo);
    router.push({
      pathname: '/activity/summary',
    });
    return null;
  }

  if (userCommunities.length > 1) {
    router.push({
      pathname: '/settings/integrations/select',
      query: {
        ...routerQuery,
      },
    });
    return null;
  }

  if (userCommunities.length === 0) {
    const searchParams = new URLSearchParams(routerQuery);

    router.push({
      pathname: '/settings/integrations/create',
      search: `?${searchParams.toString()}`,
    });
    return null;
  }

  if (!communityDetail && location.pathname !== '/settings/integrations/select') {
    router.push('/settings/integrations/select');
    return null;
  }
  return children;
};

export default CommunityProvider;
