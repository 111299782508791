import React from 'react';
import { useDispatch } from 'react-redux';
import { Image, PrimaryButton } from 'styledComponents/commonStyled';
import { setUpgradeNowEventSourceAction } from 'redux/paymentsProvider/actions/paymentsActions';
import { trackUserEvent } from 'utils/segment';
import { BannerContainer, BannerMessagePayments } from 'styledComponents/launchBannerStyled';
import { useHistory } from 'react-router';
import lockWhite from 'assets/images/icons/lockWhite.svg';
import { getDaysInFutureFloor } from 'components/Settings/BillDetails/utils';
import { workspaceValueRoleMapper } from 'constant/staffRoleData';

const BotLaunchBannerTrialPlan = ({ paymentsPlanStatus, communityRole, showUpgrade }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    history.push('/settings/billing');
    trackUserEvent('Clicked Upgrade now', {
      source: 'Top bar',
    });
    dispatch(setUpgradeNowEventSourceAction('Top bar'));
  };

  const handleExploreClick = () => {
    history.push('/support-bot/data-source');
  };

  return (
    <>
      {communityRole === workspaceValueRoleMapper.ORGANIZATION_OWNER ? (
        <div>
          <BannerContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '4px',
                backgroundColor: '#FFFFFF',
              }}>
              <BannerMessagePayments>
                🎉 You have access to Threado AI support bot for
                <span style={{ fontWeight: 600 }}>
                  &nbsp;{getDaysInFutureFloor(paymentsPlanStatus?.trialEnd)}
                </span>
                .
              </BannerMessagePayments>
              {showUpgrade ? (
                <PrimaryButton style={{ height: '24px' }} onClick={() => handleClick()}>
                  <Image src={lockWhite} alt="lock" height={10} width={10} /> Upgrade Now
                </PrimaryButton>
              ) : (
                <PrimaryButton style={{ height: '24px' }} onClick={() => handleExploreClick()}>
                  Explore Now
                </PrimaryButton>
              )}
            </div>
            {/* {showDemoButton && (
              <a
                href="https://meetings-eu1.hubspot.com/threado/demo"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackUserEvent('Clicked schedule demo', {})}>
                <PrimaryButton
                  style={{
                    height: '24px',
                    marginLeft: '12px',
                    backgroundColor: colorToken.colors.white,
                    color: colorToken.colors.purpleText,
                  }}>
                  Schedule demo
                </PrimaryButton>
              </a>
            )} */}
          </BannerContainer>
        </div>
      ) : (
        <div>
          <BannerContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '4px',
                backgroundColor: '#FFFFFF',
              }}>
              <BannerMessagePayments>
                🎉 You have access to Threado AI support bot for
                <span style={{ fontWeight: 600 }}>
                  &nbsp;{getDaysInFutureFloor(paymentsPlanStatus?.trialEnd)}
                </span>
                .
              </BannerMessagePayments>
              {showUpgrade ? (
                <PrimaryButton style={{ height: '24px' }} onClick={() => handleClick()}>
                  <Image src={lockWhite} alt="lock" height={10} width={10} /> Upgrade Now
                </PrimaryButton>
              ) : (
                <PrimaryButton style={{ height: '24px' }} onClick={() => handleExploreClick()}>
                  Explore Now
                </PrimaryButton>
              )}
            </div>
            {/* {showDemoButton && (
              <a
                href="https://meetings-eu1.hubspot.com/threado/demo"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackUserEvent('Clicked schedule demo', {})}>
                <PrimaryButton
                  style={{
                    height: '24px',
                    marginLeft: '12px',
                    backgroundColor: colorToken.colors.white,
                    color: colorToken.colors.purpleText,
                  }}>
                  Schedule demo
                </PrimaryButton>
              </a>
            )} */}
          </BannerContainer>
        </div>
      )}
    </>
  );
};

export default BotLaunchBannerTrialPlan;
