import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';
import {
  boxShadowColor,
  skyeBlueColor,
  tableCellBlackColor,
  tableCellRedColor,
  tableHeaderBackgroundColor,
  blueColor,
  borderGreyColor,
} from '../theme/colorConstants';

import { colorToken } from 'theme/designToken';
import PrimaryButtonWithLoader from 'atoms/Buttons/PrimaryButtonWithLoader';

export const ContainerBox = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
`;

export const IntegrationImage = styled.img`
  background: ${(props) => (props.integrated === 'true' ? '#ffffff' : skyeBlueColor)};
  box-shadow: 0px 6px 28px ${boxShadowColor};
  border-radius: 4px;
`;

export const CancelButton = styled(Button)`
  border-radius: 4px;
  background: ${tableHeaderBackgroundColor};
  font-weight: 600;
  font-size: 12px;
  padding: 10px 40px;
  margin: 15px;
  text-transform: capitalize;
  box-shadow: none;
  color: ${tableCellBlackColor};
  &:hover {
    background: ${tableHeaderBackgroundColor};
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const DeleteButton = styled(PrimaryButtonWithLoader)`
  border-radius: 4px;
  background: ${tableCellRedColor};
  font-weight: 600;
  font-size: 12px;
  padding: 10px 40px;
  box-shadow: none;
  margin: 15px;
  text-transform: capitalize;
  color: #ffffff;
  pointer-events: ${(props) => (props.is_loading ? 'none' : 'all')};
  &:hover {
    background: ${tableCellRedColor};
    color: #ffffff;
    box-shadow: none;
  }
  &.Mui-disabled {
    color: ${colorToken.button.primary.text.color};
  }
`;

export const BlueCommunityText = styled.span`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: ${blueColor};
`;

export const greyText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #959595;
`;

export const ItemTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${colorToken.settings.community.integration.box.title.color};
`;

export const YesButton = styled(Button)`
  border-radius: 8px;
  background: ${blueColor};
  font-weight: 600;
  font-size: 12px;
  padding: 10px 40px;
  box-shadow: none;
  margin: 15px;
  text-transform: capitalize;
  color: #ffffff;
  &:hover {
    background: ${skyeBlueColor};
    color: #ffffff;
    box-shadow: none;
  }
`;

export const IntegrationButton = styled(Button)`
  background: ${(props) => (props.integrated === 'true' ? '#ffffff' : '#696DEC')};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: ${(props) =>
    props.integrated === 'true'
      ? `0px 6px 28px
    ${boxShadowColor}`
      : 'none'};
  padding: 10px 20px;
  text-transform: capitalize;
  color: #ffff;
  min-width: ${(props) => props.mWidth || 'auto'};
  &:hover {
    background: ${(props) => (props.integrated === 'true' ? '#ffffff' : '#696DEC')};
    box-shadow: none;
  }
`;

export const ChannelBox = styled.div`
  box-shadow: None;
  border-radius: 10px;
  margin-right: 0px;
  min-width: 100px;
  padding: 5px 8px;
  padding-right: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  border: 1px solid ${borderGreyColor};
`;

export const CommunityText = styled(Typography)`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: ${tableCellBlackColor};
`;

export const DescriptionText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${tableCellBlackColor};
`;

export const RoleText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: ${tableCellBlackColor};
  display: flex;
  align-items: center;
  gap: 4px;
`;
