import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FilterOption, FilterOptionLabel } from 'styledComponents/memberStyled';
import { colorToken } from '../../../theme/designToken';
import SearchBox from '../../../commonComponents/SearchBox';
import { isEmpty } from 'lodash';
import checkedBox from 'assets/images/icons/checkedBox.svg';
import dashUnchecked from 'assets/images/icons/dashUnchecked.svg';
import uncheckedBox from 'assets/images/icons/uncheckedBox.svg';
import { platformsMapper } from 'constant/platformsMapper';
import { Image } from 'styledComponents/commonStyled';

const ProviderChannelOptions = ({
  channelFilter,
  setChannelFilter,
  channelOptions,
  setProviderFilterCallback,
  providerFilter,
  filteredChannelOptions,
}) => {
  const [allProviderChannels, setAllProviderChannels] = useState(true);
  const [filterShowLimit, setFilterShowLimit] = useState(3);
  const [clonedChannelOptions, setClonedChannelOptions] = useState([]);

  //Effect to create a deep clone copy of channels for search feature
  useEffect(() => {
    if (isEmpty(channelOptions)) {
      return;
    }
    setClonedChannelOptions(JSON.parse(JSON.stringify(channelOptions)));
  }, [channelOptions]);

  const requestSearch = (searchValue) => {
    const filterChannelsWithSearch = channelOptions?.filter((channel) =>
      channel.name.toLowerCase()?.includes(searchValue?.toLowerCase()),
    );
    setClonedChannelOptions(filterChannelsWithSearch);
  };

  const setFilterLimit = (filterLimit) => {
    if (filterLimit) {
      setFilterShowLimit(filterLimit);
    }
  };

  const handleSingleGroupChange = (event) => {
    if (allProviderChannels) {
      setAllProviderChannels(false);
    }
    if (channelFilter.includes(-1)) {
      channelFilter.splice(channelFilter.indexOf(-1), 1);
    }
    if (!event.target.checked) {
      setChannelFilter(channelFilter.filter((group) => group !== Number(event.target.value)));
      setProviderFilterCallback &&
        setProviderFilterCallback(
          channelFilter.filter((group) => group !== Number(event.target.value)),
        );
      return;
    }

    setChannelFilter([...channelFilter, Number(event.target.value)]);
    setProviderFilterCallback &&
      setProviderFilterCallback([...channelFilter, Number(event.target.value)]);
  };

  const handleallProviderChannelsChange = (event) => {
    if (event.target.checked) {
      setChannelFilter([-1]);
      setProviderFilterCallback && setProviderFilterCallback([-1]);
    }
    setAllProviderChannels(event.target.checked);
  };

  useEffect(() => {
    if (channelFilter.includes(-1)) {
      setAllProviderChannels(true);
      return;
    }
    setAllProviderChannels(false);
  }, [channelFilter]);

  return (
    <>
      <div style={{ display: 'flex', marginBottom: '16px', marginRight: '6px' }}>
        <SearchBox
          requestSearch={requestSearch}
          style={{
            width: '100%',
            border: '1px solid #EBEBEB',
            borderRadius: '4px',
          }}
        />
      </div>

      <FilterOption style={{ paddingBottom: '6px' }}>
        <Checkbox
          style={{
            paddingLeft: '0px',
            paddingRight: '2px',
            paddingTop: '0px',
            paddingBottom: '2px',
          }}
          name="allProviderChannels"
          checked={allProviderChannels}
          disabled={channelFilter?.includes(-1)}
          value="allProviderChannels"
          onChange={handleallProviderChannelsChange}
          icon={<Image src={dashUnchecked} alt="uncheckedBox" width={16} height={16} />}
          checkedIcon={<Image src={checkedBox} alt="uncheckedBox" width={16} height={16} />}
        />
        <FilterOptionLabel is_selected={allProviderChannels.toString()}>
          All
          {filteredChannelOptions?.length === 1 && filteredChannelOptions?.[0]?.value === 'github'
            ? ' repositories'
            : ' channels'}
        </FilterOptionLabel>
      </FilterOption>
      {!providerFilter.includes('all')
        ? clonedChannelOptions
            .sort((left, right) => left?.provider?.localeCompare(right?.provider))
            .filter((channel) => providerFilter.includes(channel.provider))
            .map(
              (option, index) =>
                providerFilter.includes(option.provider) && (
                  <FilterOption key={option.id}>
                    <Checkbox
                      style={{
                        paddingLeft: '0px',
                        paddingRight: '2px',
                        paddingTop: '0px',
                        paddingBottom: '2px',
                        display: index < filterShowLimit ? 'block' : 'none',
                      }}
                      name="segment"
                      checked={channelFilter.includes(option.id)}
                      value={option.id}
                      onChange={handleSingleGroupChange}
                      icon={<Image src={uncheckedBox} alt="uncheckedBox" width={16} height={16} />}
                      checkedIcon={
                        <Image src={checkedBox} alt="uncheckedBox" width={16} height={16} />
                      }
                    />
                    <div
                      style={{
                        display: index < filterShowLimit ? 'block' : 'none',
                        marginTop: '-2px',
                      }}>
                      <Image
                        src={platformsMapper[option.provider]}
                        width={20}
                        height={20}
                        alt={option.provider}
                      />
                    </div>
                    <FilterOptionLabel
                      style={{
                        display: index < filterShowLimit ? 'block' : 'none',
                        marginTop: '-6px',
                        maxWidth: '105px',
                        overflowX: 'scroll',
                      }}
                      is_selected={channelFilter.includes(option.id).toString()}>
                      {option.name}
                    </FilterOptionLabel>
                  </FilterOption>
                ),
            )
        : clonedChannelOptions
            .sort((left, right) => left?.provider?.localeCompare(right?.provider))
            .map((option, index) => (
              <FilterOption key={option.id}>
                <Checkbox
                  style={{
                    paddingLeft: '0px',
                    paddingRight: '2px',
                    paddingTop: '0px',
                    paddingBottom: '2px',
                    display: index < filterShowLimit ? 'block' : 'none',
                  }}
                  name="segment"
                  checked={channelFilter.includes(option.id)}
                  value={option.id}
                  onChange={handleSingleGroupChange}
                  icon={<Image src={uncheckedBox} alt="uncheckedBox" width={16} height={16} />}
                  checkedIcon={<Image src={checkedBox} alt="uncheckedBox" width={16} height={16} />}
                />
                <div
                  style={{
                    display: index < filterShowLimit ? 'block' : 'none',
                    marginTop: '-2px',
                  }}>
                  <Image
                    src={platformsMapper[option.provider]}
                    width={20}
                    height={20}
                    alt={option.provider}
                  />
                </div>
                <FilterOptionLabel
                  style={{
                    display: index < filterShowLimit ? 'block' : 'none',
                    marginTop: '-6px',
                    maxWidth: '105px',
                    overflowX: 'scroll',
                  }}
                  is_selected={channelFilter.includes(option.id).toString()}>
                  {option.name}
                </FilterOptionLabel>
              </FilterOption>
            ))}

      {filterShowLimit === 3 ? (
        <span
          role="none"
          style={{
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'flex-end',
            textAlign: 'right',
            textTransform: 'uppercase',
            color: colorToken.colors.purpleText,
            cursor: 'pointer',
          }}
          onClick={() => setFilterLimit(channelOptions?.length)}>
          Show More
        </span>
      ) : (
        <span
          role="none"
          style={{
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'flex-end',
            textAlign: 'right',
            textTransform: 'uppercase',
            color: colorToken.colors.purpleText,
            cursor: 'pointer',
          }}
          onClick={() => setFilterLimit(3)}>
          Show Less
        </span>
      )}
    </>
  );
};

export default ProviderChannelOptions;
