import {
  GET_OUTREACH_LIST,
  GET_OUTREACH_TEMPLATES_CATEGORIES_LIST,
  GET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM,
  GET_OUTREACH_TEMPLATE_BY_ID,
  GET_RECENT_OUTREACH_LIST,
  HANDLE_OUTREACH_LIST_ERROR,
  HANDLE_OUTREACH_TEMPLATES_CATEGORIES_LIST_ERROR,
  HANDLE_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_ERROR,
  HANDLE_OUTREACH_TEMPLATE_BY_ID_ERROR,
  HANDLE_RECENT_OUTREACH_LIST_ERROR,
  SET_IS_OUTREACH_TEMPLATE_ACTIVE,
  SET_OUTREACH_LIST_LOADING,
  SET_OUTREACH_TEMPLATES_CATEGORIES_LIST_LOADING,
  SET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_LOADING,
  SET_OUTREACH_TEMPLATE_BY_ID_LOADING,
  SET_RECENT_OUTREACH_LIST_LOADING,
} from '../types';

export const initialState = {
  recentOutreachList: [],
  recentOutreachLoading: false,
  errorFetchingRecentOutreach: false,
  outreachList: null,
  outreachLoading: false,
  errorFetchingOutreach: false,
  outreachTemplatesCategoriesList: [],
  errorFetchingOutreachTemplatesCategoriesList: false,
  outreachTemplatesCategoriesListLoading: false,
  outreachTemplatesByCategoryPlatformList: null,
  errorFetchingOutreachTemplatesByCategoryPlatformList: false,
  outreachTemplatesByCategoryPlatformLoading: false,
  outreachTemplateById: null,
  outreachTemplateByIdLoading: false,
  errorFetchingOutreachTemplateById: false,
  templatesPlatformFilters: [''],
  isOutreachTemplateActive: false,
};

const outreachTemplatesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECENT_OUTREACH_LIST:
      return {
        ...state,
        recentOutreachList: payload,
        recentOutreachLoading: false,
        errorFetchingRecentOutreach: false,
      };
    case SET_RECENT_OUTREACH_LIST_LOADING:
      return {
        ...state,
        recentOutreachLoading: true,
        errorFetchingRecentOutreach: false,
      };
    case HANDLE_RECENT_OUTREACH_LIST_ERROR:
      return {
        ...state,
        recentOutreachLoading: false,
        errorFetchingRecentOutreach: true,
      };
    case GET_OUTREACH_LIST:
      return {
        ...state,
        outreachList: payload,
        outreachLoading: false,
        errorFetchingOutreach: false,
      };
    case SET_OUTREACH_LIST_LOADING:
      return {
        ...state,
        outreachLoading: true,
        errorFetchingOutreach: false,
      };
    case HANDLE_OUTREACH_LIST_ERROR:
      return {
        ...state,
        outreachLoading: false,
        errorFetchingOutreach: true,
      };
    case GET_OUTREACH_TEMPLATES_CATEGORIES_LIST:
      return {
        ...state,
        outreachTemplatesCategoriesList: payload,
        errorFetchingOutreachTemplatesCategoriesList: false,
        outreachTemplatesCategoriesListLoading: false,
      };
    case HANDLE_OUTREACH_TEMPLATES_CATEGORIES_LIST_ERROR:
      return {
        ...state,
        errorFetchingOutreachTemplatesCategoriesList: true,
        outreachTemplatesCategoriesListLoading: false,
      };
    case SET_OUTREACH_TEMPLATES_CATEGORIES_LIST_LOADING:
      return {
        ...state,
        outreachTemplatesCategoriesListLoading: true,
        errorFetchingOutreachTemplatesCategoriesList: false,
      };
    case GET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM:
      return {
        ...state,
        outreachTemplatesByCategoryPlatformList: payload,
        outreachTemplatesByCategoryPlatformLoading: false,
        errorFetchingOutreachTemplatesByCategoryPlatformList: false,
      };
    case HANDLE_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_ERROR:
      return {
        ...state,
        outreachTemplatesByCategoryPlatformLoading: false,
        errorFetchingOutreachTemplatesByCategoryPlatformList: true,
      };
    case SET_OUTREACH_TEMPLATES_LIST_BY_CATEGORY_PLATFORM_LOADING:
      return {
        ...state,
        outreachTemplatesByCategoryPlatformLoading: true,
        errorFetchingOutreachTemplatesByCategoryPlatformList: false,
      };
    case GET_OUTREACH_TEMPLATE_BY_ID:
      return {
        ...state,
        outreachTemplateById: payload,
        outreachTemplateByIdLoading: false,
        errorFetchingOutreachTemplateById: false,
      };
    case SET_OUTREACH_TEMPLATE_BY_ID_LOADING:
      return {
        ...state,
        outreachTemplateByIdLoading: true,
        errorFetchingOutreachTemplateById: false,
      };
    case HANDLE_OUTREACH_TEMPLATE_BY_ID_ERROR:
      return {
        ...state,
        outreachTemplateByIdLoading: false,
        errorFetchingOutreachTemplateById: true,
      };
    case SET_IS_OUTREACH_TEMPLATE_ACTIVE:
      return {
        ...state,
        isOutreachTemplateActive: payload,
      }
    default:
      return state;
  }
};

export default outreachTemplatesReducer;
