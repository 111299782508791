export const getMenuDataWithPermissions = (menuData, permissions) => {
  const hasReadPermission = (item) => {
    return (
      !permissions?.[item.permissionKey]?.READ ||
      permissions?.[item.permissionKey]?.READ === 'ALLOW'
    );
  };

  return menuData
    .filter((menu) => hasReadPermission(menu))
    .map((menu) => ({
      ...menu,
      subMenu: menu.subMenu.filter((subItem) => hasReadPermission(subItem)),
    }));
};
