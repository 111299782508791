import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';
import {
  tableCellBlackColor,
  tableHeaderBackgroundColor,
  borderGreyColor,
  tableCellGreyColor,
  blueColor,
  tableCellRedColor,
} from 'theme/colorConstants';
import { colorToken } from '../../theme/designToken';
import loginBackground from 'assets/images/media/loginBackground.svg';
import bgSignupLeft from 'assets/images/media/SignupLeft.png';
import bgLogin from 'assets/images/media/bg.svg';
import { PrimaryButton } from 'styledComponents/commonStyled';

export const ContinueText = styled(Typography)`
  color: ${tableCellGreyColor};
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  display: flex;
  line-height: 12px;
  width: 100%;
  &:before,
  &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${borderGreyColor};
    height: 5px;
    font-size: 0px;
    line-height: 0px;
  }
  &:before {
    margin-right: 15px;
  }
  &:after {
    margin-left: 15px;
  }
`;

export const ThreadoContainer = styled.div`
  position: sticky;
  top: 0;
  background-image: url(${loginBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 75px;
  align-items: center;
  justify-content: center;
  @media (max-height: 550px) {
    height: 100%;
  }
`;

export const MainText = styled(Typography)`
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: ${tableCellBlackColor};
`;

export const DescriptionText = styled(Typography)`
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: ${tableCellBlackColor};
  max-width: 335px;
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
`;

export const SocialButton = styled(Button)`
  background: ${tableHeaderBackgroundColor};
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none;
  padding: 10px 30px;
  text-transform: capitalize;
  gap: 3px;
  color: ${tableCellBlackColor};
  &:hover {
    background-color: ${tableHeaderBackgroundColor};
    color: ${tableCellBlackColor};
    box-shadow: none;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
`;

export const MainContainer = styled.div`
  width: 50%;
`;

export const SocialButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1350px) {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
`;

export const BlueText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: ${blueColor};
`;

export const AccountText = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: ${tableCellBlackColor};
`;

export const LoginErrorMsg = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: ${tableCellRedColor};
`;

export const PurpleText = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: ${colorToken.colors.purpleText};
`;

export const SubText = styled(Typography)`
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  font-weight: 600;
  color: ${colorToken.colors.greyText};
`;

export const GoogleSignInBox = styled.div`
  padding: 2px;
  border-radius: 4px;
  background-color: #346ef1;
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const GoogleSignInText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${colorToken.colors.white};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
`;

export const OptionDivider = styled(Typography)`
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
  width: 100%;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 9px 0;
  grid-gap: 7px;

  &:after,
  &:before {
    content: ' ';
    display: block;
    border-bottom: 1px solid ${colorToken.genericBorder.color};
  }
`;

export const BackButton = styled(Button)`
  background: ${colorToken.colors.white};
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${colorToken.colors.greyText};
  border-radius: 4px;
  text-transform: unset;
  padding: 10px 15px;
  gap: 10px;
  &:hover {
    background: ${colorToken.colors.white};
    color: ${colorToken.colors.greyText};
    box-shadow: none;
  }
`;

export const EmailText = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${colorToken.colors.black};
`;

export const EmailTextContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: ${colorToken.login.email.backgroundColor};
  border-radius: 4px;
  align-items: center;
`;

export const MagicLinkText = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${colorToken.colors.black};
`;

export const SignupPageContainer = styled.div`
  background-size: cover;
  background-color: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  display: flex;
  display: inline-flex;
  padding: 15px 50px 13px 16px;
  align-items: center;
  gap: 45px;
  background: #fff;
  @media (max-width: 1024px) {
    padding: 10px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const SignupPageTitle = styled.div`
  color: #383838;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 61.143px; /* 134.002% */
  letter-spacing: -1.825px;

  @media (max-width: 768px) {
    font-size: 35px;
    line-height: 48px;
    letter-spacing: -1.825px;
  }
  @media (max-width: 480px) {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -1.825px;
  }
`;

export const SignupPageSubtitle = styled.div`
  margin: 20px 0 40px 0;
  color: #383838;
  font-size: 23.986px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.464px; /* 139.512% */
  letter-spacing: -0.959px;

  @media (max-width: 768px) {
    margin: 20px 0 20px 0;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.8px;
  }

  @media (max-width: 480px) {
    margin: 20px 0 20px 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
`;

export const SignupPagePointersText = styled.div`
  margin: 10px 0;
  color: #252525;
  font-size: 20.636px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.474px; /* 200.98% */
  letter-spacing: -0.929px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 36px;
  }

  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 30px;
  }
`;

export const SignupPageBrandedText = styled.div`
  margin: 20px 0;
  color: #212121;
  font-size: 20.881px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.484px; /* 155.569% */
  letter-spacing: -0.626px;
  @media (max-width: 768px) {
    margin: 10px 0;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.5px;
  }
  @media (max-width: 480px) {
    margin: 10px 0;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
`;

export const SignupFormContainer = styled.div`
  display: inline-flex;
  height: 490px;
  padding: 101px 51px;
  flex-direction: column;
  justify-content: center;
  gap: 9.824px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 57.65px 0px rgba(0, 0, 0, 0.06);
  margin: auto 0;
  @media (max-width: 1024px) {
    padding: 101px 20px;
  }
  @media (max-width: 768px) {
    align-items: center;
    padding: 11px 41px;
    height: 430px;
  }
`;

export const SignupLeftContainer = styled.div`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0.29) 100%),
    url(${bgSignupLeft}) lightgray 50% / cover no-repeat;
  background-size: cover;
`;

export const SignupFormTitle = styled.div`
  color: #545454;
  font-size: 31.232px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.249px;
`;

export const LoginPageContainer = styled.div`
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(0px);
  background-image: url(${bgLogin});
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const LoginPageContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 90%;
`;

export const LoginPageContentInternal = styled.div`
  align-self: center;
`;

export const EmailSigninBox = styled.div`
  align-self: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const LoginPageContentHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`;

export const LoginPageContentHeaderText = styled.div`
  color: #212121;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const LoginPageContentBox = styled.div`
  padding: 60px 75px;
  border-radius: 12px;
  border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 35px 0px;
  gap: 40px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(255, 255, 255, 0.056) 100%
  );
  backdrop-filter: blur(17px);
`;

export const EmailSigninContentBox = styled.div`
  border-radius: 12px;
  border: 1px solid #e2e2e2;
  background: linear-gradient(145deg, #fff -1.87%, rgba(255, 255, 255, 0.71) 79.35%);
  backdrop-filter: blur(17px);
  padding: 30px 75px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0px;
  gap: 30px;
  position: relative;
`;
export const TitleInfoText = styled.div`
  font-weight: 700;
  color: #000;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  letter-spacing: -0.18px;
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const SignupErrorText = styled.div`
  background: none;
  border: 1px solid #6f6f6f;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 240px;
  font-weight: 400;
  font-size: 12px;
  line-height: 171.52%;
  letter-spacing: -0.01em;
  color: #6f6f6f;
`;

export const OrText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const ContinueEmailButton = styled(PrimaryButton)`
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  width: 240px;
  color: #ffffff;
  gap: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.07);
  border: none;
  text-transform: unset;
  &: hover {
    background: rgba(217, 210, 210, 0.07);
  }
`;

export const ContinueEmailButtonChange = styled(PrimaryButton)`
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  width: 240px;
  color: #fffff;
  gap: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 4px;
  background: ${(props) => (props.disabledemail ? 'rgba(105, 109, 236, 0.7)' : '#696dec')};
  pointer-events: ${(props) => (props.disabledemail ? 'none' : 'all')};
  border: none;
  text-transform: unset;
  &: hover {
    background: ${(props) => (props.disabledemail ? 'rgba(105, 109, 236, 0.7)' : '#696dec')};
  }
`;

export const ContinueWithGoogleButton = styled(PrimaryButton)`
  background: #696dec;
  border-radius: 4px;
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.01em;
  width: 240px;
  color: #ffffff;
  gap: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border: ${(props) => (props.nonOfficialEmailErrorWithGoogle ? '1px solid #f04646' : 'none')};
`;

export const SubTextContinue = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  color: #939393;
  letter-spacing: -0.12px;
  margin-bottom: 10px;
  text-align: center;
`;

export const LowerContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #7e7e7f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const CenteredColumnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 10%;
`;

export const SmallText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #8c8e9e;
`;

export const VerificationEmailSent = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #545454;
`;

export const CheckEmailText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 198.52%;
  letter-spacing: -0.01em;
  color: #545454;
  max-width: 480px;
  text-align: center;
  width: 350px;
`;

export const BackToLoginButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 171.52%;
  letter-spacing: -0.01em;
  color: #696dec;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  background: none;
`;

export const LinkExpireText = styled.div`
  // background: #343434;
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 11px;
  font-weight: 400;
  font-size: 12px;
  line-height: 171.52%;
  letter-spacing: -0.01em;
  color: #545454;
`;

export const BackStyledAbsoluteButton = styled.div`
  top: 17px;
  left: 17px;
  position: absolute;
  cursor: pointer;
`;

export const SendMagicLinkTextInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 171.52%;
  letter-spacing: -0.01em;
  color: #b3b3b3;
`;

export const TrustedByOrgText = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: #353535;
  font-size: 17.861px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.861px;
  letter-spacing: -0.179px;
`;
