export const getSegmentTitle = (pathname) => {
  const title = (pathname || '').split('/').at(1) || 'root';
  return title.trim();
};

export const getPageTitle = (pathname) => {
  const title = pathname
    .split('/')
    .join(' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  return title.trim();
};

export const loadCommunityIdFromLocalStorage = () => {
  const selectedCommunityWithUserIdString = localStorage.getItem('selectedCommunity');
  try {
    return selectedCommunityWithUserIdString !== null
      ? JSON.parse(selectedCommunityWithUserIdString) || {}
      : null;
  } catch (err) {
    return null;
  }
};

export const identifyUser = (userInfo) => {
  const communityConfig = loadCommunityIdFromLocalStorage();
  const communityId = communityConfig?.communityId;
  const communityName = communityConfig?.communityName;
  const accountName = communityConfig?.orgName;
  const accountId = communityConfig?.orgId;
  const userId = userInfo?.id;
  if (!communityId || !userId) return;
  const segmentUserId = generateSegmentUserId(communityId, userId);
  const userParams = {
    email: userInfo?.email,
    username: userInfo?.name || userInfo?.username,
    name: userInfo?.name,
    globalUserId: userInfo?.id,
    communityId: communityId,
    communityName: communityName,
    accountId: accountId,
    accountName: accountName,
    segmentUserId: segmentUserId,
  };

  const communityDetails = userInfo.communities.find((inst) => inst.id === communityId);

  window.analytics?.identify(segmentUserId, {
    ...userParams,
  });

  window.analytics?.group(segmentUserId, {
    ...userParams,
    groupId: communityConfig?.orgId,
  });
};

export const trackUserEventWithoutCommunity = (eventName, params) => {
  window.analytics?.track(eventName, {
    ...params,
  });
};

export const trackUserEvent = (eventName, params) => {
  const communityConfig = loadCommunityIdFromLocalStorage();
  const communityId = communityConfig?.communityId;
  const userId = communityConfig?.userId;

  if (!communityId || !userId) return;
  const segmentUserId = generateSegmentUserId(
    communityConfig?.communityId,
    communityConfig?.userId,
  );
  window.analytics?.track(
    eventName,
    {
      ...{
        ...communityConfig,
        userId: segmentUserId,
        email: communityConfig?.email,
        globalUserId: communityConfig?.userId,
      },
      ...params,
    },
    {
      context: {
        groupId: communityConfig?.orgId,
        email: communityConfig?.email,
        globalUserId: communityConfig?.userId,
      },
    },
  );
};

export const removeAnalytics = () => {
  delete window.analytics;
};

export const generateSegmentUserId = (communityId, userId) => {
  if (!communityId) return userId;
  return `C${communityId}U${userId}`;
};

export const getCurrentCommunityUser = (userInfo) => {
  const communityConfig = loadCommunityIdFromLocalStorage();
  const communityId = communityConfig?.communityId;
  const userId = userInfo?.id;
  if (!communityId || !userId) return {};
  return {
    userId,
    communityId,
  };
};
