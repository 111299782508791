import addIcon from 'assets/images/icons/addIcon.svg';
import addIconWhite from 'assets/images/icons/addIconWhite.svg';

//All Colors should be coming from Color Palette
const colorPalette = {
  color0: {
    value: '#fed87bff',
    name: 'yellow',
  },
  color1: {
    value: '#6868ef',
    name: 'new purple',
  },
  color2: {
    value: '#5353bf',
    name: 'dark purple',
  },
  color3: {
    value: '#2bc596ff',
    name: 'green',
  },
  color4: {
    value: '#f04646ff',
    name: 'red',
  },
  color5: {
    value: '#191919ff',
    name: 'black',
  },
  color6: {
    value: '#e8e8e8',
    name: 'grey',
  },
  color7: {
    value: '#f1f1f1',
    name: 'light grey 2',
  },
  color8: {
    value: '#f8f8f8ff',
    name: 'light grey',
  },
  color9: {
    value: '#FFFFFF',
    name: 'white',
  },
  color10: {
    value: '#7a78aa',
    name: 'dark purple 2',
  },
  color11: {
    value: '#808080',
    name: 'dark grey',
  },
  color12: {
    value: '#FEEDED',
    name: 'light red',
  },
  color13: {
    value: '#B3B3B3',
    name: 'grey 2',
  },
  color14: {
    value: '#696DEC',
    name: 'purple',
  },
  color15: {
    value: '#D1D1D1',
    name: 'darkestGrey',
  },
  color16: {
    value: '#000000',
    name: 'black',
  },
  color17: {
    value: '#575757',
    name: 'black 2',
  },
  color18: {
    value: '#FFEFBE',
    name: 'light yellow',
  },
  color19: {
    value: '#FFF5DC',
    name: 'lighter yellow',
  },
  color20: {
    value: '#E9D6AD',
    name: 'yellow border',
  },
  color21: {
    value: '#50517B',
    name: 'black 3',
  },
  color22: {
    value: '#1E293B',
    name: 'grey 3',
  },
  color23: {
    value: '#E2E8F0',
    name: 'grey 4',
  },
  color24: {
    value: '#222222',
    name: 'black 4',
  },
  color25: {
    value: '#383838',
    name: 'black 4',
  },
  color26: {
    value: '#3E485A',
    name: 'black 5',
  },
  color27: {
    value: '#28203E',
    name: 'blue-black',
  },
  color28: {
    value: '#2E9A6A',
    name: 'success-green',
  },
};

const fontPalete = {};

const COLORS = {};

const FONT_SIZE = {
  _10: '10px',
  _12: '12px',
  _14: '14px',
  _16: '16px',
  _18: '18px',
  _24: '24px',
  _28: '28px',
  _32: '32px',
  _36: '36px',
};

const FONT_WEIGHT = {
  bold: 700,
  semibold: 600,
  normal: 400,
  normalBold: 500,
};

const LETTER_SPACING = {
  minusPointThreeSix: '-0.36px',
};

const OPACITY = {
  _05: '0.5',
  _08: '0.8',
};

const LINE_HEIGHT = {
  default: 1,
  text: 1.5,
  header: 1.25,
};

const SPACING = {
  _0: '0px',
  _2: '2px',
  _4: '4px',
  _6: '6px',
  _8: '8px',
  _10: '10px',
  _12: '12px',
  _16: '16px',
  _24: '24px',
  _32: '32px',
  _48: '48px',
};

const RADIUS = {
  cicle: '50%',
  small: '2px',
  medium: '4px',
  large: '8px',
};

export const colorToken = {
  background: {
    primary: {
      color: colorPalette.color9.value,
    },
    primaryAutomations: {
      color: colorPalette.color8.value,
    },
  },
  banner: {
    color: colorPalette.color0.value,
    colorLight: colorPalette.color18.value,
    colorFreePlanBanner: colorPalette.color19.value,
    borderFreePlanBanner: colorPalette.color20.value,
  },
  tableHeader: {
    primary: {
      color: colorPalette.color8.value,
    },
    text: {
      primary: {
        color: `#B3B3B3 !important`,
      },
    },
    border: {
      color: null,
      radius: '4px !important',
    },
  },
  button: {
    primary: {
      color: colorPalette.color1.value,
      width: '168px',
      text: {
        color: colorPalette.color9.value,
      },
      action: {
        active: {
          color: colorPalette.color9.value,
        },
        hover: {
          color: colorPalette.color2.value,
          text: {
            color: colorPalette.color16.value,
          },
        },
        disable: {
          color: colorPalette.color8.value,
          text: {
            color: colorPalette.color5.value,
          },
        },
      },
    },
    secondary: {
      color: colorPalette.color6.value,
    },
    inverse: {
      color: colorPalette.color6.value,
      text: {
        color: colorPalette.color5.value,
      },
    },
    text: {
      color: colorPalette.color1.value,
    },
    icons: {
      add: addIcon,
      addWhite: addIconWhite,
    },
  },
  menuSidebar: {
    primarySidebar: {
      backgroundColor: colorPalette.color27.value,
      border: {
        right: {
          width: '1px',
          type: 'solid',
          color: colorPalette.color6.value,
        },
      },
      action: {
        hover: {
          color: colorPalette.color0.value,
        },
        active: {
          color: colorPalette.color25.value,
          border: {
            radius: '5px',
          },
          shadow: {
            shadowDimension: '0px 4px 18px',
            shadowColor: colorPalette.color0.value,
          },
        },
        alwaysActive: {
          backgroundColor: colorPalette.color9.value,
          color: colorPalette.color5.value,
          border: {
            radius: '4px',
            width: '1px',
            type: 'solid',
            color: colorPalette.color9.value,
          },
        },
      },
    },
    secondarySidebar: {
      backgroundColor: colorPalette.color9.value,
      border: {
        color: colorPalette.color6.value,
        radius: '0px',
        right: {
          width: '1px',
          type: 'solid',
          color: colorPalette.color7.value,
        },
      },
      text: {
        active: {
          color: colorPalette.color5.value,
        },
        inactive: {
          color: colorPalette.color13.value,
        },
      },
    },
  },
  customDateRangeStyled: {
    color: colorPalette.color0.value,
    action: {
      hover: {
        color: colorPalette.color0.value,
      },
      focus: {
        color: colorPalette.color0.value,
      },
    },
    border: {
      color: colorPalette.color6.value,
    },
  },
  tab: {
    selected: {
      color: `#696DEC`,
      textColor: `#696DEC`,
    },
    notSelected: {
      color: colorPalette.color0.value,
      textColor: colorPalette.color5.value,
    },
    border: {
      color: colorPalette.color6.value,
    },
  },
  intervalButton: {
    backgroundColor: colorPalette.color7.value,
    action: {
      hover: {
        backgroundColor: colorPalette.color6.value,
      },
    },
    text: {
      color: {
        selected: colorPalette.color5.value,
        normal: colorPalette.color13.value,
      },
    },
  },
  linearProgress: {
    color: colorPalette.color0.value,
  },
  integrationButton: {
    backgroundColor: colorPalette.color0.value,
  },
  automation: {
    nodes: {
      backgroundColor: colorPalette.color9.value,
      action: {
        active: {
          backgroundColor: colorPalette.color9.value,
          border: {
            width: '1px',
            type: 'solid',
            color: '#696DEC',
            radius: '4px',
          },
          text: {
            color: colorPalette.color2.value,
            fontSize: '16px',
          },
        },
        hover: {},
        inactive: {
          backgroundColor: colorPalette.color9.value,
          border: {
            width: '1px',
            type: 'solid',
            color: colorPalette.color15.value,
            radius: '4px',
          },
          text: {
            color: colorPalette.color5.value,
            fontSize: '14px',
          },
        },
      },
    },
    connector: {
      backgroundColor: colorPalette.color15.value,
    },
    connectorButton: {
      backgroundColor: colorPalette.color7.value,
    },
    createWorkflow: {
      integrations: {
        color: colorPalette.color6.value,
        inactiveColor: colorPalette.color8.value,
        activeColor: colorPalette.color9.value,
        paddingBottom: '10px',
        border: {
          radius: '4px',
          width: '1px',
          color: colorPalette.color1.value,
          colorInactive: colorPalette.color9.value,
        },
      },
    },
    templates: {
      box: {
        title: {
          border: {
            color: colorPalette.color23.value,
          },
        },
      },
    },
  },
  badges: {
    border: {
      radius: '4px',
    },
    team: {
      owner: {
        background: {
          color: 'rgba(255, 217, 84, 0.3)',
        },
        border: {
          radius: '4px',
        },
        text: {
          color: colorPalette.color5.value,
        },
      },
      manager: {
        background: {
          color: 'rgba(140, 138, 196, 0.2)',
        },
        border: {
          radius: '4px',
        },
        text: {
          color: colorPalette.color2.value,
        },
      },
    },
  },
  dataGridPro: {
    row: {
      hover: {
        background: colorPalette.color8.value,
      },
    },
  },
  modal: {
    integrations: {
      color: colorPalette.color6.value,
      inactiveColor: colorPalette.color8.value,
      activeColor: colorPalette.color9.value,
      border: {
        radius: '4px',
        width: '1px',
        color: colorPalette.color1.value,
        colorInactive: colorPalette.color9.value,
      },
    },
    background: {
      secondary: colorPalette.color8.value,
      primary: colorPalette.color9.value,
    },
    errorText: {
      color: colorPalette.color4.value,
    },
  },
  notifications: {
    item: {
      border: {
        color: colorPalette.color7.value,
      },
      text: {
        date: {
          color: colorPalette.color11.value,
        },
        normal: {
          color: colorPalette.color5.value,
        },
      },
    },
    modal: {
      greyColor: colorPalette.color6.value,
      channelLoad: {
        text: {
          color: colorPalette.color5.value,
        },
        border: {
          width: '1px',
          color: colorPalette.color7.value,
        },
      },
    },
  },
  engagementAnalytics: {
    chart: {
      messages: {
        graph: {
          lineColor: colorPalette.color0.value,
        },
      },
      reactions: {
        graph: {
          lineColor: colorPalette.color0.value,
        },
      },
      border: {
        color: colorPalette.color6.value,
      },
      text: {
        inner: {
          color: colorPalette.color13.value,
        },
        title: {
          color: colorPalette.color5.value,
        },
      },
    },
  },
  retentionAnalytics: {
    dataGrid: {
      groupHeader: {
        color: colorPalette.color11.value,
        backgroundColor: colorPalette.color9.value,
      },
    },
    chart: {
      border: {
        color: colorPalette.color6.value,
      },
      inner: {
        color: colorPalette.color13.value,
      },
    },
    box: {
      border: {
        color: colorPalette.color6.value,
      },
    },
    field: {
      heading: {
        color: colorPalette.color13.value,
      },
      border: {
        color: colorPalette.color6.value,
      },
    },
  },
  auths: {
    error: {
      background: {
        color: colorPalette.color12.value,
      },
      text: {
        color: colorPalette.color5.value,
      },
    },
  },
  messages: {
    notes: {
      text: {
        color: colorPalette.color11.value,
        colorHighlight: colorPalette.color5.value,
      },
    },
    bot: {
      successText: {
        color: colorPalette.color28.value,
        fontSize: FONT_SIZE._12,
        fontWeight: FONT_WEIGHT.normalBold,
        letterSpacing: LETTER_SPACING.minusPointThreeSix,
      },
    },
  },
  tooltip: {
    arrow: {
      color: colorPalette.color9.value,
    },
    background: colorPalette.color9.value,
    color: colorPalette.color26.value,
  },
  activitySummary: {
    insights: {
      border: {
        color: colorPalette.color6.value,
      },
    },
    members: {
      border: {
        color: colorPalette.color6.value,
      },
    },
  },
  support: {
    search: {
      border: {
        color: colorPalette.color6.value,
      },
      icon: {
        color: colorPalette.color13.value,
      },
    },
    thread: {
      border: {
        color: colorPalette.color6.value,
      },
      timestamp: {
        color: colorPalette.color13.value,
      },
    },
  },
  inputFields: {
    select: {
      border: {
        color: colorPalette.color6.value,
      },
    },
    text: {
      border: {
        color: colorPalette.color6.value,
      },
    },
  },
  genericBorder: {
    color: colorPalette.color6.value,
  },
  settings: {
    community: {
      avatarButton: {
        color: colorPalette.color5.value,
      },
      integration: {
        box: {
          title: {
            color: colorPalette.color13.value,
          },
        },
      },
    },
  },
  colors: {
    black: colorPalette.color5.value,
    white: colorPalette.color9.value,
    greyText: colorPalette.color13.value,
    greyTextThree: colorPalette.color22.value,
    darkPurple: colorPalette.color2.value,
    purpleText: colorPalette.color14.value,
    blackTwo: colorPalette.color17.value,
    blackThree: colorPalette.color21.value,
  },
  avatar: {
    name: {
      color: colorPalette.color5.value,
    },
    email: {
      color: colorPalette.color13.value,
    },
  },
  login: {
    email: {
      backgroundColor: colorPalette.color8.value,
    },
  },
};

export const fontToken = {
  header: [
    {
      fontSize: FONT_SIZE._36,
      fontWeight: FONT_WEIGHT.bold,
    },
    {
      fontSize: FONT_SIZE._32,
      fontWeight: FONT_WEIGHT.bold,
    },
    {
      fontSize: FONT_SIZE._28,
      fontWeight: FONT_WEIGHT.bold,
    },
    {
      fontSize: FONT_SIZE._24,
      fontWeight: FONT_WEIGHT.bold,
    },
    {
      fontSize: FONT_SIZE._18,
      fontWeight: FONT_WEIGHT.bold,
    },
  ],
  subTitle: [
    {
      fontSize: FONT_SIZE._16,
      fontWeight: FONT_WEIGHT.semibold,
    },
    {
      fontSize: FONT_SIZE._14,
      fontWeight: FONT_WEIGHT.semibold,
    },
  ],
  topic: [
    {
      fontSize: FONT_SIZE._16,
      fontWeight: FONT_WEIGHT.normal,
    },
  ],
  button: [
    {
      fontSize: FONT_SIZE._14,
      fontWeight: FONT_WEIGHT.normal,
    },
  ],
  paragraph: [
    {
      fontSize: FONT_SIZE._16,
      fontWeight: FONT_WEIGHT.semibold,
    },
    {
      fontSize: FONT_SIZE._14,
      fontWeight: FONT_WEIGHT.semibold,
    },
  ],
  label: [
    {
      fontSize: FONT_SIZE._12,
      fontWeight: FONT_WEIGHT.normal,
    },
    {
      fontSize: FONT_SIZE._10,
      fontWeight: FONT_WEIGHT.normal,
    },
  ],
};
