import { useSelector } from 'react-redux';

//TODO remove this
import { useGetCommunities, useGetCurrentCommunity } from './hooks';

export const CheckIfIntegrationActive = (integrationName, communityId) => {
  const communities = useSelector(useGetCommunities);
  const activeIntegrations = useSelector((state) => {
    return communities || [];
  });
  const allIntegrations = Object.keys(
    (activeIntegrations || []).filter((iter) => {
      if (iter.id === communityId) {
        return iter;
      }
    })[0]?.integrations,
  );
  return allIntegrations && allIntegrations.includes(integrationName);
};

export const CheckIfIntegrationActiveFromCommunities = (
  integrationName,
  communityId,
  communities,
) => {
  const allIntegrations = Object.keys(
    (communities || []).filter((iter) => {
      if (iter.id === communityId) {
        return iter;
      }
    })[0]?.integrations,
  );
  return allIntegrations && allIntegrations.includes(integrationName);
};

export const IntegrationLists = () => {
  const currentCommunity = useSelector(useGetCurrentCommunity);
  return currentCommunity?.integrations;
};
