import { Button } from '@mui/material';
import ButtonLoader from 'atoms/ButtonLoaders/ButtonLoader';
import React from 'react';
import './ButtonWithLoader.css';

const PrimaryButtonWithLoader = (props) => {
  return (
    <span
      className={props.is_loading && 'Button_wrapper_with_cursor_not_allowed'}
      style={{ display: 'flex' }}>
      <Button {...props}>
        {props.is_loading ? (
          <>
            {props.children}
            <ButtonLoader
              height={props.loaderHeight ? props.loaderHeight : 14}
              width={props.loaderWidth ? props.loaderWidth : 14}
              isLoading={true}
              color={props.color ? props.color : '#ffffff'}
            />
          </>
        ) : (
          props.children
        )}
      </Button>
    </span>
  );
};

export default PrimaryButtonWithLoader;
