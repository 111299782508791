import { Box } from '@material-ui/core';
import styled from '@emotion/styled';
import { Accordion, AccordionDetails } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const MainContent = styled(Box)`
  flex-grow: 1;
  padding: 40px;
  ${(props) => props.custompadding && `padding: ${props.custompadding}`};
`;
