// Automation Templates
export const GET_RECENT_AUTOMATIONS_LIST_DATA = 'GET_RECENT_AUTOMATIONS_LIST_DATA';
export const SET_RECENT_AUTOMATIONS_LOADING = 'SET_RECENT_AUTOMATIONS_LOADING';
export const HANDLE_RECENT_AUTOMATIONS_ERROR = 'HANDLE_RECENT_AUTOMATIONS_ERROR';

export const GET_AUTOMATION_TEMPLATES_CATEGORIES_LIST = 'GET_AUTOMATION_TEMPLATES_CATEGORIES_LIST';
export const SET_AUTOMATION_TEMPLATES_CATEGORIES_LIST_LOADING =
  'SET_AUTOMATION_TEMPLATES_CATEGORIES_LIST_LOADING';
export const HANDLE_AUTOMATION_TEMPLATES_CATGEORIES_LIST_ERROR =
  'HANDLE_AUTOMATION_TEMPLATES_CATGEORIES_LIST_ERROR';

export const GET_AUTOMATION_TEMPLATES_BY_CATEGORY = 'GET_AUTOMATION_TEMPLATES_BY_CATEGORY';
export const SET_AUTOMATION_TEMPLATES_BY_CATEGORY_LOADING =
  'SET_AUTOMATION_TEMPLATES_BY_CATEGORY_LOADING';
export const HANDLE_AUTOMATION_TEMPLATES_BY_CATEGORY_ERROR =
  'HANDLE_AUTOMATION_TEMPLATES_BY_CATEGORY_ERROR';

export const GET_AUTOMATION_TEMPLATE_BY_ID = 'GET_AUTOMATION_TEMPLATE_BY_ID';
export const SET_AUTOMATION_TEMPLATE_BY_ID_LOADING = 'SET_AUTOMATION_TEMPLATE_BY_ID_LOADING';
export const HANDLE_AUTOMATION_TEMPLATE_BY_ID_ERROR = 'HANDLE_AUTOMATION_TEMPLATE_BY_ID_ERROR';

export const SET_USE_TEMPLATE = 'SET_USE_TEMPLATE';

export const SET_IS_RENDER_FLOWCHART_FROM_AUTOMATION_TEMPLATES =
  'SET__IS_RENDER_FLOWCHART_FROM_AUTOMATION_TEMPLATES';

export const SET_TEMPLATES_PLATFORM_FILTER = 'SET_TEMPLATES_PLATFORM_FILTER';
