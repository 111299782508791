import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';

//TODO: Change Protected Route to be handled here
const AuthRoute = ({ component: Component, user_id, ...rest }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3,
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        user_id === 'user_authenticated' ? (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}>
            <Component {...props} />
          </motion.div>
        ) : (
          // <Redirect to="/" />
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}>
            <Component {...props} />
          </motion.div>
        )
      }
    />
  );
};

const mapStateToProps = () => ({});

AuthRoute.propTypes = {};

export default connect(mapStateToProps)(AuthRoute);
