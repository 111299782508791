import {
  GET_ENGAGEMENT_MEMBERS_DATA_MESSAGES,
  GET_ENGAGEMENT_MEMBERS_DATA_REACTIONS,
  GET_ENGAGEMENT_ACTIONS_DATA_MESSAGES,
  GET_ENGAGEMENT_ACTIONS_DATA_REACTIONS,
  HANDLE_ENGAGEMENT_ERROR_MEMBERS_DATA,
  HANDLE_ENGAGEMENT_ERROR_ACTIONS_DATA,
  LOADING_ENGAGEMENT,
  UPDATE_INTEGRATION,
  UPDATE_ENGAGEMENT_METRICS_MEMBERS,
  UPDATE_ENGAGEMENT_METRICS_ACTIONS_TAKEN,
  GET_PROVIDER_CHANNELS_DATA,
  PROVIDER_CHANNELS_ERROR_HANDLE,
  SET_ENGAGEMENT_PROVIDER_CHANNELS_FILTER,
  LOADING_ENGAGEMENT_PROVIDER_CHANNELS,
} from '../types';

export const initialState = {
  engagementMembersData: [],
  engagementActionsData: [],
  engagementMembersDataMessages: [],
  engagementMembersDataReactions: [],
  engagementActionsDataMessages: [],
  engagementActionsDataReactions: [],
  toastMessage: '',
  engagementLoading: false,
  engagementProviderChannelsLoading: false,
  currentProvider: '',
  currentInterval: '7D',
  channelsFilter: [-1],
  engagementProviderChannels: [],
  membersAction: 'MEMBERS_WHO_TOOK_ACTION',
  actionsTaken: 'ACTIONS_TAKEN',
  membersActionMetrics: ['MESSAGES', 'REACTIONS'],
  actionsTakenMetrics: ['MESSAGES', 'REACTIONS'],
  engagementMembersDataFetchFail: false,
  engagementActionsDataFetchFail: false,
};

export const engagementReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ENGAGEMENT_MEMBERS_DATA_MESSAGES: {
      return {
        ...state,
        engagementMembersDataMessages: payload,
        toastMessage: 'Engagement Analytics Members data fetched success',
        engagementLoading: false,
        engagementMembersDataFetchFail: false,
      };
    }
    case GET_ENGAGEMENT_MEMBERS_DATA_REACTIONS: {
      return {
        ...state,
        engagementMembersDataReactions: payload,
        toastMessage: 'Engagement Analytics Members data fetched success',
        engagementLoading: false,
        engagementMembersDataFetchFail: false,
      };
    }
    case GET_ENGAGEMENT_ACTIONS_DATA_MESSAGES: {
      return {
        ...state,
        engagementActionsDataMessages: payload,
        toastMessage: 'Engagement Analytics Members Action data fetched success',
        engagementLoading: false,
        engagementActionsDataFetchFail: false,
      };
    }
    case GET_ENGAGEMENT_ACTIONS_DATA_REACTIONS: {
      return {
        ...state,
        engagementActionsDataReactions: payload,
        toastMessage: 'Engagement Analytics Members Action data fetched success',
        engagementLoading: false,
        engagementActionsDataFetchFail: false,
      };
    }
    case LOADING_ENGAGEMENT: {
      return {
        ...state,
        toastMessage: 'Engagement Analytics fetching data',
        engagementLoading: true,
        engagementMembersDataFetchFail: false,
        engagementActionsDataFetchFail: false,
      };
    }
    case LOADING_ENGAGEMENT_PROVIDER_CHANNELS: {
      return {
        ...state,
        toastMessage: 'Engagement Analytics provider channels fetching data',
        engagementProviderChannelsLoading: true,
      };
    }
    case HANDLE_ENGAGEMENT_ERROR_MEMBERS_DATA: {
      return {
        ...state,
        toastMessage: payload,
        engagementLoading: false,
        engagementMembersDataFetchFail: true,
      };
    }
    case HANDLE_ENGAGEMENT_ERROR_ACTIONS_DATA: {
      return {
        ...state,
        toastMessage: payload,
        engagementLoading: false,
        engagementActionsDataFetchFail: true,
      };
    }
    case UPDATE_INTEGRATION: {
      return {
        ...state,
        currentProvider: payload,
      };
    }
    case UPDATE_ENGAGEMENT_METRICS_MEMBERS: {
      return {
        ...state,
        membersActionMetrics: payload,
      };
    }
    case UPDATE_ENGAGEMENT_METRICS_ACTIONS_TAKEN: {
      return {
        ...state,
        actionsTakenMetrics: payload,
      };
    }
    case GET_PROVIDER_CHANNELS_DATA: {
      return {
        ...state,
        engagementProviderChannels: payload,
        engagementProviderChannelsLoading: false,
      };
    }
    case PROVIDER_CHANNELS_ERROR_HANDLE: {
      return {
        ...state,
        toastMessage: payload,
        engagementProviderChannelsLoading: false,
      };
    }
    case SET_ENGAGEMENT_PROVIDER_CHANNELS_FILTER:
      return {
        ...state,
        channelsFilter: payload,
      };
    default:
      return state;
  }
};
