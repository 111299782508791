import { tableCellLightPurpleColor, tableCellPurpleColor } from 'theme/colorConstants';
import { colorToken } from 'theme/designToken';

export const staffRoleDetail = {
  admin: {
    color: colorToken.badges.team.manager.text.color,
    background: colorToken.badges.team.manager.background.color,
  },
  owner: {
    color: colorToken.badges.team.owner.text.color,
    background: colorToken.badges.team.owner.background.color,
  },
  invited: {
    color: tableCellPurpleColor,
    background: tableCellLightPurpleColor,
  },
  support_agent: {
    color: tableCellPurpleColor,
    background: tableCellLightPurpleColor,
  }
};

export const staffRoleOptions = [
  { 
    value: 'ORGANIZATION_OWNER',
    label: 'Organization Owner'
  },
  {
    value: 'WORKSPACE_ADMIN',
    label: 'Workspace Admin',
  },
  {
    value: 'WORKSPACE_SUPPORT_AGENT',
    label: 'Support Agent'
  }
];

export const staffRoleMapper = {
  organization_owner: 'Organization Owner',
  workspace_admin: 'Workspace Admin',
  workspace_support_agent: 'Support Agent'
}

export const workspaceValueRoleMapper = {
  ORGANIZATION_OWNER: 'ORGANIZATION_OWNER',
  WORKSPACE_ADMIN: 'WORKSPACE_ADMIN',
  WORKSPACE_SUPPORT_AGENT: 'WORKSPACE_SUPPORT_AGENT',
};

export const workspaceValueRolePriorityMapper = {
  ORGANIZATION_OWNER: 1,
  WORKSPACE_ADMIN: 2,
  WORKSPACE_SUPPORT_AGENT: 3,
};