import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataState } from 'utils/dataStateHook';
import { FilterMenuContainer, FilterMenuItem } from 'styledComponents/memberStyled';
import IntegrationOptions from './IntegrationOptions';
import {
  useGetActivityProviderChannelsData,
  useGetMessagesChannelFilter,
  useGetMessagesProviderChannelFilter,
  useGetMessagesStatusFilter,
  useGetCurrentCommunity,
} from 'context/hooks';
import {
  setMessagesChannelsFilterAction,
  setMessagesProviderChannelsFilterAction,
  setMessagesStatusFilterAction,
} from '../../../redux/messagesProvider/actions/messagesAction';
import StatusOptions from './StatusOptions';
import useGetMessagesFilterBubbleNumber from './GetMessagesFilterBubbleNumber';
import ProviderChannelOptions from './ProviderChannelOptions';
import {
  AccordionMenu,
  AccordionMenuContainer,
  AccordionSubMenuContainer,
  FilterArrowDropDownIconImage,
  MenuContainer,
} from 'styledComponents/sidebarStyled';

import { AccordionSummary } from '@material-ui/core';

import { trackUserEvent } from 'utils/segment';
import { colorToken } from '../../../theme/designToken';
import { CheckIfFeatureActive } from 'context/useFeature';
import { CheckIfIntegrationActive } from 'context/useIntegration';
import { channelOptions } from '../../../constant/filterMenuData';
import { GlobalStyles } from '@mui/material';
import arrowIconNew from 'assets/images/icons/arrowIconNew.svg';
import filterIcon from 'assets/images/icons/filterIcon.svg';
import { useLocation } from 'react-router';
import { Image } from 'styledComponents/commonStyled';
import { useQuery } from 'utils/useQuery';

const MessagesFilterManager = () => {
  const anchorEl = useDataState(null);
  const channelsFilter = useSelector(useGetMessagesChannelFilter);
  const statusFilter = useSelector(useGetMessagesStatusFilter);
  const messagesProviderChannelFilter = useSelector(useGetMessagesProviderChannelFilter);
  const channelsFilterState = useDataState(channelsFilter);
  const statusFilterState = useDataState(statusFilter);
  const providerChannelFilterState = useDataState(messagesProviderChannelFilter);
  const providerChannelOptions = useSelector(useGetActivityProviderChannelsData);
  const dispatch = useDispatch();
  const pageInitialConfiguration = useQuery();
  const filterBubbleNumber = useGetMessagesFilterBubbleNumber();
  const communityData = useSelector(useGetCurrentCommunity);

  const handleClose = () => {
    anchorEl.set(null);
  };
  const [openAccordians, setOpenAccordians] = useState(['Integrations', 'Status', 'Channels']);

  useEffect(() => {
    if (pageInitialConfiguration?.get?.('filter')) {
      if (
        typeof pageInitialConfiguration?.get?.('tags') === 'string' &&
        pageInitialConfiguration?.get?.('tags')
      ) {
        if (pageInitialConfiguration?.get?.('tags').includes(',')) {
          const tagsArray = pageInitialConfiguration?.get?.('tags').split(',');
          // dispatch(setMessagesStatusFilterAction(tagsArray));
          statusFilterState.set(tagsArray);
          setStatusFilterCallback(tagsArray);
        } else {
          // dispatch(setMessagesStatusFilterAction([pageInitialConfiguration.tags]));
          statusFilterState.set([pageInitialConfiguration.tags]);
          setStatusFilterCallback([pageInitialConfiguration.tags]);
        }
      }
      if (
        typeof pageInitialConfiguration?.get?.('provider') === 'string' &&
        pageInitialConfiguration?.get?.('provider').length
      ) {
        dispatch(setMessagesChannelsFilterAction([pageInitialConfiguration?.get?.('provider')]));
        channelsFilterState.set([pageInitialConfiguration?.get?.('provider')]);
        setChannelFilterCallback([pageInitialConfiguration?.get?.('provider')]);
      }
      if (
        typeof pageInitialConfiguration?.get?.('extchannelid') === 'string' &&
        pageInitialConfiguration?.get?.('extchannelid').length
      ) {
        providerChannelOptions &&
          providerChannelOptions.map((channel) => {
            if (channel.externalChannelId === pageInitialConfiguration?.get?.('extchannelid')) {
              dispatch(setMessagesProviderChannelsFilterAction([channel.id]));
              providerChannelFilterState.set([channel.id]);
              setProviderFilterCallback([channel.id]);
            }
          });
      }
    } else {
      handleResetFilter();
    }
    return () => {
      if (pageInitialConfiguration?.get?.('filter')) {
        handleResetFilter();
      }
    };
  }, [pageInitialConfiguration, providerChannelOptions]);

  const handleResetFilter = () => {
    dispatch(setMessagesChannelsFilterAction(['all']));
    channelsFilterState.set(['all']);
    dispatch(setMessagesStatusFilterAction(['all']));
    statusFilterState.set(['all']);
    dispatch(setMessagesProviderChannelsFilterAction([-1]));
    providerChannelFilterState.set([-1]);
    handleClose();
  };

  const setChannelFilterCallback = (filters) => {
    track('SELECTED_FILTER', { integrations: filters });
    let channels = [];
    if (!filters.includes('all')) {
      providerChannelOptions.map((option) => {
        if (
          providerChannelFilterState?.value?.includes(option.id) &&
          filters.includes(option.provider)
        ) {
          channels = [...channels, option.id];
        }
      });
      if (channels?.length) {
        providerChannelFilterState.set(channels);
        dispatch(setMessagesProviderChannelsFilterAction(channels));
      } else {
        dispatch(setMessagesProviderChannelsFilterAction([-1]));
      }
    }
    dispatch(setMessagesChannelsFilterAction(filters));
  };

  const setStatusFilterCallback = (filters) => {
    track('SELECTED_FILTER', { statuses: filters });
    dispatch(setMessagesStatusFilterAction(filters));
  };

  const setProviderFilterCallback = (filters) => {
    track('SELECTED_FILTER', { providerChannels: getProviderNames(filters) });
    dispatch(setMessagesProviderChannelsFilterAction(filters));
  };

  const getProviderNames = (providerChannelFilters) => {
    providerChannelOptions
      .filter((provider) => providerChannelFilters.includes(provider.id))
      .map((provider) => provider.name);
  };

  const toggleAccordian = (selectedFilter) => {
    const selectedAccordian = openAccordians.map((iter) => iter);
    const index = openAccordians.indexOf(selectedFilter);
    if (index > -1) {
      selectedAccordian.splice(index, 1);
      setOpenAccordians(selectedAccordian);
    } else {
      setOpenAccordians([...openAccordians, selectedFilter]);
    }
  };

  const track = (eventName, content) => {
    switch (eventName) {
      case 'SELECTED_FILTER': {
        trackUserEvent('Selected Filter', {
          filter_type: 'Messages Filter',
          filters_name: {
            providerChannels: getProviderNames(providerChannelFilterState?.value),
            statuses: statusFilterState?.value,
            integrations: channelsFilterState?.value,
            ...content,
          },
        });
        return;
      }
    }
  };

  const filteredChannelOptions = channelOptions.filter((option) => {
    return (
      CheckIfFeatureActive(option.feature) &&
      CheckIfIntegrationActive(option.value, communityData?.id)
    );
  });
  return (
    <div>
      <div
        style={{
          borderBottom: '0.5px solid #E6E6E6',
        }}></div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ paddingRight: '7px' }}>
            <Image src={filterIcon} alt="redCross" width={10} height={10} />
          </div>
          <span
            style={{
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '24px',
              color: colorToken.menuSidebar.secondarySidebar.text.inactive.color,
              paddingBottom: '8px',
            }}>
            Filters{' '}
          </span>
        </div>
        {filterBubbleNumber !== 0 ? (
          <span
            role="none"
            onClick={handleResetFilter}
            style={{
              fontWeight: 600,
              fontSize: '10px',
              lineHeight: '24px',
              alignItems: 'flex-end',
              textAlign: 'right',
              textTransform: 'uppercase',
              color: '#696DEC',
              cursor: 'pointer',
            }}>
            Clear All
          </span>
        ) : (
          <></>
        )}
      </div>
      <div>
        {['Integrations', 'Status', 'Channels'].map((item, index) => {
          return (
            <>
              <GlobalStyles
                styles={{
                  '.MuiAccordionSummary-content.Mui-expanded': {
                    margin: '0 !important',
                  },
                }}
              />
              <div
                style={{
                  borderBottom: '0.5px solid #E6E6E6',
                }}></div>
              <AccordionMenu
                key={item}
                defaultExpanded={true}
                expanded={openAccordians.includes(item)}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ paddingLeft: '0px', height: '40px', margin: '0px' }}
                  aria-expanded={openAccordians.includes(item)}>
                  <AccordionMenuContainer
                    selected={true}
                    style={{ marginTop: '0px', marginBottom: '0px' }}>
                    <MenuContainer style={{ marginTop: '0px' }}>
                      <FilterMenuContainer style={{ marginTop: '0px' }}>
                        <FilterMenuItem
                          style={{
                            borderRight: 'none',
                            marginTop: '0px',
                            marginBottom: '0px',
                            marginLeft: '0',
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            paddingLeft: '0',
                          }}
                          is_selected={openAccordians.includes(item).toString()}
                          is_sidebar_menu="true"
                          onClick={() => toggleAccordian(item)}
                          disableRipple>
                          <div style={{ display: 'flex', marginLeft: '10px' }}>
                            <FilterArrowDropDownIconImage
                              selected={openAccordians.includes(item)}
                              src={arrowIconNew}
                              width={10}
                              height={10}
                              alt="arrow"
                            />
                          </div>
                          &nbsp;&nbsp;&nbsp;
                          {index === 2 &&
                          filteredChannelOptions?.length === 1 &&
                          filteredChannelOptions?.[0]?.value === 'github'
                            ? 'Repositories'
                            : item}
                        </FilterMenuItem>
                      </FilterMenuContainer>
                    </MenuContainer>
                  </AccordionMenuContainer>
                </AccordionSummary>
                <AccordionSubMenuContainer
                  style={{
                    marginLeft: '0px',
                    marginTop: '6px',
                    paddingLeft: '10px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingRight: '0px',
                    overflowX: 'scroll',
                  }}>
                  <div style={{ display: item === 'Integrations' ? 'block' : 'none' }}>
                    <IntegrationOptions
                      channelsFilter={channelsFilterState?.value || []}
                      setChannelsFilter={channelsFilterState.set}
                      setChannelFilterCallback={(filters) => setChannelFilterCallback(filters)}
                    />
                  </div>
                  <div style={{ display: item === 'Status' ? 'block' : 'none' }}>
                    <StatusOptions
                      statusFilter={statusFilterState?.value || []}
                      setStatusFilter={statusFilterState.set}
                      setStatusFilterCallback={(filters) => setStatusFilterCallback(filters)}
                    />
                  </div>
                  <div style={{ display: item === 'Channels' ? 'block' : 'none' }}>
                    <ProviderChannelOptions
                      channelOptions={providerChannelOptions}
                      channelFilter={providerChannelFilterState?.value || []}
                      setChannelFilter={providerChannelFilterState.set}
                      setProviderFilterCallback={(filters) => setProviderFilterCallback(filters)}
                      providerFilter={channelsFilterState?.value || []}
                      filteredChannelOptions={filteredChannelOptions}
                    />
                  </div>
                </AccordionSubMenuContainer>
              </AccordionMenu>
            </>
          );
        })}
      </div>
    </div>
  );
};
export default MessagesFilterManager;
