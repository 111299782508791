import {
  GET_OUTREACH_REPORT_BY_ID,
  GET_OUTREACH_REPORT_MEMBER_DATA,
  HANDLE_OUTREACH_REPORT_BY_ID_ERROR,
  HANDLE_OUTREACH_REPORT_MEMBER_DATA_ERROR,
  SET_OUTREACH_REPORT_BY_ID_LOADING,
  SET_OUTREACH_REPORT_MEMBER_DATA_LOADING,
} from '../types';

export const initialState = {
  outreachReportById: null,
  outreachReportByIdLoading: false,
  errorOutreachReportById: false,
  outreachReportMemberData: null,
  outreachReportMemberDataLoading: false,
  errorOutreachReportMemberData: false,
};

const outreachReportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OUTREACH_REPORT_BY_ID:
      return {
        ...state,
        outreachReportById: payload,
        outreachReportByIdLoading: false,
        errorOutreachReportById: false,
      };
    case SET_OUTREACH_REPORT_BY_ID_LOADING:
      return {
        ...state,
        outreachReportByIdLoading: true,
        errorOutreachReportById: false,
      };
    case HANDLE_OUTREACH_REPORT_BY_ID_ERROR:
      return {
        ...state,
        outreachReportByIdLoading: false,
        errorOutreachReportById: true,
      };
    case GET_OUTREACH_REPORT_MEMBER_DATA:
      return {
        ...state,
        outreachReportMemberData: payload,
        outreachReportMemberDataLoading: false,
        errorOutreachReportMemberData: false,
      };
    case SET_OUTREACH_REPORT_MEMBER_DATA_LOADING:
      return {
        ...state,
        outreachReportMemberDataLoading: true,
        errorOutreachReportMemberData: false,
      };
    case HANDLE_OUTREACH_REPORT_MEMBER_DATA_ERROR:
      return {
        ...state,
        outreachReportMemberDataLoading: false,
        errorOutreachReportMemberData: true,
      };
    default:
      return state;
  }
};

export default outreachReportReducer;
