export const GET_CATEGORY_COUNT = 'GET_CATEGORY_COUNT';
export const GET_CATEGORY_COUNT_PENDING = 'GET_CATEGORY_COUNT_PENDING';
export const GET_CATEGORY_COUNT_FAILED = 'GET_CATEGORY_COUNT_FAILED';
export const GET_CATEGORY_COUNT_LOADING = 'GET_CATEGORY_COUNT_LOADING';

export const GET_CONVERSATION = 'GET_CONVERSATION';
export const GET_CONVERSATION_LOADING = 'GET_CONVERSATION_LOADING';
export const GET_CONVERSATION_FAILED = 'GET_CONVERSATION_FAILED';

export const GET_CONVERSATION_BY_TICKET_ID = 'GET_CONVERSATION_BY_TICKET_ID';
export const GET_CONVERSATION_BY_TICKET_ID_FAILED = 'GET_CONVERSATION_BY_TICKET_ID_FAILED';
export const GET_CONVERSATION_BY_TICKET_ID_LOADING = 'GET_CONVERSATION_BY_TICKET_ID_LOADING';

export const CURRENT_TICKET_STATUS = 'CURRENT_TICKET_STATUS';
