import { AnyAction } from 'redux';
import {
  GET_CATEGORY_COUNT,
  GET_CATEGORY_COUNT_FAILED,
  GET_CATEGORY_COUNT_LOADING,
  GET_CONVERSATION,
  GET_CONVERSATION_LOADING,
  GET_CONVERSATION_FAILED,
  GET_CONVERSATION_BY_TICKET_ID,
  GET_CONVERSATION_BY_TICKET_ID_FAILED,
  GET_CONVERSATION_BY_TICKET_ID_LOADING,
} from 'redux/conversationFeedProvider/types.js';

const initialState = {
  initalCategoryCountsByCommunityId: null,
  initalCategoryCountsByCommunityIdLoading: false,
  initalCategoryCountsByCommunityIdFailed: false,
  fetchedConversations: [],
  fetchConversationFailed: false,
  fetchConversationLoading: false,
  ticketConversation: null,
  ticketConversationLoading: false,
  ticketConversationFailed: false,
};

const conversationFeedReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORY_COUNT:
      return {
        ...state,
        initalCategoryCountsByCommunityId: payload,
        initalCategoryCountsByCommunityIdLoading: false,
        initalCategoryCountsByCommunityIdFailed: false,
      };
    case GET_CATEGORY_COUNT_LOADING:
      return {
        ...state,
        initalCategoryCountsByCommunityId: [],
        initalCategoryCountsByCommunityIdLoading: true,
        initalCategoryCountsByCommunityIdFailed: false,
      };
    case GET_CATEGORY_COUNT_FAILED:
      return {
        ...state,
        initalCategoryCountsByCommunityId: [],
        initalCategoryCountsByCommunityIdLoading: false,
        initalCategoryCountsByCommunityIdFailed: true,
      };
    case GET_CONVERSATION:
      return {
        ...state,
        fetchedConversations: payload,
        fetchConversationLoading: false,
        fetchConversationFailed: false,
      };
    case GET_CONVERSATION_LOADING:
      return {
        ...state,
        fetchedConversations: [],
        fetchConversationLoading: true,
        fetchConversationFailed: false,
      };
    case GET_CONVERSATION_FAILED:
      return {
        ...state,
        fetchedConversations: [],
        fetchConversationLoading: false,
        fetchConversationFailed: true,
      };

    case GET_CONVERSATION_BY_TICKET_ID:
      return {
        ...state,
        ticketConversation: payload,
        ticketConversationLoading: false,
        ticketConversationFailed: false,
      };
    case GET_CONVERSATION_BY_TICKET_ID_LOADING:
      return {
        ...state,
        ticketConversation: null,
        ticketConversationLoading: true,
        ticketConversationFailed: false,
      };
    case GET_CONVERSATION_BY_TICKET_ID_FAILED:
      return {
        ...state,
        ticketConversation: null,
        ticketConversationLoading: false,
        ticketConversationFailed: true,
      };
    default:
      return state;
  }
};

export default conversationFeedReducer;
