import React from 'react';
import { useDispatch } from 'react-redux';
import { Image, PrimaryButton } from 'styledComponents/commonStyled';
import { colorToken } from '../../theme/designToken';
import { setUpgradeNowEventSourceAction } from '../../redux/paymentsProvider/actions/paymentsActions';
import { trackUserEvent } from '../../utils/segment';
import {
  BannerContainerFreePlan,
  TextFreePlanBanner,
  VerticalDividerFreePlan,
} from 'styledComponents/launchBannerStyled';
import { useHistory } from 'react-router';
import lockIconPurple from 'assets/images/icons/lockIconPurple.svg';
import { workspaceValueRoleMapper } from 'constant/staffRoleData';

const LaunchBannerFreePlan = ({ communityRole, showDemoButton }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div>
      <BannerContainerFreePlan>
        {communityRole === workspaceValueRoleMapper.ORGANIZATION_OWNER ? (
          <TextFreePlanBanner style={{ margin: '0 10px' }}>
            You are on <span style={{ fontWeight: 700 }}>FREE</span> plan
          </TextFreePlanBanner>
        ) : (
          <TextFreePlanBanner style={{ margin: '0 10px' }}>
            You are on <span style={{ fontWeight: 700 }}>FREE</span> plan.
          </TextFreePlanBanner>
        )}

        {/* {communityRole === 'OWNER' && ( */}
        <>
          <VerticalDividerFreePlan />

          <PrimaryButton
            style={{
              height: '24px',
              backgroundColor: 'transparent',
              color: colorToken.colors.purpleText,
              fontSize: '11px',
            }}
            onClick={() => {
              // setOpenPaymentsModal(true);
              history.push('/settings/billing');
              dispatch(setUpgradeNowEventSourceAction('Top bar'));
              trackUserEvent('Clicked Upgrade now', { source: 'Top bar' });
            }}>
            <Image src={lockIconPurple} alt="lock" height={10} width={10} /> Upgrade Now
          </PrimaryButton>
          <VerticalDividerFreePlan />
          {showDemoButton && (
            <a
              href="https://meetings-eu1.hubspot.com/threado/demo"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackUserEvent('Clicked schedule demo', {})}>
              <PrimaryButton
                style={{
                  height: '24px',
                  backgroundColor: 'transparent',
                  color: colorToken.colors.purpleText,
                  fontSize: '11px',
                }}>
                Schedule demo
              </PrimaryButton>
            </a>
          )}
        </>
        {/* )} */}
      </BannerContainerFreePlan>
    </div>
  );
};

export default LaunchBannerFreePlan;
