import { styled } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import React from 'react';

export const UseCommunityMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    marginTop: theme.spacing(1),
    minWidth: 280,
    // left: '59px !important',
    border: 'none !important',
    paddingTop: '0px !important',
    left: '0px !important',
    bottom: '70px !important',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    boxShadow: '0px 41px 40px rgba(0, 61, 107, 0.25)',
    '& .MuiMenu-list': {
      paddingTop: '0px',
    },
  },
}));
