import {
    GET_REPORT_RESPONSIVENESS,
    GET_REPORT_RESPONSIVENESS_ERROR,
    GET_REPORT_RESPONSIVENESS_LOADING,
    SET_ENGAGEMENT_INSIGHT_LIST,
    SET_RESPONSIVENESS_INSIGHT_STATIC_DATA,
    SET_RESPONSIVENESS_INSIGHT_STATIC_DATA_LOADING
} from '../types';

const initialState = {
    engagementInsightList: [],
    engagementInsightListDetails: {},
    chartLoading: false,
    chartLoadingError: false,
    chartDataSentiment: null,
    responsivenessInsightData: null,
    responsivenessInsightDataLoading: false
};

const engagementInsightReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_ENGAGEMENT_INSIGHT_LIST:
            return {
                ...state,
                engagementInsightList: payload.data,
                engagementInsightListDetails: payload.details,
            };
        case GET_REPORT_RESPONSIVENESS: {
            return {
                ...state,
                chartDataSentiment: payload,
                chartLoading: false,
                chartLoadingError: false,
            };
        }
        case GET_REPORT_RESPONSIVENESS_LOADING: {
            return {
                ...state,
                chartDataSentiment: null,
                chartLoading: true,
                chartLoadingError: false,
            };
        }
        case GET_REPORT_RESPONSIVENESS_ERROR: {
            return {
                ...state,
                chartDataSentiment: null,
                chartLoading: false,
                chartLoadingError: true,
            };
        }

        case SET_RESPONSIVENESS_INSIGHT_STATIC_DATA: {
            return {
                ...state,
                responsivenessInsightData: payload,
                responsivenessInsightDataLoading: false,
            };
        }
        case SET_RESPONSIVENESS_INSIGHT_STATIC_DATA_LOADING: {
            return {
                ...state,
                responsivenessInsightDataLoading: payload,
            };
        }

        default:
            return state;
    }
};

export default engagementInsightReducer;
