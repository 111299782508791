import React from 'react';
import { useAuth } from 'context/auth/AuthContext';
import { useEffect } from 'react';
import { getSegmentTitle, getPageTitle } from 'utils/segment';

// Segment integration imports
import GlobalLoader from 'commonComponents/Loader/Global/GlobalLoader';
import { useHistory } from 'react-router';
import { useQuery } from 'utils/useQuery';

const PASSWORD_RESET_URL = '/accounts/password/reset';
const LOGIN_PAGE_URL = '/login';
const DEFAULT_URL = '/';
const SIGN_UP_PAGE_URL = '/signup';
const HOME_PAGE_URL = '/activity/summary';
const EVENTS_JOIN_URL = '/events/join';
const ERRORS_URL = '/errors';
const UNSUBSCRIBE_URL = '/email/unsubscribe';

const unprotectedRoutes = [
  LOGIN_PAGE_URL,
  SIGN_UP_PAGE_URL,
  PASSWORD_RESET_URL,
  EVENTS_JOIN_URL,
  UNSUBSCRIBE_URL,
];

const isBrowser = () => typeof window !== 'undefined';

const ProtectedRoute = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();

  if (!isBrowser()) {
    return <div />;
  }

  if (isLoading) {
    return <GlobalLoader isDark={false} />;
  }
  if (window.location.pathname.includes('/community-roundup22')) {
    return children;
  }

  if (window.location.pathname.includes(ERRORS_URL)) {
    return <div style={{ textAlign: 'center' }}>Something wrong! please try again.</div>;
  }

  if (
    isAuthenticated &&
    (window.location.pathname === '/login' ||
      window.location.pathname === DEFAULT_URL ||
      window.location.pathname === '/accounts/login' ||
      window.location.pathname === '/signup')
  ) {
    window.location.replace(HOME_PAGE_URL + window.location.search);
    return null;
  }

  if (!isAuthenticated && !unprotectedRoutes.find((r) => window.location.pathname.includes(r))) {
    if (window.location.pathname.includes('password/reset')) {
      window.location.replace(PASSWORD_RESET_URL);
    }
    if (window.location.pathname.includes('/signup')) {
      window.location.replace(SIGN_UP_PAGE_URL);
    } else {
      window.location.replace(LOGIN_PAGE_URL);
    }
    return null;
  }

  if (isAuthenticated || unprotectedRoutes.find((r) => window.location.pathname.startsWith(r))) {
    return children;
  }

  return null;
};

export default ProtectedRoute;
